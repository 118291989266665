<template>
  <AppTooltip
    placement="top"
    v-bind="$attrs"
    :content="content"
    :disabled="!showTooltip"
    @mouseenter.native="checkVisibility"
  >
    <span ref="activator" class="truncate"> {{ content }}</span>
  </AppTooltip>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppTruncatedTooltip',
})
export default class AppTruncatedTooltip extends Vue {
  @Prop({ default: '' })
  content: string;

  showTooltip = false;

  $refs: {
    activator: HTMLDivElement;
  };

  checkVisibility() {
    if (this.$refs.activator) {
      this.showTooltip =
        this.$refs.activator.scrollWidth > this.$refs.activator.clientWidth;
    }
  }
}
</script>
