<template>
  <div class="wrap relative z-notification">
    <Confirm v-for="item in list.list" :key="item.id" v-bind="item" />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import Confirm from './Confirm.vue';

import { list } from './index';

@Component({
  name: 'AppConfirmation',
  components: { Confirm },
})
export default class AppConfirmation extends Vue {
  list = list;
}
</script>
