import appConfig from '@/core/config/appConfig';
import { Validation } from '@jigsaw/vuelidate-types';
import i18n from '@/core/plugins/vue-i18n';

// Custom rules
const requireDigit = (val: string) => /\d/.test(val);
const requireLowercase = (val: string) => /^(?=.*[a-z])/.test(val);
const requireNonAlphanumeric = (val: string) => /[^a-zA-Z\d\s:]/.test(val);
const requireUppercase = (val: string) => /^(?=.*[A-Z])/.test(val);

// Password rules definitions
export const validationRulesDefinitions = {
  minLength: 'PUBLIC_PASSWORD_MUST_BE_AT_LEAST_X_CHARACTERS', // requires a parameter
  requireDigit: 'PUBLIC_REQUIRE_DIGIT',
  requireLowercase: 'PUBLIC_REQUIRE_LOWERCASE',
  requireNonAlphanumeric: 'PUBLIC_REQUIRE_SPECIAL_CHARACTER',
  requireUppercase: 'PUBLIC_REQUIRE_UPPERCASE',
  sameAs: 'PUBLIC_PASSWORDS_MUST_MATCH',
  notOldPassword: 'PASSWORD_MUST_BE_NEW',
};

export function getValidationErrorList(validationField: Validation): string[] {
  const errors: string[] = [];
  for (const key in validationField) {
    const value = validationRulesDefinitions[key];
    if (!validationField[key] && value) {
      switch (validationField.$params[key]?.type) {
        case 'minLength':
          errors.push(i18n.t(value, [validationField.$params[key].min]));
          break;
        default:
          errors.push(i18n.t(value));
          break;
      }
    }
  }
  return errors;
}

/**
 *
 * @param passwordConfig appConfig.passwordConfig
 * @returns object with rules
 */
export const getPasswordValidationRules = (
  passwordConfig = appConfig.passwordConfig
) => ({
  ...(passwordConfig.requireDigit && { requireDigit }),
  ...(passwordConfig.requireLowercase && { requireLowercase }),
  ...(passwordConfig.requireNonAlphanumeric && { requireNonAlphanumeric }),
  ...(passwordConfig.requireUppercase && { requireUppercase }),
});
