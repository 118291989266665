<template>
  <span
    :aria-disabled="disabled"
    class="inline-flex cursor-pointer items-center aria-disabled:cursor-not-allowed"
    @click="$emit('click', $event)"
  >
    <input
      type="checkbox"
      class="app-switch relative h-14 w-24 min-w-24 cursor-[inherit] appearance-none rounded-full border border-main-dark-20 bg-main-dark-20 text-24 outline-none transition"
      :class="switchClasses"
      :id="_uid"
      :checked="checked"
      :disabled="disabled"
      @input.prevent="inputHandler"
    />

    <label :for="_uid" class="mb-0 ml-8 cursor-[inherit] text-14">
      <slot />
    </label>
  </span>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
  name: 'AppSwitch',
})
class AppSwitch extends Vue {
  @Prop({ default: null })
  value: string | number;

  @Model('change', { type: [Boolean, Array] })
  checked!: boolean | Array<string | number>;

  @Prop({ default: false, type: Boolean })
  alwaysColored: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: 'default' })
  variant: TSwitchVariants;

  private _uid: string;

  get switchClasses() {
    const checkedIconClass = this.checked ? 'is-checked' : '';
    const alwaysColoredClass = this.alwaysColored ? 'is-colored' : '';

    if (this.disabled) {
      return [
        checkedIconClass,
        alwaysColoredClass,
        'bg-main-dark-40 bg-opacity-60',
      ];
    }

    if (this.alwaysColored) {
      return [
        checkedIconClass,
        alwaysColoredClass,
        TSwitchVariantClasses[this.variant],
      ];
    }

    return [
      checkedIconClass,
      alwaysColoredClass,
      { [`${TSwitchVariantClasses[this.variant]}`]: this.checked },
    ];
  }

  inputHandler() {
    if (this.disabled) {
      return;
    }

    this.$emit('change', !this.checked);
  }
}
export default AppSwitch;

export type TSwitchVariants = 'default' | 'purple' | 'cyan';

const TSwitchVariantClasses = {
  default:
    'checked:border-accent-purple checked:bg-accent-purple focus:border-accent-purple',
  white:
    'checked:border-main-dark-10 checked:bg-main-dark-10 focus:border-main-dark-10',
  cyan: 'checked:border-accent-cyan checked:bg-accent-cyan focus:border-accent-cyan',
};
</script>

<style lang="scss" scoped>
.app-switch {
  &::before {
    content: '';
    @apply absolute inset-1 h-10 w-10 transform rounded-full transition;
  }
  &:is(.is-colored)::before {
    @apply bg-white;
  }
  &::before {
    @apply bg-white;
  }
  &.is-checked::before {
    @apply translate-x-10 bg-white;
  }
}
</style>
