import { Features } from '@/core/common/Features';
import { HAS_LICENSE_FEATURE } from '@/core/services/store/licence.module';
import Vue from 'vue';

export default class FeaturesService {
  public static hasFeature(feature: Features): boolean {
    return Vue.$globalStore.getters[HAS_LICENSE_FEATURE]({
      featureName: feature,
    });
  }
}
