export enum ToggleStateType {
  OFF,
  ON,
  NO_CHANGE,
}

export class ToggleState {
  toggleState: ToggleStateType = null;

  constructor(toggleAction: ToggleStateType) {
    this.toggleState = toggleAction;
  }

  public static fromToggleStateType(
    toggleAction: ToggleStateType
  ): boolean | null {
    switch (toggleAction) {
      case ToggleStateType.ON:
        return true;
      case ToggleStateType.OFF:
        return false;
      case ToggleStateType.NO_CHANGE:
        return null;
    }
  }

  public static toToggleStateType(value: boolean | null): ToggleStateType {
    if (value === null) {
      return ToggleStateType.NO_CHANGE;
    }
    return value ? ToggleStateType.ON : ToggleStateType.OFF;
  }
}
