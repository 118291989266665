<template>
  <div
    class="flex h-full flex-col justify-between bg-palatinate-blue pb-16 pt-36 transition-width ease-in-out z-1"
    :class="{
      'w-240 min-w-240': !compactMenu,
      'fixed z-20 w-72 hover:w-240': compactMenu,
      'relative': (isHalloween || isChristmas || isThanksgiving) && !compactMenu,
    }"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <div
      v-if="!loading && isChristmas"
      class="absolute -right-32 bottom-0 top-0 h-full w-56 overflow-hidden"
    >
      <lottie-player
        src="/christmas-media/string-lights.json"
        background="transparent"
        speed="1"
        class="absolute -top-56 right-28 -rotate-90 transform"
        style="width: 1671px; height: 56px; transform-origin: right center"
        loop
        autoplay
      ></lottie-player>
    </div>

    <SidebarSkeletonLoading v-if="loading" />

    <SidebarLeavesfall v-if="!loading && isHalloween" />
    <SidebarLeavesfall v-if="!loading && isThanksgiving" :leaves="4" />

    <div
      v-show="!loading"
      class="flex w-full flex-grow flex-col justify-between"
    >
      <div class="w-full">
        <!-- Logo -->
        <div class="mb-80 block w-full px-18">
          <router-link
            :to="{ name: 'landing' }"
            class="fixed max-w-160"
            :class="{ 'w-full': isFullLogo, 'w-fit': !isFullLogo }"
          >
            <JigsawLogo
              data-automation-id="jigsaw-logo"
              :full-logo="isFullLogo"
              :is-halloween="isHalloween"
              :is-christmas="isChristmas"
            />
          </router-link>
        </div>
        <!-- Logo -->

        <!-- Navigation -->
        <div class="mt-24 w-full">
          <router-link
            v-for="(item, index) in navigationRoutesList"
            :key="index"
            :to="{ name: item.routeName }"
            v-slot="{ isExactActive, navigate }"
            custom
          >
            <div
              class="sidebar-item h-48 tall:h-60"
              :class="{
                'bg-accent-purple': isExactActive,
              }"
              :data-automation-id="item.automationId"
              @click="navigate"
            >
              <div class="flex h-24 w-24 min-w-24 items-center justify-center">
                <AppIcon
                  v-if="item.icon"
                  :name="item.icon"
                  class="text-white"
                />
                <AppImage
                  v-else-if="item.image"
                  :src="item.image"
                  class="text-white"
                />
                <div
                  v-if="
                    compactMenu &&
                    !menuExtended &&
                    (item.countDanger || item.count)
                  "
                  class="absolute z-10 -mt-24 ml-24 flex h-12 min-w-12 items-center justify-center rounded-8 p-4 text-8"
                  :class="{
                    'rounded-8': item.countDanger >= 10 || item.count >= 10,
                    'rounded-full': item.countDanger < 10 || item.count < 10,
                    'bg-pinkinity text-white': item.countDanger,
                    'bg-white text-accent-purple': item.count,
                  }"
                >
                  <span v-if="item.countDanger" class="leading-none">
                    {{ item.countDanger }}
                  </span>
                  <span v-else-if="item.count" class="leading-none">
                    {{ item.count }}
                  </span>
                </div>
              </div>
              <div class="flex w-full items-center truncate">
                <p class="truncate pl-16 text-15 leading-none text-white">
                  {{ item.label }}
                </p>

                <p
                  v-if="item.countDanger"
                  class="ml-8 flex h-24 items-center justify-center rounded-8 bg-pinkinity px-8 pt-1 text-14 leading-none text-white"
                >
                  {{ item.countDanger }}
                </p>
                <p
                  v-else-if="item.count"
                  class="ml-8 text-14 leading-none text-white opacity-40"
                >
                  ({{ item.count }})
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <ImportFromPowerPoint v-if="canImportFromPowerPoint" />

      <div v-if="isAdministrationEnabled">
        <div class="sidebar-item h-48 tall:h-60" @click="toAdministration()">
          <div class="flex h-24 w-24 min-w-24 items-center justify-center">
            <AppIcon name="settings" class="text-white" />
          </div>
          <div class="flex w-full items-center truncate">
            <p class="truncate pl-16 text-15 leading-none text-white">
              {{ $t('Administration') }}
            </p>
          </div>
        </div>
      </div>

      <div class="mt-24 w-full">
        <!-- Steps Designer -->
        <router-link
          v-if="isStepsDesignEnabled"
          :to="{
            name: $routeNames.stepsDesign,
          }"
          v-slot="{ isExactActive, navigate }"
          custom
        >
          <div
            class="sidebar-item h-48 tall:h-60"
            :class="{
              'bg-accent-purple': isExactActive,
            }"
            @click="navigate"
          >
            <div class="flex h-24 w-24 min-w-24 items-center justify-center">
              <AppIcon name="steps-designer" class="text-white" />
            </div>
            <div class="flex w-full items-center truncate">
              <p class="truncate pl-16 text-15 leading-none text-white">
                {{ $t('STEPS_DESIGN') }}
              </p>
            </div>
          </div>
        </router-link>
        <!-- Themes -->
        <router-link
          v-if="
            !isStarterView && hasPermission(ApplicationPermissions.ThemesRead)
          "
          :to="themePageRoute"
          v-slot="{ isExactActive, navigate }"
          custom
        >
          <div
            class="sidebar-item h-48 tall:h-60"
            :class="{
              'bg-accent-purple': isExactActive,
            }"
            data-automation-id="sidebar-themes"
            @click="navigate"
          >
            <div class="flex h-24 w-24 min-w-24 items-center justify-center">
              <AppIcon name="themes" class="text-white" />
            </div>
            <div class="flex w-full items-center truncate">
              <p class="truncate pl-16 text-15 leading-none text-white">
                {{ $t('THEMES') }}
              </p>
            </div>
          </div>
        </router-link>
        <!-- Import Definitions -->
        <router-link
          v-if="canManageImportDefinitions"
          :to="importDefinitionsManager"
          v-slot="{ isExactActive, navigate }"
          custom
        >
          <div
            class="sidebar-item h-60"
            :class="{
              'bg-accent-purple': isExactActive,
            }"
            data-automation-id="sidebar-import-definitions"
            @click="navigate"
          >
            <div class="flex h-24 w-24 min-w-24 items-center justify-center">
              <AppIcon name="folder-new" class="text-white" />
            </div>
            <div class="flex w-full items-center truncate">
              <p class="truncate pl-16 text-15 leading-none text-white">
                {{ $t('IMPORT_DEFINITIONS') }}
              </p>
            </div>
          </div>
        </router-link>
        <!-- Data Properties -->
        <router-link
          v-if="
            hasPermission(ApplicationPermissions.EntityType) &&
            hasFeature(featureType.DataFull)
          "
          :to="{ name: $routeNames.dataProperties }"
          v-slot="{ isExactActive, navigate }"
          custom
        >
          <div
            class="sidebar-item h-60"
            :class="{
              'bg-accent-purple': isExactActive,
            }"
            @click="navigate"
          >
            <div class="flex h-24 w-24 min-w-24 items-center justify-center">
              <AppIcon name="data-properties" class="text-white" />
            </div>
            <div class="flex w-full items-center truncate">
              <p class="truncate pl-16 text-15 leading-none text-white">
                {{ $t('DATA_PROPERTIES') }}
              </p>
            </div>
          </div>
        </router-link>
        <!-- Feedback -->
        <a
          :href="feedbackEmailLink"
          data-automation-id="sidebar-send-feedback"
          class="sidebar-item h-48 tall:h-60"
        >
          <div class="flex h-24 w-24 min-w-24 items-center justify-center">
            <AppIcon name="feedback" size="24" class="text-white" />
          </div>
          <div class="flex w-full items-center truncate">
            <p class="truncate pl-16 text-15 leading-none text-white">
              {{ $t('FEEDBACK') }}
            </p>
          </div>
        </a>
      </div>
      <!-- Navigation -->
    </div>

    <!-- Profile -->
    <SidebarProfileDropdown v-show="!loading" />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter, Mutation } from 'vuex-class';

import PermissionMixin from '@/mixins/Permission.mixin';
import {
  ClientHostSettingsDto,
  CurrentUserProfileEditDto,
  LicenseTypes,
} from '@/api/models';
import { GET_CURRENT_SETTINGS } from '@/core/services/store/settings.module';
import { GET_IS_DOCUMENTMANAGER } from '@/core/services/store/user.module';
import {
  GET_IS_COMPACT_MENU,
  SET_IS_MENU_EXTENDED,
  GET_IS_MENU_EXTENDED,
  GLOBAL_NAMESPACE,
  GET_HALLOWEEN_THEME,
  GET_THANKSGIVING_THEME,
  GET_CHRISTMAS_THEME,
} from '@/core/services/store/global.module';

import SidebarLeavesfall from './components/SidebarLeavesfall.vue';
import SidebarProfileDropdown from './components/SidebarProfileDropdown.vue';
import { GET_CURRENT_USER } from '@/core/services/store/user.module';
import { GET_IS_STEP_DESIGNER } from '@/core/services/store/user.module';
import { GET_LICENSE_TYPE } from '@/core/services/store/licence.module';
import SidebarSkeletonLoading from './components/SidebarSkeletonLoading.vue';
import SpinnerKeys from '@/core/common/SpinnerKeys';
import { loadersList } from '../AppLoadingOverlay';
import appConfig from '@/core/config/appConfig';
import { Features } from '@/core/common/Features';
import JigsawLogo from '../../../assets/svg/JigsawLogo.vue';
import AdministrationUtils from '@/core/utils/AdministrationUtils';
import ImportFromPowerPoint from '@/view/pages/hub/components/imports/ImportFromPowerPoint.vue';

type TNavigationItem = {
  routeName: string;
  label: string;
  icon?: string;
  count?: number;
  countDanger?: number;
  image?: string;
  automationId?: string;
};

@Component({
  name: 'AppSidebar',
  components: {
    ImportFromPowerPoint,
    SidebarProfileDropdown,
    SidebarSkeletonLoading,
    JigsawLogo,
    SidebarLeavesfall,
  },
})
export default class AppSidebar extends mixins(PermissionMixin) {
  @Prop({ default: 0, type: Number })
  pendingRequestsCount: number;

  @Prop({ default: false, type: Boolean })
  isStarterView: boolean;

  @Getter(GET_CURRENT_SETTINGS)
  currentSettings: ClientHostSettingsDto;

  @Getter(GET_CURRENT_USER)
  currentUser: CurrentUserProfileEditDto;

  @Getter(GET_IS_STEP_DESIGNER)
  isStepDesigner: boolean;

  @Getter(GET_IS_DOCUMENTMANAGER)
  isDocumentManager: boolean;

  @Getter(GET_LICENSE_TYPE)
  licenceType: LicenseTypes;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_COMPACT_MENU}`)
  compactMenu: boolean;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_MENU_EXTENDED}`)
  menuExtended: boolean;

  @Mutation(`${GLOBAL_NAMESPACE}/${SET_IS_MENU_EXTENDED}`)
  setIsMenuExtendedMutation: (value: boolean) => void;

  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isHalloween: boolean;

  @Getter(GET_THANKSGIVING_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isThanksgiving: boolean;

  @Getter(GET_CHRISTMAS_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isChristmas: boolean;

  loadingList = loadersList;

  get navigationRoutesNonLicensedList(): TNavigationItem[] {
    return [
      {
        routeName: 'landing',
        label: this.$t('HOME'),
        icon: 'home',
      },
    ];
  }

  get canImportFromPowerPoint(): boolean {
    return (
      this.hasFeature(this.featureType.ImportPowerPoint) &&
      this.hasPermission(this.ApplicationPermissions.ImportCreatePpt)
    );
  }

  get importDefinitionsManager(): Record<string, any> {
    return { name: 'hub-import', query: { page: '1' } };
  }

  get navigationRoutesList(): TNavigationItem[] {
    if (this.isStarterView) {
      return [
        {
          routeName: this.$routeNames.home,
          label: this.$t('HOME'),
          icon: 'home',
        },
      ];
    }

    return this.currentUser.isLicensed ||
      this.licenceType == LicenseTypes.Unrestricted
      ? this.navigationRoutesListLicensed
      : this.navigationRoutesNonLicensedList;
  }

  get isAdministrationEnabled(): boolean {
    return (
      appConfig.administration.isEnabled &&
      this.hasPermission(this.ApplicationPermissions.Administration)
    );
  }

  get isIManageEnabled(): boolean {
    return this.hasFeature(Features.iManage) && appConfig.iManage.isEnabled;
  }

  get navigationRoutesListLicensed(): TNavigationItem[] {
    const routes: any = [
      {
        routeName: this.$routeNames.home,
        label: this.$t('HOME'),
        icon: 'home',
      },
      ...(this.isIManageEnabled
        ? [
            {
              routeName: this.$routeNames.imanage,
              label: this.$t('IMANAGE'),
              image: '/media/imanage/imanage_logo.png',
            },
          ]
        : []),
      ...(this.isDocumentManager
        ? [
            {
              routeName: this.$routeNames.documentManager,
              label: this.$t('DOCUMENT_MANAGER'),
              icon: 'folder-folder',
              automationId: 'sidebar-document-manager',
            },
          ]
        : []),
      {
        routeName: this.$routeNames.accessRequests,
        label: this.$t('REQUESTS'),
        icon: 'requests',
        countDanger: this.pendingRequestsCount,
        automationId: 'sidebar-requests',
      },
      {
        routeName: this.$routeNames.allDocuments,
        label: this.$t('ALL_DOCUMENTS'),
        icon: 'folder-folder',
        automationId: 'sidebar-all-documents',
      },
      {
        routeName: this.$routeNames.favourites,
        label: this.$t('FAVOURITES'),
        icon: 'favorites-outline',
        automationId: 'sidebar-favourites',
      },
      // Temporary disabled
      // {
      //   routeName: 'templates',
      //   label: this.$t('TEMPLATES'),
      //   icon: 'templates',
      //   count: this.folderStats.templates,
      // },
      {
        routeName: this.$routeNames.deletedFiles,
        label: this.$t('DELETED_FILES'),
        icon: 'delete',
        automationId: 'sidebar-deleted-files',
      },
      ...(this.isDocumentProfilingEnabled
        ? [
            {
              routeName: this.$routeNames.documentProfiling,
              label: this.$t('DOCUMENT_PROFILING'),
              icon: 'list-view',
              automationId: 'sidebar-document-profiling',
            },
          ]
        : []),
    ];
    return routes;
  }

  get themePageRoute() {
    return { name: this.$routeNames.themes, query: { page: '1' } };
  }

  get feedbackEmailLink(): string {
    let link = `mailto:${this.currentSettings.feedbackSettings.toEmail}?`;
    if (this.currentSettings.feedbackSettings.ccEmail) {
      link += `cc=${this.currentSettings.feedbackSettings.ccEmail}&`;
    }
    if (this.currentSettings.feedbackSettings.bccEmail) {
      link += `bcc=${this.currentSettings.feedbackSettings.bccEmail}`;
    }
    return link;
  }
  get isDocumentProfilingEnabled(): boolean {
    return (
      appConfig.documentProfiling.isEnabled &&
      this.hasPermission(this.ApplicationPermissions.DocumentsManager)
    );
  }

  get isStepsDesignEnabled(): boolean {
    return (
      this.isStepDesigner &&
      this.hasFeature(this.featureType.Steps) &&
      this.hasFeature(this.featureType.StepsDesign)
    );
  }
  get canManageImportDefinitions(): boolean {
    return (
      (this.hasFeature(this.featureType.Import) ||
        this.hasFeature(this.featureType.ImportExcel)) &&
      this.hasPermission(this.ApplicationPermissions.ImportDefinitionsRead)
    );
  }

  get loading(): boolean {
    return this.loadingList.includes(SpinnerKeys.SidebarSkeleton);
  }

  get isFullLogo(): boolean {
    return !this.compactMenu || (this.compactMenu && this.menuExtended);
  }

  handleMouseOver(): void {
    if (this.compactMenu) {
      this.setIsMenuExtendedMutation(true);
    }
  }

  handleMouseLeave(): void {
    if (this.compactMenu && this.menuExtended) {
      this.setIsMenuExtendedMutation(false);
    }
  }

  toAdministration(): void {
    AdministrationUtils.redirect();
  }
}
</script>

<style lang="scss">
.sidebar-item {
  @apply flex w-full cursor-pointer items-center px-24 transition duration-200 hover:bg-accent-purple;
}
</style>
