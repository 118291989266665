<template>
  <div
    class="animate-skeleton-pulse w-full h-full flex flex-col justify-between bg-palatinate-blue"
  >
    <div class="w-full px-24 mb-24">
      <div class="w-full max-w-160 block">
        <img
          v-if="!isHalloween"
          src="/resources/icons/dash/full_logo.svg"
          alt="Jigsaw Logo"
        />
        <img
          v-else
          src="/resources/icons/full_logo_halloween.svg"
          alt="Jigsaw Logo"
        />
        <!-- <img src="/resources/icons/dash/full_logo.svg" alt="Jigsaw Logo" /> -->
      </div>
    </div>

    <div class="h-full px-24">
      <div
        v-for="i in 6"
        :key="`top-${i}`"
        class="h-60 w-full flex items-center"
      >
        <div class="flex-shrink-0 h-24 w-24 mr-16 bg-main-dark-20" />
        <div class="h-15 w-full bg-main-dark-20" />
      </div>
    </div>

    <div class="h-full px-24 flex flex-col justify-end">
      <div
        v-for="i in 4"
        :key="`bottom-${i}`"
        class="h-60 w-full flex items-center"
      >
        <div class="flex-shrink-0 h-24 w-24 mr-16 bg-main-dark-20" />
        <div class="h-15 w-full bg-main-dark-20" />
      </div>
      <div class="h-60 w-full flex items-center mb-24">
        <div
          class="flex-shrink-0 h-32 w-32 mr-8 bg-main-dark-20 rounded-full"
        />
        <div class="h-15 w-full bg-main-dark-20" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  GET_HALLOWEEN_THEME,
  GLOBAL_NAMESPACE,
} from '@/core/services/store/global.module';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'SidebarSkeletonLoading',
})
export default class SidebarSkeletonLoading extends Vue {
  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isHalloween: boolean;
}
</script>
