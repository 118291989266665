import {
  DefaultNodePlacer,
  GenericLayoutData,
  GraphComponent,
  GroupCompactionPolicy,
  HierarchicLayout,
  HierarchicLayoutData,
  HierarchicLayoutLayeringStrategy,
  HierarchicLayoutSubcomponentDescriptor,
  HierarchicLayoutSubcomponentPlacementPolicy,
  IEdge,
  IGraph,
  INode,
  LayoutGraph,
  LayoutGraphAdapter,
  LayoutGraphHider,
  LayoutGraphUtilities,
  LayoutGroupingSupport,
  LayoutKeys,
  LayoutOrientation,
  LayoutStageBase,
  List,
  SimplexNodePlacer,
  TemporaryGroupDescriptor,
  TemporaryGroupNodeInsertionData,
  TemporaryGroupNodeInsertionStage,
  TreeLayout,
  TreeLayoutData,
  YDimension,
  YNode,
} from 'yfiles';
import { NodeInfo, SymmetryCorrectionStage } from './SymmetryCorrectionStage';
import { BuildDirection } from '../../BuildDirection';

export type LayoutOptions = {
  minimumLayerDistance: number;
  minimumNodeDistance: number;
};

function prepTemporaryGroup(
  component: INode[],
  leftMap: Map<INode, INode[]>,
  rightMap: Map<INode, INode[]>,
  data: TemporaryGroupNodeInsertionData
): void {
  if (component.length > 1) {
    const completeMembers = new List<INode>();
    for (const member of component) {
      completeMembers.add(member);

      const leftChildren = leftMap.get(member);
      if (leftChildren) {
        for (const child of leftChildren) {
          completeMembers.add(child);
        }
      }

      const rightChildren = rightMap.get(member);
      if (rightChildren) {
        for (const child of rightChildren) {
          completeMembers.add(child);
        }
      }
    }

    const descriptor = new TemporaryGroupDescriptor();
    descriptor.insets = null;
    data.temporaryGroups.add(descriptor).items = completeMembers;
    //console.log(component.map(n => n.tag.quickStartData.id))
  }
}

function configureSubcomponents(
  minmumNodeDistance: number,
  rightMap: Map<INode, INode[]>,
  leftMap: Map<INode, INode[]>,
  layoutData: HierarchicLayoutData,
  child2Index: Map<INode, number>
): TreeLayoutData {
  const sideNodeSpacing = minmumNodeDistance;
  const rightTreeLayout = new TreeLayout({
    layoutOrientation: LayoutOrientation.LEFT_TO_RIGHT,
  });
  (<DefaultNodePlacer>(
    rightTreeLayout.defaultNodePlacer
  )).minimumLastSegmentLength = sideNodeSpacing;
  (<DefaultNodePlacer>(
    rightTreeLayout.defaultNodePlacer
  )).minimumFirstSegmentLength = sideNodeSpacing;

  for (const component of rightMap.values()) {
    layoutData.subcomponents.add(
      new HierarchicLayoutSubcomponentDescriptor({
        layoutAlgorithm: rightTreeLayout,
        placementPolicy:
          HierarchicLayoutSubcomponentPlacementPolicy.ALWAYS_INTEGRATED,
      })
    ).items = component;
  }

  const leftTreeLayout = new TreeLayout({
    layoutOrientation: LayoutOrientation.RIGHT_TO_LEFT,
  });
  (<DefaultNodePlacer>(
    leftTreeLayout.defaultNodePlacer
  )).minimumLastSegmentLength = sideNodeSpacing;
  (<DefaultNodePlacer>(
    leftTreeLayout.defaultNodePlacer
  )).minimumFirstSegmentLength = sideNodeSpacing;

  for (const component of leftMap.values()) {
    layoutData.subcomponents.add(
      new HierarchicLayoutSubcomponentDescriptor({
        layoutAlgorithm: leftTreeLayout,
        placementPolicy:
          HierarchicLayoutSubcomponentPlacementPolicy.ALWAYS_INTEGRATED,
      })
    ).items = component;
  }

  const treeLayoutData = new TreeLayoutData();
  treeLayoutData.outEdgeComparers.constant = (e1: IEdge, e2: IEdge): number => {
    const n1 = e1.targetNode!;
    const n2 = e2.targetNode!;
    const compare = child2Index.get(n1)! - child2Index.get(n2)!;
    if (compare > 0) {
      return 1;
    } else if (compare < 0) {
      return -1;
    }
    return 0;
  };
  return treeLayoutData;
}

function insertNewNode(
  graph: IGraph,
  parent: INode | null,
  layer: List<INode> | null,
  newNode: INode
): layer is List<INode> {
  return Boolean(
    parent &&
      !getSuccessor(graph, newNode) &&
      !getPredecessor(graph, newNode) &&
      !isNodeLeft(newNode) &&
      !isNodeRight(newNode)
  );
}

function incrementRanks(
  graph: IGraph,
  excludedNode: INode,
  lowerThreshold: number
): void {
  graph.nodes
    .filter(
      (d) =>
        d !== excludedNode &&
        getLayer(d) == getLayer(excludedNode) &&
        getSiblingRank(d) >= lowerThreshold
    )
    .forEach((d) => incSiblingRank(d));
}

function incSiblingRank(node: INode): void {
  setSiblingRank(node, node.tag.quickStartData.siblingRank + 1);
}

function enforceCurrentOrder(
  layoutData: HierarchicLayoutData,
  graph: IGraph,
  newNode?: INode
): void {
  const layeredNodes = new Map<number, List<INode>>();

  //collect representatives for all groups of horizontal siblings (excluding the newly added node)
  //TODO: If the new node can be inserted on the left of an only child, the assumption "newNode is an only child or not first node" doesn't hold
  for (const node of graph.nodes) {
    if (
      node !== newNode &&
      !getPredecessor(graph, node) &&
      (isNodeUp(node) || isNodeDown(node) || isRoot(node))
    ) {
      const layerIndex = getLayer(node);
      let layer = layeredNodes.get(layerIndex);
      if (!layer) {
        layer = new List<INode>();
        layeredNodes.set(layerIndex, layer);
      }
      layer.add(node);
    }
  }

  for (const layer of layeredNodes.values()) {
    layer.sort((n1, n2) => {
      const compare =
        n1.tag.quickStartData.siblingRank - n2.tag.quickStartData.siblingRank;
      if (compare > 0) {
        return 1;
      }
      if (compare < 0) {
        return -1;
      }
      return 0;
    });

    let prev = null;
    for (const representative of layer) {
      //enforce order between groups of siblings
      if (prev) {
        layoutData.sequenceConstraints.placeAfter(prev, representative);
      }

      //enforce order between siblings in the representative's group
      prev = representative;
      let successor = getSuccessor(graph, prev);
      while (successor) {
        layoutData.sequenceConstraints.placeAfter(prev, successor);
        prev = successor;
        successor = getSuccessor(graph, successor);
      }
    }
  }

  //insert new node
  if (newNode) {
    const parent = getParent(graph, newNode);
    const layer = layeredNodes.get(getLayer(newNode)) ?? null;
    if (insertNewNode(graph, parent, layer, newNode)) {
      //the new node has no siblings and can have children of its own
      if (!layer) {
        //the new node is the first node on this layer
        setSiblingRank(newNode, 0);
        return;
      }
      const center = newNode.layout.center.x;
      let placed = false;
      let previous: INode | null = null;
      let nextIndex = 1;
      for (let node of layer) {
        const start = getStartCoordinate(graph, node);
        const end = getEndCoordinate(graph, node);
        const currentRank = getSiblingRank(node);

        if (start > center) {
          //left of current but right of potential previous
          if (previous != null) {
            layoutData.sequenceConstraints.placeAfter(
              getOutermostSibling(graph, previous, true),
              newNode
            );
          }
          setSiblingRank(newNode, currentRank);
          incrementRanks(graph, newNode, currentRank);
          layoutData.sequenceConstraints.placeAfter(
            newNode,
            getOutermostSibling(graph, node, false)
          );
          placed = true;
          break;
        } else if (start < center && end > center) {
          //inside neighbour group

          //find how many crossings are necessary on each side
          let rightSide = 0;
          let leftSide = 0;
          let current: INode | null = getOutermostSibling(graph, node, false);
          while (current) {
            for (let edge of graph.edgesAt(current)) {
              const target = edge.opposite(current) as INode;
              if (getLayer(target) === getLayer(parent)) {
                const centerX = target.layout.center.x;
                if (centerX > center) {
                  //edge ends right of newNode's parent
                  rightSide++;
                } else if (centerX < center) {
                  leftSide++;
                }
              }
            }
            current = getNeighbour(graph, current, true);
          }

          if (rightSide > leftSide) {
            //place to the left
            if (previous != null) {
              layoutData.sequenceConstraints.placeAfter(
                getOutermostSibling(graph, previous, true),
                newNode
              );
            }

            setSiblingRank(newNode, currentRank);
            incrementRanks(graph, newNode, currentRank);
            layoutData.sequenceConstraints.placeAfter(
              newNode,
              getOutermostSibling(graph, node, false)
            );
            placed = true;
            break;
          }
          if (leftSide > rightSide) {
            //place to the right
            setSiblingRank(newNode, currentRank + 1);
            layoutData.sequenceConstraints.placeAfter(
              getOutermostSibling(graph, node, true),
              newNode
            );
            if (nextIndex < layer.size) {
              const next = layer.get(nextIndex);
              incrementRanks(graph, newNode, currentRank + 1);
              layoutData.sequenceConstraints.placeAfter(
                newNode,
                getOutermostSibling(graph, next, false)
              );
            }
            placed = true;
            break;
          }

          //edge crossings don't indicate clear preference => take shorter route
          if (Math.abs(start - center) < Math.abs(end - center)) {
            //place to the left
            setSiblingRank(newNode, currentRank);
            if (previous != null) {
              layoutData.sequenceConstraints.placeAfter(
                getOutermostSibling(graph, previous, true),
                newNode
              );
            }
            incrementRanks(graph, newNode, currentRank);
            layoutData.sequenceConstraints.placeAfter(
              newNode,
              getOutermostSibling(graph, node, false)
            );
          } else {
            //place to the right
            setSiblingRank(newNode, currentRank + 1);
            layoutData.sequenceConstraints.placeAfter(
              getOutermostSibling(graph, node, true),
              newNode
            );
            if (nextIndex < layer.size) {
              const next = layer.get(nextIndex);
              incrementRanks(graph, newNode, currentRank + 1);
              layoutData.sequenceConstraints.placeAfter(
                newNode,
                getOutermostSibling(graph, next, false)
              );
            }
          }
          placed = true;
          break;
        }

        previous = node;
        nextIndex++;
      }

      if (!placed) {
        //right of all
        if (previous != null) {
          setSiblingRank(newNode, previous.tag.quickStartData.siblingRank + 1);
          layoutData.sequenceConstraints.placeAfter(
            getOutermostSibling(graph, previous, true),
            newNode
          );
        }
      }
    }
  }
}

function setSiblingRank(node: INode, rank: number): void {
  const oldTag = node.tag;
  const newTag = {
    ...node.tag,
    quickStartData: { ...oldTag.quickStartData },
  };
  newTag.quickStartData.siblingRank = rank;
  node.tag = newTag;
}

/**
 * Returns the leftmost coordinate of a node or its siblings
 */
function getStartCoordinate(graph: IGraph, node: INode): number {
  const predecessor = getOutermostSibling(graph, node, false);
  return predecessor.layout.x;
}

/**
 * Returns the rightmost coordinate of a node or its siblings
 */
function getEndCoordinate(graph: IGraph, node: INode): number {
  const successor = getOutermostSibling(graph, node, true);
  return successor.layout.x + successor.layout.width;
}

function getOutermostSibling(
  graph: IGraph,
  node: INode,
  successor: boolean
): INode {
  let current = node;
  while (getNeighbour(graph, current, successor)) {
    current = getNeighbour(graph, current, successor)!;
  }
  return current;
}

function getNeighbour(graph: IGraph, node: INode, successor: boolean): INode {
  // @ts-ignore
  return successor ? getSuccessor(graph, node) : getPredecessor(graph, node);
}

function configureHierarchicLayout(
  hierarchicLayout: HierarchicLayout,
  layoutData: HierarchicLayoutData,
  graph: IGraph,
  minimumNodeDistance: number,
  options: LayoutOptions,
  newNode?: INode
): void {
  const hierarchicLayerSpacing = options?.minimumLayerDistance;
  hierarchicLayout.fromScratchLayeringStrategy =
    HierarchicLayoutLayeringStrategy.USER_DEFINED;
  hierarchicLayout.edgeLayoutDescriptor.minimumLastSegmentLength =
    hierarchicLayerSpacing;
  hierarchicLayout.orthogonalRouting = true;
  hierarchicLayout.automaticEdgeGrouping = false;
  hierarchicLayout.nodeToNodeDistance = minimumNodeDistance;
  (<SimplexNodePlacer>hierarchicLayout.nodePlacer).groupCompactionStrategy =
    GroupCompactionPolicy.NONE;

  //prepare fixed layering
  let minLayer = 0;
  //normalize layers, as negative layer indices are currently problematic for given layers layerer
  graph.nodes.forEach(
    (d: INode) => (minLayer = Math.min(d.tag.quickStartData.layer))
  );
  layoutData.givenLayersLayererIds = (node: INode) => getLayer(node) - minLayer;

  //prepare edge grouping
  layoutData.sourceGroupIds.delegate = (key: IEdge) =>
    edgeIsDown(key)
      ? `source${key.sourceNode?.tag.quickStartData.layoutId} ${key.tag.quickStartData.direction}`
      : null;
  layoutData.targetGroupIds.delegate = (key: IEdge) =>
    isEdgeUp(key)
      ? `target${key.targetNode?.tag.quickStartData.layoutId} ${key.tag.quickStartData.direction}`
      : null;

  //incrementally enforce sequence constraints
  enforceCurrentOrder(layoutData, graph, newNode);
}

function collectNodesBySiblingGroup(
  graph: IGraph,
  leftMap: Map<INode, INode[]>,
  rightMap: Map<INode, INode[]>,
  upMap: Map<INode, INode[]>,
  downMap: Map<INode, INode[]>
): void {
  function fillMap(map: Map<INode, INode[]>, ref: INode, node: INode): void {
    if (map.has(ref)) {
      map.get(ref)!.push(node);
    } else {
      map.set(ref, [node]);
    }
  }

  for (const node of graph.nodes) {
    if (isNodeLeft(node)) {
      fillMap(leftMap, getParent(graph, node), node);
    } else if (isNodeRight(node)) {
      fillMap(rightMap, getParent(graph, node), node);
    } else if (isNodeUp(node)) {
      fillMap(upMap, getParent(graph, node), node);
    } else if (isNodeDown(node)) {
      fillMap(downMap, getParent(graph, node), node);
    }
  }
}

function configurePartitionGrid(
  graph: IGraph,
  layoutData: HierarchicLayoutData,
  hierarchicLayout: HierarchicLayout
): void {
  let maxLayerIndex = 0;
  let minLayerIndex = 0;

  for (const node of graph.nodes) {
    maxLayerIndex = Math.max(maxLayerIndex, getLayer(node));
    minLayerIndex = Math.min(minLayerIndex, getLayer(node));
  }

  maxLayerIndex -= minLayerIndex;

  layoutData.partitionGridData.grid.addColumn();
  for (let i = 0; i <= maxLayerIndex; i++) {
    const rowDescriptor = layoutData.partitionGridData.grid.addRow();
    rowDescriptor.topInset = hierarchicLayout.minimumLayerDistance / 2;
    rowDescriptor.bottomInset = hierarchicLayout.minimumLayerDistance / 2;
  }

  layoutData.partitionGridData.cellIds.contextDelegate = (node, grid) =>
    grid.createCellId(getLayer(node) - minLayerIndex, 0);
}

function configureTemporaryGroupInsertion(
  upMap: Map<INode, INode[]>,
  leftMap: Map<INode, INode[]>,
  rightMap: Map<INode, INode[]>,
  downMap: Map<INode, INode[]>,
  data: TemporaryGroupNodeInsertionData
): void {
  for (const component of upMap.values()) {
    prepTemporaryGroup(component, leftMap, rightMap, data);
  }

  for (const component of downMap.values()) {
    prepTemporaryGroup(component, leftMap, rightMap, data);
  }
}

function prepareSymmetryCorrectionStage(
  layout: SymmetryCorrectionStage,
  layoutData: GenericLayoutData,
  graph: IGraph
): void {
  layout.intermediateLineSnappingAllowed = true;
  layoutData.addNodeItemMapping(LayoutKeys.NODE_ID_DP_KEY, (node) => node);
  layoutData.addNodeItemMapping(
    SymmetryCorrectionStage.NODE_INFO_DP_KEY,
    (node) => {
      const info = new NodeInfo();
      info.direction = getDirection(node);
      info.layer = getLayer(node);

      info.parentId = getParent(graph, node);
      info.predecessorId = getPredecessor(graph, node);
      info.successorId = getSuccessor(graph, node);

      info.firstChildUpId = getFirstChild(graph, node, 'up');
      info.firstChildLeftId = getFirstChild(graph, node, 'left');
      info.firstChildDownId = getFirstChild(graph, node, 'down');
      info.firstChildRightId = getFirstChild(graph, node, 'right');
      return info;
    }
  );
}

export async function applyLayout(
  graphComponent: GraphComponent,
  graph: IGraph,
  options: LayoutOptions,
  newNode?: INode
): Promise<void> {
  const minimumNodeDistance = options.minimumNodeDistance;
  const hierarchicLayout = new HierarchicLayout();
  const layoutData = new HierarchicLayoutData();

  configureHierarchicLayout(
    hierarchicLayout,
    layoutData,
    graph,
    minimumNodeDistance,
    options,
    newNode
  );

  //add partition grid to prevent side nodes from overlapping neighbouring layers
  configurePartitionGrid(graph, layoutData, hierarchicLayout);

  const rightMap = new Map<INode, INode[]>();
  const leftMap = new Map<INode, INode[]>();
  const downMap = new Map<INode, INode[]>();
  const upMap = new Map<INode, INode[]>();
  collectNodesBySiblingGroup(graph, leftMap, rightMap, upMap, downMap);

  const child2Index = new Map<INode, number>();

  calcSideNodeOrder(child2Index, graph);

  const treeLayoutData = configureSubcomponents(
    minimumNodeDistance,
    rightMap,
    leftMap,
    layoutData,
    child2Index
  );

  const groupInsertion = new TemporaryGroupNodeInsertionStage(hierarchicLayout);
  const groupInsertionData = new TemporaryGroupNodeInsertionData();

  if (newNode) {
    const groupAdjustment = new GroupPositioningStage(newNode);
    groupAdjustment.coreLayout = hierarchicLayout;
    groupInsertion.coreLayout = groupAdjustment;
  }

  configureTemporaryGroupInsertion(
    upMap,
    leftMap,
    rightMap,
    downMap,
    groupInsertionData
  );

  const layout = new SymmetryCorrectionStage(
    groupInsertion,
    minimumNodeDistance
  );
  const symmetryCorrectionLayoutData = new GenericLayoutData();
  prepareSymmetryCorrectionStage(layout, symmetryCorrectionLayoutData, graph);
  graphComponent.graph.applyLayout(
    layout,
    layoutData
      .combineWith(groupInsertionData)
      .combineWith(treeLayoutData)
      .combineWith(symmetryCorrectionLayoutData)
  );
}

function calcSideNodeOrder(
  child2Index: Map<INode, number>,
  graph: IGraph
): void {
  for (const node of graph.nodes) {
    deriveOrder(getOrderedChildren(graph, node, 'left'));
    deriveOrder(getOrderedChildren(graph, node, 'right'));
  }

  function deriveOrder(orderedChildren: List<INode>): void {
    for (let i = 0; i < orderedChildren.size; i++) {
      child2Index.set(orderedChildren.get(i), i);
    }
  }
}

function isNodeUp(node: INode): boolean {
  return node.tag.quickStartData.direction === 'up';
}

function isNodeDown(node: INode): boolean {
  return node.tag.quickStartData.direction === 'down';
}

function isNodeLeft(node: INode): boolean {
  return node.tag.quickStartData.direction === 'left';
}

function isNodeRight(node: INode): boolean {
  return node.tag.quickStartData.direction === 'right';
}

export function isNodeInDirection(
  node: INode,
  direction: BuildDirection
): boolean {
  switch (direction) {
    case 'up':
      return isNodeUp(node);
    case 'down':
      return isNodeDown(node);
    case 'left':
      return isNodeLeft(node);
    case 'right':
      return isNodeRight(node);
  }
}

function getFirstChild(
  graph: IGraph,
  node: INode,
  direction: BuildDirection
): any {
  // @ts-ignore
  let lookupUuid: string = null;
  switch (direction) {
    case 'up':
      lookupUuid = node.tag.quickStartData.firstChildUpUuid;
      break;
    case 'down':
      lookupUuid = node.tag.quickStartData.firstChildDownUuid;
      break;
    case 'left':
      lookupUuid = node.tag.quickStartData.firstChildLeftUuid;
      break;
    case 'right':
      lookupUuid = node.tag.quickStartData.firstChildRightUuid;
      break;
  }

  return graph.nodes.find((d) => d.tag.uuid == lookupUuid);
}

function getDirection(node: INode): 1 | 0 | 2 | 3 | 4 {
  const direction = node.tag.quickStartData.direction as
    | 'up'
    | 'down'
    | 'left'
    | 'right'
    | 'root';
  switch (direction) {
    case 'up':
      return SymmetryCorrectionStage.UP;
    case 'down':
      return SymmetryCorrectionStage.DOWN;
    case 'left':
      return SymmetryCorrectionStage.LEFT;
    case 'right':
      return SymmetryCorrectionStage.RIGHT;
    case 'root':
      return SymmetryCorrectionStage.ROOT;
  }
}

export function isEdgeUp(edge: IEdge): boolean {
  return edge.tag.quickStartData.direction === 'up';
}

export function edgeIsDown(edge: IEdge): boolean {
  return edge.tag.quickStartData.direction === 'down';
}

export function isEdgeLeft(edge: IEdge): boolean {
  return edge.tag.quickStartData.direction === 'left';
}

export function isEdgeRight(edge: IEdge): boolean {
  return edge.tag.quickStartData.direction === 'right';
}

function getPredecessor(graph: IGraph, child: INode): INode | null {
  return graph.nodes.find(
    (d) => d.tag.uuid == child.tag.quickStartData.predecessorUuid
  );
}

function getSuccessor(graph: IGraph, child: INode): INode | null {
  return graph.nodes.find(
    (d) => d.tag.uuid == child.tag.quickStartData.successorUuid
  );
}

function getSiblingRank(node: INode): number {
  return node.tag.quickStartData.siblingRank;
}

function getParent(graph: IGraph, child: INode): INode {
  // @ts-ignore
  return graph.nodes.find(
    (d) => d.tag.uuid == child.tag.quickStartData.parentUuid
  );
}

function getId(node: INode): number {
  return node.tag.quickStartData.layoutId;
}

function isRoot(node: INode): boolean {
  return getId(node) === 0;
}

function getLayer(node: INode): number {
  return node.tag.quickStartData.layer;
}

function getOrderedChildren(
  graph: IGraph,
  node: INode,
  direction: BuildDirection
): List<INode> {
  const neighbors = graph.neighbors(node);
  let firstChild;
  for (const child of neighbors) {
    if (
      isNodeInDirection(child, direction) &&
      !getPredecessor(graph, child) &&
      getParent(graph, child) === node
    ) {
      firstChild = child;
    }
  }
  const children = new List<INode>();
  while (firstChild) {
    children.add(firstChild);
    firstChild = getSuccessor(graph, firstChild);
  }
  return children;
}

class GroupPositioningStage extends LayoutStageBase {
  newLayoutNode: YNode | null;

  constructor(private readonly newNode?: INode) {
    super();
    this.newLayoutNode = null;
  }

  applyLayout(graph: LayoutGraph): void {
    this.findNewLayoutNode(graph);
    const hider = new LayoutGraphHider(graph);
    if (this.newLayoutNode) {
      hider.hide(this.newLayoutNode!);
    }

    const grouping = new LayoutGroupingSupport(graph);
    for (const group of grouping.getChildren(null)!) {
      const children = grouping.getChildren(group);
      if (children) {
        const boundingBox = LayoutGraphUtilities.getBoundingBoxOfNodes(
          graph,
          children!.nodes()
        );
        graph.setSize(
          group,
          new YDimension(boundingBox.width, boundingBox.height)
        );
        graph.setLocation(group, boundingBox.x, boundingBox.y);
      }
    }

    this.cleanUp(hider, grouping);

    this.coreLayout?.applyLayout(graph);
  }

  private findNewLayoutNode(graph: LayoutGraph): void {
    if (!this.newNode) {
      return;
    }

    const origNodeDp = graph.getDataProvider(
      LayoutGraphAdapter.ORIGINAL_NODE_DP_KEY
    )!;
    for (const node of graph.nodes) {
      if (origNodeDp.get(node) === this.newNode) {
        this.newLayoutNode = node;
      }
    }
  }

  private cleanUp(
    hider: LayoutGraphHider,
    grouping: LayoutGroupingSupport
  ): void {
    if (this.newLayoutNode) {
      hider.unhideNode(this.newLayoutNode, true);
    }

    grouping.dispose();
  }
}
