import { ArrowType } from 'yfiles';

export default [
  {
    name: 'None',
    type: ArrowType.NONE,
    srcUrl: '/resources/arrows/none.svg',
  },
  {
    name: 'Triangle',
    type: ArrowType.TRIANGLE,
    srcUrl: '/resources/arrows/triangle.svg',
  },
  {
    name: 'Short',
    type: ArrowType.SHORT,
    srcUrl: '/resources/arrows/short.svg',
  },
  {
    name: 'Default',
    type: ArrowType.DEFAULT,
    srcUrl: '/resources/arrows/default.svg',
  },
  {
    name: 'Diamond',
    type: ArrowType.DIAMOND,
    srcUrl: '/resources/arrows/diamond.svg',
  },
  {
    name: 'Circle',
    type: ArrowType.CIRCLE,
    srcUrl: '/resources/arrows/circle.svg',
  },
];
