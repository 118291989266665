import {
  ApplicationPermissions,
  IPermission,
} from '@/api/ApplicationPermissions';
import { GET_USER_PERMISSIONS } from '@/core/services/store/user.module';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class PermissionMixin extends Vue {
  ApplicationPermissions = ApplicationPermissions;

  hasPermission(permission: IPermission): boolean {
    try {
      const permissions = this.$store.getters[GET_USER_PERMISSIONS];
      if (!permissions) return false;
      return permissions.includes(permission.key);
    } catch (error) {
      console.warn(error);
    }
  }
  doesNotHavePermission(permission: IPermission): boolean {
    return !this.hasPermission(permission);
  }
}
