<template>
  <div
    v-if="$slots.default && !$slots.content"
    class="cursor-default select-none font-normal text-base text-normal text-main-dark-60 leading-none px-24 py-12"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownGroupLabel',
})
export default class AppDropdownGroupLabel extends Vue {}
</script>
