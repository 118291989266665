import {
  GraphComponent,
  GraphModelManager,
  HierarchicNestingPolicy,
  ICanvasObjectGroup,
  IEdge,
  IEnumerable,
  IModelItem,
  INode,
  LabelLayerPolicy,
} from 'yfiles';
import NodeLabelDescriptor from './descriptors/NodeLabelDescriptor';

export default class JigsawGraphModelManager extends GraphModelManager {
  constructor(
    graphComponent: GraphComponent,
    contentGroup: ICanvasObjectGroup = graphComponent.contentGroup,
    labelLayerPolicy: LabelLayerPolicy = LabelLayerPolicy.AT_OWNER
  ) {
    super(graphComponent, contentGroup);

    //https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#tab_effect-LabelLayerPolicy
    this.labelLayerPolicy = labelLayerPolicy;

    // https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#customizing_view-z_order_canvasobject
    this.hierarchicNestingPolicy = HierarchicNestingPolicy.NONE;
    this.edgeLabelGroup.below(this.nodeGroup);
    this.edgeGroup.below(this.nodeGroup);
    this.nodeLabelGroup.toFront();
    this.nodeLabelDescriptor = new NodeLabelDescriptor();
  }

  public override toFront(args: IModelItem): void;
  public override toFront(args: IEnumerable<IModelItem>): void;
  public override toFront(args: IEnumerable<IModelItem> | IModelItem): void {
    if (args instanceof IModelItem) {
      const item = args;
      super.toFront(args);
      if (INode.isInstance(item)) {
        this.setDisplayOrder(item, this.getNodesMaxDisplayOrder() + 1);
      } else if (IEdge.isInstance(item)) {
        this.setDisplayOrder(item, this.getEdgesMinDisplayOrder() + 1);
      }
    } else {
      const items = args as IEnumerable<IModelItem>;
      super.toFront(items);

      let nodesMaxDisplayOrder = this.getNodesMaxDisplayOrder();
      items
        .filter((i) => INode.isInstance(i))
        .orderBy((i: INode) => i.tag.displayOrder)
        .forEach((i: INode) => this.setDisplayOrder(i, ++nodesMaxDisplayOrder));

      let edgesMaxDisplayOrder = this.getEdgesMaxDisplayOrder();
      items
        .filter((i) => IEdge.isInstance(i))
        .orderBy((i: IEdge) => i.tag.displayOrder)
        .forEach((i: IEdge) => this.setDisplayOrder(i, ++edgesMaxDisplayOrder));
    }
  }

  public override toBack(args: IModelItem): void;
  public override toBack(args: IEnumerable<IModelItem>): void;
  public override toBack(args: IEnumerable<IModelItem> | IModelItem): void {
    if (args instanceof IModelItem) {
      const item = args;
      super.toBack(args);
      if (INode.isInstance(item)) {
        this.setDisplayOrder(item, this.getNodesMinDisplayOrder() - 1);
      } else if (IEdge.isInstance(item)) {
        this.setDisplayOrder(item, this.getEdgesMinDisplayOrder() - 1);
      }
    } else {
      const items = args as IEnumerable<IModelItem>;
      super.toBack(items);

      let nodesMinDisplayOrder = this.getNodesMinDisplayOrder();
      items
        .filter((i) => INode.isInstance(i))
        .orderByDescending((i: INode) => i.tag.displayOrder)
        .forEach((i: INode) => this.setDisplayOrder(i, --nodesMinDisplayOrder));

      let edgesMinDisplayOrder = this.getEdgesMinDisplayOrder();
      items
        .filter((i) => IEdge.isInstance(i))
        .orderByDescending((i: IEdge) => i.tag.displayOrder)
        .forEach((i: IEdge) => this.setDisplayOrder(i, --edgesMinDisplayOrder));
    }
  }

  // Implement if we need it
  // Should be implemented in same way as toFront/toBack to set displayOrder value
  public override raise(args: IModelItem): void;
  public override raise(args: IEnumerable<IModelItem>): void;
  public override raise(args: IEnumerable<IModelItem> | IModelItem): void {
    throw 'Method is not implemented';
  }

  // Implement if we need it
  // Should be implemented in same way as toFront/toBack to set displayOrder value
  public override lower(args: IModelItem): void;
  public override lower(args: IEnumerable<IModelItem>): void;
  public override lower(args: IEnumerable<IModelItem> | IModelItem): void {
    throw 'Method is not implemented';
  }

  public setNewItemDisplayOrder(item: INode | IEdge): void {
    const displayOrder =
      item instanceof INode
        ? this.getNodesMaxDisplayOrder() + 1
        : this.getEdgesMaxDisplayOrder() + 1;
    this.setDisplayOrder(item, displayOrder);
  }

  private getNodesMaxDisplayOrder(): number {
    return Math.max(...this.graph.nodes.map((n) => n.tag.displayOrder)) ?? 0;
  }

  private getEdgesMaxDisplayOrder(): number {
    return Math.max(...this.graph.edges.map((e) => e.tag.displayOrder)) ?? 0;
  }

  private getNodesMinDisplayOrder(): number {
    return Math.min(...this.graph.nodes.map((n) => n.tag.displayOrder)) ?? 0;
  }

  private getEdgesMinDisplayOrder(): number {
    return Math.min(...this.graph.edges.map((e) => e.tag.displayOrder)) ?? 0;
  }

  private setDisplayOrder(item: INode | IEdge, value: number): void {
    item.tag.displayOrder = value;
  }
}
