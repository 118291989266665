import { htmlToElement } from '@/core/utils/html.utils';
import { toBlob } from 'html-to-image';
import { fetchAsDataURL } from 'html-to-image/src/dataurl';
import { GraphComponent, GraphMLSupport } from 'yfiles';
import BackgroundGraphService from '../../graph/BackgroundGraphService';
import ContentPagination from '../ContentPagination';
import ExportOptions from '../ExportOptions';
import ExportUtils from '../ExportUtils';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import SvgExportProvider from './SvgExportProvider';
import i18n from '@/core/plugins/vue-i18n';
import { DocumentDto, DocumentPageContentType } from '@/api/models';
import ExportPage from '../ExportPage';
import LegendUtils from '@/components/DiagramLegend/LegendUtils';
import customFontFamilyStyles from '@/assets/sass/_custom-fonts.scss';

const jsZipImport = () => import('jszip');

export default class PngExportProvider implements IExportProvider {
  private _fileExtension = 'png';
  private _mimeType = 'image/png';
  private _svgExportProvider = new SvgExportProvider();
  private static customFontFamilyCss = null;

  private static async loadCustomFontFamiliesAsDataUrls() {
    this.customFontFamilyCss = customFontFamilyStyles;
    const regexUrl = /url\(["']?(?<url>[^"')]+)["']?\)/g;
    const fontLocs = [];

    for (const match of this.customFontFamilyCss.matchAll(regexUrl)) {
      fontLocs.push(match.groups.url);
    }

    await Promise.all(
      fontLocs.map(async (url: string) => {
        return fetchAsDataURL<void>(url, undefined, ({ result }) => {
          this.customFontFamilyCss = this.customFontFamilyCss.replace(
            `url("${url}")`,
            `url(${result})`
          );
        }).catch(() => {
          console.log('Could not load font ' + url);
        });
      })
    );
  }

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const exportResult = await this._svgExportProvider.exportGraphAsString(
      options,
      graphComponent,
      null,
      true
    );
    const svgElement = htmlToElement(<string>exportResult.result);

    if (!PngExportProvider.customFontFamilyCss) {
      await PngExportProvider.loadCustomFontFamiliesAsDataUrls();
    }

    const blob = await toBlob(svgElement, {
      type: this._mimeType,
      backgroundColor: 'white',
      width: exportResult.size.width,
      height: exportResult.size.height,
      pixelRatio: 4,
      fontEmbedCSS: PngExportProvider.customFontFamilyCss
    });

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportResult.size,
      result: blob
    };
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    const jsZip = (await jsZipImport()).default;
    const zip = new jsZip();

    let pageNumber = 0;
    for (const exportPage of options.pages) {
      pageNumber++;

      let blob: Blob;
      if (exportPage.page.contentType == DocumentPageContentType.MasterLegend) {
        blob = await this.exportMasterLegendPage(exportPage, options.document);
      } else if (exportPage.page.diagram) {
        blob = await this.exportDiagramPage(options, exportPage);
      } else {
        pageNumber += ContentPagination.getPageCount(exportPage.page) - 1;
        continue;
      }

      const fileName = `${i18n.t('PAGE')}_${String(pageNumber).padStart(
        2,
        '0'
      )}.${this._fileExtension}`;
      zip.file(fileName, blob);

      pageNumber += ContentPagination.getPageCount(exportPage.page) - 1;
    }

    const zipBlob = await zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE'
    });

    return {
      fileExtension: 'zip',
      mimeType: 'application/zip',
      result: zipBlob
    };
  }

  public async exportDiagramPage(
    options: ExportOptions,
    exportPage: ExportPage
  ): Promise<Blob> {
    options.metadata.currentPage = exportPage;
    const sourceGraph = BackgroundGraphService.createGraph(
      exportPage.page.diagram
    );
    const graphComponent: GraphComponent = ExportUtils.copyGraphComponent(
      sourceGraph,
      options.withFilters,
      options.format,
      options.lowDetailDiagram,
      options.withHighlight
    );
    const exportBlob = await this.exportGraphAsBlob(options, graphComponent);
    graphComponent.cleanUp();

    return exportBlob.result as Blob;
  }

  public async exportMasterLegendPage(
    exportPage: ExportPage,
    document: DocumentDto
  ): Promise<Blob> {
    const masterLegend = await LegendUtils.generateLegendImageSVG(
      document,
      exportPage.page
    );

    const blob = toBlob(masterLegend as unknown as HTMLElement, {
      type: this._mimeType,
      backgroundColor: 'white',
      width: Number(masterLegend.getAttribute('width')),
      height: Number(masterLegend.getAttribute('height')),
      pixelRatio: 4
    });

    return blob;
  }
}
