<template>
  <li
    class="-ml-1 first:ml-0 py-8 px-10 bg-white border border-main-dark-20 first:rounded-l-4 last:rounded-r-4 leading-none cursor-pointer"
    :class="pageClasses"
    @click="clickHandler"
  >
    <slot />
  </li>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'PaginationItem',
})
export default class PaginationItem extends Vue {
  @Prop({ default: null, type: [Number] })
  page: number | null;

  @Prop({ default: null, type: [Number] })
  activePage: number | null;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  get isActive() {
    if (this.page !== null && this.activePage !== null) {
      return this.page === this.activePage;
    }

    return false;
  }

  get pageClasses() {
    return [
      this.isActive && !this.disabled
        ? 'bg-accent-purple text-white'
        : 'hover:bg-main-dark-10',
      this.disabled && 'bg-main-dark-10 text-main-dark-60 cursor-not-allowed',
    ];
  }

  clickHandler() {
    if (this.disabled) return;

    this.$emit('click');
  }
}
</script>
