import axios, {
    AxiosResponse
} from 'axios';
import {
    UserDto,
    ZadarResponse,
    DocumentAccessLevel,
    EditDocumentProfileDto,
    EditDocumentAutoSaveDto,
    EditDocumentUsePresetPropertiesDto,
    MarkAsFavouriteRequest,
    DocumentSharingDetailsDto,
    DocumentPreviewDetailsDto,
    GetDocumentForViewDto,
    CreateOrEditDocumentOutput,
    CreateOrEditDocumentDto,
    CloneDocumentDto,
    EntityDto,
    DocumentAttachmentDto,
    AttachmentType,
    RestoreDeletedEntityRequestDto
} from './models'
export default class DocumentsApiService {
    public static getDocumentOwner(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < UserDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentOwner`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentName(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentName`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentDefaultAccessLevel(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentAccessLevel >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentDefaultAccessLevel`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentLastModificationTime(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentLastModificationTime`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static updateDocumentProfile(body: EditDocumentProfileDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UpdateDocumentProfile`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static updateDocumentAutoSave(body: EditDocumentAutoSaveDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UpdateDocumentAutoSave`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static updateDocumentUsePresetProperties(body: EditDocumentUsePresetPropertiesDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UpdateDocumentUsePresetProperties`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static markAsFavourite(body: MarkAsFavouriteRequest, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/MarkAsFavourite`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static unmarkAsFavourite(body: MarkAsFavouriteRequest, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UnmarkAsFavourite`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getDocumentSharingDetails(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentSharingDetailsDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentSharingDetails`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentPreviewDetails(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentPreviewDetailsDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentPreviewDetails`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static get(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDocumentForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Get`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentFromExternalFileId(params ? : {
        externalFileId ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDocumentForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentFromExternalFileId`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static createOrEdit(body: CreateOrEditDocumentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreateOrEditDocumentOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/CreateOrEdit`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static create(body: CreateOrEditDocumentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < CreateOrEditDocumentOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Create`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static clone(body: CloneDocumentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Clone`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static unlockDocument(body: EntityDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/UnlockDocument`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static hardDelete(body ? : EntityDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/HardDelete`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(body: EntityDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/Delete`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static deleteDocuments(body ? : EntityDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/DeleteDocuments`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getDocumentAttachments(params ? : {
        documentId ? : number,
        attachmentType ? : AttachmentType
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentAttachmentDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/GetDocumentAttachments`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static addDocumentAttachment(body: DocumentAttachmentDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/AddDocumentAttachment`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static restoreDocument(body: RestoreDeletedEntityRequestDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/RestoreDocument`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static restoreDocuments(body ? : RestoreDeletedEntityRequestDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/Documents/RestoreDocuments`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}