﻿import {
  CreateOrEditDocumentDto,
  DiagramDto,
  DiagramPosition,
  DocumentDto,
  DocumentPageContentType,
  DocumentPageType,
  GetThemeOutput,
  ImportedFileResultDto,
  PageDesignDto,
  PageElementPosition,
  ThemeDto,
  FlipbookState,
  CreateOrEditDocumentPageOutput,
  TimelineDto,
  PageDesignSetDto,
} from '@/api/models';
import DocumentsApiService from '@/api/DocumentsApiService';
import DocumentPagesApiService from '@/api/DocumentPagesApiService';
import { Component, Vue } from 'vue-property-decorator';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT,
  SET_RIBBON_DEFAULT_TAB,
} from '@/core/services/store/document.module';
import { RibbonTab } from '@/view/pages/document/ribbon/RibbonTab';
import {
  GET_DEFAULT_THEME,
  THEMES_NAMESPACE,
} from '@/core/services/store/theme.module';
import AnalyticsService from '@/core/services/analytics/AnalyticsService';
import { AnalyticsEvent } from '@/core/services/analytics/AnalyticsEvent';
import { Action, Getter, Mutation } from 'vuex-class';
import transparentTableSwatch from '@/core/common/TransparentTableSwatch';
import DocumentService from '@/core/services/document/DocumentService';
import { GET_AUTOSAVE_ENABLED } from '@/core/services/store/settings.module';
import {
  GLOBAL_NAMESPACE,
  SET_GLOBAL_LOADING,
} from '@/core/services/store/global.module';
import FeaturesService from '@/core/services/FeaturesService';
import { Features } from '@/core/common/Features';
import { RouterParams } from '@/core/config/routerParams';
import { RouterQueries } from '@/core/config/routerQueries';
import {
  GET_DEFAULT_PAGE_DESIGN,
  PAGE_DESIGN_NAMESPACE,
  LOAD_PAGE_DESIGNS,
  GET_LAYOUT_PAGE_DESIGNS,
  GET_ALL_PAGE_DESIGNS,
  GET_STEPS_FONT_PRESETS,
} from '@/core/services/store/page-design.module';
import { LayoutItemType } from '@/components/LayoutEditor/Items/LayoutItemType';
import TimelineUtils from '@/core/utils/Timeline.utils';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import ExportUtils from '@/core/services/export/ExportUtils';
import StepsDesignerConfig from '@/core/config/stepsDesignerConfig';
import { DocumentPageLayoutType } from '@/api/models';
import { StepsFontPresets } from '@/view/pages/administration/steps-designer/StepsFontPresets';

/**
 * This is used to preserve styling in an empty span within CK Editor.
 * Works with the preserve-styling plugin
 */

interface CreateDocumentRequest {
  name: string;
  theme: ThemeDto;
  pageDesignSet?: PageDesignSetDto;
  stepsDocument: boolean;
  timelinesDocument: boolean;
  folderId: number;
  importId?: number;
}

@Component({})
export default class CreateDocumentMixin extends Vue {
  @Getter(GET_DOCUMENT, { namespace: DOCUMENT_NAMESPACE })
  public document: DocumentDto;

  @Getter(GET_DEFAULT_PAGE_DESIGN, { namespace: PAGE_DESIGN_NAMESPACE })
  public defaultPageDesign: PageDesignDto;

  @Getter(GET_LAYOUT_PAGE_DESIGNS, { namespace: PAGE_DESIGN_NAMESPACE })
  public layoutPageDesigns: PageDesignDto[];

  @Getter(GET_ALL_PAGE_DESIGNS, { namespace: PAGE_DESIGN_NAMESPACE })
  public allLayoutPageDesigns: PageDesignDto[];

  @Getter(`${PAGE_DESIGN_NAMESPACE}/${GET_STEPS_FONT_PRESETS}`)
  stepsFontPresets: StepsFontPresets[];

  @Getter(GET_AUTOSAVE_ENABLED)
  isAutoSaveOn: boolean;

  @Action(LOAD_PAGE_DESIGNS, { namespace: PAGE_DESIGN_NAMESPACE })
  public loadPageDesignsAction: () => Promise<Array<PageDesignDto>>;

  @Action(GET_DEFAULT_THEME, { namespace: THEMES_NAMESPACE })
  getDefaultThemeAction: () => Promise<GetThemeOutput>;

  @Mutation(`${GLOBAL_NAMESPACE}/${SET_GLOBAL_LOADING}`)
  setGlobalLoading;

  public async createNewDocument(
    stepsDocument: boolean,
    pageDesignSet?: PageDesignSetDto,
    folderId?: number
  ): Promise<void> {
    this.setGlobalLoading(true);
    try {
      const defaultThemeResult = await this.getDefaultThemeAction();
      const documentId = await this.createDocument({
        stepsDocument: stepsDocument,
        timelinesDocument: false,
        theme: defaultThemeResult.theme,
        pageDesignSet: pageDesignSet,
        importId: null,
        name: null,
        folderId: folderId,
      });
      if (this.document != null) {
        const routeData = this.$router.resolve({
          name: this.$routeNames.documentDetails,
          params: {
            [RouterParams.documentId]: documentId.toString(),
          },
          query: {
            [RouterQueries.newTabDocument]: null,
          },
        });
        const link = `${window.location.origin}/${routeData.href}`;
        window.open(link, '_blank');
      } else {
        this.onDocumentCreated(documentId, stepsDocument);
      }
    } finally {
      this.setGlobalLoading(false);
    }
  }

  public async createNewDocumentFromImport(
    stepsDocument: boolean,
    importedFile: ImportedFileResultDto
  ): Promise<void> {
    try {
      if (!this.defaultPageDesign) {
        await this.loadPageDesignsAction();
      }
      await DocumentService.closeDocument();
    } finally {
      const documentId = await this.createDocument({
        folderId: null,
        stepsDocument: stepsDocument,
        timelinesDocument: false,
        theme: importedFile.theme,
        importId: importedFile.importId,
        name: importedFile.name.replace('.xlsx', ''),
      });
      this.onDocumentCreated(documentId, stepsDocument);
    }
  }

  public async importJsonDocument(): Promise<void> {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.jgy';
    fileInput.click();
    fileInput.addEventListener('change', async (e) => {
      const file = fileInput.files[0];
      if (!file) {
        return;
      }
      try {
        const documentJson = await file.text();
        const document = JSON.parse(documentJson) as DocumentDto;
        const response = await DocumentsApiService.clone({
          document: document,
        });
        const documentId = response.data.result.toString();

        this.$notify({
          title: this.$t('IMPORT_SUCCESS'),
          type: 'success',
        });

        this.$router.push({
          name: this.$routeNames.documentDetails,
          params: {
            [RouterParams.documentId]: documentId.toString(),
          },
        });

        AnalyticsService.trackEvent(AnalyticsEvent.DocumentImported);
      } catch {
        this.$notify({
          title: this.$t('IMPORT_FAILED'),
          type: 'error',
        });
      }
    });
  }

  /**
   * Creates a new document
   * @param request {CreateDocumentRequest}
   * @returns
   */

  protected async createDocument(
    request: CreateDocumentRequest
  ): Promise<number> {
    let currentPageType: DocumentPageType = DocumentPageType.Diagram;
    if (
      request.stepsDocument &&
      this.hasFeature(Features.TextPages) &&
      this.hasFeature(Features.Steps)
    ) {
      currentPageType = DocumentPageType.Split;
    }

    const pageDesignSet = request.pageDesignSet;
    let pageDesign: PageDesignDto = null;
    if (request.stepsDocument && pageDesignSet) {
      pageDesign = pageDesignSet.pageDesigns.find(
        (p) => p.pageType == currentPageType
      );
    } else {
      if (!this.defaultPageDesign) {
        await this.loadPageDesignsAction();
      }
      pageDesign = this.defaultPageDesign;
    }

    const newDocument: CreateOrEditDocumentDto = {
      name: request.name ? request.name : this.$t('NEW_DOCUMENT').toString(),
      isPristine: request.importId ? false : true,
      importId: request.importId,
      autoSave: this.isAutoSaveOn,
      hasSteps: request.stepsDocument,
      hasTimelines: request.timelinesDocument,
      pageStyle: request.timelinesDocument
        ? null
        : request.stepsDocument
        ? pageDesign.pageStyle
        : StepsDesignerConfig.createDefaultPageStyle(),
      headerStyle: request.timelinesDocument ? null : pageDesign.headerStyle,
      footerStyle: request.timelinesDocument ? null : pageDesign.footerStyle,
      headerLayout: request.stepsDocument ? pageDesign.headerLayout : null,
      footerLayout: request.stepsDocument ? pageDesign.footerLayout : null,
      backgroundLayout: request.stepsDocument
        ? pageDesign.backgroundLayout
        : null,
      headerFooterDate: request.stepsDocument ? new Date().toISOString() : null,
      legendPosition:
        request.theme.legendPosition ?? PageElementPosition.BottomLeft,
      logoPosition: request.theme.logoPosition ?? PageElementPosition.TopRight,
      themeId: request.theme.id,
      pageDesignSetId: request.stepsDocument
        ? pageDesign.pageDesignSetId
        : null,
      tableSwatch: request.theme.tableSwatch ?? transparentTableSwatch,
      fontStyles: request.theme.fontStyles,
      defaultPageType: request.timelinesDocument
        ? DocumentPageType.Timeline
        : DocumentPageType.Diagram,
      folderId: request.folderId,
      flipbookState: FlipbookState.Disabled,
      usePresetProperties: false,
      quickBuildSettings: null,
    };
    try {
      const result = await DocumentsApiService.create(newDocument);
      const documentId = result.data.result.id;
      const contentType =
        DocumentService.getDefaultPageContentType(currentPageType);

      let content = null;
      if (
        (currentPageType == DocumentPageType.Split ||
          // @ts-ignore
          currentPageType == DocumentPageType.Content) &&
        contentType == DocumentPageContentType.Html
      ) {
        content = ExportUtils.getInitialPageContent();
      }

      let timelineContent: TimelineDto = null;
      if (newDocument.defaultPageType == DocumentPageType.Timeline) {
        timelineContent = TimelineUtils.getInitialTimeline();
      }

      const showDate =
        request.stepsDocument &&
        !!LayoutUtils.getContentAreaByItemType(
          pageDesign,
          LayoutItemType.Date,
          true
        );
      const showPageNumber =
        request.stepsDocument &&
        !!LayoutUtils.getContentAreaByItemType(
          pageDesign,
          LayoutItemType.PageNumber,
          true
        );

      const showDivider =
        (currentPageType == DocumentPageType.Split &&
          pageDesign.splitDividerVisibility) ||
        // @ts-ignore
        (currentPageType == DocumentPageType.Content &&
          contentType == DocumentPageContentType.Html &&
          pageDesign.htmlContentDividerVisibility);

      const pageTitle =
        pageDesign?.pageTitle ?? LayoutUtils.getDefaultPageTitle();
      const titleLayout = pageTitle.titleLayout;
      const showTitle = pageTitle.show;

      const diagramPosition = request.stepsDocument
        ? this.allLayoutPageDesigns.find(
            (pd) => pd.pageType === DocumentPageType.Split
          )?.defaultDiagramPosition
        : DiagramPosition.Right;

      await this.createPage(
        documentId,
        currentPageType,
        contentType,
        request.stepsDocument && newDocument.headerStyle?.show,
        request.stepsDocument && newDocument.footerStyle?.show,
        newDocument.headerLayout,
        newDocument.footerLayout,
        newDocument.backgroundLayout,
        false,
        content,
        null,
        timelineContent,
        request.stepsDocument ? 1 : 0,
        'Page 1',
        showDate,
        showPageNumber,
        showDivider,
        request.stepsDocument ? showTitle : false,
        request.stepsDocument ? pageTitle.height : 0,
        0,
        request.stepsDocument ? titleLayout : null,
        DocumentPageLayoutType.None,
        null,
        diagramPosition ?? DiagramPosition.Right
      );

      if (
        request.stepsDocument &&
        this.hasFeature(this.featureType.ImagePages)
      ) {
        const coverPageDesign = this.layoutPageDesigns?.find(
          (pageDesign) =>
            pageDesign.isDefault &&
            pageDesign.layoutType === DocumentPageLayoutType.Cover
        );
        const closingPageDesign = this.layoutPageDesigns?.find(
          (pageDesign) =>
            pageDesign.isDefault &&
            pageDesign.layoutType === DocumentPageLayoutType.Closing
        );

        if (
          coverPageDesign &&
          coverPageDesign.bodyLayout &&
          coverPageDesign.bodyLayout !== '[]'
        ) {
          await this.createLayoutPage({
            documentId,
            pageDesign: coverPageDesign,
            isPristine: false,
            showHeader: newDocument.headerStyle.show,
            showFooter: newDocument.footerStyle.show,
            showDate,
            showPageNumber,
            order: 0,
          });
        }

        if (
          closingPageDesign &&
          closingPageDesign.bodyLayout &&
          closingPageDesign.bodyLayout !== '[]'
        ) {
          await this.createLayoutPage({
            documentId,
            pageDesign: closingPageDesign,
            isPristine: false,
            showHeader: newDocument.headerStyle.show,
            showFooter: newDocument.footerStyle.show,
            showDate,
            showPageNumber,
            order: 2,
          });
        }
      }

      return documentId;
    } catch {
      this.$notify({
        title: this.$t('ERROR_CREATING_NEW_DOCUMENT').toString(),
        type: 'error',
      });
    }
  }

  /**
   * Create a new page for the given @param documentId
   * @param documentId
   * @param documentPageType
   * @param contentType
   * @returns
   */

  private async createPage(
    documentId: number,
    documentPageType: DocumentPageType,
    contentType: DocumentPageContentType,
    showHeader: boolean,
    showFooter: boolean,
    headerLayout: string,
    footerLayout: string,
    backgroundLayout: string,
    isPristine: boolean,
    content: string,
    diagram: DiagramDto,
    timeline: TimelineDto,
    order: number,
    name: string,
    showDate: boolean,
    showPageNumber: boolean,
    showDivider: boolean,
    showTitle: boolean,
    titleHeight: number,
    maxTitleHeight: number,
    titleLayout: string,
    layoutType: DocumentPageLayoutType,
    bodyLayout: string,
    diagramPosition: DiagramPosition = DiagramPosition.Right
  ): Promise<CreateOrEditDocumentPageOutput> {
    const result = await DocumentPagesApiService.createOrEdit({
      name: name,
      documentId: documentId,
      order: order,
      diagram: diagram,
      timeline: timeline,
      showLogo: false,
      showLegend: false,
      pageType: documentPageType,
      contentType: contentType,
      contentColumns: 0,
      showHeader: showHeader,
      showFooter: showFooter,
      headerLayout: headerLayout,
      footerLayout: footerLayout,
      backgroundLayout: backgroundLayout,
      isPristine: isPristine,
      content: content,
      diagramPosition: diagramPosition,
      showDate: showDate,
      showPageNumber: showPageNumber,
      showDivider: showDivider,
      showTitle: showTitle,
      titleHeight: titleHeight,
      maxTitleHeight: maxTitleHeight,
      titleLayout: titleLayout,
      layoutType,
      stepsFontPresets: DocumentService.getFontPresetsByLayoutType(
        this.stepsFontPresets,
        layoutType
      ),
      bodyLayout: bodyLayout,
    });
    return result.data.result;
  }

  public async createMasterLegendPage(
    documentId: number,
    document: DocumentDto | CreateOrEditDocumentDto
  ): Promise<Promise<CreateOrEditDocumentPageOutput>> {
    const showDate = !!LayoutUtils.getContentAreaByItemType(
      this.defaultPageDesign,
      LayoutItemType.Date,
      true
    );
    const masterLegendPageDesign = this.allLayoutPageDesigns?.find(
      (pageDesign) =>
        pageDesign.layoutType === DocumentPageLayoutType.None &&
        pageDesign.contentType === DocumentPageContentType.MasterLegend
    );
    const showPageNumber = masterLegendPageDesign
      ? !!LayoutUtils.getContentAreaByItemType(
          masterLegendPageDesign,
          LayoutItemType.PageNumber,
          true
        )
      : false;

    return await this.createPage(
      documentId,
      DocumentPageType.Content,
      DocumentPageContentType.MasterLegend,
      document.headerStyle?.show,
      document.footerStyle?.show,
      document.headerLayout,
      document.footerLayout,
      document.backgroundLayout,
      false,
      null,
      null,
      null,
      0,
      'MasterLegend',
      showDate,
      showPageNumber,
      false,
      StepsDesignerConfig.get.pageDesign.showTitle,
      StepsDesignerConfig.get.pageDesign.titleHeight,
      0,
      StepsDesignerConfig.get.pageDesign.titleLayout,
      DocumentPageLayoutType.None,
      null,
      null
    );
  }

  // used just when steps document is creating
  public async createLayoutPage(args: {
    documentId: number;
    pageDesign: PageDesignDto;
    isPristine: boolean;
    showHeader: boolean;
    showFooter: boolean;
    showDate: boolean;
    showPageNumber: boolean;
    order?: number;
  }): Promise<CreateOrEditDocumentPageOutput> {
    return await this.createPage(
      args.documentId,
      DocumentPageType.Content,
      DocumentPageContentType.Layout,
      args.showHeader,
      args.showFooter,
      null,
      null,
      null,
      false,
      null,
      null,
      null,
      args.order || 0,
      'New Page',
      args.showDate,
      args.showPageNumber,
      false,
      false,
      0,
      0,
      null,
      args.pageDesign.layoutType,
      args.pageDesign.bodyLayout
    );
  }

  /**
   * Once the document has been created and is ready to be navigated to, this method should be invoked
   *
   * @param documentId
   * @param steps
   */

  private onDocumentCreated(documentId: number, stepsDocument: boolean): void {
    // show toast
    this.$notify({
      title: this.$t('DOCUMENT_CREATED').toString(),
      type: 'success',
    });

    // setup ribbon
    this.$store.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_RIBBON_DEFAULT_TAB}`,
      RibbonTab.Home
    );
    // navigate
    this.$router.push({
      name: this.$routeNames.documentDetails,
      params: {
        [RouterParams.documentId]: documentId.toString(),
      },
    });

    if (stepsDocument) {
      AnalyticsService.trackEvent(AnalyticsEvent.DocumentCreatedSteps);
    } else {
      AnalyticsService.trackEvent(AnalyticsEvent.DocumentCreated);
    }
  }
}
