import Vue from 'vue';
import moment from 'moment';

import { formatCsvString } from '@/core/utils/common.utils';

const upper = (value: string) => {
  if (typeof value === 'string') {
    return value.toUpperCase();
  }

  return value;
};

const time = (value: string, format: string = 'L') => {
  if (value && moment(value).isValid()) {
    return moment(value).format(format);
  }

  return value;
};

export const fromNow = (value: string) => {
  if (value && moment(value).isValid()) {
    const isLessThen = moment(value).diff(Date.now(), 'hours') > -21;

    return isLessThen ? moment(value).fromNow() : time(value);
  }

  return value;
};

export const highlight = (words, query, highlightClass = 'highlight') => {
  query = query?.toString().replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') || ''; //eslint-disable-line

  return words.toString().trim().split(' ').map(word => {
    const queryWord = query.split(' ').find(q => word.toLowerCase().includes(q.toLowerCase()));
    if (!queryWord) {
      return '<span class="inline-block break-word">' + word + '</span>';
    }

    const check = new RegExp(queryWord.trim(), 'ig');
    const highlightWord = word.replace(check, (matchedText) => {
      if (!matchedText) {
        return '';
      }

      return "<span class='" + highlightClass + "'>" + matchedText + '</span>';
    });

    return '<span class="inline-block break-word">' + highlightWord + '</span>';
  }).join(' ');
}

Vue.filter('highlight', highlight);
Vue.filter('upper', upper);
Vue.filter('time', time);
Vue.filter('fromNow', fromNow);
Vue.filter('formatCsvString', formatCsvString);
