import {
  DiagramDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  DocumentDto,
  DocumentPageDto,
} from '@/api/models';
import IDisposable from '@/core/common/IDisposable';
import DiagramUtils from '@/core/utils/DiagramUtils';
import DocumentService from '../document/DocumentService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import Vue, { CreateElement } from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_PAGE_SYNC_CONTEXT,
  SET_PAGE_SYNC_CONTEXT,
} from '../store/document.module';
import PageSyncContext from './PageSyncContext';
import CommandExecutor from './PageSyncCommandExecutor';
import PageSyncType from './PageSyncType';
import IPageSyncItem from './IPageSyncItem';
import { PageSyncCommand } from './PageSyncCommand';
import LegendSyncService from './LegendSyncService';
import { debounceAccumulator } from '@/core/common/DebounceAccumulatorDecorator';
import BackgroundGraphService from '../graph/BackgroundGraphService';
import { PageSyncChangeType } from './PageSyncChangeType';
import INodePageSyncCommand from './INodePageSyncCommand';
import IEdgePageSyncCommand from './IEdgePageSyncCommand';
import { PageSyncCommandType } from './PageSyncCommandType';
import ISyncItemResult from './ISyncItemResult';
import ISyncNodeResult from './ISyncNodeResult';
import ISyncEdgeResult from './ISyncEdgeResult';
import SerializedDiagramUtils from './SerializedDiagramUtils';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import IPageSyncCommand from './IPageSyncCommand';
import DiagramLayoutHelper from '../graph/DiagramLayoutHelper';
import i18n from '@/core/plugins/vue-i18n';
import { ICustomButtons } from '@/components/shared/AppConfirm';
import ContentPagination from '../export/ContentPagination';
import { TryStopPageSyncSessionResult } from './TryStopPageSyncSessionResult';
import { confirm } from '@/components/shared/AppConfirm';
import { notify } from '@/components/shared/AppNotification';

export default class PageSyncService implements IDisposable {
  public static $class = 'PageSyncService';
  public isDisposed: boolean;

  private static _isModalShown = false;
  public static get isModalShown(): boolean {
    return PageSyncService._isModalShown;
  }

  public get enabled(): boolean {
    return this.context?.enabled;
  }

  private get currentDocument(): DocumentDto {
    return DocumentService.currentDocument;
  }

  private get currentPage(): DocumentPageDto {
    return DocumentService.selectedPage;
  }

  public get context(): PageSyncContext {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_PAGE_SYNC_CONTEXT}`
    ];
  }

  private set context(context: PageSyncContext) {
    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_PAGE_SYNC_CONTEXT}`,
      context
    );
  }

  private get shouldTrackChanges(): boolean {
    return !this.isDisposed && this.context.active;
  }

  constructor(graphService: IGraphService) {
    if (!this.currentDocument?.hasSteps) {
      return;
    }
    this.context = new PageSyncContext(
      this.currentDocument,
      this.currentPage,
      graphService
    );
    this.addEventListeners();
  }

  public dispose(): void {
    if (this.isDisposed) return;
    this.removeEventListeners();
    this.context?.dispose();
    this.isDisposed = true;
  }

  public static getInstance(
    graphService?: IGraphService
  ): PageSyncService | null {
    if (!graphService) {
      graphService = DocumentService.graphServiceInstance;
    }
    return graphService?.getService<PageSyncService>(PageSyncService.$class);
  }

  /**
   * Sync common nodes changes from current diagram to related ones
   */

  public async sync(type: PageSyncType, items: IPageSyncItem[]): Promise<void> {
    const commands =
      type == PageSyncType.Pending
        ? this.context.pendingCommands
        : this.context.appliedCommands;
    if (commands.length === 0 || items.length === 0) {
      return;
    }

    const modifiedItems: {
      pageSyncItem: IPageSyncItem;
      itemsToReroute: Set<DiagramNodeDto | DiagramEdgeDto>;
    }[] = [];
    for (const item of items) {
      let syncItemResult: ISyncItemResult = null;
      if (item.active) {
        syncItemResult = this.syncItem(item, commands);
      } else {
        syncItemResult = this.undoSyncItem(item);
      }
      if (syncItemResult.isModified) {
        this.ensureDiagramIsValidAfterSync(item.targetPage.diagram, commands);
        modifiedItems.push({
          pageSyncItem: item,
          itemsToReroute: syncItemResult.itemsToReroute,
        });
      }
    }

    for (const item of modifiedItems) {
      const page = item.pageSyncItem.targetPage;
      const diagram = page.diagram;
      const graphService = new BackgroundGraphService(diagram);

      await SerializedDiagramUtils.updateLegend(
        this.currentDocument,
        page,
        diagram,
        graphService.graph
      );
      SerializedDiagramUtils.updateRouting(
        this.currentPage.diagram,
        diagram,
        item.itemsToReroute,
        graphService
      );
      DiagramLayoutHelper.recalculateDefaultDiagramLayout(
        this.currentDocument,
        page,
        diagram
      );

      graphService.dispose();
    }

    if (modifiedItems.length > 0) {
      EventBus.$emit(
        EventBusActions.DOCUMENT_PAGES_SYNCED,
        modifiedItems.map((i) => i.pageSyncItem.targetPage)
      );
    }
  }

  public syncItem(
    item: IPageSyncItem,
    commands: PageSyncCommand[]
  ): ISyncItemResult {
    const syncItemResult: ISyncItemResult = {
      isModified: false,
      itemsToReroute: new Set(),
    };

    // Need to execute all node commands before executing edge commands (edges depend on source/target nodes being present)
    commands = [
      ...commands.filter(
        (c) => c.type == PageSyncCommandType.NodePageSyncCommand
      ),
      ...commands.filter(
        (c) => c.type == PageSyncCommandType.EdgePageSyncCommand
      ),
    ];

    for (const command of commands) {
      let result: ISyncNodeResult | ISyncEdgeResult = null;

      if (command.type === PageSyncCommandType.NodePageSyncCommand) {
        result = this.syncNode(command, item);
      } else if (command.type === PageSyncCommandType.EdgePageSyncCommand) {
        result = this.syncEdge(command, item);
      }

      if (result.isModified) {
        syncItemResult.isModified = true;
        if (result.item) {
          result.item.highlight = true;
        }
      }
      if (result.needRerouting && result.item) {
        syncItemResult.itemsToReroute.add(result.item);
      }
    }

    if (syncItemResult.isModified) {
      item.isModifiedInCurrentContext = true;
    }
    return syncItemResult;
  }

  public syncNode(
    command: INodePageSyncCommand,
    item: IPageSyncItem
  ): ISyncNodeResult {
    const targetNode = DiagramUtils.findRelatedItems(
      item.targetPage.diagram.nodes,
      command.originalItem
    )[0];
    if (
      !targetNode &&
      !command.changes.some(
        (change) => change.type == PageSyncChangeType.AddNode
      )
    ) {
      return {
        isModified: false,
        needRerouting: false,
        item: null,
      };
    }

    const sourceDiagram = this.context.page.diagram;

    return CommandExecutor.executeNodeCommand(
      command,
      sourceDiagram,
      targetNode,
      item.targetPage.diagram
    );
  }

  public syncEdge(
    command: IEdgePageSyncCommand,
    item: IPageSyncItem
  ): ISyncEdgeResult {
    const targetEdge = DiagramUtils.findRelatedItems(
      item.targetPage.diagram.edges,
      command.originalItem
    )[0];
    if (
      !targetEdge &&
      !command.changes.some(
        (change) => change.type == PageSyncChangeType.AddEdge
      )
    ) {
      return {
        isModified: false,
        needRerouting: false,
        item: null,
      };
    }

    const sourceDiagram = this.context.page.diagram;

    return CommandExecutor.executeEdgeCommand(
      command,
      sourceDiagram,
      targetEdge,
      item.targetPage.diagram
    );
  }

  public undoSyncItem(item: IPageSyncItem): ISyncItemResult {
    const syncItemResult: ISyncItemResult = {
      isModified: false,
      itemsToReroute: new Set(),
    };

    const targetDiagram = item.targetPage.diagram;

    if (this.undoSyncNodes(item, targetDiagram)) {
      syncItemResult.isModified = true;
    }
    if (this.undoSyncEdges(item, targetDiagram)) {
      syncItemResult.isModified = true;
    }

    if (syncItemResult.isModified) {
      item.isModifiedInCurrentContext = false;
    }

    targetDiagram.nodes.forEach((n) => (n.highlight = false));
    targetDiagram.edges.forEach((e) => (e.highlight = false));

    return syncItemResult;
  }

  private undoSyncNodes(
    item: IPageSyncItem,
    targetDiagram: DiagramDto
  ): boolean {
    let isModified = false;

    // Undo adding nodes
    const addedNodes = this.context.appliedCommands
      .filter((command) =>
        command.changes.some(
          (change) =>
            change.type == PageSyncChangeType.AddNode && command.updatedItem
        )
      )
      .map((command) => command.updatedItem as DiagramNodeDto);

    for (const addedNode of addedNodes) {
      let innerIsModified = false;
      const targetDiagramNode = targetDiagram.nodes.find(
        (n) => n.originalUuid === addedNode.uuid
      );
      if (targetDiagramNode) {
        innerIsModified = CommandExecutor.executeUndoAddNode(
          targetDiagramNode,
          targetDiagram
        );
      }
      if (innerIsModified) {
        isModified = true;
      }
    }

    // Undo modifying/deleting nodes
    for (const targetNodeSnapshot of item.targetNodeSnapshots) {
      let innerIsModified = false;
      const targetNode = targetDiagram.nodes.find(
        (n) => n.uuid == targetNodeSnapshot.uuid
      );
      if (!targetNode) {
        innerIsModified = CommandExecutor.executeUndoDeleteNode(
          targetNodeSnapshot,
          targetDiagram
        );
      } else {
        innerIsModified = CommandExecutor.executeUndoModifyNode(
          targetNode,
          targetNodeSnapshot
        );
      }
      if (innerIsModified) {
        isModified = true;
      }
    }

    return isModified;
  }

  private undoSyncEdges(
    item: IPageSyncItem,
    targetDiagram: DiagramDto
  ): boolean {
    let isModified = false;

    // Undo adding edges
    const addedEdges = this.context.appliedCommands
      .filter((command) =>
        command.changes.some(
          (change) =>
            change.type == PageSyncChangeType.AddEdge && command.updatedItem
        )
      )
      .map((command) => command.updatedItem as DiagramEdgeDto);

    for (const addedEdge of addedEdges) {
      let innerIsModified = false;
      const targetDiagramEdge = targetDiagram.edges.find(
        (e) => e.originalUuid === addedEdge.uuid
      );
      if (targetDiagramEdge) {
        innerIsModified = CommandExecutor.executeUndoAddEdge(
          targetDiagramEdge,
          targetDiagram
        );
      }
      if (innerIsModified) {
        isModified = true;
      }
    }

    // Undo modifying/deleting edges
    for (const targetEdgeSnapshot of item.targetEdgeSnapshots) {
      let innerIsModified = false;
      const targetEdge = targetDiagram.edges.find(
        (e) => e.uuid == targetEdgeSnapshot.uuid
      );
      if (!targetEdge) {
        innerIsModified = CommandExecutor.executeUndoDeleteEdge(
          targetEdgeSnapshot,
          targetDiagram
        );
      } else {
        innerIsModified = CommandExecutor.executeUndoModifyEdge(
          targetEdge,
          targetEdgeSnapshot,
          targetDiagram
        );
      }
      if (innerIsModified) {
        isModified = true;
      }
    }

    return isModified;
  }

  private addEventListeners(): void {
    EventBus.$on(EventBusActions.DIAGRAM_CHANGED, this.onDiagramChanged);
  }

  private removeEventListeners(): void {
    EventBus.$off(EventBusActions.DIAGRAM_CHANGED, this.onDiagramChanged);
  }

  private onDiagramChanged = async (
    oldDiagram: DiagramDto,
    newDiagram: DiagramDto
  ): Promise<void> => {
    if (!this.shouldTrackChanges) {
      return;
    }

    await this.onDiagramChangedDebounce({ oldDiagram, newDiagram } as any);
  };

  @debounceAccumulator(200, false, true)
  public async onDiagramChangedDebounce(
    accumulatedArgs: { oldDiagram: DiagramDto; newDiagram: DiagramDto }[]
  ): Promise<void> {
    if (!this.shouldTrackChanges) {
      return;
    }

    const originalDiagram = accumulatedArgs[0].oldDiagram;
    const latestDiagram =
      accumulatedArgs[accumulatedArgs.length - 1].newDiagram;
    this.context.refreshCommands(originalDiagram, latestDiagram);
    await this.sync(
      PageSyncType.Pending,
      this.context.items.filter((i) => i.active)
    );

    LegendSyncService.sync();
  }

  public enable(): void {
    this.context.enabled = true;
  }

  public disable(): void {
    this.context.enabled = false;
  }

  public cancelSync(): void {
    if (!this.context.enabled) {
      return;
    }

    const itemsToSync = this.context.items.filter((i) => i.active);
    itemsToSync.forEach((i) => (i.active = false));
    this.sync(PageSyncType.Full, itemsToSync);
  }

  public static async tryStopPageSyncSession(
    predicate?: () => boolean
  ): Promise<TryStopPageSyncSessionResult> {
    const pageSyncService = this.getInstance();
    if (
      !pageSyncService ||
      !pageSyncService.enabled ||
      (predicate && !predicate())
    ) {
      return TryStopPageSyncSessionResult.None;
    }

    return await pageSyncService.openSavePageSyncChangesModal();
  }

  public async openSavePageSyncChangesModal(): Promise<TryStopPageSyncSessionResult> {
    if (
      PageSyncService.isModalShown ||
      !this.context.active ||
      !this.context.items.some((i) => i.isModifiedInCurrentContext)
    ) {
      return TryStopPageSyncSessionResult.None;
    }

    const customButtons: ICustomButtons[] = [
      {
        text: i18n.t('CONFIRM_CHANGES'),
        action: 'saveAndClose',
        variant: 'purple',
        fullWidth: true,
      },
      {
        text: i18n.t('DONT_MIRROR'),
        action: 'closeWithoutSaving',
        fullWidth: true,
      },
      {
        text: i18n.t('GO_BACK'),
        action: 'cancel',
        fullWidth: true,
      },
    ];

    PageSyncService._isModalShown = true;
    try {
      const result = await confirm({
        title: i18n.t('EXIT_MIRRORING'),
        text: (h: CreateElement) => {
          return h('div', null, [
            h('p', null, i18n.t('END_MIRRORING_SESSION_WARNING')),
            h('br', null, ' '),
            h('p', null, this.buildModalBodyText()),
          ]);
        },
        minWidth: 550,
        maxWidth: 600,
        closeOnBackdrop: false,
        bodyClasses: 'text-14',
        showIcon: false,
        customButtons: customButtons,
      });

      if (result === 'saveAndClose') {
        this.context.active = false;
      } else if (result === 'closeWithoutSaving') {
        this.cancelSync();
        this.context.active = false;
        notify({
          title: i18n.t('CHANGES_NOT_MIRRORED_TO_DUPLICATES'),
        });
      }
      PageSyncService._isModalShown = false;
      return result === 'cancel'
        ? TryStopPageSyncSessionResult.Canceled
        : TryStopPageSyncSessionResult.Submitted;
    } finally {
      PageSyncService._isModalShown = false;
    }
  }

  private buildModalBodyText(): string {
    const modifiedItems = this.context.items.filter(
      (i) => i.active && i.isModifiedInCurrentContext
    );

    const pageNumbers = modifiedItems.map((i) =>
      ContentPagination.getPageNumber(
        DocumentService.currentDocument,
        i.targetPage
      )
    );

    return pageNumbers.length > 1
      ? i18n.t('X_PAGES_HAVE_UNCONFIRMED_MIRRORED_CHANGES_APPLIED', [
          pageNumbers.length,
        ])
      : i18n.t('1_PAGE_HAS_UNCONFIRMED_MIRRORED_CHANGES_APPLIED');
  }

  private ensureDiagramIsValidAfterSync(
    diagram: DiagramDto,
    commands: IPageSyncCommand<DiagramNodeDto | DiagramEdgeDto>[]
  ): void {
    const deleteNodeCommandExecuted = commands.some((c) =>
      c.changes.some((change) => change.type === PageSyncChangeType.DeleteNode)
    );
    if (deleteNodeCommandExecuted) {
      SerializedDiagramUtils.deleteEdgesWithoutNodes(diagram);
    }
  }
}
