enum ExportPagePart {
  None,
  All,
  Background,
  Header,
  Body,
  Footer,
  Diagram,
  Logo,
  Legend,
  Title,
}
export default ExportPagePart;
