import {
  BaseClass,
  BendEventArgs,
  CanvasComponent,
  CollectionModelManager,
  ConcurrencyController,
  Cursor,
  delegate,
  EdgeEventArgs,
  GraphComponent,
  GraphItemTypes,
  ICanvasObject,
  ICanvasObjectDescriptor,
  IEnumerable,
  IGraph,
  IGraphSelection,
  IInputMode,
  IInputModeContext,
  IModelItem,
  INode,
  InputModeBase,
  IRenderContext,
  ItemEventArgs,
  ItemSelectionChangedEventArgs,
  IVisualCreator,
  LabelEventArgs,
  MouseButtons,
  MouseEventArgs,
  NodeEventArgs,
  ObservableCollection,
  Point,
  PortEventArgs,
  Rect,
  Size,
  SvgVisual,
  Visual,
} from 'yfiles';
import Button from './Button';
import QueryButtonsEvent from './QueryButtonsEvent';
import ButtonCollection from './ButtonCollection';
import TriggerType from './TriggerType';
import QueryButtonsListener from './QueryButtonsListener';
import ButtonGroup from './ButtonGroup';
import ButtonDescriptor from './ButtonDescriptor';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_QUICK_START_STATE,
} from '@/core/services/store/document.module';
import { QuickStartState } from '@/api/models';

export default class JigsawButtonInputMode extends InputModeBase {
  public cursor: Cursor = null;
  public hoveredOwner: IModelItem = null;
  private validOwnerTypes: GraphItemTypes = GraphItemTypes.NODE;
  public hoveredButton: Button = null;
  private innerHitBoxIndicator: HitBoxIndicator = new HitBoxIndicator('green');
  private innerHitBoxCanvasObject: ICanvasObject;
  private buttonManager: CollectionModelManager<Button> = null;
  private allowTriggerOnHover = true;

  public get descriptor(): ICanvasObjectDescriptor {
    return this.buttonManager.descriptor;
  }

  private queryButtonsListener: QueryButtonsListener = null;

  /* Event Listeners */
  private graphChangedListener = this.onGraphChanged.bind(this);
  private itemRemovedListener = this.onItemRemoved.bind(this);
  private mouseMoveListener = this.onMouseMove.bind(this);
  private selectionChangedListener = this.onSelectionChange.bind(this);
  private mouseClickedListener = this.onMouseClicked.bind(this);
  private activeChangedListener = this.onActiveChanged.bind(this);

  public constructor() {
    super();
    this.priority = 0;
    this.enabled = true;
    this.exclusive = true;
    this.createButtonCollectionModel();
    this.cursor = Cursor.POINTER;
  }

  private createButtonCollectionModel(): void {
    this.buttonManager = new CollectionModelManager<Button>(Button.$class);
    this.buttonManager.descriptor = new ButtonDescriptor();
    this.buttonManager.model = new ObservableCollection<Button>();
  }

  private isActive(): boolean {
    return this.controller && this.controller.active;
  }

  private get buttons(): ObservableCollection<Button> {
    return this.buttonManager?.model as ObservableCollection<Button>;
  }
  /* Configuration / Setup / Tear Down */
  public install(
    context: IInputModeContext,
    controller: ConcurrencyController
  ): void {
    super.install(context, controller);
    const graphComponent = context.canvasComponent as GraphComponent;
    this.buttonManager!.canvasObjectGroup =
      graphComponent.inputModeGroup.addGroup();

    this.innerHitBoxCanvasObject =
      context.canvasComponent.inputModeGroup.addChild(
        this.innerHitBoxIndicator
      );
    this.addListeners(graphComponent, controller);
  }

  public uninstall(context: IInputModeContext): void {
    const graphComponent = context.canvasComponent as GraphComponent;
    this.removeListeners(graphComponent);
    this.updateHoveredButton(null);
    this.innerHitBoxCanvasObject.remove();
    this.buttonManager.canvasObjectGroup.remove();
    super.uninstall(context);
  }

  private addListeners(
    graphComponent: GraphComponent,
    controller: ConcurrencyController
  ): void {
    const graph = graphComponent.graph;
    graph.addNodeRemovedListener(this.itemRemovedListener);
    graph.addEdgeRemovedListener(this.itemRemovedListener);
    graph.addLabelRemovedListener(this.itemRemovedListener);
    graph.addPortRemovedListener(this.itemRemovedListener);
    graph.addBendRemovedListener(this.itemRemovedListener);

    graphComponent.addMouseMoveListener(this.mouseMoveListener);
    graphComponent.addGraphChangedListener(this.graphChangedListener);
    graphComponent.addMouseClickListener(this.mouseClickedListener);
    graphComponent.selection.addItemSelectionChangedListener(
      this.selectionChangedListener
    );

    controller.addActiveChangedListener(this.activeChangedListener);
  }

  private removeListeners(graphComponent: GraphComponent): void {
    const graph = graphComponent.graph;
    graph.removeNodeRemovedListener(this.itemRemovedListener);
    graph.removeEdgeRemovedListener(this.itemRemovedListener);
    graph.removeLabelRemovedListener(this.itemRemovedListener);
    graph.removePortRemovedListener(this.itemRemovedListener);
    graph.removeBendRemovedListener(this.itemRemovedListener);

    graphComponent.removeMouseMoveListener(this.mouseMoveListener);
    graphComponent.removeGraphChangedListener(this.graphChangedListener);
    graphComponent.removeMouseClickListener(this.mouseClickedListener);
    graphComponent.selection.removeItemSelectionChangedListener(
      this.selectionChangedListener
    );

    this.controller.removeActiveChangedListener(this.activeChangedListener);
  }

  /* Methods */
  public addQueryButtonsListener(listener: QueryButtonsListener): void {
    this.queryButtonsListener = delegate.combine(
      this.queryButtonsListener,
      listener
    ) as QueryButtonsListener;
  }
  public removeQueryButtonsListener(listener: QueryButtonsListener): void {
    this.queryButtonsListener = delegate.remove(
      this.queryButtonsListener,
      listener
    ) as QueryButtonsListener;
  }

  /**
   * If the @param predicate returns true, then this button will be removed
   * @param predicate
   */
  private removeButtonsQuery(predicate: (btn: Button) => boolean): void {
    for (let index = this.buttons.size - 1; index >= 0; index--) {
      const btn: Button = this.buttons.elementAt(index);
      if (predicate(btn)) {
        this.buttons.remove(btn);
      }
    }
  }

  public hideButton(button: Button): void {
    this.removeButtonsQuery((btn) => btn == button);
  }

  public showButton(button: Button): void {
    this.buttons.add(button);
  }

  public hideButtonsForOwner(owner: IModelItem): void {
    this.removeButtonsQuery((btn) => btn.owner == owner);
  }

  public refreshExistingButtons(): void {
    if (this.hasMutex()) {
      return;
    }
    this.removeButtonsQuery((btn) => {
      return !btn.canHide || btn.canHide(this, btn);
    });
  }

  /**
   * Asks all buttons to hide
   * Then queries EVERY node on the graph to see if any buttons need to be displayed
   * @returns
   */
  public queryAllButtons(): void {
    if (!this.inputModeContext) return;
    const canvasComponent = this.inputModeContext.canvasComponent;
    if (
      !(canvasComponent instanceof GraphComponent) ||
      !canvasComponent.graph
    ) {
      return;
    }

    this.refreshExistingButtons();
    const graph = canvasComponent.graph;
    const visibleNodes = graph.nodes
      .filter((n) =>
        n.style.renderer
          .getVisibilityTestable(n, n.style)
          .isVisible(this.inputModeContext, canvasComponent.viewport)
      )
      .toArray();
    for (let index = 0; index < visibleNodes.length; index++) {
      const node = visibleNodes[index];
      this.queryButtons(node);
    }
  }

  public queryButtons(node: INode): void {
    this.showButtons(node, 'query', null);
  }

  private showButtons(
    item: IModelItem,
    triggerType: TriggerType,
    location: Point
  ): void {
    if (!this.isActive()) {
      return;
    }

    if (!item) {
      return;
    }
    const newButtons = this.getButtons(item, triggerType, location);

    for (let index = 0; index < newButtons.length; index++) {
      const btnGroup = newButtons[index];
      const existing = this.buttons.find(
        (d) => d.tag.id == btnGroup.tag.id && d.owner == item
      );

      if (btnGroup instanceof ButtonGroup) {
        if (btnGroup.buttons.length == 0) {
          return;
        }

        if (!existing) {
          this.buttons.add(btnGroup);
          return;
        }
        if (!(existing instanceof ButtonGroup)) {
          throw 'Unable to merge ButtonGroup with type Button';
        }
        const newChildButtons = btnGroup.buttons.filter((newChildButton) =>
          existing.buttons.every(
            (childButton) => childButton.tag.id != newChildButton.tag.id
          )
        );
        // merge existing buttons with new ones
        existing.buttons.push(...newChildButtons);
      } else if (!existing) {
        this.buttons.add(btnGroup);
      }
    }
  }

  private getButtons(
    item: IModelItem,
    triggerType: TriggerType,
    location: Point
  ): Button[] {
    const isQuickBuildInProgress =
      Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_QUICK_START_STATE}`
      ] == QuickStartState.InProgress;

    const existingButtons: Button[] = this.buttons
      .filter((btn) =>
        isQuickBuildInProgress
          ? btn.owner === item
          : btn.owner.tag.uuid === item.tag.uuid
      )
      .toArray();
    const event = new QueryButtonsEvent(
      this,
      item,
      new ButtonCollection(existingButtons),
      triggerType,
      location
    );
    if (this.queryButtonsListener) {
      this.queryButtonsListener(this, event);
    }

    return event.newButtons;
  }

  private getHitButtons(
    context: IInputModeContext,
    location: Point
  ): IEnumerable<Button> {
    return context.canvasComponent
      .hitElementsAt(context, location, this.buttonManager.canvasObjectGroup)
      .map((canvasObject) => canvasObject.userObject as Button);
  }

  private updateHoveredButton(newButton: Button): void {
    if (this.hoveredButton != newButton) {
      if (this.hoveredButton) {
        // reset cursor
        this.controller!.preferredCursor = null;
        if (this.hasMutex()) {
          this.releaseMutex();
        }
        if (this.hoveredButton.onHoverOut) {
          this.hoveredButton.onHoverOut(this, this.hoveredButton);
        }
        this.hoveredButton = null;
        this.refreshExistingButtons();
      }

      this.hoveredButton = newButton;

      if (this.hoveredButton) {
        // set cursor specified by button or this input mode
        this.controller!.preferredCursor =
          this.hoveredButton.cursor ?? this.cursor;
        if (this.canRequestMutex()) {
          this.requestMutex();
        }
        if (this.hoveredButton.onHoverOver) {
          this.hoveredButton.onHoverOver(this, this.hoveredButton);
        }
      }
    } else if (this.hoveredButton) {
      this.controller!.preferredCursor =
        this.hoveredButton.cursor ?? this.cursor;
    }
  }

  private updateHoveredOwner(location: Point): void {
    const hitItem = this.getHitItem(
      this.inputModeContext?.canvasComponent as GraphComponent,
      location
    );

    this.innerHitBoxIndicator.layout = hitItem
      ? this.getInnerHoverRect(hitItem as INode)
      : null;
    if (hitItem != this.hoveredOwner) {
      if (hitItem) {
        this.hoveredOwner = hitItem;
        this.showButtons(this.hoveredOwner, 'hover', location);
      } else {
        this.hoveredOwner = null;
        this.refreshExistingButtons();
      }
    }
  }

  private triggerAction(btn: Button, evt: MouseEventArgs): void {
    // try to acquire the mutex at least a short time to prevent the default behavior
    if (!this.hasMutex() && this.canRequestMutex()) {
      this.requestMutex();
      this.releaseMutex();
    } else if (this.hasMutex()) {
      this.releaseMutex();
    }
    const tryRequestMutex = (): void => {
      if (this.canRequestMutex() || this.hasMutex()) {
        const newButton = this.getHitButtons(
          this.inputModeContext!,
          evt.location
        ).firstOrDefault();
        this.updateHoveredButton(newButton);
      }
    };
    const result = btn.onAction(this, btn, evt.location);
    if (result instanceof Promise) {
      result.then(() => {
        tryRequestMutex();
      });
    } else {
      tryRequestMutex();
    }
  }

  private getHitItem(
    graphComponent: GraphComponent,
    location: Point
  ): IModelItem {
    const context = this.inputModeContext!;

    return graphComponent
      .hitElementsAt(
        context,
        location,
        graphComponent.graphModelManager.nodeGroup,
        (o) => this.isValidItem(o.userObject)
      )
      .map((o) => o.userObject)
      .find() as IModelItem;
  }

  protected isValidItem(item: IModelItem): boolean {
    const itemType = GraphItemTypes.getItemType(item);
    return (this.validOwnerTypes & itemType) == itemType;
  }

  private getInnerHoverRect(node: INode): Rect {
    return node.layout.toRect();
  }

  /* Event Handlers */

  private onActiveChanged(): void {
    setTimeout(() => {
      this.queryAllButtons();
    }, 0);
  }

  private onGraphChanged(
    sender: GraphComponent,
    evt: ItemEventArgs<IGraph>
  ): void {
    if (evt.item) {
      this.removeListeners(sender);
    }
    if (sender.graph) {
      this.addListeners(sender, this.controller);
    }
  }
  private onItemRemoved(
    sender: IGraph,
    evt:
      | NodeEventArgs
      | EdgeEventArgs
      | LabelEventArgs
      | PortEventArgs
      | BendEventArgs
  ): void {
    this.hideButtonsForOwner(evt.item);
  }

  private onMouseMove(sender: CanvasComponent, evt: MouseEventArgs): void {
    if (
      (!this.canRequestMutex() && !this.hasMutex()) ||
      !this.allowTriggerOnHover
    ) {
      return;
    }
    const newButton = this.getHitButtons(
      this.inputModeContext!,
      evt.location
    ).firstOrDefault();
    this.updateHoveredButton(newButton);
    this.updateHoveredOwner(evt.location);
  }

  private onMouseClicked(sender: CanvasComponent, evt: MouseEventArgs): void {
    const leftClick = (evt.changedButtons & MouseButtons.LEFT) !== 0;
    if (leftClick) {
      const hitButton = this.getHitButtons(
        this.inputModeContext!,
        evt.location
      ).firstOrDefault();
      if (hitButton && !evt.defaultPrevented) {
        evt.preventDefault();
        this.triggerAction(hitButton, evt);
      }
    }
  }

  private onSelectionChange(
    sender: IGraphSelection,
    evt: ItemSelectionChangedEventArgs<IModelItem>
  ): void {
    if (sender.size > 1) {
      // We never show buttons when the selection count is great than 1
      this.refreshExistingButtons();
      return;
    }
    if (evt.itemSelected) {
      this.showButtons(evt.item, 'selection', null);
    } else {
      this.refreshExistingButtons();
    }
  }
}
const debug: boolean = false;
class HitBoxIndicator extends BaseClass<IVisualCreator>(IVisualCreator) {
  public layout: Rect | null;
  constructor(private readonly color: string) {
    super();
  }
  createVisual(context: IRenderContext): Visual {
    if (!debug) {
      return null;
    }
    if (!this.layout) {
      return null;
    }
    const element = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'rect'
    );
    element.setAttribute('width', this.layout.width.toString());
    element.setAttribute('height', this.layout.height.toString());
    element.setAttribute('x', this.layout.x.toString());
    element.setAttribute('y', this.layout.y.toString());
    element.setAttribute('stroke', this.color);
    element.setAttribute('fill', 'none');

    return new SvgVisual(element);
  }
  updateVisual(context: IRenderContext, oldVisual: Visual): Visual {
    return this.createVisual(context);
  }
}
