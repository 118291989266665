import DataPropertyTagTextMatcher from '../matchers/DataPropertyTagTextMatcher';
import DataPropertyTextMatcher from '../matchers/DataPropertyTextMatcher';
import EdgeTextMatcher from '../matchers/EdgeTextMatcher';
import NodeTextMatcher from '../matchers/NodeTextMatcher';
import ISearchParameter from '../parameters/ISearchParameter';
import TextParameter from '../parameters/TextParameter';
import ISpecification from '../specifications/ISpecification';
import MatchSpecification from '../specifications/MatchSpecification';
import OrSpecification from '../specifications/OrSpecification';
import Filter from './Filter';
import { FilterScope } from './FilterScope';
import { FilterType } from './FilterType';

export default class TextFilter extends Filter {
  static matchers = [
    new NodeTextMatcher(),
    new EdgeTextMatcher(),
    new DataPropertyTextMatcher(),
    new DataPropertyTagTextMatcher(),
  ];
  public type: FilterType;
  public scope = FilterScope.Text;
  public parameter: TextParameter;
  protected _specification: ISpecification;

  constructor(
    parameter: ISearchParameter,
    type: FilterType = FilterType.Include
  ) {
    super();
    this.type = type;
    this.parameter = parameter as TextParameter;

    const words = this.parameter.text
      .split(' ')
      .map((w) => w.trim())
      .filter((w) => w.length > 0);

    this._specification = new OrSpecification(
      words.map(
        (w) =>
          new MatchSpecification<TextParameter>(
            TextFilter.matchers,
            new TextParameter(w)
          )
      )
    );
  }
}
