export default class UrlParamsHelper {
  private static readonly returnUrlParam = 'ReturnUrl';
  private static readonly stateParam = 'state';

  public static getReturnUrl(): string {
    const urlParams = this.getSearchParams();
    return urlParams.get(this.returnUrlParam);
  }

  public static getState(): string {
    const urlParams = this.getSearchParams();
    return urlParams.get(this.stateParam);
  }

  private static getSearchParams(): URLSearchParams {
    const idx = window.location.hash.indexOf('?');
    if (idx >= 0) {
      return new URLSearchParams(window.location.hash.substr(idx));
    }
    return new URLSearchParams();
  }
}
