import { Features } from '@/core/common/Features';
import { HAS_LICENSE_FEATURE } from '@/core/services/store/licence.module';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({})
export default class FeaturesMixin extends Vue {
  @Getter(HAS_LICENSE_FEATURE)
  hasLicenseFeature: (payload: { featureName: Features }) => boolean;

  hasFeature(feature: Features): boolean {
    return this.hasLicenseFeature({ featureName: feature });
  }

  get featureType(): typeof Features {
    return Features;
  }
}
