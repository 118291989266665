﻿import * as signalR from '@microsoft/signalr';
import { appendUrl } from '@/core/utils/common.utils';
import appConfig from '@/core/config/appConfig';
import AuthService from '../auth/AuthService';
import { EventBus, EventBusActions } from '../events/eventbus.service';

export default class SignalRHelper<
  TMethods extends string,
  THandlers extends string
> {
  public connection: signalR.HubConnection;

  constructor(private readonly endPoint: string) {}
  public async start(): Promise<void> {
    const url = appendUrl(appConfig.apiBaseUrl, this.endPoint);

    this.connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => AuthService.getCachedAccessToken(),
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    try {
      await this.connection.start();
      console.debug(`Connected successfully to ${this.endPoint}`);
    } catch (err) {
      console.debug(`Failed to connect to ${this.endPoint} ${err}`);
    }

    this.connection.onclose((error) => {
      EventBus.$emit(EventBusActions.REALTIME_ONCLOSE, this.endPoint);
    });
    this.connection.onreconnected((error) => {
      EventBus.$emit(EventBusActions.REALTIME_ONRECONNECTED, this.endPoint);
    });
    this.connection.onreconnecting((error) => {
      EventBus.$emit(EventBusActions.REALTIME_ONRECONNECTING, this.endPoint);
    });
  }

  public get isConnected(): boolean {
    return this.connection?.state == signalR.HubConnectionState.Connected;
  }

  public on(methodName: THandlers, callback: (args: any[]) => void): void {
    this.connection.on(methodName, callback);
  }

  public invoke(method: TMethods, args: any): Promise<any> {
    return this.connection.invoke(method, args);
  }
}
