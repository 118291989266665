import { IModelItem, Point, Rect } from 'yfiles';
import Button from './Button';
import JigsawButtonInputMode from './JigsawButtonInputMode';

import TriggerType from './TriggerType';
import ButtonGroup from './ButtonGroup';
import ButtonCollection from './ButtonCollection';
import IButtonGroupOptions from './IButtonGroupOptions';
import IButtonOptions from './IButtonOptions';

export default class QueryButtonsEvent {
  private readonly _mode: JigsawButtonInputMode;
  private readonly _owner: IModelItem;
  private readonly _existingButtons: ButtonCollection;
  private readonly _newButtons: Button[];
  private readonly _triggerType: TriggerType;
  private readonly _location: Point;

  public constructor(
    mode: JigsawButtonInputMode,
    item: IModelItem,
    existingButtons: ButtonCollection,
    triggerType: TriggerType,
    location: Point
  ) {
    this._mode = mode;
    this._owner = item;
    this._existingButtons = existingButtons;
    this._newButtons = [];
    this._triggerType = triggerType;
    this._location = location;
  }

  public get inputMode(): JigsawButtonInputMode {
    return this._mode;
  }
  public get existingButtons(): ButtonCollection {
    return this._existingButtons;
  }

  public get newButtons(): Button[] {
    return this._newButtons;
  }

  public get triggerType(): TriggerType {
    return this._triggerType;
  }

  public get owner(): IModelItem {
    return this._owner;
  }

  public get location(): Point {
    return this._location;
  }

  public addButton(options: IButtonOptions): Button {
    const button = new Button(this.owner, options);
    this._newButtons.push(button);
    return button;
  }

  public addButtonGroup(options: IButtonGroupOptions): void {
    const buttonGroup = new ButtonGroup(this.owner, {
      buttonOptions: options.buttonOptions,
      anchor: options.anchor,
      fill: options.fill,
      stroke: options.stroke,
      strokeWidth: options.strokeWidth,
      tag: options.tag,
      cornerRadius: options.cornerRadius,
    });

    this._newButtons.push(buttonGroup);
  }
}
