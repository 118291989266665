<template>
  <img
    :src="image"
    :height="height"
    :width="width"
    @click="$emit('click', $event)"
  />
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppImage',
})
export default class AppImage extends Vue {
  @Prop({ default: '' }) image: string;
  @Prop({ default: 24 }) height: number;
  @Prop({ default: 24 }) width: number;
}
</script>
