import { GraphComponent, GraphMLSupport } from 'yfiles';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import ContentPagination from '../ContentPagination';
import PdfDocumentBuilder from '../pdf/PdfDocumentBuilder';
import ExportConfig from '@/core/config/ExportConfig';
import { customPdfFonts } from '../pdf/PdfFonts';
import PdfPageBuilder from '../pdf/PdfPageBuilder';
import { PdfTableLayouts } from '../pdf/PdfTableLayouts';
import ExportUtils from '../ExportUtils';

// Uncomment to use LegacyPdfExportProvider (deprecated)
// const pdfMakeImport = () => import('@jigsaw/pdfmake/build/pdfmake.js');
const pdfMakeImport = () => Promise.resolve(null);

/**
  LegacyPdfPageBuilder requies the following packages (currently removed):
    @jigsaw/html-to-pdfmake
    @jigsaw/pdfmake
    pdf-lib
 */
export default class LegacyPdfExportProvider implements IExportProvider {
  private _fileExtension = 'pdf';
  private _mimeType = 'application/pdf';

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    return this.exportDocumentPdf(options);
  }

  private async exportDocumentPdf(
    options: ExportOptions
  ): Promise<IExportResult> {
    const builder = new PdfDocumentBuilder();

    for (const exportPage of options.pages) {
      options.metadata.currentPage = exportPage;
      const pageExportResult = await this.exportPagePdf(options);
      builder.pages.push(pageExportResult.result as Blob);

      // Adjust current page number taking into account all the preceeding sub-pages
      // TODO uncomment to exclude layout pages from page numbering
      // if (exportPage.page.contentType != DocumentPageContentType.Layout) {
      options.metadata.currentPageNumber += ContentPagination.getPageCount(
        exportPage.page
      );
      //}
    }
    const pdfData = await builder.getDocument();
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: new Blob([pdfData], { type: 'application/pdf' }),
    };
  }

  private async exportPagePdf(options: ExportOptions): Promise<IExportResult> {
    if (!options.metadata.currentPage) {
      throw 'No page to export';
    }

    const builder = new PdfPageBuilder(options);
    const pageDefinition = await builder.getPageDefinition();

    async function getBlobAsync(): Promise<Blob> {
      const pdfMake = await pdfMakeImport();

      return new Promise<Blob>((resolve, reject) => {
        try {
          const contentPadding = ExportUtils.calculatePadding(
            options.document,
            options.metadata.currentPage.page,
            'htmlContent'
          );

          const tableLayouts = {
            noPadding: PdfTableLayouts.noPaddingTableLayout,
            headerFooter: PdfTableLayouts.headerFooterTableLayout,
            textElement: PdfTableLayouts.textElementTableLayout,
            htmlContent:
              PdfTableLayouts.createHtmlContentTableLayout(contentPadding),
          };

          pdfMake
            .createPdf(pageDefinition, tableLayouts, customPdfFonts)
            .getBlob((blob: Blob) => {
              resolve(blob);
            });
          if (ExportConfig.pdfmakePlaygroundMode) {
            console.debug(pageDefinition);
          }
        } catch (e) {
          reject(e);
        }
      });
    }

    const blob = await getBlobAsync();

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: blob,
    };
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    throw new Error('Not supported');
  }
}
