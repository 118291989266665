import diagramConfig from '@/core/config/diagram.definition.config';
import { calculateHash } from '@/core/utils/common.utils';
import StyleCreator from '@/core/utils/StyleCreator';
import {
  BaseClass,
  DefaultGraph,
  GeneralPath,
  GraphComponent,
  HashMap,
  IBoundsProvider,
  ICanvasContext,
  IModelItem,
  INode,
  Intersection,
  IntersectionItemTypes,
  Intersections,
  IRectangle,
  IRenderContext,
  IVisualCreator,
  Point,
  Rect,
  Stroke,
  SvgVisual,
  SvgVisualGroup,
  Visual,
  YObject,
} from 'yfiles';

export type GroupOptions = {
  margin: number;
  glueExtent: number;
  minimumBridgeSize: number;
};

type GroupOptionsProvider = () => GroupOptions;

type Axis = 'horizontal' | 'vertical' | undefined;
class Segment extends YObject {
  constructor(public p1: Point, public p2: Point, public direction: Direction) {
    super();
  }

  public get horizontal(): boolean {
    return this.direction % 2 === 0;
  }

  public isOnSegment(p: Point): boolean {
    if (this.horizontal) {
      const minX = Math.min(this.p1.x, this.p2.x);
      const maxX = Math.max(this.p1.x, this.p2.x);
      return p.y === this.p1.y && minX < p.x && p.x < maxX;
    } else {
      const minY = Math.min(this.p1.y, this.p2.y);
      const maxY = Math.max(this.p1.y, this.p2.y);
      return p.x === this.p1.x && minY < p.y && p.y < maxY;
    }
  }

  public split(p: Point): Segment {
    const { p2 } = this;
    this.p2 = p;
    return new Segment(p, p2, this.direction);
  }

  public isInside(rect: IRectangle): boolean {
    const minX = Math.min(this.p1.x, this.p2.x);
    const maxX = Math.max(this.p1.x, this.p2.x);
    const minY = Math.min(this.p1.y, this.p2.y);
    const maxY = Math.max(this.p1.y, this.p2.y);
    if (this.horizontal) {
      return (
        rect.x <= minX && maxX <= rect.maxX && rect.y < minY && minY < rect.maxY
      );
    } else {
      return (
        rect.x < minX && minX < rect.maxX && rect.y <= minY && maxY <= rect.maxY
      );
    }
  }

  public has(p: Point): boolean {
    return p.equals(this.p1) || p.equals(this.p2);
  }

  toString(): string {
    return `(${this.p1.x}, ${this.p1.y})-(${this.p2.x}, ${this.p2.y})`;
  }

  hashCode(): number {
    const k = 31;
    const minX = Math.min(this.p1.x, this.p2.x);
    const maxX = Math.max(this.p1.x, this.p2.x);
    const minY = Math.min(this.p1.y, this.p2.y);
    const maxY = Math.max(this.p1.y, this.p2.y);
    return minX + k * (maxX + k * (minY + k * maxY));
  }

  equals(other: any): boolean {
    const minX = Math.min(this.p1.x, this.p2.x);
    const maxX = Math.max(this.p1.x, this.p2.x);
    const minY = Math.min(this.p1.y, this.p2.y);
    const maxY = Math.max(this.p1.y, this.p2.y);

    const otherMinX = Math.min(other.p1.x, other.p2.x);
    const otherMaxX = Math.max(other.p1.x, other.p2.x);
    const otherMinY = Math.min(other.p1.y, other.p2.y);
    const otherMaxY = Math.max(other.p1.y, other.p2.y);

    return (
      minX === otherMinX &&
      maxX === otherMaxX &&
      minY === otherMinY &&
      maxY === otherMaxY
    );
  }
}

type Segments = {
  horizontalSegments: Map<number, Segment[]>;
  verticalSegments: Map<number, Segment[]>;
};
const VISUAL_HASH_KEY = 'NodeHash';
export default class GroupingVisual extends BaseClass(
  IVisualCreator,
  IBoundsProvider
) {
  private _optionsProvider: () => {
    margin: number;
    glueExtent: number;
    minimumBridgeSize: number;
  };
  private bounds: Rect = null;

  constructor(options: { valueProvider: GroupOptionsProvider }) {
    super();

    if (!options) {
      throw 'options is required';
    }
    this._optionsProvider = options.valueProvider;
  }

  private getGroupedNodes(context: ICanvasContext): INode[] {
    return (context.canvasComponent as GraphComponent).graph.nodes
      .filter((d) => !d.tag.isGroupNode && !!d.tag.groupUuid)
      .toArray();
  }

  private getParentNodes(context: ICanvasContext): INode[] {
    return (context.canvasComponent as GraphComponent).graph.nodes
      .filter((d) => d.tag.isGroupNode && !!d.tag.groupUuid)
      .toArray();
  }

  private getHash(
    groupedNodes: INode[],
    parentNodes: INode[],
    options: GroupOptions
  ): string {
    let hashString = groupedNodes
      .map(
        (node) =>
          `${node.layout.x},${node.layout.y},${node.layout.width},${node.layout.height}`
      )
      .join('-');

    hashString += parentNodes
      .map(
        (node) =>
          `${node.tag.grouping.fillColor},${node.tag.grouping.strokeColor},${node.tag.grouping.strokeWidth},${node.tag.grouping.strokeDash}`
      )
      .join('-');

    hashString += `${options.margin}-${options.glueExtent}-${options.minimumBridgeSize}`;

    return calculateHash(hashString);
  }

  getBounds(context: ICanvasContext): Rect {
    const paths = this.getPaths(context, this._optionsProvider());
    if (paths.size == 0) {
      return Rect.EMPTY;
    }
    const allBounds: Rect[] = [...paths.values()].map((d) => d.getBounds());

    const minX = Math.min(...allBounds.map((d) => d.minX));
    const minY = Math.min(...allBounds.map((d) => d.minY));
    const maxX = Math.max(...allBounds.map((d) => d.maxX));
    const maxY = Math.max(...allBounds.map((d) => d.maxY));
    return new Rect(minX, minY, maxX - minX, maxY - minY);
  }

  private getPaths(
    context: ICanvasContext,
    options: GroupOptions
  ): Map<INode, GeneralPath> {
    const graphComponent = context.canvasComponent as GraphComponent;
    const graph = graphComponent.graph;
    const m = options.margin;
    const groupedNodes = this.getGroupedNodes(context);
    const rectsByParent = new Map<INode, Rect[]>();

    for (const node of groupedNodes) {
      const parent = graph.getParent(node);
      const color = parent?.tag.grouping.fillColor;
      if (!color) {
        continue;
      }
      const nodesWithColor = rectsByParent.get(parent);
      const rect = node.layout.toRect().getEnlarged(m);

      if (nodesWithColor) {
        nodesWithColor.push(rect);
      } else {
        rectsByParent.set(parent, [rect]);
      }
    }
    const parentNodePathMap: Map<INode, GeneralPath> = new Map();
    for (const parent of rectsByParent.keys()) {
      const ge = options.glueExtent;
      const mbs = options.minimumBridgeSize;
      const gp = createGeometry(rectsByParent.get(parent)!, ge, mbs);
      parentNodePathMap.set(parent, gp);
    }
    return parentNodePathMap;
  }

  createVisual(context: IRenderContext): Visual | null {
    const groupedNodes = this.getGroupedNodes(context);
    const parentNodes = this.getParentNodes(context);
    var options = this._optionsProvider();
    const visualGroup = new SvgVisualGroup();
    for (const map of this.getPaths(context, options)) {
      const parent = map[0];
      const gp = map[1];
      const fillColor = parent.tag.grouping.fillColor;
      const strokeColor = parent.tag.grouping.strokeColor;
      const strokeWidth = parent.tag.grouping.strokeWidth;
      const strokeDash = parent.tag.grouping.strokeDash;

      var path = gp.createSvgPath();
      path.setAttribute('fill', fillColor);
      path.setAttribute(
        'fill-opacity',
        diagramConfig.groupingDefaults.fillOpacity.toString()
      );
      path.setAttribute('group-uuid', parent.tag.groupUuid);
      const dashStyle = StyleCreator.getDashStyle(strokeDash);
      const stroke = new Stroke({
        dashStyle: dashStyle,
        fill: strokeColor,
        thickness: strokeWidth,
      });
      stroke.applyTo(path, context);

      visualGroup.add(new SvgVisual(path));
    }
    visualGroup[VISUAL_HASH_KEY] = this.getHash(
      groupedNodes,
      parentNodes,
      options
    );
    return visualGroup;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual | null
  ): Visual | null {
    const oldHash = oldVisual[VISUAL_HASH_KEY];
    if (!oldHash) {
      return this.createVisual(context);
    }
    const groupedNodes = this.getGroupedNodes(context);
    const options = this._optionsProvider();
    const newHash = this.getHash(
      groupedNodes,
      this.getParentNodes(context),
      options
    );
    if (oldHash != newHash) {
      return this.createVisual(context);
    }

    return oldVisual;
  }
}

function addGlueRects(
  rects: Rect[],
  glueExtent: number,
  minBridgeSize: number
): void {
  const graph = new DefaultGraph();
  for (const rect of rects) {
    const x = rect.x;
    const y = rect.y;
    const w = rect.width;
    const h = rect.height;
    graph.createNode({
      layout: [x, y - glueExtent, w, glueExtent],
      tag: 'top',
    });
    graph.createNode({
      layout: [x - glueExtent, y, glueExtent, h],
      tag: 'left',
    });
    graph.createNode({ layout: [x, y + h, w, glueExtent], tag: 'bottom' });
    graph.createNode({ layout: [x + w, y, glueExtent, h], tag: 'right' });
  }

  const intersections = new Intersections();
  intersections.consideredItemTypes = IntersectionItemTypes.NODE;
  const result = intersections.run(graph);

  for (const intersection of result.intersections) {
    const points = intersection.intersectionPoints;
    if (points.size < 2) {
      continue;
    }

    const item1 = intersection.item1;
    const item2 = intersection.item2;
    const direction = getGlueDirection(item1.tag, item2.tag);
    if (!direction) {
      continue;
    }

    let r = Rect.EMPTY;
    for (const point of points) {
      r = r.add(point);
    }

    let size = 0;
    if (direction === 'vertical') {
      for (const item of [item1, item2]) {
        const nl = (item as INode).layout;
        switch (item.tag) {
          case 'bottom':
            r = r.add(new Point(r.x, nl.y));
            break;
          case 'top':
            r = r.add(new Point(r.x, nl.maxY));
            break;
        }
      }
      size = r.width;
    } else if (direction === 'horizontal') {
      for (const item of [item1, item2]) {
        const nl = (item as INode).layout;
        switch (item.tag) {
          case 'right':
            r = r.add(new Point(nl.x, r.y));
            break;
          case 'left':
            r = r.add(new Point(nl.maxX, r.y));
            break;
        }
      }
      size = r.height;
    }

    if (size >= minBridgeSize && r.area > 0) {
      rects.push(r);
    }
  }
}

function getGlueDirection(tag1: string, tag2: string): Axis {
  switch (tag1) {
    case 'top':
      return tag2 === 'bottom' ? 'vertical' : undefined;
    case 'left':
      return tag2 === 'right' ? 'horizontal' : undefined;
    case 'bottom':
      return tag2 === 'top' ? 'vertical' : undefined;
    case 'right':
      return tag2 === 'left' ? 'horizontal' : undefined;
  }
  return undefined;
}

function createGeometry(
  rects: Rect[],
  glueExtent: number,
  minBridgeSize: number
): GeneralPath {
  addGlueRects(rects, glueExtent * 0.5, minBridgeSize);
  return createGeometryImpl(rects);
}

function createGeometryImpl(rects: Rect[]): GeneralPath {
  // Take all exterior rectangle line segments (4 per rect)
  // Find all intersection points
  // For each intersection point:
  //   Find the segments that are split by it
  //   Split those segments into two
  // Remove interior segments
  // Now we only have exterior segments and just have to order them

  const graph = new DefaultGraph();
  for (const rect of rects) {
    graph.createNode({ layout: rect });
  }
  const intersections = new Intersections();
  intersections.consideredItemTypes = IntersectionItemTypes.NODE;
  const result = intersections.run(graph);

  const clustersOfIntersectingItems = new Set<Set<IModelItem>>();
  const itemToCluster: Map<IModelItem, Set<IModelItem>> = new Map();
  const itemToIntersections: Map<IModelItem, Intersection[]> = new Map();

  function addIntersection(intersection: Intersection): void {
    if (itemToIntersections.has(intersection.item1)) {
      itemToIntersections.get(intersection.item1)?.push(intersection);
    } else {
      itemToIntersections.set(intersection.item1, [intersection]);
    }
    if (itemToIntersections.has(intersection.item2)) {
      itemToIntersections.get(intersection.item2)?.push(intersection);
    } else {
      itemToIntersections.set(intersection.item2, [intersection]);
    }
  }

  function mergeSets(intersection: Intersection): void {
    const { item1, item2 } = intersection;
    const set1 = itemToCluster.get(item1);
    const set2 = itemToCluster.get(item2);
    if (set1) {
      if (set2) {
        if (set1 !== set2) {
          set2.forEach((item) => {
            set1.add(item);
            itemToCluster.set(item, set1);
          });
          clustersOfIntersectingItems.delete(set2);
        }
      } else {
        set1.add(item2);
        itemToCluster.set(item2, set1);
      }
    } else {
      if (set2) {
        set2.add(item1);
        itemToCluster.set(item1, set2);
      } else {
        const set = new Set([item1, item2]);
        itemToCluster.set(item1, set);
        itemToCluster.set(item2, set);
        clustersOfIntersectingItems.add(set);
      }
    }
  }

  for (const intersection of result.intersections) {
    if (intersection.intersectionPoints.size < 2) {
      continue;
    }

    addIntersection(intersection);
    mergeSets(intersection);
  }

  function insertSegment(
    segmentMap: Map<number, Segment[]>,
    coordinate: number,
    segment: Segment
  ): void {
    const array = segmentMap.get(coordinate);
    if (array) {
      array.push(segment);
    } else {
      segmentMap.set(coordinate, [segment]);
    }
  }

  function initializeSegmentsByCoordinate(segments: Segment[]): Segments {
    const horizontalSegments: Map<number, Segment[]> = new Map();
    const verticalSegments: Map<number, Segment[]> = new Map();

    for (const segment of segments) {
      if (segment.horizontal) {
        insertSegment(horizontalSegments, segment.p1.y, segment);
      } else {
        insertSegment(verticalSegments, segment.p1.x, segment);
      }
    }

    return { horizontalSegments, verticalSegments };
  }

  function splitSegmentsAtIntersection(
    intersectionPoint: Point,
    segments: Segment[] | undefined
  ): void {
    if (segments) {
      for (let i = segments.length - 1; i > -1; --i) {
        const segment = segments[i];
        if (segment.isOnSegment(intersectionPoint)) {
          const newSegment = segment.split(intersectionPoint);
          segments.push(newSegment);
        }
      }
    }
  }

  function splitSegmentsAtIntersections(
    cluster: Set<IModelItem>,
    { horizontalSegments, verticalSegments }: Segments
  ): void {
    for (const item of cluster) {
      for (const intersection of itemToIntersections.get(item)!) {
        for (const intersectionPoint of intersection.intersectionPoints) {
          // Determine whether this point splits any segments
          splitSegmentsAtIntersection(
            intersectionPoint,
            horizontalSegments.get(intersectionPoint.y)
          );
          splitSegmentsAtIntersection(
            intersectionPoint,
            verticalSegments.get(intersectionPoint.x)
          );
        }
      }
    }
  }

  const gp = new GeneralPath();

  for (const cluster of clustersOfIntersectingItems) {
    // Prepare and place all segments into sorted arrays based on either x or y location of the segment
    const segments = initializeSegmentsByCoordinate(createSegments(cluster));

    splitSegmentsAtIntersections(cluster, segments);

    removeDuplicateSegments(segments);

    appendToPath(gp, segments);
  }

  // Append the isolated nodes that do not intersect anything as well
  for (const node of graph.nodes) {
    if (!itemToCluster.has(node)) {
      gp.appendRectangle(node.layout, false);
    }
  }

  return gp;
}

function createSegments(cluster: Set<IModelItem>): Segment[] {
  const segments: Segment[] = [];
  for (const item of cluster) {
    const nl = (item as INode).layout;
    segments.push(new Segment(nl.topLeft, nl.topRight, Direction.Right));
    segments.push(new Segment(nl.bottomRight, nl.bottomLeft, Direction.Left));
    segments.push(new Segment(nl.bottomLeft, nl.topLeft, Direction.Up));
    segments.push(new Segment(nl.topRight, nl.bottomRight, Direction.Down));
  }
  return segments;
}

function removeDuplicateSegments({
  horizontalSegments,
  verticalSegments,
}: Segments): void {
  for (const bucket of horizontalSegments.values()) {
    removeDuplicateSegmentsImpl(bucket);
  }
  for (const bucket of verticalSegments.values()) {
    removeDuplicateSegmentsImpl(bucket);
  }
}

function removeDuplicateSegmentsImpl(segments: Segment[]): void {
  // stores for a given line segment all the sides of the original rectangles
  // this line segment belongs to
  const duplicates = new HashMap<Segment, Set<Direction>>();
  for (const segment of segments) {
    if (duplicates.has(segment)) {
      duplicates.get(segment)!.add(segment.direction);
    } else {
      duplicates.set(segment, new Set<Direction>([segment.direction]));
    }
  }

  const empty = new Set<Direction>();
  for (let i = segments.length - 1; i > -1; --i) {
    const segment = segments[i];
    if (duplicates.get(segment)!.size === 1) {
      duplicates.set(segment, empty);
    } else {
      segments.splice(i, 1);
    }
  }
}

function appendToPath(
  path: GeneralPath,
  { horizontalSegments, verticalSegments }: Segments
): void {
  let segmentCount = 0;

  let min = Number.MAX_VALUE;
  for (const key of verticalSegments.keys()) {
    segmentCount += verticalSegments.get(key)!.length;
    if (min > key) {
      min = key;
    }
  }
  for (const bucket of horizontalSegments.values()) {
    segmentCount += bucket.length;
  }

  const bucket = verticalSegments.get(min)!;
  const startSegment = bucket[0];

  const startPoint = startSegment.p1;
  let currentPoint = startSegment.p2;
  let currentSegment = startSegment;
  path.moveTo(startPoint);
  path.lineTo(currentPoint);

  const n = segmentCount;
  const needle = new Segment(Point.ORIGIN, Point.ORIGIN, Direction.Right);
  for (let i = 0; i < n && !currentPoint.equalsEps(startPoint, 1e-10); ++i) {
    const segmentsAtPoint: Segment[] = [];

    const x = currentPoint.x;
    const y = currentPoint.y;
    for (const segment of verticalSegments.get(x)!) {
      if (segment.p1.y === y) {
        segmentsAtPoint.push(segment);
      }
    }
    for (const segment of horizontalSegments.get(y)!) {
      if (segment.p1.x === x) {
        segmentsAtPoint.push(segment);
      }
    }

    needle.direction = (currentSegment.direction + 2) % 4;
    segmentsAtPoint.push(needle);
    segmentsAtPoint.sort((ds1: Segment, ds2: Segment) => {
      const d1 = ds1.direction;
      const d2 = ds2.direction;
      if (d1 < d2) {
        return -1;
      } else if (d1 > d2) {
        return 1;
      } else {
        return 0;
      }
    });

    const index = segmentsAtPoint.indexOf(needle);
    currentSegment = segmentsAtPoint[(index + 1) % segmentsAtPoint.length];
    currentPoint = currentSegment.p2;

    path.lineTo(currentPoint);
  }
  path.close();
}

enum Direction {
  Right,
  Down,
  Left,
  Up,
}
