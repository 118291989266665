import OwnershipMatcher from '../matchers/OwnershipMatcher';
import ISearchParameter from '../parameters/ISearchParameter';
import OwnershipParameter from '../parameters/OwnershipParameter';
import ISpecification from '../specifications/ISpecification';
import MatchSpecification from '../specifications/MatchSpecification';
import Filter from './Filter';
import { FilterScope } from './FilterScope';
import { FilterType } from './FilterType';

export default class OwnershipFilter extends Filter {
  static matchers = [new OwnershipMatcher()];
  public type: FilterType;
  public scope = FilterScope.Ownership;
  public parameter: OwnershipParameter;
  protected _specification: ISpecification;

  constructor(
    parameter: ISearchParameter,
    type: FilterType = FilterType.Include
  ) {
    super();
    this.type = type;
    this.parameter = parameter as OwnershipParameter;
    this._specification = new MatchSpecification<OwnershipParameter>(
      OwnershipFilter.matchers,
      this.parameter
    );
  }

  // Ownership filters behave like metadata and should not affect the search results
  // Override base implementation to ignore filter type and not invert the specification
  get specification() {
    return this._specification;
  }
}
