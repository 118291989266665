import { waitWhile } from '@/core/utils/common.utils';
import { DocumentSyncMessageType } from './DocumentSyncMessageType';
import DocumentSyncOptions from './DocumentSyncOptions';
import DocumentSyncRequest from './DocumentSyncRequest';
import DocumentSyncResponse from './DocumentSyncResponse';

export default class DocumentSyncService {
  private channelName = 'jigsaw_document_sync';
  private channel: BroadcastChannel = null;
  private options: DocumentSyncOptions;
  private responseTimeout = 300; // How long to wait (in ms) for other tabs to respond to sync request
  private responseListener: (response: DocumentSyncResponse) => void;

  constructor() {
    this.channel = new BroadcastChannel(this.channelName);
    this.channel.addEventListener('message', this.onSyncMessage);
  }

  public start(options: DocumentSyncOptions) {
    this.stop();
    this.options = options;
  }

  public stop() {
    this.options = null;
    this.responseListener = null;
  }

  public checkEditingExternally(documentId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      (async (): Promise<void> => {
        try {
          let responseReceived = false;
          this.responseListener = (response): void => {
            if (response.documentId == documentId && response.isEditing) {
              responseReceived = true;
              resolve(true);
            }
          };
          this.channel.postMessage(new DocumentSyncRequest(documentId));
          await waitWhile(() => responseReceived, this.responseTimeout);
          resolve(false);
        } catch (e) {
          console.debug(e);
          reject(e);
        } finally {
          this.responseListener = null;
        }
      })();
    });
  }

  private onSyncMessage = (evt: MessageEvent) => {
    console.debug('Document sync message', evt.data);
    switch (evt.data.type) {
      case DocumentSyncMessageType.Request:
        this.onRequestMessage(evt.data as DocumentSyncRequest);
        break;
      case DocumentSyncMessageType.Response:
        this.onResponseMessage(evt.data as DocumentSyncResponse);
        break;
    }
  };

  private onRequestMessage = (request: DocumentSyncRequest) => {
    if (!this.options) {
      return;
    }
    const isEditing =
      !this.options.isReadOnly && this.options.documentId == request.documentId;
    this.channel.postMessage(
      new DocumentSyncResponse(request.documentId, isEditing)
    );
  };

  private onResponseMessage = (response: DocumentSyncResponse) => {
    if (this.responseListener) {
      this.responseListener(response);
    }
  };
}
