import { Module } from 'vuex';
import Vue from 'vue';
import { ThemeElementDto } from '@/api/models';
export const SET_ELEMENT_PREVIEW = 'setElementPreview';
export const CLEAR_ELEMENT_PREVIEW = 'clearElementPreview';
export const GET_ELEMENT_PREVIEW = 'getElementPreview';

function getPreview(elementId: ThemeElementDto | number, previews: Object) {
  if (elementId instanceof ThemeElementDto) {
    elementId = elementId.id;
  }

  if (elementId == null || isNaN(elementId)) {
    return;
  }

  return previews[elementId];
}

const svgPreviewModule: Module<any, any> = {
  state: {
    elementPreviews: {},
  },
  mutations: {
    [SET_ELEMENT_PREVIEW](state, { id, src }) {
      Vue.set(state.elementPreviews, id, src);
    },

    [CLEAR_ELEMENT_PREVIEW](state, { id }) {
      Vue.set(state.elementPreviews, id, null);
    },
  },
  actions: {
    [SET_ELEMENT_PREVIEW](context, { id, src }) {
      context.commit(SET_ELEMENT_PREVIEW, { id, src });
    },

    [CLEAR_ELEMENT_PREVIEW](context, { id }) {
      context.commit(CLEAR_ELEMENT_PREVIEW, { id });
    },
  },
  getters: {
    [GET_ELEMENT_PREVIEW](state) {
      return (id) => getPreview(id, state.elementPreviews);
    },
  },
};
export default svgPreviewModule;
