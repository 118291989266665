export enum HitResultLocation {
  NONE,
  NODE,
  DECORATOR,
}

/**
 * HitResult
 */
export default class HitResult {
  /**
   * A simple HitResult of type none
   */
  static NONE: HitResult = new HitResult({
    hitLocation: HitResultLocation.NONE,
    isHit: false,
  });

  static ALWAYS_DECORATOR: HitResult = new HitResult({
    hitLocation: HitResultLocation.DECORATOR,
    isHit: true,
  });

  /**
   * An enum of position hit types
   */
  public hitLocation: HitResultLocation;
  /**
   * Determines whether it was hit
   */
  public isHit: boolean;
  /**
   * An object which can contain any meta data about the hit.
   * Each decorator implementation can provide data to something that consumes the hit result   *
   */
  public meta: any;
  /**
   * A unique key defining which decorator was hit
   */
  public decoratorType: any;

  constructor(options?: {
    hitLocation?: HitResultLocation;
    isHit?: boolean;
    meta?: any;
    decoratorType?: any;
  }) {
    if (!options) {
      options = {};
    }
    this.hitLocation = options.hitLocation ?? HitResultLocation.NONE;
    this.isHit = options.isHit ?? false;
    this.meta = options.meta ?? null;
    this.decoratorType = options.decoratorType ?? null;
  }
}
