import { NodeShape } from '@/api/models';

export default [
  {
    name: 'DIAMOND',
    shape: NodeShape.Diamond,
    id: 70001,
    fill: '#ABDEE6',
  },
  {
    name: 'CIRCLE',
    shape: NodeShape.Circle,
    id: 70002,
    fill: '#CBAACB',
  },
  {
    name: 'ARROW_RIGHT',
    shape: NodeShape.FatArrow,
    id: 70003,
    fill: '#FFFFB5',
  },
  {
    name: 'ARROW_LEFT',
    shape: NodeShape.FatArrow2,
    id: 70004,
    fill: '#F3B0C3',
  },
  {
    name: 'HEXAGON',
    shape: NodeShape.Hexagon,
    id: 70005,
    fill: '#C6DBDA',
  },
  {
    name: 'RECTANGLE',
    shape: NodeShape.Rectangle,
    id: 70006,
    fill: '#FEE1E8',
  },
  {
    name: 'ROUNDED_RECTANGLE',
    shape: NodeShape.RoundedRectangle,
    id: 70007,
    fill: '#FED7C3',
  },
  {
    name: 'SHEARED_RECTANGLE_RIGHT',
    shape: NodeShape.ShearedRectangle,
    id: 70008,
    fill: '#CCE2CB',
  },
  {
    name: 'SHEARED_RECTANGLE_LEFT',
    shape: NodeShape.ShearedRectangle2,
    id: 70009,
    fill: '#B6CFB6',
  },
  { name: 'STAR_5', shape: NodeShape.Star5, id: 70010, fill: '#b7a8df' },
  { name: 'STAR_6', shape: NodeShape.Star6, id: 70011, fill: '#d25b4f' },
  {
    name: 'TRAPEZIUM_1',
    shape: NodeShape.Trapez,
    id: 70012,
    fill: '#55CBCD',
  },
  {
    name: 'TRAPEZIUM_2',
    shape: NodeShape.Trapez2,
    id: 70013,
    fill: '#FCB9AA',
  },
  {
    name: 'TRIANGLE_UP',
    shape: NodeShape.Triangle,
    id: 70014,
    fill: '#ECEAE4',
  },
  {
    name: 'TRIANGLE_DOWN',
    shape: NodeShape.Triangle2,
    id: 70015,
    fill: '#ECD5E3',
  },
  {
    name: 'PILL',
    shape: NodeShape.Pill,
    id: 70016,
    fill: '#FFA500',
  },
];
