import { DividingLineType, NodeShape } from '@/api/models';

export default [
  {
    name: 'HORIZONTAL_DIVIDER',
    imagePath: '/media/svg/arrows/arrow-13.svg',
    type: DividingLineType.Horizontal,
  },
  {
    name: 'VERTICAL_DIVIDER',
    imagePath: '/media/svg/arrows/arrow-14.svg',
    type: DividingLineType.Vertical,
  },
];
