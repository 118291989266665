import {
  ILabel,
  ILabelModelParameter,
  INode,
  BaseClass,
  Point,
  ILabelModel,
} from 'yfiles';
import JigsawExteriorNodeLabelModel from './JigsawExteriorNodeLabelModel';

export default class JigsawExteriorNodeLabelModelParameter extends BaseClass<ILabelModelParameter>(
  ILabelModelParameter
) {
  /**
   *
   * @param _model
   * @param options
   */

  public offset: Point = null;
  public positionVector: Point = null;
  constructor(
    private readonly _model: JigsawExteriorNodeLabelModel,
    readonly options: {
      readonly offset?: Point;
      readonly positionVector: Point;
    }
  ) {
    super();

    if (options && !options.positionVector) {
      throw 'positionVector cannot be null';
    }
    if (!options) {
      options = {
        offset: null,
        positionVector: JigsawExteriorNodeLabelModel.SOUTH.positionVector,
      };
    }

    this.offset = options.offset;
    this.positionVector = options.positionVector;
  }

  public clone(): this {
    return this;
  }

  get model(): ILabelModel {
    return this._model as unknown as ILabelModel;
  }

  public supports(label: ILabel): boolean {
    return label.owner instanceof INode;
  }
}
