import {
  IRenderContext,
  INode,
  SvgVisualGroup,
  SvgVisual,
  IInputModeContext,
  Point,
} from 'yfiles';
import { isViewPortChanging } from '@/core/services/graph/input-modes/JigsawMoveViewportInputMode';
import JigsawNodeDecorator from './decorators/JigsawNodeDecorator';
import HitResult, { HitResultLocation } from './HitResult';
import renderingConfig from '../config/renderingConfig';

interface IRenderCache {
  decoratorCount: number;
}

export const RenderCacheKey = 'render-cache';
const NameKey = 'visual-name';
export default class SvgRenderUtils {
  private static getRenderCache(node: INode): IRenderCache {
    let cache: IRenderCache = node[RenderCacheKey];
    if (cache) {
      return cache;
    }

    return { decoratorCount: 0 };
  }

  private static setRenderCache(node: INode, cache: IRenderCache): void {
    node[RenderCacheKey] = cache;
  }

  public static createDecorationSvgVisual(
    context: IRenderContext,
    node: INode,
    decorators: JigsawNodeDecorator[]
  ): SvgVisualGroup {
    let group = new SvgVisualGroup();

    if (context.zoom < renderingConfig.nodeDecoratorZoomThreshold) {
      return group;
    }

    decorators.forEach((decorator) => {
      if (decorator.isVisible(context, node)) {
        let visual = decorator.createVisual(context, node) as SvgVisual;
        if (!visual) {
          console.debug(`got a null visual from ${decorator.$class}`);
        }
        SvgRenderUtils.setVisualName(
          visual,
          SvgRenderUtils.getDecoratorName(decorator)
        );
        group.add(visual as SvgVisual);
      } else {
        group.add(new SvgVisualGroup());
      }
    });

    let cache = this.getRenderCache(node);
    cache.decoratorCount = decorators.length;
    SvgRenderUtils.setRenderCache(node, cache);
    this.setVisualName(group, 'svg-element-group');
    return group;
  }

  public static updateDecorationSvgVisual(
    context: IRenderContext,
    oldVisual: SvgVisualGroup,
    node: INode,
    decorators: JigsawNodeDecorator[]
  ): SvgVisual {
    //TODO:Performancem this could cause a big dip in performance when the threshold is passed
    if (
      context.zoom < renderingConfig.nodeDecoratorZoomThreshold ||
      isViewPortChanging(context)
    ) {
      while (oldVisual.children.size > 0) {
        oldVisual.children.removeAt(0);
      }
      return oldVisual;
    }

    let oldGroup = oldVisual as SvgVisualGroup;
    let cache = this.getRenderCache(node);

    if (
      cache.decoratorCount != decorators.length ||
      oldGroup.children.size != decorators.length
    ) {
      console.debug(
        'Regenerating decorators SvgVisual - this should not happen often, if ever'
      );
      return SvgRenderUtils.createDecorationSvgVisual(
        context,
        node,
        decorators
      );
    }

    for (let index = 0; index < decorators.length; index++) {
      const decorator = decorators[index];
      const decoratorName = SvgRenderUtils.getDecoratorName(decorator);
      let oldDecoratorVisual = SvgRenderUtils.getVisualByName(
        oldGroup,
        decoratorName
      );

      let visual: SvgVisual = null;
      if (decorator.isVisible(context, node)) {
        if (!oldDecoratorVisual) {
          visual = decorator.createVisual(context, node) as SvgVisual;
        } else {
          visual = decorator.updateVisual(
            context,
            node,
            oldDecoratorVisual as SvgVisual
          ) as SvgVisual;
        }

        SvgRenderUtils.setVisualName(visual, decoratorName);
      } else {
        visual = new SvgVisualGroup();
      }
      if (oldDecoratorVisual != visual) {
        oldGroup.children.set(index, visual);
      }
    }

    return oldGroup;
  }

  public static isHit(
    context: IInputModeContext,
    location: Point,
    node: INode,
    decorators: JigsawNodeDecorator[]
  ): boolean {
    // Decorators are no longer clickable
    return false;
  }

  /**
   * Performs hit testing on the given node & decorators
   * decorators are tested first, the  the node
   * @param context
   * @param location
   * @param node
   * @param decorators
   * @returns
   */
  public static getHitLocation(
    context: IInputModeContext,
    location: Point,
    node: INode,
    decorators: JigsawNodeDecorator[]
  ): HitResult {
    if (context.zoom > renderingConfig.nodeDecoratorZoomThreshold) {
      // we only test decorators if they will be visible

      if (decorators && decorators.length > 0) {
        for (let i = 0; i < decorators.length; i++) {
          const decorator = decorators[i];
          const isVisible = decorator.isVisible(
            context.canvasComponent.createRenderContext(),
            node
          );
          if (!isVisible) {
            continue;
          }
          const hitResult = decorator.isHit(context, location, node);
          if (hitResult.isHit) {
            return hitResult;
          }
        }
      }
    }
    //const nodeStyle = DiagramUtils.unwrapNodeStyle(node);

    // var shapeGeometry = nodeStyle.baseStyle.renderer.getShapeGeometry(
    //   node,
    //   nodeStyle.baseStyle
    // );

    //const hit = shapeGeometry.getOutline().areaContains(location, 0);
    let rect = node.layout.toRect();
    const hit = rect.containsWithEps(location, context.hitTestRadius);
    if (hit) {
      return new HitResult({
        hitLocation: HitResultLocation.NODE,
        isHit: true,
        meta: null,
        decoratorType: null,
      });
    }
    // we didn't hit anything
    return HitResult.NONE;
  }

  public static getDecoratorName(decorator: JigsawNodeDecorator): string {
    return `DECORATOR-${decorator.$class}`;
  }

  public static setVisualName(visual: SvgVisual, name: string): void {
    if (!visual) {
      // sometimes we get null visuals and this is OK
      return;
    }
    visual.svgElement.setAttribute(NameKey, name);
  }

  public static getVisualByName(
    visualGroup: SvgVisualGroup,
    name: string
  ): SvgVisual {
    if (!visualGroup) {
      return;
    }
    return visualGroup.children.find(
      (d) => d && d.svgElement.getAttribute(NameKey) == name
    );
  }

  public static addToGroup(
    group: SvgVisualGroup,
    visual: SvgVisual,
    name: string
  ): void {
    if (group == null) {
      throw 'cannot be null';
    }
    group.add(visual);
    SvgRenderUtils.setVisualName(visual, name);
  }
}
