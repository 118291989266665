<template>
  <AppModal
    v-model="showModalIdle"
    :title="$t('SESSION_TIMEOUT_NOTIFICATION')"
    :close-on-esc="false"
    :close-on-backdrop="false"
    :show-hide-button="false"
  >
    <!-- C O N T E N T -->
    <p class="mb-16">{{ $t('YOUR_SESSION_IS_ABOUT_TO_EXPIRE') }}.</p>
    <p class="mb-16">
      {{ $t(content) }}
    </p>
    <div class="w-full h-16 rounded-16 bg-accent-purple-05">
      <div
        class="flex items-center justify-center h-14 bg-accent-purple rounded-16 transition-width"
        :style="progressWidth"
      >
        <span class="text-white text-10 leading-none">
          {{ `${value}s` }}
        </span>
      </div>
    </div>

    <!-- F O O T E R -->
    <template #footer>
      <AppButton class="mr-16" @click="logout">{{ $t('LOGOUT') }}</AppButton>
      <AppButton variant="purple" @click="hideModal">
        {{ $t('STAY_CONNECTED') }}
      </AppButton>
    </template>
  </AppModal>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import IdleManager from '@/core/services/idle/IdleManager';
import { LogoutConfirmationConfig } from '@/core/config/LogoutConfirmationConfig';
import { Getter } from 'vuex-class';
import { GET_CURRENT_SETTINGS } from '@/core/services/store/settings.module';
import { ClientHostSettingsDto } from '@/api/models';
import { EventBusActions } from '@/core/services/events/eventbus.service';
@Component({
  name: 'ModalIdle',
})
export default class ModalIdle extends Vue {
  @Prop({ default: false })
  showModalIdle: boolean;

  @Getter(GET_CURRENT_SETTINGS)
  currentSettings: ClientHostSettingsDto;

  get max(): number | null {
    return this.currentSettings.sessionTimeout
      ? this.currentSettings.sessionTimeout.showTimeOutNotificationSecond
      : 0;
  }

  get progressWidth() {
    return { width: `${(this.value / this.max) * 100}%` };
  }

  value: number = 0;
  content: string = LogoutConfirmationConfig.content;
  timer: null | ReturnType<typeof setTimeout> = null;

  logout() {
    this.hideModal();
    this.eventBusEmit(EventBusActions.APPLICATION_SESSION_TIMED_OUT);
  }
  hideModal() {
    this.stopTimer();
    IdleManager.INSTANCE.restart();
    this.$emit('toggle', false);
  }
  startTimer() {
    this.value =
      this.currentSettings.sessionTimeout.showTimeOutNotificationSecond;
    this.timer = setInterval(() => {
      this.value--;
      if (this.value === 0) {
        this.stopTimer();
        this.hideModal();
        this.logout();
      }
    }, 1000);
  }
  stopTimer() {
    clearInterval(this.timer);
    this.timer = null;
    this.value =
      this.currentSettings.sessionTimeout.showTimeOutNotificationSecond;
  }

  beforeDestroy() {
    this.stopTimer();
  }

  @Watch('showModalIdle')
  onShowModalChanged(newVal: boolean) {
    if (newVal) {
      this.startTimer();
    } else {
      this.stopTimer();
    }
  }
}
</script>
