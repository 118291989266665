<template>
  <div class="w-full max-w-240">
    <AppUserStack
      :users="[currentUser]"
      no-border
      size="lg"
      background-color="bg-accent-cyan"
    />
    <div
      v-if="showUserDetails"
      class="w-full flex justify-between items-center pl-8 truncate"
    >
      <div class="w-full truncate">
        <p class="text-white text-14 leading-none truncate">
          {{ currentUser.name }} {{ currentUser.surname }}
        </p>
        <p
          v-if="hasPermission(ApplicationPermissions.Administration)"
          class="opacity-40 text-white text-12 mt-4 leading-none"
        >
          {{ $t('ADMIN') }}
        </p>
      </div>
      <div class="w-auto">
        <div class="flex transform -rotate-90">
          <AppIcon
            name="arrow-drop-down"
            size="20"
            class="text-white opacity-40"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { GET_CURRENT_USER } from '@/core/services/store/user.module';

import UserLanguageMixin from '@/mixins/UserLanguage.mixin';
import PermissionMixin from '@/mixins/Permission.mixin';
import { CurrentUserProfileEditDto } from '@/api/models';
import { Getter, Mutation } from 'vuex-class';
import { ApplicationPermissions } from '@/api/ApplicationPermissions';
import {
  GET_IS_COMPACT_MENU,
  GET_IS_MENU_EXTENDED,
  GLOBAL_NAMESPACE,
} from '@/core/services/store/global.module';

@Component({
  name: 'SidebarProfile',
})
export default class SidebarProfile extends mixins(
  UserLanguageMixin,
  PermissionMixin
) {
  @Getter(GET_CURRENT_USER)
  currentUser: CurrentUserProfileEditDto;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_COMPACT_MENU}`)
  compactMenu: boolean;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_MENU_EXTENDED}`)
  menuExtended: boolean;

  get showUserDetails(): boolean {
    return !this.compactMenu || (this.compactMenu && this.menuExtended);
  }
}
</script>
