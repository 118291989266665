import LicensesApiService from '@/api/LicensesApiService';
import { LicenseTypes } from '@/api/models';
import { Features } from '@/core/common/Features';
import jwt from 'jsonwebtoken';
import { License } from 'yfiles';
// action types
export const SET_LICENCE_REQUESTED = 'setLicenceRequested';
export const SET_LICENSE = 'setLicense';
export const SET_YFILES_LICENSE = 'setyFilesLicense';
export const SET_LICENSE_FEATURES = 'setLicenseFeatures';
export const SET_LICENSE_TYPE = 'setLicenseType';
export const GET_LICENSE_TYPE = 'getLicenseType';
export const HAS_LICENSE_FEATURE = 'hasLicenseFeature';
export const LOAD_LICENSE = 'loadLicense';
export const GET_LICENSE = 'getLicense';
export const IS_LICENSED = 'isLicensed';
export const GET_YFILES_LICENSE = 'getyFilesLicense';
export const HAS_LICENCE_BEEN_REQUESTED = 'licenseRequested';

export default {
  state: {
    license: {},
    yfileslicense: {},
    features: [],
    isLicensed: false,
    licenseRequested: false,
    licenseType: LicenseTypes.Unrestricted,
  },
  getters: {
    [HAS_LICENCE_BEEN_REQUESTED](state) {
      return state.licenseRequested;
    },
    [IS_LICENSED](state) {
      return state.isLicensed;
    },
    [GET_LICENSE_TYPE](state) {
      return state.licenseType;
    },
    [HAS_LICENSE_FEATURE](state) {
      return (payload: { featureName: Features }) => {
        return state.features.includes(payload.featureName);
      };
    },
    [GET_LICENSE](state) {
      return state.license;
    },
    [GET_YFILES_LICENSE](state) {
      return state.yfileslicense;
    },
  },
  actions: {
    [LOAD_LICENSE](context) {
      const loadPromise = LicensesApiService.get();
      loadPromise.then((response) => {
        let license = response.data.result;
        if (response.status == 200) {
          if (license.license) {
            context.commit(SET_LICENSE, license);
            if (license.isValid) {
              const jwtoken = jwt.decode(license.license.licenseInfo);
              const lobj = JSON.parse(jwtoken['yFiles']);
              if (jwtoken['features']) {
                const features = jwtoken['features'].split(',');
                context.commit(SET_LICENSE_FEATURES, features);
              }
              if (jwtoken['licensetype']) {
                context.commit(
                  SET_LICENSE_TYPE,
                  LicenseTypes[jwtoken['licensetype']]
                );
              }
              License.value = lobj;
              context.commit(SET_YFILES_LICENSE, lobj);
            } else {
              context.commit(SET_LICENSE_FEATURES, []);
            }
          }
        }
      });
      return loadPromise;
    },
    [SET_LICENSE](context, payload) {
      context.commit(SET_LICENSE, payload);
    },
    [SET_YFILES_LICENSE](context, payload) {
      context.commit(SET_YFILES_LICENSE, payload);
    },
    [SET_LICENSE_FEATURES](context, payload) {
      context.commit(SET_LICENSE_FEATURES, payload);
    },
    [SET_LICENSE_TYPE](context, payload) {
      context.commit(SET_LICENSE_TYPE, payload);
    },
  },
  mutations: {
    [SET_LICENCE_REQUESTED](state, payload) {
      state.licenseRequested = payload;
    },
    [SET_LICENSE](state, payload) {
      state.themes = payload;
    },
    [SET_YFILES_LICENSE](state, payload) {
      state.yfileslicense = payload;
      state.isLicensed = true;
    },
    [SET_LICENSE_FEATURES](state, payload) {
      state.features = payload;
    },
    [SET_LICENSE_TYPE](state, payload) {
      state.licenseType = payload;
    },
  },
};
