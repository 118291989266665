<template>
  <div class="leavesfall">
    <i v-for="leaf in leaves" :key="leaf"></i>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "SidebarLeavesfall",
})
export default class SidebarLeavesfall extends Vue {
  @Prop({
    default: 12,
    type: Number,
  })
  leaves: number;
}
</script>

<style lang="scss" scoped>
.leavesfall {
  position: absolute;
  top: -100px;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: right;
  pointer-events: none;
  overflow: hidden;

  --root-height: 1000px;
  --h1: calc(var(--root-height) + 0px + 100px);
  --h2: calc(var(--root-height) + 40px + 100px);
  --h3: calc(var(--root-height) + 80px + 100px);
  i {
    display: inline-block;
    width: 200px;
    height: 150px;
    background: linear-gradient(to bottom right, #309900, #005600);
    transform: skew(20deg);
    border-radius: 5% 40% 70%;
    box-shadow: inset 0px 0px 1px #222;
    border: 1px solid #333;
    z-index: 1;
    animation: falling 5s 0s infinite;

    &:nth-of-type(2n) {
      animation: falling2 5s 0s infinite;
    }
    &:nth-of-type(3n) {
      animation: falling3 5s 0s infinite;
    }

    &:before {
      position: absolute;
      content: "";
      top: 117px;
      right: 9px;
      height: 27px;
      width: 32px;
      transform: rotate(49deg);
      border-radius: 0% 15% 15% 0%;
      border-top: 1px solid #222;
      border-bottom: 1px solid #222;
      border-left: 0px solid #222;
      border-right: 1px solid #222;
      background: linear-gradient(to right, rgba(0, 100, 0, 1), #005600);
      z-index: 1;
    }

    &:after {
      content: "";
      height: 125px;
      width: 10px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
      display: block;
      transform: rotate(125deg);
      position: absolute;
      left: 85px;
      border-radius: 50%;
    }

    &:nth-of-type(n) {
      height: 23px;
      width: 30px;
    }
    &:nth-of-type(n):before {
      width: 7px;
      height: 5px;
      top: 17px;
      right: 1px;
    }
    &:nth-of-type(n):after {
      width: 2px;
      height: 17px;
      left: 12px;
      top: 0px;
    }

    &:nth-of-type(2n + 1) {
      height: 11px;
      width: 16px;
    }
    &:nth-of-type(2n + 1):before {
      width: 4px;
      height: 3px;
      top: 7px;
      right: 0px;
    }
    &:nth-of-type(2n + 1):after {
      width: 2px;
      height: 6px;
      left: 5px;
      top: 1px;
    }

    &:nth-of-type(3n + 2) {
      height: 17px;
      width: 23px;
    }
    &:nth-of-type(3n + 2):before {
      height: 4px;
      width: 4px;
      top: 12px;
      right: 1px;
    }
    &:nth-of-type(3n + 2):after {
      height: 10px;
      width: 2px;
      top: 1px;
      left: 8px;
    }

    &:nth-of-type(n) {
      animation-delay: 1.9s;
    }
    &:nth-of-type(2n) {
      animation-delay: 3.9s;
    }
    &:nth-of-type(3n) {
      animation-delay: 2.3s;
    }
    &:nth-of-type(4n) {
      animation-delay: 4.4s;
    }
    &:nth-of-type(5n) {
      animation-delay: 5s;
    }
    &:nth-of-type(6n) {
      animation-delay: 3.5s;
    }
    &:nth-of-type(7n) {
      animation-delay: 2.8s;
    }
    &:nth-of-type(8n) {
      animation-delay: 1.5s;
    }
    &:nth-of-type(9n) {
      animation-delay: 3.3s;
    }
    &:nth-of-type(10n) {
      animation-delay: 2.5s;
    }
    &:nth-of-type(11n) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(12n) {
      animation-delay: 4.1s;
    }
    &:nth-of-type(13n) {
      animation-delay: 1s;
    }
    &:nth-of-type(14n) {
      animation-delay: 4.7s;
    }
    &:nth-of-type(15n) {
      animation-delay: 3s;
    }

    &:nth-of-type(n) {
      background: linear-gradient(to bottom right, #be5109, #f1ab10);
    }
    &:nth-of-type(2n + 2) {
      background: linear-gradient(to bottom right, #8f2123, #fc9a30);
    }
    &:nth-of-type(4n + 1) {
      background: linear-gradient(to bottom right, #990, #564500);
    }

    &:nth-of-type(n) {
      transform: rotate(180deg);
    }

    &:nth-of-type(n) {
      animation-timing-function: ease-in-out;
    }

    @keyframes falling {
      0% {
        transform: translate3d(280, 0, 0) rotate(0deg);
      }

      100% {
        transform: translate3d(-200px, var(--h1), 0) rotate(90deg);
        opacity: 0.3;
      }
    }

    @keyframes falling3 {
      0% {
        transform: translate3d(0, 0, 0) rotate(-20deg);
      }

      100% {
        transform: translate3d(-130px, var(--h2), 0) rotate(-70deg);
        opacity: 0.3;
      }
    }

    @keyframes falling2 {
      0% {
        transform: translate3d(0, 0, 0) rotate(90deg);
      }

      100% {
        transform: translate3d(-200px, var(--h3), 0) rotate(0deg);
        opacity: 0.3;
      }
    }
  }
}
</style>
