import {
  Point,
  IGraph,
  IEdge,
  PortCandidate,
  PortDirections,
  INode,
} from 'yfiles';
import JPoint from '../common/JPoint';
import DiagramUtils from '../utils/DiagramUtils';
import EdgePortGenerator from './EdgePortGenerator';
import IEdgeParams from './IEdgeParams';
export default class EdgeRoutingHelper {
  public static getFixedEdgeParams(
    edge: IEdge,
    sourceEnd: boolean
  ): IEdgeParams {
    const node = sourceEnd ? edge.sourceNode : edge.targetNode;
    const port = sourceEnd ? edge.sourcePort : edge.targetPort;
    const portDirection = sourceEnd
      ? edge.tag.sourcePortDirection
      : edge.tag.targetPortDirection;
    let diff = port.location.subtract(node.layout.center);
    let fixedCandidate = PortCandidate.createCandidate(
      diff.x,
      diff.y,
      portDirection,
      0
    );

    // Straight edges must be "pulled" to the geometry of the node if they do not intersect with the nodes bounds for fixed ports
    // Dynamic ports will be moved to a more suitable position, making this not an issue.
    //e.g. a straigt line connecting to a port on the oppposite side of the node, the edge would go through the node.

    fixedCandidate = this.pullPortCandidateToGeometry(node, fixedCandidate);

    return {
      candidates: [fixedCandidate],
      constraint: null,
    };
  }

  public static getEdgeParams(
    graph: IGraph,
    edge: IEdge,
    sourceEnd: boolean,
    ignoreLocations: Point[]
  ): IEdgeParams {
    const node = sourceEnd ? edge.sourceNode : edge.targetNode;
    const oppositePortLocation = sourceEnd
      ? edge.targetPort.location
      : edge.sourcePort.location;

    var incomingPortSide = DiagramUtils.getSide(
      JPoint.fromYFiles(node.layout.center.toPoint()),
      JPoint.fromYFiles(oppositePortLocation)
    );
    //this logic still stands
    if (sourceEnd) {
      if (edge.tag.sourcePortFixed) {
        return EdgeRoutingHelper.getFixedEdgeParams(edge, sourceEnd);
      }
    } else {
      if (edge.tag.targetPortFixed) {
        return EdgeRoutingHelper.getFixedEdgeParams(edge, sourceEnd);
      }
    }

    // generate candidates
    var candidates = new EdgePortGenerator().generateCandidates(
      node.layout.toRect(),
      oppositePortLocation,
      incomingPortSide,
      ignoreLocations
    );
    // pull them into the geometry
    for (let index = candidates.length - 1; index >= 0; index--) {
      let candidate = candidates[index];
      candidate = candidates[index] = this.pullPortCandidateToGeometry(
        node,
        candidate
      );
    }

    candidates.sort((a, b) => {
      return a.cost - b.cost
     });
    return { candidates: candidates, constraint: null };
  }

  public static pullPortCandidateToGeometry(
    owner: INode,
    portCandidate: PortCandidate
  ): PortCandidate {
    const xOffset = portCandidate.xOffset;
    const yOffset = portCandidate.yOffset;
    const hitPoint = EdgeRoutingHelper.pullPointToGeometry(
      owner,
      new Point(xOffset, yOffset).add(owner.layout.center),
      portCandidate.direction
    );
    const relative = hitPoint.subtract(owner.layout.center);
    return DiagramUtils.createPortCandidate(
      owner,
      relative.x,
      relative.y,
      portCandidate.direction,
      portCandidate.cost
    );
  }

  public static pullPointToGeometry(
    owner: INode,
    startPoint: Point,
    portDirection: PortDirections
  ): Point {
    if (portDirection != PortDirections.ANY) {
      let directionVector: Point = null;
      switch (portDirection) {
        case PortDirections.NORTH:
          directionVector = new Point(0, 1);
          break;
        case PortDirections.SOUTH:
          directionVector = new Point(0, -1);
          break;
        case PortDirections.EAST:
          directionVector = new Point(-1, 0);
          break;
        case PortDirections.WEST:
          directionVector = new Point(1, 0);
          break;
      }
      const anchor = EdgeRoutingHelper.getAnchor(
        directionVector.x,
        directionVector.y,
        startPoint,
        owner
      );

      let shapeGeometry = owner.style.renderer.getShapeGeometry(
        owner,
        owner.style
      );

      const generalPath = shapeGeometry.getOutline();
      const hit = generalPath.findRayIntersection(anchor, directionVector);
      if (hit === Infinity) {
        return startPoint;
      }
      const hitPoint = anchor.add(directionVector.multiply(hit));
      return hitPoint;
    }
    return startPoint;
  }

  public static getAnchor(
    xVector: number,
    yVector: number,
    snappedLocation: Point,
    node: INode
  ): Point {
    if (xVector !== 0 && yVector !== 0) {
      throw 'invalid direction vector';
    }
    const layout = node.layout;
    const flyoutDistance = 5;
    let x = snappedLocation.x;
    let y = snappedLocation.y;
    if (xVector === 0) {
      y =
        yVector === 1
          ? layout.y - flyoutDistance
          : layout.maxY + flyoutDistance;
    }
    if (yVector === 0) {
      x =
        xVector === 1
          ? layout.x - flyoutDistance
          : layout.maxX + flyoutDistance;
    }
    return new Point(x, y);
  }

  public static isNodeIgnoredInEdgeRouting(node: INode): boolean {
    return (
      DiagramUtils.isDividingLine(node) ||
      DiagramUtils.isHatch(node) ||
      DiagramUtils.isCross(node) ||
      node.tag.isGroupNode
    );
  }

  /**
   * The json export can be copy pasted into external debug application  to visualize the candidates
   * @param portCandidates
   * @param node
   */
  dump(portCandidates: PortCandidate[], node: INode): void {
    const dir = PortDirections;
    console.debug(dir);
    const data = {
      candidates: portCandidates.map((pc) => {
        return {
          xOffset: pc.xOffset,
          yOffset: pc.yOffset,
          cost: pc.cost,
          direction: DiagramUtils.getPortDirectionName(pc.direction),
        };
      }),
      layout: {
        x: node.layout.x,
        y: node.layout.y,
        width: node.layout.width,
        height: node.layout.height,
        center: {
          x: node.layout.center.x,
          y: node.layout.center.y,
        },
      },
    };
    console.debug(JSON.stringify(data));
  }
}
