import { waitWhile } from '../utils/common.utils';

class BackgroundDomService {
  private backgroundIFrame: HTMLIFrameElement;
  private elementsContainer: HTMLDivElement;
  private isDebug = false;

  // eslint-disable-next-line no-undef
  public get window(): Window & typeof globalThis {
    return this.backgroundIFrame.contentWindow.window;
  }

  public get document(): Document {
    return this.backgroundIFrame.contentDocument;
  }

  private get container(): HTMLElement {
    return this.elementsContainer;
  }

  constructor() {
    this.init();
  }

  public async init(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.backgroundIFrame) {
        this.backgroundIFrame.remove();
      }
      this.backgroundIFrame = this.createBackgroundIFrame();
      window.document.body.appendChild(this.backgroundIFrame);
      this.document.open();
      this.document.write(
        '<!DOCTYPE html><html><head></head><body></body></html>'
      );
      this.document.close();

      this.elementsContainer = this.createElementsContainer();
      this.document.body.appendChild(this.elementsContainer);

      this.copyStyles();

      let fontsLoaded = false;
      this.document.fonts.ready.then(() => {
        fontsLoaded = true;
      });
      // if for some reason fonts would not be loaded, we make sure that after 2s we resolve the promise.
      waitWhile(() => fontsLoaded, 2000).then((timeout) => {
        if (timeout) {
          console.debug('[BackgroundDomService] Font loading timeout');
        }
        resolve();
      });
    });
  }

  public createElement(tagName: string): HTMLElement {
    return this.document.createElement(tagName);
  }

  public appendElement(element: Element): void {
    this.container.appendChild(element);
  }

  public removeElement(element: Element): void {
    element.remove();
  }

  public getElementById(elementId: string): HTMLElement {
    return this.document.getElementById(elementId);
  }

  public isHtmlElement(obj: any): obj is HTMLElement {
    return (
      obj instanceof window.HTMLElement ||
      obj instanceof this.window.HTMLElement
    );
  }

  public updateParentStyleElements(): void {
    this.cleanupStyleElements();
    this.copyParentStyleElements();
  }

  private copyStyles(): void {
    // Remove all existing styles and links from an iframe
    this.cleanupStyles();

    // Copy parent styles and links to an iframe
    this.copyParentStyleElements();
    this.copyParentStyleLinks();
    this.copyParentFontLinks();
    this.copyParentBodyStyles();
  }

  private cleanupStyles(): void {
    this.cleanupStyleElements();
    this.cleanupLinkElements();
  }

  private cleanupStyleElements(): void {
    const iframeStyles = this.document.head.getElementsByTagName('style');
    for (const iframeStyle of [...iframeStyles]) {
      iframeStyle.remove();
    }
  }

  private cleanupLinkElements(): void {
    const iframeLinks = this.document.head.querySelectorAll(
      'link[rel="stylesheet"], link[as="font"]'
    );
    for (const iframeLink of [...iframeLinks]) {
      iframeLink.remove();
    }
  }

  private copyParentStyleElements(): void {
    const parentStyles = window.document.head.getElementsByTagName('style');
    for (const parentStyle of parentStyles) {
      const iframeStyle = this.document.createElement('style');
      iframeStyle.innerHTML = parentStyle.innerHTML;
      this.document.head.appendChild(iframeStyle);
    }
  }

  private copyParentStyleLinks(): void {
    const parentLinks = window.document.head.querySelectorAll(
      'link[rel="stylesheet"]'
    );
    for (const parentLink of parentLinks) {
      const iframeLink = this.document.createElement('link');
      iframeLink.as = 'style';
      iframeLink.rel = 'stylesheet';
      iframeLink.crossOrigin = 'anonymous';
      iframeLink.href = (parentLink as HTMLLinkElement).href;
      this.document.head.appendChild(iframeLink);
    }
  }

  private copyParentFontLinks(): void {
    const parentLinks =
      window.document.head.querySelectorAll('link[as="font"]');
    for (const parentLink of parentLinks) {
      const iframeLink = this.document.createElement('link');
      iframeLink.as = 'font';
      iframeLink.rel = 'preload';
      iframeLink.crossOrigin = 'anonymous';
      iframeLink.href = (parentLink as HTMLLinkElement).href;
      this.document.head.appendChild(iframeLink);
    }
  }

  private copyParentBodyStyles(): void {
    const bodyStyle = window.document.body.getAttribute('style');
    if (bodyStyle) {
      const elementStyle = this.elementsContainer.getAttribute('style');
      const newElementStyle = elementStyle + '; ' + bodyStyle;
      this.elementsContainer.setAttribute('style', newElementStyle);
    }
  }

  private createBackgroundIFrame(): HTMLIFrameElement {
    const iframe = window.document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.margin = '0';
    iframe.style.padding = '0';

    if (!this.isDebug) {
      iframe.style.top = '-30000px';
      iframe.width = '0';
      iframe.height = '0';
    } else {
      iframe.style.top = '1000px';
      iframe.width = '100%';
      iframe.height = '100%';
    }

    return iframe;
  }

  private createElementsContainer(): HTMLDivElement {
    const container = window.document.createElement('div');
    if (!this.isDebug) {
      container.style.overflow = 'hidden';
    }
    container.style.position = 'absolute';
    container.style.width = '3000px';
    container.style.height = '3000px';
    return container;
  }
}

const instance = new BackgroundDomService();
export default instance;
