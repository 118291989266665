import { stringsEqualCI } from '@/core/utils/common.utils';
import { IEdge, INode } from 'yfiles';
import EntityTypeParameter from '../parameters/EntityTypeParameter';
import { EntityTypeParameterBaseType } from '../parameters/EntityTypeParameterBaseType';
import { ITypedMatcher } from './IMatcher';

export default class EntityTypeMatcher
  implements ITypedMatcher<IEdge | INode, EntityTypeParameter>
{
  public canMatch(candidate: any, parameter: EntityTypeParameter): boolean {
    return candidate instanceof INode || candidate instanceof IEdge;
  }

  public isMatch(
    candidate: IEdge | INode,
    parameter: EntityTypeParameter
  ): boolean {
    return (
      ((candidate instanceof INode &&
        parameter.baseType == EntityTypeParameterBaseType.Node) ||
        (candidate instanceof IEdge &&
          parameter.baseType == EntityTypeParameterBaseType.Edge)) &&
      stringsEqualCI(candidate.tag?.name, parameter.entityType)
    );
  }
}
