<template>
  <button
    ref="buttonRef"
    class="start-creating-button relative flex justify-center flex-col items-center bg-white border-1 border-cerebral-grey transition duration-300 hover:shadow-full hover:border-main-dark-10 disabled:cursor-not-allowed disabled:shadow-none"
    :class="buttonSizeClass"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <img :src="img" style="height: 50%" />

    <p
      class="xl:mt-16 mt-8 3xl:text-16 xl:text-14 text-10 leading-none text-main-dark"
    >
      <slot />
    </p>

    <slot name="decoration" />
  </button>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';

@Component({
  name: 'StartCreatingButton',
})
export default class StartCreatingButton extends Vue {
  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: '' })
  img: string;

  //Reference to button
  @Ref('buttonRef')
  buttonRef!: HTMLButtonElement;

  //Set buttonWidth variable
  buttonSizeClass: string = '';

  //Set initial button width and add event listener
  mounted() {
    this.setButtonWidth();
    window.addEventListener('resize', this.setButtonWidth);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.setButtonWidth);
  }

  setButtonWidth() {
    //Change border radius for large buttons
    this.buttonSizeClass =
      this.buttonRef.clientWidth >= 268 ? 'rounded-16' : 'rounded-8';
  }
}
</script>
