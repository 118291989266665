import IDisposable from '@/core/common/IDisposable';
import { Mapper } from 'yfiles';
import { CommandHandlerType } from './CommandHandlerType';
import { CommandType } from './CommandType';
import CommandHandlerBase from './CommandHandlerBase';
import ICommandProvider from './ICommandProvider';
import isNil from 'lodash/isNil';
import DocumentService from '../document/DocumentService';
import DiagramCommandHandler from '@/view/pages/document/DiagramCommandHandler';
import LayoutEditorServiceManager from '@/components/LayoutEditor/Items/LayoutEditorServiceManager';
import { DocumentPageLayoutType } from '@/api/models';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';

export interface CommandHandlerChangeArgs {
  activeCommandHandler?: CommandHandlerBase;
}

export default class CommandManager implements IDisposable {
  isDisposed: boolean;
  private providers: Mapper<string, ICommandProvider>;
  private activeCommandHandler: CommandHandlerBase;
  private commandHandlerChangeListeners: Array<
    (sender: CommandManager, args: CommandHandlerChangeArgs) => void
  > = [];

  public static INSTANCE: CommandManager = new CommandManager();

  constructor() {
    this.providers = new Mapper<string, ICommandProvider>();
  }

  private onActiveCommandHandlerChanged(
    activeCommandHandler: CommandHandlerBase
  ): void {
    this.commandHandlerChangeListeners.forEach((listener) =>
      listener(this, { activeCommandHandler })
    );
  }

  public addActiveCommandHandlerChangedListener(
    action: (sender: CommandManager, args: CommandHandlerChangeArgs) => void
  ): void {
    this.commandHandlerChangeListeners.push(action);
  }

  public removeActiveCommandHandlerChangedListener(
    action: (sender: CommandManager, args: CommandHandlerChangeArgs) => void
  ): void {
    const index = this.commandHandlerChangeListeners.indexOf(action);
    if (index !== -1) {
      this.commandHandlerChangeListeners.splice(index);
    }
  }

  public getActiveCommandHandlerType(): CommandHandlerType | null {
    if (!this.activeCommandHandler) {
      return null;
    }
    return this.activeCommandHandler.type;
  }

  public isCommandHandlerActive(commandHandler: CommandHandlerBase): boolean {
    return (
      this.activeCommandHandler && this.activeCommandHandler === commandHandler
    );
  }

  public isCommandHandlerInitialized(): boolean {
    return !isNil(this.activeCommandHandler);
  }

  public detach(): void {}

  public attachProvider(provider: ICommandProvider): void {
    this.providers.set(provider.provider, provider);
  }

  public setCommandHandler(commandHandler: CommandHandlerBase): void {
    if (!commandHandler) {
      throw Error('CommandHandler should not be null');
    }

    if (
      commandHandler.isDisposed ||
      this.isCommandHandlerActive(commandHandler)
    ) {
      return;
    }

    this.unsetCommandHandler(commandHandler);
    commandHandler.publishAllValues();
    this.activeCommandHandler = commandHandler;
    this.providers.entries.forEach((p) => {
      p.value.refocused();
    });
    this.onActiveCommandHandlerChanged(this.activeCommandHandler);
  }

  public unsetCommandHandler(activeCommandHandler?: CommandHandlerBase): void {
    const oldCommandHandler = this.activeCommandHandler;
    if (
      !this.activeCommandHandler ||
      (activeCommandHandler &&
        this.activeCommandHandler !== activeCommandHandler &&
        !activeCommandHandler.isChildOf(this.activeCommandHandler) &&
        !this.activeCommandHandler.isChildOf(activeCommandHandler))
    ) {
      return;
    }

    this.providers.entries.forEach((p) => {
      p.value.refocused(true);
    });

    // In most cases CkEditorCommandHandler has parent CommandHandler.
    // For those cases need to select active CommandHandler to parent's instance.
    this.activeCommandHandler =
      this.activeCommandHandler?.release(activeCommandHandler);

    if (this.activeCommandHandler?.isDisposed) {
      this.activeCommandHandler = null;
    }

    let defaultHandlerResult = false;
    if (!this.activeCommandHandler) {
      defaultHandlerResult = this.setDefaultCommandHandler();
    }

    if (
      !defaultHandlerResult &&
      oldCommandHandler !== this.activeCommandHandler
    ) {
      this.onActiveCommandHandlerChanged(this.activeCommandHandler);
    }
  }

  public setDefaultCommandHandler(): boolean {
    const selectedPage = DocumentService.selectedPage;
    if (!selectedPage) {
      return false;
    }
    if (selectedPage.layoutType === DocumentPageLayoutType.None) {
      if (DocumentService.isGraphServiceActive) {
        CommandManager.INSTANCE.setCommandHandler(
          DocumentService.graphServiceInstance.getService<DiagramCommandHandler>(
            DiagramCommandHandler.$class
          )
        );
        return true;
      }
    } else {
      const layoutEditorService =
        LayoutEditorServiceManager.getServiceByContentArea(
          DocumentContentArea.BodyLayout
        );
      if (layoutEditorService) {
        layoutEditorService.context.reset();
        CommandManager.INSTANCE.setCommandHandler(
          layoutEditorService.context.focusTracker.commandHandler
        );
        return true;
      }
    }
    return false;
  }

  public isEnabled(command: CommandType, enabled): void {
    this.providers.entries.forEach((p) => {
      p.value.isEnabled(command, enabled);
    });
  }

  // Talks to the Providers
  public managerValueChanged(command: CommandType, value: any): void {
    // console.log('managerValueChanged:' + CommandType[command] + ':' + value); // << KEEP
    this.providers.entries.forEach((p) => {
      p.value.valueChanged(command, value);
    });
  }

  // Talks to the currently selected manager
  public providerValueChanged(command: CommandType, value: any): void {
    // Notify The Currently selected Manager ...
    if (this.activeCommandHandler) {
      // console.log('providerValueChanged:' + CommandType[command] + ':' + value); //<< KEEP
      this.activeCommandHandler.executeCommand(command, value);
    }
  }

  dispose(): void {
    if (this.isDisposed) {
      return;
    }

    this.providers.clear();
    this.commandHandlerChangeListeners = [];
    this.isDisposed = true;
  }
}
