import {
  INode,
  IEdge,
  ShapeNodeStyle,
  PolylineEdgeStyle,
  BezierEdgeStyle,
  Stroke,
  IArrow,
  INodeStyle,
  IEdgeStyle,
  Insets,
  ArcEdgeStyle,
  ImageNodeStyle,
  IPoint,
  Fill,
} from 'yfiles';
import appConsts from '../config/appConsts';
import DataPropertyStyleService from '../services/graph/DataPropertyStyleService';
import CompositeNodeStyle, {
  StyleDefinition,
} from '../styles/composite/CompositeNodeStyle';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import DividingLineNodeStyle from '@/core/styles/DividingLineNodeStyle';
import DataPropertyUtils from './DataPropertyUtils';
import DiagramUtils from './DiagramUtils';
import JigsawPathShapeNodeStyle from '../styles/JigsawPathShapeNodeStyle';

export default class GraphElementsComparer {
  public static nodesEqual(nodeA: INode, nodeB: INode): boolean {
    // Check if node entities are different
    if (nodeA.tag.name?.toLowerCase() !== nodeB.tag.name?.toLowerCase()) {
      return false;
    }

    // if one is a group node and the other isn't
    if (nodeA.tag.isGroupNode !== nodeB.tag.isGroupNode) {
      return false;
    }

    // both group nodes, but not the same group
    if (nodeA.tag.isGroupNode && nodeB.tag.isGroupNode) {
      return nodeA.tag.groupUuid === nodeB.tag.groupUuid;
    }

    // Check if both nodes are annotations with the same name
    if (
      nodeA.tag.isAnnotation &&
      nodeB.tag.isAnnotation &&
      nodeA.tag.annotationType == nodeB.tag.annotationType &&
      nodeA.tag.name &&
      nodeB.tag.name &&
      nodeA.tag.name == nodeB.tag.name &&
      this.nodeStylesEqual(
        DiagramUtils.unwrapNodeStyle(nodeA).baseStyle,
        DiagramUtils.unwrapNodeStyle(nodeB).baseStyle
      )
    ) {
      return true;
    }

    // Check to see if both node styles are of type JigsawNodeStyle
    if (
      !(DiagramUtils.unwrapNodeStyle(nodeA) instanceof JigsawNodeStyle) ||
      !(DiagramUtils.unwrapNodeStyle(nodeB) instanceof JigsawNodeStyle)
    ) {
      return false;
    }

    // Check if both nodes have same state of data property style
    let styleIsActiveA = nodeA.tag?.dataPropertyStyle?.isActive;
    let styleIsActiveB = nodeB.tag?.dataPropertyStyle?.isActive;
    if (
      styleIsActiveA !== styleIsActiveB &&
      styleIsActiveA != null &&
      styleIsActiveB != null
    ) {
      return false;
    }

    // If both node have activated data property style - check if their data properties are equal
    if (
      DataPropertyStyleService.isDataPropertyStyleActive(nodeA) &&
      DataPropertyStyleService.isDataPropertyStyleActive(nodeB)
    ) {
      if (!this.nodeDataPropertiesEqual(nodeA, nodeB)) {
        return false;
      }
    }

    // Compare node styles, if equal, return true
    if (
      this.nodeStylesEqual(
        DiagramUtils.unwrapNodeStyle(nodeA).baseStyle,
        DiagramUtils.unwrapNodeStyle(nodeB).baseStyle
      )
    ) {
      return true;
    }
    // always fallback to false.
    return false;
  }

  public static nodeDataPropertiesEqual(nodeA: INode, nodeB: INode): boolean {
    let dataPropertyA = DataPropertyUtils.getDataPropertyFromNode(
      nodeA,
      appConsts.JURISDICTION_DEFINITION_ID
    );
    let dataPropertyB = DataPropertyUtils.getDataPropertyFromNode(
      nodeB,
      appConsts.JURISDICTION_DEFINITION_ID
    );

    return dataPropertyA?.value === dataPropertyB?.value;
  }

  public static nodeStylesEqual(
    styleA: INodeStyle,
    styleB: INodeStyle
  ): boolean {
    if (styleA instanceof ShapeNodeStyle && styleB instanceof ShapeNodeStyle) {
      return GraphElementsComparer.shapeNodeStyleAreEqual(styleA, styleB);
    } else if (
      styleA instanceof ImageNodeStyle &&
      styleB instanceof ImageNodeStyle
    ) {
      return GraphElementsComparer.imageNodeStylesAreEqual(styleA, styleB);
    } else if (
      styleA instanceof CompositeNodeStyle &&
      styleB instanceof CompositeNodeStyle
    ) {
      return GraphElementsComparer.compositeNodeStylesAreEqual(styleA, styleB);
    } else if (
      styleA instanceof DividingLineNodeStyle &&
      styleB instanceof DividingLineNodeStyle
    ) {
      return GraphElementsComparer.dividingLineNodeStylesAreEqual(
        styleA,
        styleB
      );
    } else if (
      styleA instanceof JigsawPathShapeNodeStyle &&
      styleB instanceof JigsawPathShapeNodeStyle
    ) {
      return GraphElementsComparer.jigsawPathShapeNodeStyleAreEqual(
        styleA,
        styleB
      );
    } else {
      return false;
    }
  }

  private static shapeNodeStyleAreEqual(
    styleA: ShapeNodeStyle,
    styleB: ShapeNodeStyle
  ): boolean {
    return (
      styleA.shape == styleB.shape &&
      styleA?.fill?.hasSameValue(styleB.fill) &&
      this.strokesEqual(styleA.stroke, styleB.stroke)
    );
  }

  private static compositeNodeStylesAreEqual(
    styleA: CompositeNodeStyle,
    styleB: CompositeNodeStyle
  ): boolean {
    // if they differ in size, then easy check
    if (styleA.styleDefinitions.length != styleB.styleDefinitions.length) {
      return false;
    }

    for (let index = 0; index < styleA.styleDefinitions.length; index++) {
      const styleDefA = styleA.styleDefinitions[index];
      const styleDefB = styleB.styleDefinitions[index];

      if (
        !GraphElementsComparer.styleDefinitionsAreEqual(styleDefA, styleDefB)
      ) {
        // when any definition is no the same, they return false
        return false;
      }
    }

    return true;
  }

  private static imageNodeStylesAreEqual(
    styleA: ImageNodeStyle,
    styleB: ImageNodeStyle
  ): boolean {
    return styleA.image == styleB.image;
  }

  private static dividingLineNodeStylesAreEqual(
    styleA: DividingLineNodeStyle,
    styleB: DividingLineNodeStyle
  ): boolean {
    return styleA.type === styleB.type
      ? GraphElementsComparer.strokesEqual(styleA.stroke, styleB.stroke)
      : false;
  }

  private static jigsawPathShapeNodeStyleAreEqual(
    styleA: JigsawPathShapeNodeStyle,
    styleB: JigsawPathShapeNodeStyle
  ): boolean {
    return (
      styleA.shape == styleB.shape &&
      styleA?.fill?.hasSameValue(styleB.fill) &&
      this.strokesEqual(styleA.stroke, styleB.stroke)
    );
  }

  private static styleDefinitionsAreEqual(
    styleDefinitionA: StyleDefinition,
    styleDefinitionB: StyleDefinition
  ): boolean {
    // test the insets first
    if (
      !GraphElementsComparer.insetsAreEqual(
        styleDefinitionA.insets as Insets,
        styleDefinitionB.insets as Insets
      )
    ) {
      return false;
    }

    // fall back to default node style comparison
    return GraphElementsComparer.nodeStylesEqual(
      styleDefinitionA.nodeStyle,
      styleDefinitionB.nodeStyle
    );
  }

  private static insetsAreEqual(insetsA: Insets, insetsB: Insets): boolean {
    if (insetsA == null && insetsB == null) {
      return true;
    }
    return (
      insetsA?.top == insetsB?.top &&
      insetsA?.right == insetsB?.right &&
      insetsA?.bottom == insetsB?.bottom &&
      insetsA?.left == insetsB?.left
    );
  }

  public static edgesEqual(edgeA: IEdge, edgeB: IEdge): boolean {
    // Check if edge types are different
    if (edgeA.tag.name != edgeB.tag.name) {
      return false;
    }

    // Check if edge styles are different
    return this.edgeStylesEqual(edgeA.style, edgeB.style);
  }

  public static edgeStylesEqual(
    styleA: IEdgeStyle,
    styleB: IEdgeStyle
  ): boolean {
    if (
      styleA instanceof PolylineEdgeStyle &&
      styleB instanceof PolylineEdgeStyle
    ) {
      return (
        styleA.smoothingLength == styleB.smoothingLength &&
        this.arrowsEqual(styleA.sourceArrow, styleB.sourceArrow) &&
        this.arrowsEqual(styleA.targetArrow, styleB.targetArrow) &&
        this.strokesEqual(styleA.stroke, styleB.stroke)
      );
    } else if (
      styleA instanceof BezierEdgeStyle &&
      styleB instanceof BezierEdgeStyle
    ) {
      return (
        this.arrowsEqual(styleA.sourceArrow, styleB.sourceArrow) &&
        this.arrowsEqual(styleA.targetArrow, styleB.targetArrow) &&
        this.strokesEqual(styleA.stroke, styleB.stroke)
      );
    } else if (
      styleA instanceof ArcEdgeStyle &&
      styleB instanceof ArcEdgeStyle
    ) {
      return (
        this.arrowsEqual(styleA.sourceArrow, styleB.sourceArrow) &&
        this.arrowsEqual(styleA.targetArrow, styleB.targetArrow) &&
        this.strokesEqual(styleA.stroke, styleB.stroke)
      );
    } else {
      return false;
    }
  }

  public static fillsEqual(fillA: Fill, fillB: Fill): boolean {
    return fillA.hasSameValue(fillB);
  }

  public static strokesEqual(strokeA: Stroke, strokeB: Stroke): boolean {
    if (!strokeA && !strokeB) {
      return true;
    }

    return (
      strokeA.fill.hasSameValue(strokeB.fill) &&
      strokeA.lineCap == strokeB.lineCap &&
      strokeA.lineJoin == strokeB.lineJoin &&
      strokeA.miterLimit == strokeB.miterLimit &&
      strokeA.thickness == strokeB.thickness &&
      (strokeA.dashStyle?.dashes?.size ?? 0) ==
        (strokeB.dashStyle?.dashes?.size ?? 0) &&
      (!strokeA.dashStyle?.dashes ||
        !strokeB.dashStyle?.dashes ||
        strokeA.dashStyle.dashes.every(
          (v, i) => v === strokeB.dashStyle.dashes.get(i)
        ))
    );
  }

  public static arrowsEqual(arrowA: IArrow, arrowB: IArrow): boolean {
    return (
      arrowA.length == arrowB.length && arrowA.cropLength == arrowB.cropLength
    );
  }

  public static pointsEqual(pointA: IPoint, pointB: IPoint): boolean {
    return pointA.x == pointB.x && pointA.y == pointB.y;
  }
}
