import DecorationStateManager from '@/core/services/DecorationStateManager';
import {
  DefaultLabelStyle,
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  Font,
  IconLabelStyle,
  IInputModeContext,
  INode,
  Insets,
  IRectangle,
  IRenderContext,
  Matrix,
  Point,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  ShapeNodeStyleRenderer,
  SimpleLabel,
  SimpleNode,
  Size,
  SolidColorFill,
  Stroke,
  SvgVisual,
  SvgVisualGroup,
  TextRenderSupport,
  Visual,
} from 'yfiles';
import DecorationState from '../DecorationState';
import HitResult from '../HitResult';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import DataPropertyTagFilter from '@/core/services/search/filters/DataPropertyTagFilter';
import { FilterType } from '@/core/services/search/filters/FilterType';
import { RenderCacheKey } from '../SvgRenderUtils';

export interface FilterState extends DecorationState {
  filters: DataPropertyTagFilter[];
}
export default class FilterDecorators implements JigsawNodeDecorator {
  public $class = 'FilterDecorators';
  public static INSTANCE: FilterDecorators = new FilterDecorators();

  // Node, label and styles used to render the final tag visual
  public decoratorNode: SimpleNode;
  public decoratorNodeStyle: ShapeNodeStyle;
  public decoratorLabel: SimpleLabel;
  public decoratorLabelStyle: IconLabelStyle;
  public decoratorLabelInnerStyle: DefaultLabelStyle;
  /**
   * The spacing between each of the decorators to render
   */
  public spacing: number;
  /**
   * Size of the tag icon (included/excluded symbol)
   */
  public iconSize: Size;
  /**
   * Max label size of each individual decorator
   */
  public maxLabelSize: Size;
  /**
   * Tag label padding within the container
   */
  public labelPadding: Point;
  /**
   * Tag label font size
   */
  public fontSize: number;
  /**
   * Tag label font color
   */
  public fontColor: string;
  /**
   * Corner radius of the tag label container rectangle
   */
  public cornerRadius: number;

  private readonly includedImage = '/media/svg/filters/included.svg';
  private readonly excludedImage = '/media/svg/filters/excluded.svg';

  constructor(options?: {
    spacing?: number;
    iconSize?: Size;
    maxLabelSize?: Size;
    labelPadding?: Point;
    fontSize?: number;
    fontColor?: string;
    containerRadius?: number;
  }) {
    this.spacing = options?.spacing ?? 3;
    this.iconSize = options?.iconSize ?? new Size(12, 12);
    this.maxLabelSize = options?.maxLabelSize ?? new Size(150, 20);
    this.labelPadding = options?.labelPadding ?? new Point(3, 2);
    this.fontSize = options?.fontSize ?? 11;
    this.fontColor = options?.fontColor ?? 'white';
    this.cornerRadius = options?.containerRadius ?? 2;

    this.decoratorNode = this.createDecoratorNode();
    this.decoratorNodeStyle = this.createDecoratorNodeStyle();
    this.decoratorLabel = this.createDecoratorLabel();
    this.decoratorLabelStyle = this.createDecoratorLabelStyle();
    this.decoratorLabelInnerStyle = this.decoratorLabelStyle
      .wrapped as DefaultLabelStyle;
  }

  public isVisible(renderContext: IRenderContext, node: INode): boolean {
    return true;
  }

  public createVisual(context: IRenderContext, node: INode): Visual {
    let visualGroup = new SvgVisualGroup();
    let tagFilters = this.getTagFilters(node);

    for (let i = 0; i < tagFilters.length; i++) {
      const filter = tagFilters[i];
      this.prepareRenderVisuals(node, filter, i);

      const nodeVisual = this.decoratorNodeStyle.renderer
        .getVisualCreator(this.decoratorNode, this.decoratorNodeStyle)
        .createVisual(context) as SvgVisual;

      const labelVisual = this.decoratorLabelStyle.renderer
        .getVisualCreator(this.decoratorLabel, this.decoratorLabelStyle)
        .createVisual(context) as SvgVisualGroup;

      const transform = new Matrix();
      transform.translate(this.decoratorNode.layout.toPoint());
      labelVisual.transform = transform;

      const group = new SvgVisualGroup();
      group.add(nodeVisual);
      group.add(labelVisual);
      visualGroup.add(group);
    }

    visualGroup[RenderCacheKey] = {
      previousTagCount: tagFilters.length,
    };

    return visualGroup;
  }

  public updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    const oldVisualGroup = oldVisual as SvgVisualGroup;

    const tagFilters = this.getTagFilters(node)!;
    const renderCache = oldVisualGroup[RenderCacheKey];

    if (!renderCache || renderCache.previousTagCount != tagFilters.length) {
      return this.createVisual(context, node);
    }

    for (let i = 0; i < tagFilters.length; i++) {
      const filter = tagFilters[i];
      this.decoratorLabel.text = filter.parameter.tagName;
      this.prepareRenderVisuals(node, filter, i);

      const group = oldVisualGroup.children.elementAt(i) as SvgVisualGroup;
      const nodeVisual = this.decoratorNodeStyle.renderer
        .getVisualCreator(this.decoratorNode, this.decoratorNodeStyle)
        .updateVisual(context, group.children.elementAt(0)) as SvgVisual;
      group.children.set(0, nodeVisual);

      const labelVisual = this.decoratorLabelStyle.renderer
        .getVisualCreator(this.decoratorLabel, this.decoratorLabelStyle)
        .updateVisual(context, group.children.elementAt(1)) as SvgVisualGroup;
      const transform = new Matrix();
      transform.translate(this.decoratorNode.layout.toPoint());
      labelVisual.transform = transform;

      group.children.set(1, labelVisual);
    }

    return oldVisualGroup;
  }

  public isHit(
    context: IInputModeContext,
    location: Point,
    node: INode
  ): HitResult {
    // tags are not clickable
    return HitResult.NONE;
  }

  private prepareRenderVisuals(
    node: INode,
    filter: DataPropertyTagFilter,
    filterIndex: number
  ) {
    this.decoratorLabel.text = filter.parameter.tagName;
    this.decoratorLabelStyle.icon =
      filter.type == FilterType.Include
        ? this.includedImage
        : this.excludedImage;
    this.decoratorNodeStyle.fill = new SolidColorFill(
      filter.parameter.tagColor
    );

    const size = this.getDecoratorSize(this.decoratorLabel.text);
    const position = this.getDecoratorPosition(node.layout, size, filterIndex);
    this.decoratorNode.layout = new Rect(
      position.x,
      position.y,
      size.width,
      size.height
    );
  }

  private getTagFilters(node: INode): DataPropertyTagFilter[] {
    const state = DecorationStateManager.getState(
      FilterDecorators.INSTANCE,
      node
    ) as FilterState;

    return state.filters ?? [];
  }

  private getDecoratorPosition(
    ownerLayout: IRectangle,
    decoratorSize: Size,
    filterIndex: number
  ): Point {
    const x = ownerLayout.x;
    const y =
      ownerLayout.y - (decoratorSize.height + this.spacing) * (filterIndex + 1);
    return new Point(x, y);
  }

  private getDecoratorSize(text: string): Size {
    const textSize = TextRenderSupport.measureText(
      text,
      this.decoratorLabelInnerStyle.font,
      this.maxLabelSize
    );
    return new Size(
      textSize.width + this.iconSize.width + this.labelPadding.x * 3,
      textSize.height + this.labelPadding.y * 2
    );
  }

  private createDecoratorNode(): SimpleNode {
    return new SimpleNode();
  }

  private createDecoratorNodeStyle(): ShapeNodeStyle {
    const style = new ShapeNodeStyle({
      stroke: Stroke.TRANSPARENT,
      shape: ShapeNodeShape.ROUND_RECTANGLE,
    });
    (style.renderer as ShapeNodeStyleRenderer).roundRectArcRadius =
      this.cornerRadius;
    return style;
  }

  private createDecoratorLabel(): SimpleLabel {
    return new SimpleLabel();
  }

  private createDecoratorLabelStyle(): IconLabelStyle {
    const style = new IconLabelStyle();
    style.iconSize = this.iconSize;
    style.iconPlacement = new ExteriorLabelModel({
      insets: new Insets(
        -this.iconSize.width - this.labelPadding.x,
        -this.iconSize.height - this.labelPadding.y,
        0,
        0
      ),
    }).createParameter(ExteriorLabelModelPosition.NORTH_WEST);
    style.wrappedInsets = new Insets(this.iconSize.width, 0, 0, 0);

    const innerStyle = style.wrapped as DefaultLabelStyle;
    innerStyle.font = new Font({ fontSize: this.fontSize });
    innerStyle.textFill = new SolidColorFill(this.fontColor);
    innerStyle.clipText = false;
    innerStyle.insets = new Insets(
      this.labelPadding.x * 2,
      this.labelPadding.y,
      this.labelPadding.x,
      this.labelPadding.y
    );
    return style;
  }

  public defaultState(): FilterState {
    return {
      filters: [],
    };
  }
}
