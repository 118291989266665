import { elementToSVG } from '@jigsaw/dom-to-svg';
import {
  LabelStyleBase,
  IRenderContext,
  ILabel,
  SvgVisual,
  Size,
  INode,
  Visual,
  IEdge,
  Rect,
  Point,
  GraphComponent,
  Insets,
  ICanvasContext,
  ILabelModelParameter,
  IInputModeContext,
} from 'yfiles';
import renderingConfig from '../config/renderingConfig';
import AutomationUtils, {
  SvgElementTypes,
} from '../services/analytics/AutomationUtils';
import BackgroundDomService from '../services/BackgroundDomService';
import CacheType from '../services/caching/CacheType';
import CachingService from '../services/caching/CachingService';
import DiagramUtils from '../utils/DiagramUtils';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import { stripHtml } from '../utils/html.utils';
import ExportConfig from '../config/ExportConfig';
import diagramConfig from '@/core/config/diagram.definition.config';
import { fixSvgElementStyles, intersects } from '../utils/common.utils';
import { isViewPortChanging } from '../services/graph/input-modes/JigsawMoveViewportInputMode';

const RenderCacheKey = 'RichTextCacheKey';
const RenderContainerElementId = 'rt-label-render-container';
const DebugEnabled = false;

export default class JigsawRichTextLabelStyle extends LabelStyleBase {
  insets: Insets = null;

  constructor() {
    super();
    this.insets = new Insets(0);
  }

  private createDebugVisual(label: ILabel): SVGGElement {
    // create a debug rect;
    const rect1 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'rect'
    );
    rect1.style.stroke = 'red';
    rect1.style.strokeWidth = '3px';
    rect1.style.fill = 'transparent';

    const size = this.getSize(label);
    rect1.setAttribute('width', `${size.width}px`);
    rect1.setAttribute('height', `${size.height}px`);

    rect1.setAttribute('x', '0');
    rect1.setAttribute('y', '0');

    const rect2 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'rect'
    );
    rect2.style.stroke = 'green';
    rect2.style.strokeWidth = '1px';
    rect2.style.fill = 'transparent';
    2;
    const rawSize = JigsawRichTextLabelStyle.measureTextRaw(
      label.text,
      size.width
    );
    rect2.setAttribute('width', `${size.width}px`);
    rect2.setAttribute('height', `${rawSize.height}px`);

    rect2.setAttribute('x', '0'); // this.paddingX.toString());
    rect2.setAttribute('y', '0');

    const g = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    // g.appendChild(rect1);
    g.appendChild(rect2);
    return g;
  }

  private createOrUpdateRenderCache(
    cacheOwner: any,
    label: ILabel
  ): IRenderCache {
    const renderCache: IRenderCache = cacheOwner[RenderCacheKey];

    let ownerLayout: Rect = null;
    if (INode.isInstance(label.owner)) {
      ownerLayout = label.owner.layout.toRect();
    }
    if (!renderCache) {
      const labelRect = label.layoutParameter.model.getGeometry(
        label,
        label.layoutParameter
      );
      return ((cacheOwner[RenderCacheKey] as IRenderCache) = {
        text: label.text,
        ownerLayout: ownerLayout,
        labelSize: labelRect.toSize(),
        labelAnchor: new Point(labelRect.anchorX, labelRect.anchorY),
        requiresRedraw: false,
        parameterType: label.layoutParameter,
      });
    }
    if (renderCache.text != label.text) {
      renderCache.requiresRedraw = true;
    }

    if (
      (ownerLayout &&
        !DiagramUtils.areSizeEquals(
          ownerLayout.toSize(),
          renderCache.ownerLayout.toSize()
        )) ||
      renderCache.parameterType != label.layoutParameter
    ) {
      renderCache.requiresRedraw = true;
    }

    return renderCache;
  }

  createVisual(context: IRenderContext, label: ILabel): SvgVisual {
    if (!label?.text?.trim()) {
      return null;
    }
    let plainText = stripHtml(label.text);
    if (!plainText.trim()) {
      return null;
    }

    // This implementation creates a 'g' element and uses it for the rendering of the label.
    const g = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    const svgVisual = new SvgVisual(g);
    const renderCache = this.createOrUpdateRenderCache(svgVisual, label);
    if (DebugEnabled && label.owner instanceof IEdge) {
      const debugElement = this.createDebugVisual(label);
      // Render the label
      g.appendChild(debugElement);
    }
    this.render(label, g, renderCache);
    this.transformElement(label, g, renderCache);

    AutomationUtils.attachAutomationIdToSvg(
      svgVisual.svgElement,
      SvgElementTypes.LabelElement
    );

    return svgVisual;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual,
    label: ILabel
  ): Visual {
    if (isViewPortChanging(context)) {
      return oldVisual;
    }
    const svgVisual = oldVisual as SvgVisual;
    const renderCache = this.createOrUpdateRenderCache(svgVisual, label);
    if (renderCache.requiresRedraw) {
      (context.canvasComponent as GraphComponent).graph.setLabelPreferredSize(
        label,
        this.getSize(label)
      );
      return this.createVisual(context, label);
    }
    this.transformElement(
      label,
      svgVisual.svgElement as SVGElement,
      renderCache
    );

    AutomationUtils.attachAutomationIdToSvg(
      svgVisual.svgElement,
      SvgElementTypes.LabelElement
    );
    return oldVisual;
  }

  private transformElement(
    label: ILabel,
    el: SVGElement,
    renderCache: IRenderCache
  ): void {
    // const labelSize = renderCache.labelSize;
    // const labelAnchor = renderCache.labelAnchor;
    const t = label.layoutParameter.model.getGeometry(
      label,
      label.layoutParameter
    );

    el.setAttribute(
      'transform',
      `translate(${t.anchorX} ${t.anchorY - t.height})`
    );
  }

  private static getRenderContainer(): HTMLElement {
    let renderContainer = BackgroundDomService.getElementById(
      RenderContainerElementId
    );
    if (renderContainer) {
      renderContainer.style.width = '';
      renderContainer.style.maxWidth = '';
      renderContainer.style.height = '';
      renderContainer.style.maxHeight = '';
      return renderContainer;
    }

    const outerContainer = BackgroundDomService.createElement('div');
    outerContainer.style.position = 'absolute';
    if (DebugEnabled) {
      outerContainer.style.left = '300px';
      outerContainer.style.top = '200px';
      outerContainer.style.zIndex = '999';
      outerContainer.style.border = '1px solid red';
    }

    renderContainer = BackgroundDomService.createElement('div');
    renderContainer.id = RenderContainerElementId;
    renderContainer.classList.add(
      ...[ExportConfig.pageContentClass, ExportConfig.diagramContentClass]
    );
    renderContainer.style.wordBreak = 'break-word';
    if (DebugEnabled) {
      renderContainer.style.border = '1px solid red';
    }
    //SY: this is added as a workaround for a bug in the dom-to-svg-library
    renderContainer.style.whiteSpace = 'normal';
    outerContainer.appendChild(renderContainer);
    BackgroundDomService.appendElement(outerContainer);

    return renderContainer;
  }

  private render(
    label: ILabel,
    container: Element,
    renderData: IRenderCache
  ): void {
    if (label.text == null || label.text == '') {
      return;
    }

    const svgElement = this.getSvgElement(renderData.labelSize, label);

    if (label.owner instanceof IEdge) {
      // apply a white background to edges so they sit on top of the edge properly
      const bgRect = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect'
      );
      bgRect.style.fill = 'white';
      bgRect.setAttribute('width', `${renderData.labelSize.width}px`);
      bgRect.setAttribute('height', `${renderData.labelSize.height}px`);

      container.appendChild(bgRect);
    }
    // add the SVG without the styles to the container
    container.appendChild(svgElement);
  }

  private getSvgElement(size: Size, label: ILabel): Node {
    const cachedSvgElementKey = CachingService.generateKey(
      CacheType.RichTextLabelSvgElement,
      label.text,
      size.width,
      size.height
    );
    const cachedSvgElement =
      CachingService.get<SVGElement>(cachedSvgElementKey);
    if (cachedSvgElement) {
      return cachedSvgElement.cloneNode(true);
    }

    const renderContainer = JigsawRichTextLabelStyle.getRenderContainer();
    renderContainer.innerHTML = JigsawRichTextLabelStyle.prepareTextContent(
      label.text
    );
    renderContainer.style.width = size.width + 'px';
    renderContainer.style.height = size.height + 'px';

    // we null this out to prevent the generated SVG having the ID copied, yes elementToSVG also duplicates the ID.
    renderContainer.id = '';

    const svg = elementToSVG(renderContainer, {
      defaultFontSize: diagramConfig.defaultFontSize + 'pt',
    });
    renderContainer.id = RenderContainerElementId;

    const svgElement = svg.documentElement.querySelector(
      '.' + ExportConfig.diagramContentClass
    ) as SVGGElement;
    this.removeEmptyGroupTags(svgElement);
    fixSvgElementStyles(svgElement);
    // this allows labels that are wider than their container to still be visible
    svgElement.style.overflow = 'visible';

    // elementToSVG will copy all styles from the document into the SVG, including fonts and external bits.
    // this, when added back into the dom causes a flicker as the browser tries to reload these
    // external resources again
    // so we need to remove them

    // find all style elements within the SVG
    const styles = svgElement.querySelectorAll('style');

    // loop them
    styles.forEach((d) => {
      // remove them
      svgElement.removeChild(d);
    });

    CachingService.set(cachedSvgElementKey, {
      data: svgElement.cloneNode(true),
    });
    svgElement.classList.remove('document-page-content', 'diagram-content');
    return svgElement;
  }

  private removeEmptyGroupTags(el: Element | null): void {
    el.querySelectorAll('g:empty').forEach((g) => g.remove());
  }

  public static prepareTextContent(text: string): string {
    if (!text) {
      return text;
    }
    // Remove all leftover ZWSs
    text = text.replaceAll(ZERO_WIDTH_SPACE, '');
    return text.trim();
  }

  public static measureTextRaw(text: string, maxWidth?: number): Size {
    if (text == null || text == '' || stripHtml(text) == '') {
      return Size.ZERO;
    }

    const cachedTextSizeKey = CachingService.generateKey(
      CacheType.RichTextLabelTextSize,
      text,
      maxWidth ?? 0
    );
    const cachedTextSize = CachingService.get<Size>(cachedTextSizeKey);
    if (cachedTextSize) {
      return cachedTextSize;
    }
    let renderContainer = JigsawRichTextLabelStyle.getRenderContainer();
    renderContainer.innerHTML = text;

    renderContainer.style.maxWidth = maxWidth ? `${maxWidth}px` : '';

    const clientRect = renderContainer.getBoundingClientRect();
    const size = new Size(clientRect.width, clientRect.height);

    CachingService.set(cachedTextSizeKey, { data: size });

    return size;
  }

  private getSizeForNode(label: ILabel): Size {
    if (!(label.owner instanceof INode)) {
      throw 'owner is not an node';
    }

    const maxWidth = DiagramUtils.getNodeLabelMaxWidth(label);

    const rawSize = JigsawRichTextLabelStyle.measureTextRaw(
      label.text,
      maxWidth
    );
    return new Size(
      rawSize.width + this.insets.horizontalInsets,
      rawSize.height + this.insets.verticalInsets
    );
  }

  private getSizeForEdge(label: ILabel): Size {
    if (!(label.owner instanceof IEdge)) {
      throw 'owner is not an edge';
    }
    const htmlEl = JigsawRichTextLabelStyle.measureTextRaw(label.text);
    //window.document.body.appendChild(htmlEl);
    //const clientRect = htmlEl.getBoundingClientRect();
    const size = new Size(
      Math.ceil(htmlEl.width) + this.insets.horizontalInsets,
      Math.ceil(htmlEl.height) + this.insets.verticalInsets
    );

    //window.document.body.removeChild(htmlEl);
    return size;
  }

  private getSize(label: ILabel): Size {
    if (label.owner instanceof INode) {
      return this.getSizeForNode(label);
    } else if (label.owner instanceof IEdge) {
      return this.getSizeForEdge(label);
    }
    throw 'unsupported ILabelOwner';
  }
  isVisible(context: ICanvasContext, rectangle: Rect, label: ILabel): boolean {
    return (
      isLabelVisibleAtZoom(context.zoom) &&
      intersects(
        rectangle,
        new Rect(
          label.layout.anchorX,
          label.layout.anchorY,
          label.layout.width,
          label.layout.height
        )
      )
    );
  }

  getPreferredSize(label: ILabel): Size {
    return this.getSize(label);
  }

  calculateBaseLineShift(label: ILabel): number {
    if (label.owner instanceof INode) {
      return 5;
    }
    const height = label.preferredSize.height;
    if (height >= 18 && height <= 20) {
      return 3;
    }
    if (height >= 22 && height <= 23) {
      return 4;
    }
    if (height >= 25 && height <= 26) {
      return 5;
    }
    if (height >= 30 && height <= 33) {
      return 6;
    }
    if (height >= 37 && height <= 42) {
      return 7;
    }
    return 5;
  }

  protected override isHit(
    context: IInputModeContext,
    location: Point,
    label: ILabel
  ): boolean {
    if (label.owner instanceof IEdge) {
      return label.layout.bounds.containsWithEps(
        location,
        diagramConfig.defaultEdgeLabelHitTestRadius
      );
    }
    return super.isHit(context, location, label);
  }
}

const labelRenderCache = {};
class IRenderCache {
  text: string;
  ownerLayout: Rect;
  labelSize: Size;
  labelAnchor: Point;
  requiresRedraw: boolean;
  parameterType: ILabelModelParameter;
}

export const isLabelVisibleAtZoom = (currentZoom: number) =>
  currentZoom > renderingConfig.labelVisibilityZoomThreshold ||
  window.localStorage.getItem('webglenabled') === 'true';
