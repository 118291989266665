import {
  ArrowStyleDto,
  CompositeNodeStyleDto,
  CompositeShape,
  CompositeStyleDefinitionDto,
  DataPropertyDefinitionItemDto,
  EdgeStyleDto,
  EdgeVisualType,
  FillDto,
  ImageNodeStyleDto,
  JigsawPathShapeNodeStyleDto,
  NodeShape,
  NodeVisualType,
  ShapeNodeStyleDto,
  StrokeDto,
} from '@/api/models';
import { INode, IEdge, IModelItem } from 'yfiles';
import DiagramWriter from '../services/graph/serialization/diagram-writer.service';
import { AnnotationType } from '../common/AnnotationType';
import { calculateHash } from './common.utils';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import appConsts from '@/core/config/appConsts';

interface IHashDataNode {
  groupUuid: string;
  isGroupNode: boolean;
  style: IHashDataNodeStyle;
  isAnnotation: boolean;
  name: string;
  annotationType: AnnotationType;
  isDataPropertyStyleActive?: boolean;
  dataProperties?: {
    jurisdiction: any;
  };
}

interface IHashDataEdge {
  name: string;
  style: IHashDataEdgeStyle;
}

interface IHashDataDataPropertyDefinitionItem {
  definitionId: number;
  imageData: string;
  itemValue: string;
}

interface IHashDataEdgeStyle {
  stroke: StrokeDto;
  sourceArrow: ArrowStyleDto;
  targetArrow: ArrowStyleDto;
  visualType: EdgeVisualType;
}

interface IHashDataNodeStyle {
  visualType: NodeVisualType;
  shape?: NodeShape | CompositeShape;
  fill?: FillDto;
  stroke?: StrokeDto;
  image?: string;
  definitions?: CompositeStyleDefinitionDto[];
}

export default class GraphElementsHashGenerator {
  public static getElementHashKey(item: IModelItem): string {
    if (item instanceof INode) {
      return this.getNodeHashKey(item);
    } else if (item instanceof IEdge) {
      return this.getEdgeHashKey(item);
    }
    return null;
  }

  public static getNodeHashKey(
    node: INode,
    shouldProcessStroke = false
  ): string {
    const jurisdiction = DataPropertyUtils.getDataPropertyFromNode(
      node,
      appConsts.JURISDICTION_DEFINITION_ID
    );

    const data: IHashDataNode = {
      groupUuid:
        node.tag.isGroupNode && node.tag.groupUuid ? node.tag.groupUuid : null,
      isGroupNode: node.tag.isGroupNode ?? null,
      style: this.getNodeStyle(node, shouldProcessStroke),
      isAnnotation: node.tag.isAnnotation ?? null,
      name: node.tag?.name?.toLowerCase(),
      annotationType: node.tag.annotationType,
      isDataPropertyStyleActive: node.tag?.dataPropertyStyle?.isActive ?? false,
    };

    if (data.isDataPropertyStyleActive) {
      data.dataProperties = {
        jurisdiction: jurisdiction?.value,
      };
    }

    return calculateHash(data);
  }

  public static getEdgeHashKey(edge: IEdge): string {
    let data: IHashDataEdge = {
      style: this.getEdgeStyle(edge),
      name: edge.tag?.name?.toLowerCase(),
    };

    return calculateHash(data);
  }

  public static getDataPropertyDefinitionItem(
    definition: DataPropertyDefinitionItemDto
  ): string {
    const data: IHashDataDataPropertyDefinitionItem = {
      definitionId: definition.dataPropertyDefinitionId,
      imageData: definition.imageData,
      itemValue: definition.itemValue,
    };

    return calculateHash(data);
  }

  private static getNodeStyle(
    node: INode,
    shouldProcessStroke = false
  ): IHashDataNodeStyle {
    const nodeStyleDto = DiagramWriter.convertNodeStyle(node);

    if (
      nodeStyleDto instanceof ShapeNodeStyleDto ||
      nodeStyleDto instanceof JigsawPathShapeNodeStyleDto
    ) {
      return {
        fill: nodeStyleDto.fill,
        stroke: shouldProcessStroke
          ? this.getNodeStroke(nodeStyleDto.stroke)
          : nodeStyleDto.stroke,
        visualType: nodeStyleDto.visualType,
        shape: Number(nodeStyleDto.shape),
      };
    } else if (nodeStyleDto instanceof ImageNodeStyleDto) {
      return {
        image: nodeStyleDto.imageUrl,
        visualType: nodeStyleDto.visualType,
      };
    } else if (nodeStyleDto instanceof CompositeNodeStyleDto) {
      let definitions = [];
      for (const definition of nodeStyleDto.styleDefinitions) {
        let def = {
          insets: definition.insets,
          style: {
            fill: definition.nodeStyle.fill,
            stroke: definition.nodeStyle.stroke,
            shape: Number(definition.nodeStyle.shape),
          },
        };
        definitions.push(def);
      }
      return {
        shape: Number(nodeStyleDto.shape),
        definitions: definitions,
        visualType: nodeStyleDto.visualType,
      };
    }
  }

  private static getEdgeStyle(edge: IEdge): IHashDataEdgeStyle {
    const edgeStyleDto: EdgeStyleDto = DiagramWriter.convertEdgeStyle(edge);
    return {
      stroke: edgeStyleDto.stroke,
      sourceArrow: edgeStyleDto.sourceArrow,
      targetArrow: edgeStyleDto.targetArrow,
      visualType: edgeStyleDto.visualType,
    };
  }

  /**
   * Node stroke properties need to be in a specific order to calculate the correct hash
   */
  private static getNodeStroke(stroke: StrokeDto): StrokeDto {
    if (!stroke) {
      return stroke;
    }
    return {
      thickness: stroke.thickness,
      fill: stroke.fill,
      dashStyle: stroke.dashStyle,
    };
  }
}
