import JInsets from '@/core/common/JInsets';
import ExportConfig from '@/core/config/ExportConfig';

const zeroLayout: LayoutItem = (): number => 0;
const defaultColorLayout = (): string => 'white';

type LayoutItem = (i, node) => number | string;

type TableLayout = {
  paddingTop?: LayoutItem;
  paddingBottom?: LayoutItem;
  paddingLeft?: LayoutItem;
  paddingRight?: LayoutItem;
  hLineWidth?: LayoutItem;
  vLineWidth?: LayoutItem;
  hLineColor?: LayoutItem;
  vLineColor?: LayoutItem;
};

export class PdfTableLayouts {
  public static noPaddingTableLayout = this.createTableLayout({});

  public static headerFooterTableLayout = this.createTableLayout({
    hLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    vLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    hLineColor: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
    vLineColor: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
  });

  public static textElementTableLayout = this.createTableLayout({
    hLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    vLineWidth: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderWidth ?? 0
        : 0;
    },
    hLineColor: (i, node) => {
      return i === 0 || i === node?.table?.body?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
    vLineColor: (i, node) => {
      return i === 0 || i === node?.table?.widths?.length
        ? node?.table?.borderColor ?? 'white'
        : 'white';
    },
  });

  public static createHtmlContentTableLayout(padding: JInsets): TableLayout {
    return this.createTableLayout({
      paddingTop: (i, node) => {
        return padding.top;
      },
      paddingBottom: (i, node) => {
        return padding.bottom;
      },
      paddingLeft: (i, node) => {
        return i === 0 ? padding.left : 0;
      },
      paddingRight: (i, node) => {
        return i === node?.table?.widths?.length - 1 ? padding.right : 0;
      },
    });
  }

  public static createTableLayout(layout: TableLayout): TableLayout {
    return {
      paddingTop: layout.paddingTop ?? zeroLayout,
      paddingBottom: layout.paddingBottom ?? zeroLayout,
      paddingLeft: layout.paddingLeft ?? zeroLayout,
      paddingRight: layout.paddingRight ?? zeroLayout,
      hLineWidth: layout.hLineWidth ?? zeroLayout,
      vLineWidth: layout.vLineWidth ?? zeroLayout,
      hLineColor: layout.hLineColor ?? defaultColorLayout,
      vLineColor: layout.vLineColor ?? defaultColorLayout,
    };
  }
}
