import {
  DocumentPageContentType,
  DocumentPageLayoutType,
  DocumentPageType,
  PageDesignDto,
  PageDesignSetDto,
} from '@/api/models';
import LayoutSerializer from '@/components/LayoutEditor/LayoutSerializer';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import ExportConfig from '@/core/config/ExportConfig';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import { PageDesignConfig } from '../StepsDesignerTypes';
import { sortArrayBy } from '@/core/utils/common.utils';
import { CSSProperties } from 'vue/types/jsx';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import { PageType } from '../PageDesignGroup';

export default class StepsDesignerUtils {
  public static async generatePageDesignHtmlThumbnail(
    pageDesign: PageDesignDto,
    pageConfig?: PageDesignConfig
  ): Promise<string> {
    if (pageDesign.layoutType === DocumentPageLayoutType.None) {
      return this.buildDiagramPageThumbnailHtml(pageDesign, pageConfig);
    } else {
      return this.buildThumbnailHtmlPart(pageDesign.bodyLayout);
    }
  }

  public static getThumbnailStyle(
    pageDesign: PageDesignDto,
    thumbnailWidth: number
  ): CSSProperties {
    const heightScale =
      pageDesign.pageStyle.height / pageDesign.pageStyle.width;
    const newHeight = thumbnailWidth * heightScale + 'px';
    return { width: thumbnailWidth + 'px', height: newHeight };
  }

  public static sortPageDesignSets(
    pageDesignSets: Array<PageDesignSetDto>,
    skipSort: boolean = false
  ): Array<PageDesignSetDto> {
    if (!skipSort) {
      pageDesignSets = sortArrayBy(pageDesignSets, 'name', 'ascending');
    }
    const defaultSetIndex = pageDesignSets.findIndex((s) => s.isDefault);
    const defaultSet = pageDesignSets[defaultSetIndex];
    pageDesignSets.splice(defaultSetIndex, 1);
    pageDesignSets.unshift(defaultSet);
    return pageDesignSets;
  }

  public static async buildThumbnailHtmlPart(content: string): Promise<string> {
    const items = LayoutSerializer.deserializeFromJson(content);
    const html = await LayoutSerializer.serializeToHtml(items);
    return html;
  }

  public static async buildThumbnailTitleHtml(
    pageDesign: PageDesignDto,
    pageConfig: PageDesignConfig
  ): Promise<string> {
    if (!pageConfig) {
      return '';
    }

    const currentPageTitle = pageDesign.pageTitle;
    if (currentPageTitle && currentPageTitle.show) {
      const layoutHtml = await this.buildThumbnailHtmlPart(
        currentPageTitle.titleLayout
      );

      const showHeaderWidget = LayoutWidgetUtils.contentAreaContainsWidgets(
        pageDesign,
        DocumentContentArea.Header
      );
      const left = pageDesign.pageStyle.fullMargins.left;
      const right = pageDesign.pageStyle.fullMargins.right;

      let top = 0;
      if (pageDesign.headerStyle.show || showHeaderWidget) {
        top = pageDesign.pageStyle.innerMargins.top;
      } else {
        top = pageDesign.pageStyle.fullMargins.top;
      }

      return `<div style="
          position: relative;
          margin-top: ${top}pt;
          margin-left: ${left}pt;
          margin-right: ${right}pt;
          height: ${currentPageTitle.height}pt;
          z-index: 2;
          ">
        ${layoutHtml}
      </div>`;
    }

    return '';
  }

  public static async buildDiagramPageThumbnailHtml(
    pageDesign: PageDesignDto,
    pageConfig?: PageDesignConfig
  ): Promise<string> {
    const pageContainsTextPane = pageConfig
      ? this.pageContainsTextPane(pageConfig.type)
      : false;
    const headerHtml = await this.buildThumbnailHtmlPart(
      pageDesign.headerLayout
    );
    const footerHtml = await this.buildThumbnailHtmlPart(
      pageDesign.footerLayout
    );
    const backgroundHtml = await this.buildThumbnailHtmlPart(
      pageDesign.backgroundLayout
    );
    const headerHeight = pageDesign.headerStyle.height;
    const footerHeight = pageDesign.footerStyle.height;
    const headerContainsWidget = LayoutWidgetUtils.contentAreaContainsWidgets(
      pageDesign,
      DocumentContentArea.Header,
      true
    );
    const footerContainsWidget = LayoutWidgetUtils.contentAreaContainsWidgets(
      pageDesign,
      DocumentContentArea.Footer,
      true
    );
    const showHeader = pageDesign.headerStyle.show || headerContainsWidget;
    const showFooter = pageDesign.footerStyle.show || footerContainsWidget;

    const header =
      showHeader || pageContainsTextPane
        ? `<div style="position: relative; overflow: hidden; z-index: 2; height: ${headerHeight}pt">${
            showHeader ? headerHtml : ''
          }</div>`
        : '';
    const footer =
      showFooter || pageContainsTextPane
        ? `<div style="position: absolute; bottom: 0; overflow: hidden; z-index: 2; height: ${footerHeight}pt; width: 100%;">${
            showFooter ? footerHtml : ''
          }</div>`
        : '';

    const titleHtml = await this.buildThumbnailTitleHtml(
      pageDesign,
      pageConfig
    );

    return `
            <div style="
                width: ${pageDesign.pageStyle.width}pt;
                height: ${pageDesign.pageStyle.height}pt;
            ">
              <div style="position: relative; height: 100%;">
                <div style="position: absolute; top: 0; bottom: 0; width: 100%; z-index: 1;">${backgroundHtml}</div>
                ${header}
                ${titleHtml}
                ${footer}
              </div>
            </div>`;
  }

  public static getPageScale(
    pageDesign: PageDesignDto,
    containerWidth: number
  ): number {
    let { width, height } = pageDesign.pageStyle;
    width = width * ExportConfig.pointToPixelFactor;
    height = height * ExportConfig.pointToPixelFactor;
    const widthScale = containerWidth / pageDesign.pageStyle.width;
    const thumbnailHeight = pageDesign.pageStyle.height * widthScale;

    const availableSizeRatio = containerWidth / thumbnailHeight;
    const pageSizeRatio = width / height;

    let scale = 1;

    if (availableSizeRatio < pageSizeRatio) {
      const scaledHeight = containerWidth / pageSizeRatio;
      scale = scaledHeight / height;
    } else {
      const scaledWidth = thumbnailHeight * pageSizeRatio;
      scale = scaledWidth / width;
    }

    return scale;
  }

  public static mapPageType(pageDesign: PageDesignDto): PageType {
    if (
      pageDesign.layoutType === DocumentPageLayoutType.None &&
      pageDesign.contentType === DocumentPageContentType.None &&
      pageDesign.pageType === DocumentPageType.Diagram
    ) {
      return PageType.Diagram;
    } else if (
      pageDesign.layoutType === DocumentPageLayoutType.None &&
      pageDesign.contentType === DocumentPageContentType.Html &&
      pageDesign.pageType === DocumentPageType.Split
    ) {
      return PageType.DiagramText;
    } else if (
      pageDesign.layoutType === DocumentPageLayoutType.None &&
      pageDesign.contentType === DocumentPageContentType.Html &&
      pageDesign.pageType === DocumentPageType.Content &&
      pageDesign.contentColumns === 0
    ) {
      return PageType.Text;
    } else if (
      pageDesign.layoutType === DocumentPageLayoutType.None &&
      pageDesign.contentType === DocumentPageContentType.Html &&
      pageDesign.pageType === DocumentPageType.Content &&
      pageDesign.contentColumns === 2
    ) {
      return PageType.TextText;
    } else if (pageDesign.layoutType === DocumentPageLayoutType.Cover) {
      return PageType.Cover;
    } else if (pageDesign.layoutType === DocumentPageLayoutType.Filler) {
      return PageType.Filler;
    } else if (pageDesign.layoutType === DocumentPageLayoutType.Closing) {
      return PageType.Closing;
    } else if (
      pageDesign.layoutType === DocumentPageLayoutType.None &&
      pageDesign.contentType === DocumentPageContentType.MasterLegend
    ) {
      return PageType.MasterLegend;
    } else {
      throw new Error('Page design does not match any known type');
    }
  }

  public static pageContainsTextPane(type: PageType): boolean {
    return (
      type === PageType.DiagramText ||
      type === PageType.Text ||
      type === PageType.TextText
    );
  }
}
