import { ExportFormat } from '../ExportFormat';
import GraphMLExportProvider from './GraphMLExportProvider';
import IExportProvider from './IExportProvider';
import JsonExportProvider from './JsonExportProvider';
import LegacyPdfExportProvider from './LegacyPdfExportProvider';
import PdfExportProvider from './PdfExportProvider';
import PngExportProvider from './PngExportProvider';
import PowerPointExportProvider from './PowerPointExportProvider';
import SvgExportProvider from './SvgExportProvider';
import VisioExportProvider from './VisioExportProvider';

export default class ExportProviderFactory {
  public static getProvider(format: ExportFormat): IExportProvider {
    switch (format) {
      case ExportFormat.GraphML:
        return new GraphMLExportProvider();
      case ExportFormat.LegacyPdf:
        return new LegacyPdfExportProvider();
      case ExportFormat.Pdf:
        return new PdfExportProvider();
      case ExportFormat.Png:
        return new PngExportProvider();
      case ExportFormat.PowerPoint:
        return new PowerPointExportProvider();
      case ExportFormat.Svg:
        return new SvgExportProvider();
      case ExportFormat.Visio:
        return new VisioExportProvider();
      case ExportFormat.Json:
        return new JsonExportProvider();
      default:
        throw 'Unknown export format: ' + format;
    }
  }
}
