import { Module } from 'vuex';

import EntityTypesApiService from '@/api/EntityTypesApiService';
import { EntityTypeDto } from '@/api/models';

import i18n from '@/core/plugins/vue-i18n';
import { notify } from '@/components/shared/AppNotification';

export const ENTITY_TYPES_NAMESPACE = 'entityTypes';
export const GET_ENTITY_LOADING = 'getEntityLoading';
export const GET_ENTITY_TYPES = 'getEntityTypes';
export const GET_FORMATTED_ENTITY_TYPES = 'getFormattedEntityTypes';
export const SET_ENTITY_LOADING = 'setEntityLoading';
export const SET_ENTITY_TYPES = 'setEntityTypes';
export const LOAD_ENTITY_TYPES = 'loadEntityTypes';

interface State {
  entityTypesLoading: boolean;
  entityTypes: EntityTypeDto[];
}

const EntityTypesModule: Module<State, any> = {
  namespaced: true,

  state: {
    entityTypesLoading: false,
    entityTypes: [],
  },

  getters: {
    [GET_ENTITY_LOADING](state) {
      return state.entityTypesLoading;
    },

    [GET_ENTITY_TYPES](state) {
      return state.entityTypes;
    },

    [GET_FORMATTED_ENTITY_TYPES](state) {
      return state.entityTypes.map((el) => {
        return el.systemOwned
          ? { ...el, name: i18n.t(`${el.name}`).toString() }
          : el;
      });
    },
  },

  actions: {
    async [LOAD_ENTITY_TYPES](context) {
      context.commit(SET_ENTITY_LOADING, true);
      try {
        const response = await EntityTypesApiService.getAll();
        const entityTypes = response.data.result;
        context.commit(SET_ENTITY_TYPES, entityTypes);
        return entityTypes;
      } catch (error) {
        notify({
          title: i18n.t('ERROR_GET_ENTITY_TYPES_PLEASE_TRY_AGAIN').toString(),
          type: 'error',
        });
      } finally {
        context.commit(SET_ENTITY_LOADING, false);
      }
    },
  },

  mutations: {
    [SET_ENTITY_LOADING](state, payload) {
      state.entityTypesLoading = payload;
    },

    [SET_ENTITY_TYPES](state, payload) {
      state.entityTypes = payload;
    },
  },
};
export default EntityTypesModule;
