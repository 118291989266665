import {
  DiagramNodeDto,
  ShapeNodeStyleDto,
  CompositeNodeStyleDto,
  NodeVisualType,
  DiagramEdgeDto,
  LayoutDto,
  LabelStyleDto,
  ImageNodeStyleDto,
  DiagramDto,
  DocumentDto,
  DocumentPageDto,
  JigsawPathShapeNodeStyleDto,
} from '@/api/models';
import { AnnotationType } from '@/core/common/AnnotationType';
import JPoint from '@/core/common/JPoint';
import DiagramUtils from '@/core/utils/DiagramUtils';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/core/plugins/vue-i18n';
import CKEditorUtils from '@/core/utils/CKEditorUtils';
import BackgroundGraphService from '../graph/BackgroundGraphService';
import { AdjacencyTypes, IEdge, IGraph, PolylineEdgeStyle } from 'yfiles';
import DiagramWriter from '../graph/serialization/diagram-writer.service';
import LegendUtils from '@/components/DiagramLegend/LegendUtils';
import appConsts from '@/core/config/appConsts';
import { generateUuid } from '@/core/utils/common.utils';
import { SerializedDiagramEntityComparer } from './SerializedDiagramEntityComparer';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames,
} from '@/core/common/DataPropertyDisplayType';
import JurisdictionDecorator, {
  JurisdictionDecorationState,
} from '@/core/styles/decorators/JurisdictionDecorator';

export default class SerializedDiagramUtils {
  public static getNodeStyles(
    node: DiagramNodeDto
  ): Array<ShapeNodeStyleDto | ImageNodeStyleDto> {
    const styles:
      | CompositeNodeStyleDto
      | JigsawPathShapeNodeStyleDto
      | ShapeNodeStyleDto[]
      | ImageNodeStyleDto[] = [];
    if (node.style.visualType == NodeVisualType.Composite) {
      const targetNodeStyle = node.style as CompositeNodeStyleDto;
      styles.push(...targetNodeStyle.styleDefinitions.map((d) => d.nodeStyle));
    } else if (
      node.style.visualType == NodeVisualType.Shape ||
      node.style.visualType == NodeVisualType.JigsawPathShape ||
      node.style.visualType === NodeVisualType.Image ||
      node.style.visualType == NodeVisualType.DividingLine
    ) {
      styles.push(node.style);
    }
    return styles;
  }

  public static copyNodeType = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): void => {
    if (
      sourceNode.style.visualType === targetNode.style.visualType &&
      Number(sourceNode.style.visualType) === NodeVisualType.Shape
    ) {
      (targetNode.style as ShapeNodeStyleDto).shape = (
        sourceNode.style as ShapeNodeStyleDto
      ).shape;
    } else if (
      sourceNode.style.visualType === targetNode.style.visualType &&
      Number(sourceNode.style.visualType) === NodeVisualType.JigsawPathShape
    ) {
      (targetNode.style as JigsawPathShapeNodeStyleDto).shape = (
        sourceNode.style as JigsawPathShapeNodeStyleDto
      ).shape;
    } else {
      targetNode.style = cloneDeep(sourceNode.style);
    }

    targetNode.data['annotationType'] = sourceNode.data['annotationType'];
    targetNode.data['isAnnotation'] = sourceNode.data['isAnnotation'];
    targetNode.data['name'] = sourceNode.data['name'];
    targetNode.data['labelData'] = sourceNode.data['labelData'];
    targetNode.data['placeholderText'] = sourceNode.data['placeholderText'];

    if (
      targetNode.data['labelIsPlaceholder'] &&
      sourceNode.data['labelIsPlaceholder']
    ) {
      this.copyLabel(sourceNode, targetNode);
    } else if (
      targetNode.data['labelIsPlaceholder'] &&
      !sourceNode.data['labelIsPlaceholder']
    ) {
      const labelText = this.getPlaceholderLabelText(targetNode);
      const label = this.getHtmlLabelContent(targetNode, labelText);
      targetNode.label = label;
    }

    const targetNodeCenter = this.getNodeCenter(targetNode.layout);
    this.copyNodeSize(sourceNode, targetNode);
    this.setNodeCenter(targetNode.layout, targetNodeCenter);
  };

  public static copyNodeLayout = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): void => {
    targetNode.layout = { ...sourceNode.layout };
  };

  public static copyNodeSize = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): void => {
    targetNode.layout.height = sourceNode.layout.height;
    targetNode.layout.width = sourceNode.layout.width;
  };

  public static applyPositionDifference(
    targetNode: DiagramNodeDto,
    positionDifference: JPoint
  ): void {
    targetNode.layout.x -= positionDifference.x;
    targetNode.layout.y -= positionDifference.y;
  }

  public static copyNodeLocation = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): void => {
    const sourceNodeCenter = this.getNodeCenter(sourceNode.layout);
    const targetNodeCenter = this.getNodeCenter(targetNode.layout);
    targetNode.layout.x =
      targetNode.layout.x - (targetNodeCenter.x - sourceNodeCenter.x);
    targetNode.layout.y =
      targetNode.layout.y - (targetNodeCenter.y - sourceNodeCenter.y);
  };

  public static getNodeCenter(layout: LayoutDto): JPoint {
    const x = layout.x + layout.width / 2;
    const y = layout.y + layout.height / 2;
    return new JPoint(x, y);
  }

  public static setNodeCenter(layout: LayoutDto, center: JPoint): void {
    const currentCenter = this.getNodeCenter(layout);
    layout.x = layout.x + center.x - currentCenter.x;
    layout.y = layout.y + center.y - currentCenter.y;
  }

  public static copyLabel = <T extends DiagramEdgeDto | DiagramNodeDto>(
    sourceItem: T,
    targetItem: T
  ): void => {
    targetItem.label = sourceItem.label;
    targetItem.data['labelIsPlaceholder'] =
      sourceItem.data['labelIsPlaceholder'];
  };

  public static copyLabelData = <T extends DiagramEdgeDto | DiagramNodeDto>(
    sourceItem: T,
    targetItem: T
  ): void => {
    if (targetItem.data) {
      targetItem.data.labelData = cloneDeep(sourceItem.data?.labelData);
    }
  };

  public static copyNodeVisualType = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): void => {
    targetNode.style.visualType = sourceNode.style.visualType;
  };

  public static copyNodeShape = (
    sourceStyle: ShapeNodeStyleDto,
    targetStyle: ShapeNodeStyleDto
  ): void => {
    targetStyle.shape = sourceStyle.shape;
  };

  public static copyNodeStrokeDashStyle = (
    sourceStyle: ShapeNodeStyleDto,
    targetStyle: ShapeNodeStyleDto
  ): void => {
    targetStyle.stroke.dashStyle = { ...sourceStyle.stroke.dashStyle };
  };

  public static copyNodeStrokeThickness = (
    sourceStyle: ShapeNodeStyleDto,
    targetStyle: ShapeNodeStyleDto
  ): void => {
    targetStyle.stroke.thickness = sourceStyle.stroke.thickness;
  };

  public static copyNodeStrokeFill = (
    sourceStyle: ShapeNodeStyleDto,
    targetStyle: ShapeNodeStyleDto
  ): void => {
    targetStyle.stroke.fill = { ...sourceStyle.stroke.fill };
  };

  public static copyNodeFill = (
    sourceStyle: ShapeNodeStyleDto,
    targetStyle: ShapeNodeStyleDto
  ): void => {
    targetStyle.fill = { ...sourceStyle.fill };
  };

  public static copyNodeAngle = (
    sourceStyle: ImageNodeStyleDto,
    targetStyle: ImageNodeStyleDto
  ): void => {
    targetStyle.rotation = sourceStyle.rotation;
  };

  /**
   * Updates only jurisdiction or state data properties
   * @param sourceNode
   * @param targetNode
   * @param definitionId Must be appConsts.JURISDICTION_DEFINITION_ID or appConsts.STATE_DEFINITION_ID
   * @returns
   */
  public static copyNodeJurisdictionOrState = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    definitionId: number
  ): void => {
    if (
      definitionId !== appConsts.JURISDICTION_DEFINITION_ID &&
      definitionId !== appConsts.STATE_DEFINITION_ID
    ) {
      console.error('Wrong data property definition id');
      return;
    }

    const sourceDp = sourceNode.dataProperties.find(
      (dp) => dp.dataPropertyDefinitionId === definitionId
    );
    const targetDpIndex = targetNode.dataProperties.findIndex(
      (dp) => dp.dataPropertyDefinitionId === definitionId
    );

    if (!sourceDp && targetDpIndex == -1) {
      return;
    }

    const removeStateIfExists = () => {
      const stateDpIndex = targetNode.dataProperties.findIndex(
        (dp) => dp.dataPropertyDefinitionId === appConsts.STATE_DEFINITION_ID
      );
      if (stateDpIndex >= 0) {
        targetNode.dataProperties.splice(stateDpIndex, 1);
      }
    };

    if (!sourceDp && targetDpIndex >= 0) {
      targetNode.dataProperties.splice(targetDpIndex, 1);
      if (definitionId === appConsts.JURISDICTION_DEFINITION_ID) {
        removeStateIfExists();
      }
      return;
    }

    if (targetDpIndex >= 0) {
      const targetJurisdictionDp = targetNode.dataProperties[targetDpIndex];
      const oldValue = targetJurisdictionDp.value;
      targetJurisdictionDp.value = sourceDp.value;
      targetJurisdictionDp.isHidden = sourceDp.isHidden;
      targetJurisdictionDp.isIncluded = sourceDp.isIncluded;
      targetJurisdictionDp.isPropertyGroup = sourceDp.isPropertyGroup;
      if (
        definitionId === appConsts.JURISDICTION_DEFINITION_ID &&
        oldValue !== targetJurisdictionDp.value
      ) {
        removeStateIfExists();
      }
    } else {
      targetNode.dataProperties.push({
        ...sourceDp,
        uuid: generateUuid(),
        diagramNodeId: targetNode.id,
        id: null,
      });
    }
  };

  public static copyEdgeRouting = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto,
    targetDiagram: DiagramDto
  ): void => {
    this.copyEdgeHeight(sourceEdge, targetEdge);
    this.copyEdgeBends(sourceEdge, targetEdge);
    this.copyEdgePorts(sourceEdge, targetEdge, 'all');
    this.copyEdgePortDirections(sourceEdge, targetEdge);
    this.setPortOwners(sourceEdge, targetEdge, 'all');
    this.ensurePortOwners(targetEdge, targetDiagram, 'all');
  };

  public static copyEdgePorts(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto,
    portType?: 'source' | 'target' | 'all'
  ): void {
    switch (portType) {
      case 'source':
        targetEdge.sourcePort = cloneDeep(sourceEdge.sourcePort);
        break;
      case 'target':
        targetEdge.targetPort = cloneDeep(sourceEdge.targetPort);
        break;
      default:
        targetEdge.sourcePort = cloneDeep(sourceEdge.sourcePort);
        targetEdge.targetPort = cloneDeep(sourceEdge.targetPort);
        break;
    }
  }

  public static setPortOwners(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto,
    portType?: 'source' | 'target' | 'all'
  ): void {
    switch (portType) {
      case 'source':
        targetEdge.sourceNodeUuid = sourceEdge.sourceNodeUuid;
        break;
      case 'target':
        targetEdge.targetNodeUuid = sourceEdge.targetNodeUuid;
        break;
      default:
        targetEdge.sourceNodeUuid = sourceEdge.sourceNodeUuid;
        targetEdge.targetNodeUuid = sourceEdge.targetNodeUuid;
        break;
    }
  }

  public static ensurePortOwners(
    edge: DiagramEdgeDto,
    diagram: DiagramDto,
    portType?: 'source' | 'target' | 'all'
  ): void {
    switch (portType) {
      case 'source':
        this.ensurePortOwner(edge, diagram, edge.sourceNodeUuid);
        break;
      case 'target':
        this.ensurePortOwner(edge, diagram, edge.targetNodeUuid);
        break;
      default:
        this.ensurePortOwner(edge, diagram, edge.sourceNodeUuid);
        this.ensurePortOwner(edge, diagram, edge.targetNodeUuid);
        break;
    }
  }

  public static ensurePortOwner(
    edge: DiagramEdgeDto,
    diagram: DiagramDto,
    nodeUuid: string
  ): void {
    const nodeExists = diagram.nodes.some((n) => n.uuid === nodeUuid);
    if (!nodeExists) {
      this.deleteEdge(edge, diagram);
    }
  }

  public static copyEdgePortDirections(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void {
    targetEdge.data.sourcePortFixed = sourceEdge.data.sourcePortFixed;
    targetEdge.data.sourcePortDirection = cloneDeep(
      sourceEdge.data.sourcePortDirection
    );
    targetEdge.data.targetPortFixed = sourceEdge.data.targetPortFixed;
    targetEdge.data.targetPortDirection = cloneDeep(
      sourceEdge.data.targetPortDirection
    );
  }

  public static copyEdgeBends(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void {
    targetEdge.bends = cloneDeep(sourceEdge.bends);
  }

  public static copyEdgeHeight(
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void {
    targetEdge.style.height = sourceEdge.style.height;
  }

  public static canApplyNodeStyle = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): boolean => {
    switch (sourceNode.style.visualType) {
      case NodeVisualType.DividingLine: {
        return (
          sourceNode.style.visualType === NodeVisualType.DividingLine &&
          targetNode.style.visualType === NodeVisualType.DividingLine
        );
      }
      case NodeVisualType.Image: {
        return (
          sourceNode.style.visualType === NodeVisualType.Image &&
          targetNode.style.visualType === NodeVisualType.Image
        );
      }
      case NodeVisualType.Shape:
      case NodeVisualType.JigsawPathShape:
      case NodeVisualType.Composite: {
        return (
          (sourceNode.style.visualType === NodeVisualType.Shape ||
            sourceNode.style.visualType === NodeVisualType.Composite ||
            sourceNode.style.visualType == NodeVisualType.JigsawPathShape) &&
          (targetNode.style.visualType === NodeVisualType.Shape ||
            targetNode.style.visualType === NodeVisualType.Composite ||
            targetNode.style.visualType == NodeVisualType.JigsawPathShape)
        );
      }
      default:
        return false;
    }
  };

  public static applyNodeStyle = (
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    action: (
      sourceStyle: ShapeNodeStyleDto | ImageNodeStyleDto,
      targetStyle: ShapeNodeStyleDto | ImageNodeStyleDto
    ) => void,
    compositeStyleIndex?: number
  ): void => {
    if (!this.canApplyNodeStyle(sourceNode, targetNode)) {
      return;
    }

    const sourceStyles:
      | CompositeNodeStyleDto
      | JigsawPathShapeNodeStyleDto
      | ShapeNodeStyleDto[] = [];

    if (sourceNode.style.visualType == NodeVisualType.Composite) {
      const sourceNodeStyle = sourceNode.style as CompositeNodeStyleDto;
      sourceStyles.push(
        ...sourceNodeStyle.styleDefinitions.map((d) => d.nodeStyle)
      );
    } else {
      sourceStyles.push(sourceNode.style);
    }

    if (targetNode.style.visualType == NodeVisualType.Composite) {
      const targetNodeStyle = targetNode.style as CompositeNodeStyleDto;
      for (
        let index = 0;
        index < targetNodeStyle.styleDefinitions.length;
        index++
      ) {
        if (index < sourceStyles.length && compositeStyleIndex === index) {
          action(
            sourceStyles[index],
            targetNodeStyle.styleDefinitions[index].nodeStyle
          );
        }
      }
    } else {
      action(sourceStyles[0], targetNode.style);
    }
  };

  public static findEdgesByNode(
    edges: DiagramEdgeDto[],
    node: DiagramNodeDto
  ): DiagramEdgeDto[] {
    return edges.filter((edge) => this.findEdgeByNodePredicate(edge, node));
  }

  public static findEdgesByNodes(
    edges: DiagramEdgeDto[],
    nodes: DiagramNodeDto[]
  ): DiagramEdgeDto[] {
    return edges.filter((e) =>
      nodes.some((n) => this.findEdgeByNodePredicate(e, n))
    );
  }

  public static findEdgeByNodePredicate = (
    edge: DiagramEdgeDto,
    node: DiagramNodeDto
  ): boolean =>
    node.uuid === edge.targetNodeUuid || node.uuid === edge.sourceNodeUuid;

  public static isTextBoxNode(node: DiagramNodeDto): boolean {
    return node.data['annotationType'] === AnnotationType.Text;
  }

  /**
   * Gets the text only portion of a label, should not include any html
   * @param item
   * @returns
   */
  public static getPlaceholderLabelText(
    item: DiagramNodeDto | DiagramEdgeDto
  ): string {
    if (item.data['labelIsPlaceholder']) {
      return `[${i18n.t(item.data['placeholderText'] || item.data['name'])}]`;
    }
    return `[${item.data['name']}]`;
  }

  /**
   * This is the counterpart to @method getPlaceholderLabelText
   * Will return the included HTML
   * @param item the node for which the label is being created
   * @param text optional, the text to use for the label
   * @param labelStyleDto the label style to use for the label, if none if specified,
   * it'll fallback to extracting it from the node style, then the system default
   *
   */
  public static getHtmlLabelContent(
    item: DiagramNodeDto | DiagramEdgeDto,
    text?: string,
    labelStyleDto?: LabelStyleDto
  ): string {
    labelStyleDto =
      labelStyleDto ??
      this.tryExtractLabelStyleFromOwner(item) ??
      DiagramUtils.getSystemDefaultLabelStyle();

    return CKEditorUtils.createHtmlStringFromStyle(
      labelStyleDto.fill,
      null,
      labelStyleDto.font,
      text
    );
  }

  public static tryExtractLabelStyleFromOwner(
    item: DiagramNodeDto | DiagramEdgeDto
  ): LabelStyleDto {
    return item.data?.style?.labelStyle;
  }

  public static deleteEdge(
    targetEdge: DiagramEdgeDto,
    targetDiagram: DiagramDto
  ): boolean {
    if (!targetEdge) {
      return false;
    }

    const edgeIndex = targetDiagram.edges.findIndex(
      (e) => e.uuid === targetEdge.uuid
    );

    if (edgeIndex === -1) {
      return false;
    }

    targetDiagram.edges.splice(edgeIndex, 1);
    return true;
  }

  public static updateRouting(
    sourceDiagram: DiagramDto,
    targetDiagram: DiagramDto,
    itemsToReroute: Set<DiagramNodeDto | DiagramEdgeDto>,
    graphService?: BackgroundGraphService
  ): void {
    if (!itemsToReroute || itemsToReroute.size === 0) {
      return;
    }

    let needDispose = false;
    if (!graphService) {
      graphService = new BackgroundGraphService(targetDiagram);
      needDispose = true;
    }

    const graph = graphService.graph;
    const edgesSet = new Set<IEdge>();

    for (const item of itemsToReroute) {
      const node = graph.nodes.find((n) => n.tag.uuid === item.uuid);
      if (node) {
        graph.edgesAt(node, AdjacencyTypes.ALL).forEach((edge) => {
          if (edge.style instanceof PolylineEdgeStyle) {
            edgesSet.add(edge);
          }
        });
        continue;
      }

      const edge = graph.edges.find((e) => e.tag.uuid === item.uuid);
      if (edge) {
        if (edge.style instanceof PolylineEdgeStyle) {
          edgesSet.add(edge);
        }
        continue;
      }
    }

    if (edgesSet.size > 0) {
      const edges = new Array<IEdge>();
      for (let edge of edgesSet) {
        if (this.shouldReroute(edge, sourceDiagram, targetDiagram)) {
          edge.tag.isFixedInLayout = false;
          edges.push(edge);
        }
      }
      graphService.edgeService.applyEdgeRouterForEdges(edges);

      const convertedEdges = new DiagramWriter().convertEdges(edges);

      convertedEdges.forEach((convertedEdge) => {
        const index = targetDiagram.edges.findIndex(
          (edge) => edge.uuid === convertedEdge.uuid
        );
        if (index !== -1) {
          targetDiagram.edges[index] = convertedEdge;
        }
      });
    }

    if (needDispose) {
      graphService.dispose();
    }
  }

  public static async updateLegend(
    document: DocumentDto,
    page: DocumentPageDto,
    diagram: DiagramDto,
    graph: IGraph
  ): Promise<void> {
    await LegendUtils.regenerateDiagramLegendFromGraph(
      document,
      page,
      diagram,
      graph,
      {
        hasSteps: true,
      }
    );
  }

  public static deleteEdgesWithoutNodes(diagram: DiagramDto): void {
    const nodeExists = (uuid: string) =>
      uuid && diagram.nodes.some((n) => n.uuid === uuid);
    diagram.edges = diagram.edges.filter(
      (e) => nodeExists(e.targetNodeUuid) && nodeExists(e.sourceNodeUuid)
    );
  }

  private static shouldReroute(
    edge: IEdge,
    sourceDiagram: DiagramDto,
    targetDiagram: DiagramDto
  ): boolean {
    const targetDiagramEdge = targetDiagram.edges.find(
      (e) => e.uuid === edge.tag.uuid
    ) as DiagramEdgeDto;
    if (!targetDiagramEdge) {
      return false;
    }
    const sourceDiagramEdge = DiagramUtils.findRelatedItems(
      sourceDiagram.edges,
      targetDiagramEdge
    )[0] as DiagramEdgeDto;
    if (!sourceDiagramEdge) {
      return true;
    }

    const portsEqual =
      SerializedDiagramEntityComparer.edgePortsEqual(
        {
          port: sourceDiagramEdge.sourcePort,
          nodeUuid: null,
        },
        {
          port: targetDiagramEdge.sourcePort,
          nodeUuid: null,
        }
      ) &&
      SerializedDiagramEntityComparer.edgePortsEqual(
        {
          port: sourceDiagramEdge.targetPort,
          nodeUuid: null,
        },
        {
          port: targetDiagramEdge.targetPort,
          nodeUuid: null,
        }
      );
    if (!portsEqual) {
      return true;
    }

    const sourceDiagramSourceNode = sourceDiagram.nodes.find(
      (n) => n.uuid === sourceDiagramEdge.sourceNodeUuid
    );
    const targetDiagramSourceNode = targetDiagram.nodes.find(
      (n) => n.uuid === targetDiagramEdge.sourceNodeUuid
    );

    const sourceNodesShapeTypeEqual =
      SerializedDiagramEntityComparer.nodeTypesEqual(
        sourceDiagramSourceNode,
        targetDiagramSourceNode
      );
    if (!sourceNodesShapeTypeEqual) {
      return true;
    }

    const sourceNodesLayoutEqual =
      SerializedDiagramEntityComparer.nodeLocationsEqual(
        sourceDiagramSourceNode.layout,
        targetDiagramSourceNode.layout
      ) &&
      SerializedDiagramEntityComparer.nodeSizesEqual(
        sourceDiagramSourceNode.layout,
        targetDiagramSourceNode.layout
      );
    if (!sourceNodesLayoutEqual) {
      return true;
    }

    const sourceDiagramTargetNode = sourceDiagram.nodes.find(
      (n) => n.uuid === sourceDiagramEdge.targetNodeUuid
    );
    const targetDiagramTargetNode = targetDiagram.nodes.find(
      (n) => n.uuid === targetDiagramEdge.targetNodeUuid
    );

    const targetNodesShapeTypeEqual =
      SerializedDiagramEntityComparer.nodeTypesEqual(
        sourceDiagramTargetNode,
        targetDiagramTargetNode
      );
    if (!targetNodesShapeTypeEqual) {
      return true;
    }

    const targetNodesLayoutEqual =
      SerializedDiagramEntityComparer.nodeLocationsEqual(
        sourceDiagramTargetNode.layout,
        targetDiagramTargetNode.layout
      ) &&
      SerializedDiagramEntityComparer.nodeSizesEqual(
        sourceDiagramTargetNode.layout,
        targetDiagramTargetNode.layout
      );
    if (!targetNodesLayoutEqual) {
      return true;
    }

    return false;
  }

  public static copyEdgeType = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void => {
    targetEdge.style = cloneDeep(sourceEdge.style);

    targetEdge.data['isAnnotation'] = sourceEdge.data['isAnnotation'];
    targetEdge.data['name'] = sourceEdge.data['name'];
    targetEdge.data['isOrphan'] = sourceEdge.data['isOrphan'];
  };

  public static copyEdgeStrokeDashStyle = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void => {
    targetEdge.style.stroke.dashStyle = {
      ...sourceEdge.style.stroke.dashStyle,
    };
  };

  public static copyEdgeStrokeThickness = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void => {
    targetEdge.style.stroke.thickness = sourceEdge.style.stroke.thickness;
    targetEdge.style.sourceArrow.scale = sourceEdge.style.sourceArrow.scale;
    targetEdge.style.targetArrow.scale = sourceEdge.style.targetArrow.scale;
  };

  public static copyEdgeStrokeFill = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void => {
    targetEdge.style.stroke.fill = { ...sourceEdge.style.stroke.fill };
    this.copyEdgeArrowFill(sourceEdge, targetEdge);
  };

  public static copyEdgeArrowFill = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto,
    destination?: 'source' | 'target' | 'all'
  ): void => {
    switch (destination) {
      case 'source':
        targetEdge.style.sourceArrow.fill = {
          ...sourceEdge.style.sourceArrow.fill,
        };
        return;
      case 'target':
        targetEdge.style.targetArrow.fill = {
          ...sourceEdge.style.targetArrow.fill,
        };
        return;
      default:
        targetEdge.style.sourceArrow.fill = {
          ...sourceEdge.style.sourceArrow.fill,
        };
        targetEdge.style.targetArrow.fill = {
          ...sourceEdge.style.targetArrow.fill,
        };
    }
  };

  public static copyEdgeArrowType = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto,
    destination: 'source' | 'target'
  ): void => {
    switch (destination) {
      case 'source':
        targetEdge.style.sourceArrow.type = sourceEdge.style.sourceArrow.type;
        return;
      case 'target':
        targetEdge.style.targetArrow.type = sourceEdge.style.targetArrow.type;
        return;
    }
  };

  public static copyEdgeBridgeState = (
    sourceEdge: DiagramEdgeDto,
    targetEdge: DiagramEdgeDto
  ): void => {
    targetEdge.style.bridge = sourceEdge.style.bridge;
  };

  public static copyDisplayOrder = <T extends DiagramEdgeDto | DiagramNodeDto>(
    sourceItem: T,
    targetItem: T
  ): void => {
    targetItem.data['displayOrder'] = sourceItem.data['displayOrder'];
  };

  public static copyNodeDataPropertyStyleState(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): void {
    targetNode.data['dataPropertyStyle']['isActive'] =
      sourceNode.data['dataPropertyStyle']['isActive'];
  }

  public static copyNodeJurisdictionDisplayTypeState(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto,
    dataPropertyDisplayTypeName: DataPropertyDisplayTypeNames,
    dataPropertyDisplayType: DataPropertyDisplayType
  ): void {
    const targetArr = targetNode.data['dataPropertyDisplayTypes'][
      dataPropertyDisplayTypeName
    ] as DataPropertyDisplayType[];
    const nodeLabelIncluded = sourceNode.data['dataPropertyDisplayTypes'][
      dataPropertyDisplayTypeName
    ].includes(dataPropertyDisplayType);
    if (nodeLabelIncluded) {
      if (!targetArr.includes(dataPropertyDisplayType)) {
        targetArr.push(dataPropertyDisplayType);
      }
    } else {
      const index = targetArr.indexOf(dataPropertyDisplayType);
      if (index !== -1) {
        targetArr.splice(index, 1);
      }
    }
  }

  public static copyJurisdictionLocation(
    sourceNode: DiagramNodeDto,
    targetNode: DiagramNodeDto
  ): void {
    const sourceJDS = sourceNode.data.decorationStates[
      JurisdictionDecorator.INSTANCE.$class
    ] as JurisdictionDecorationState;
    const targetJDS = targetNode.data.decorationStates[
      JurisdictionDecorator.INSTANCE.$class
    ] as JurisdictionDecorationState;

    targetJDS['jurisdictionLocation'] = cloneDeep(
      sourceJDS['jurisdictionLocation']
    );
  }
}
