export default {
  name: null,
  surname: null,
  userName: null,
  emailAddress: null,
  userId: null,
  phoneNumber: null,
  timezone: null,
  authenticationSource: null,
  roles: null,
  language: null,
  hasDocuments: false,
};
