import {
  ILabel,
  ILabelModelParameter,
  INode,
  BaseClass,
  ILabelModel,
} from 'yfiles';
import TextBoxLabelModel from './TextBoxLabelModel';

export default class TextBoxLabelModelParameter extends BaseClass<ILabelModelParameter>(
  ILabelModelParameter
) {
  constructor(private readonly _model: TextBoxLabelModel) {
    super();
  }

  public clone(): this {
    return this;
  }

  get model(): ILabelModel {
    return this._model;
  }

  public supports(label: ILabel): boolean {
    return label.owner instanceof INode;
  }
}
