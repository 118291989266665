import { DocumentDto } from '@/api/models';
import IDisposable from '@/core/common/IDisposable';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { GraphComponent, ScrollBarVisibility } from 'yfiles';
import Vue from 'vue';
import { DOCUMENT_NAMESPACE, GET_DOCUMENT } from '../store/document.module';
import isNil from 'lodash/isNil';
export default class ScrollBarService implements IDisposable {
  public static readonly $class: string = 'ScrollBarService';
  private readonly graphComponent: GraphComponent;
  public isDisposed: boolean;

  private get document(): DocumentDto {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
  }

  constructor(graphService: IGraphService) {
    this.graphComponent = graphService.graphComponent;
    this.setScrollBarsVisibility();
  }

  public dispose(): void {
    if (this.isDisposed) return;
    this.isDisposed = true;
  }

  public setScrollBarsVisibility(
    verticalScrollBarVisibility?: ScrollBarVisibility,
    horizontalScrollBarVisibility?: ScrollBarVisibility
  ): void {
    if (!isNil(verticalScrollBarVisibility)) {
      this.graphComponent.verticalScrollBarPolicy = verticalScrollBarVisibility;
    }
    if (!isNil(horizontalScrollBarVisibility)) {
      this.graphComponent.horizontalScrollBarPolicy =
        horizontalScrollBarVisibility;
    }
  }
}
