import { Module } from 'vuex';
import Vue from 'vue';

export const ADD_STICKY_ITEMS = 'addStickyItems';
export const REMOVE_STICKY_ITEMS = 'removeStickyItems';
export const GET_STICKY_ITEMS = 'getStickyItems';
export interface IStickyToolbarItem {
  id: string;
  tooltip: string;
  panelId: string;
  iconClass?: string;
  srcUrl?: string;
  canShow?: () => boolean;
}
const stickyToolbarModule: Module<any, any> = {
  state: {
    items: [],
  },
  mutations: {
    [ADD_STICKY_ITEMS](state, items: IStickyToolbarItem[]) {
      items.forEach((item) => {
        let i = state.items.findIndex((x) => x.id == item.id);
        if (i < 0) {
          state.items.push(item);
        }
      });
    },
    [REMOVE_STICKY_ITEMS](state, items: IStickyToolbarItem[]) {
      items.forEach((item) => {
        let i = state.items.findIndex((x) => x.id == item.id);
        if (i >= 0) {
          state.items.splice(i, 1);
        }
      });
    },
  },
  actions: {
    [ADD_STICKY_ITEMS](
      context,
      items: IStickyToolbarItem | IStickyToolbarItem[]
    ) {
      if (!Array.isArray(items)) {
        items = [items];
      }
      context.commit(ADD_STICKY_ITEMS, items);
    },
    [REMOVE_STICKY_ITEMS](
      context,
      items: IStickyToolbarItem | IStickyToolbarItem[]
    ) {
      if (!Array.isArray(items)) {
        items = [items];
      }
      context.commit(REMOVE_STICKY_ITEMS, items);
    },
  },
  getters: {
    [GET_STICKY_ITEMS](state) {
      return state.items.filter((x) => x.canShow == null || x.canShow());
    },
  },
};

export default stickyToolbarModule;
