﻿<template>
  <svg
    v-if="isChristmas"
    :class="{ 'hide-christmas-logo': !fullLogo }"
    class="transition-all"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="160"
    height="64"
    viewBox="0 0 160 64"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="0.421"
        cy="0.028"
        r="0.655"
        gradientTransform="translate(0 -0.037) scale(1 1.148)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#ff3e3e" />
        <stop offset="1" stop-color="#ce0643" />
      </radialGradient>
      <radialGradient
        id="radial-gradient-2"
        cx="0.444"
        cy="0.189"
        r="1.067"
        gradientTransform="translate(0 -0.01) scale(1 1.054)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#acb2ff" />
      </radialGradient>
    </defs>
    <g id="Logo-Christmas-64" transform="translate(-25 -121)">
      <g
        id="full_logo_2_"
        data-name="full_logo (2)"
        transform="translate(25.006 121.01)"
      >
        <path
          id="Path_2729"
          class="small-logo"
          data-name="Path 2729"
          d="M14.43,40.54a14.08,14.08,0,0,1-14-14.06V24.56H4.24v1.92A10.2,10.2,0,1,0,14.43,16.29H12.5V12.43h1.93a14.06,14.06,0,1,1,0,28.11Z"
          transform="translate(0 1.183)"
          fill="#fff"
        />
        <rect
          id="Rectangle_2624"
          class="small-logo"
          data-name="Rectangle 2624"
          width="28.75"
          height="3.86"
          transform="translate(-0.006 6.726) rotate(-13.55)"
          fill="#fff"
        />
        <path
          id="Path_2730"
          data-name="Path 2730"
          d="M36.6,40.5H32.74V20.25H30.93V16.4H36.6Z"
          transform="translate(1.504 1.299)"
          fill="#fff"
        />
        <path
          id="Path_2731"
          data-name="Path 2731"
          d="M36.6,12.37H32.74V8.52L36.6,8.5Z"
          transform="translate(1.572 0.366)"
          fill="#fff"
        />
        <path
          id="Path_2732"
          data-name="Path 2732"
          d="M61.1,16.4v3.22a12.05,12.05,0,1,0,0,17.66v6.95H43.39v3.86H65V16.4ZM52.91,36.64a8.19,8.19,0,1,1,8.19-8.19h0A8.19,8.19,0,0,1,52.91,36.64Z"
          transform="translate(2.271 1.9)"
          fill="#fff"
        />
        <path
          id="Path_2733"
          data-name="Path 2733"
          d="M76.44,40.56A13.11,13.11,0,0,1,70,39l2-3.33a9.23,9.23,0,0,0,4.49,1.06A6.42,6.42,0,0,0,81.28,35a2.21,2.21,0,0,0,.53-1.91c-.18-1.13-1-2.06-4.6-2.91S70.13,28,69.62,24.3a6.24,6.24,0,0,1,1.54-5,9.31,9.31,0,0,1,7.09-2.89A11.64,11.64,0,0,1,84,17.87l-2,3.32a7.86,7.86,0,0,0-3.8-.93,5.54,5.54,0,0,0-4.18,1.57,2.39,2.39,0,0,0-.63,1.94c.07.49.23,1.62,4.66,2.68,2,.48,6.81,1.62,7.52,6a6.15,6.15,0,0,1-1.4,5C82.58,39.45,79.74,40.56,76.44,40.56Z"
          transform="translate(3.639 1.303)"
          fill="#fff"
        />
        <path
          id="Path_2734"
          data-name="Path 2734"
          d="M110,16.46v3.22a12.05,12.05,0,1,0,0,17.66v3.22h3.85V16.46ZM101.8,36.7a8.2,8.2,0,1,1,8.2-8.19h0a8.2,8.2,0,0,1-8.18,8.19Z"
          transform="translate(4.988 1.304)"
          fill="#fff"
        />
      </g>
      <g id="hat" transform="translate(124.324 125.573)">
        <path
          id="Path_2736"
          data-name="Path 2736"
          d="M5.7,18.952C5.258-2.029,33.721-7.029,44.267,12.077S40.443,56.106,26.957,56.106c.015,0,0,1,0,.194,17.736-6.67,14.4-35.343,10.652-40.972s-9.624-4.03-7.874,0S5.7,18.952,5.7,18.952Z"
          transform="translate(-10.235 -0.728)"
          fill="url(#radial-gradient)"
        />
        <path
          id="Path_2849"
          data-name="Path 2849"
          d="M29.307,16.75c-2.35-4.412.374-6.925,3.71-10.423,0,.073,6.976,1.683,10.447,14.651,4.781,17.862-5.758,32.765-16.886,35.128.015,0,0,1,0,.194C44,49.627,40.719,20.938,37.041,15.306s-9.453-4.032-7.734,0S29.307,16.75,29.307,16.75Z"
          transform="translate(-9.486 -0.728)"
          fill="#cf1d14"
        />
        <path
          id="Path_2739"
          data-name="Path 2739"
          d="M2,1.321A4.128,4.128,0,0,1,2.7,0a1.851,1.851,0,0,0,.261,1.191,2.856,2.856,0,0,1,1.433.029l-.2.583A2.579,2.579,0,0,1,5.487,3.972a2.578,2.578,0,0,1-1.23,2.206,1.887,1.887,0,0,0,.121-.834,3.246,3.246,0,0,1-3.76.144A1.693,1.693,0,0,0,1.6,5.166,2.37,2.37,0,0,1,.211,1.939a1.546,1.546,0,0,0,.805.767,1.975,1.975,0,0,1,.2-1.978,2.179,2.179,0,0,0,.764.655"
          transform="translate(11.641 54.077) rotate(-30)"
          fill="url(#radial-gradient-2)"
        />
        <rect
          id="Rectangle_2625"
          data-name="Rectangle 2625"
          width="28.378"
          height="35.617"
          transform="translate(0.001)"
          fill="none"
        />
        <path
          id="Path_2738"
          data-name="Path 2738"
          d="M26.666,63.231a4.537,4.537,0,0,0,2.048-2.724,3.1,3.1,0,0,1-1.177.4c.744-1.33.385-2.516-.1-3.835L26.712,58.3a6.735,6.735,0,0,0-3.678-3.532c-1.666-.651-3.655-.67-4.98-1.865l.467,1.318c-3.092.228-6.408.406-9.034-1.192l.211.8c-2.016-.453-4.274.35-6.143,1.284A4.924,4.924,0,0,0,.563,57.562c-1.125,1.942.867,1.43.691,2.8a2.088,2.088,0,0,0,1.588,2.3,5.243,5.243,0,0,0,4.811-.86c2.279-1.486,5.74-.406,5.74-.406a8.735,8.735,0,0,1-2.051.849,24.023,24.023,0,0,0,11.812.688.544.544,0,0,1-.273.649,4.421,4.421,0,0,0,2.939.057c.191-.089.583-.275.848-.406"
          transform="translate(-6.54 -42.329)"
          fill="url(#radial-gradient-2)"
        />
      </g>
      <path
        id="w"
        d="M144.57,40.3l-3.62-.01-5.5-15.22-5.49,15.22-3.63.01-8.26-22.34,3.62-1.33,6.43,17.38,5.52-15.28h3.63l5.52,15.28,6.43-17.38,3.62,1.33Z"
        transform="translate(32.16 122.304)"
        fill="#fff"
      />
    </g>
  </svg>
  <svg
    v-else-if="!isHalloween || !fullLogo"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    class="transition-all"
    :height="logoHeight"
    :class="{ 'hide-full-logo': !fullLogo }"
  >
    <path
      d="M14.43,40.54a14.08,14.08,0,0,1-14-14.06V24.56H4.24v1.92A10.2,10.2,0,1,0,14.43,16.29H12.5V12.43h1.93a14.06,14.06,0,1,1,0,28.11Z"
      style="fill: #fff"
    />
    <rect
      x="0.05"
      y="3.31"
      width="28.75"
      height="3.86"
      transform="translate(-0.83 3.52) rotate(-13.55)"
      style="fill: #fff"
    />
    <polygon
      class="full-logo"
      points="36.6 40.5 32.74 40.5 32.74 20.25 30.93 20.25 30.93 16.4 36.6 16.4 36.6 40.5"
      style="fill: #fff"
    />
    <polygon
      class="full-logo"
      points="36.6 12.37 32.74 12.37 32.74 8.52 36.6 8.5 36.6 12.37"
      style="fill: #fff"
    />
    <path
      class="full-logo"
      d="M61.1,16.4v3.22a12.05,12.05,0,1,0,0,17.66v6.95H43.39v3.86H65V16.4ZM52.91,36.64a8.19,8.19,0,1,1,8.19-8.19h0A8.19,8.19,0,0,1,52.91,36.64Z"
      style="fill: #fff"
    />
    <path
      class="full-logo"
      d="M76.44,40.56A13.11,13.11,0,0,1,70,39l2-3.33a9.23,9.23,0,0,0,4.49,1.06A6.42,6.42,0,0,0,81.28,35a2.21,2.21,0,0,0,.53-1.91c-.18-1.13-1-2.06-4.6-2.91S70.13,28,69.62,24.3a6.24,6.24,0,0,1,1.54-5,9.31,9.31,0,0,1,7.09-2.89A11.64,11.64,0,0,1,84,17.87l-2,3.32a7.86,7.86,0,0,0-3.8-.93,5.54,5.54,0,0,0-4.18,1.57,2.39,2.39,0,0,0-.63,1.94c.07.49.23,1.62,4.66,2.68,2,.48,6.81,1.62,7.52,6a6.15,6.15,0,0,1-1.4,5C82.58,39.45,79.74,40.56,76.44,40.56Z"
      style="fill: #fff"
    />
    <path
      class="full-logo"
      d="M110,16.46v3.22a12.05,12.05,0,1,0,0,17.66v3.22h3.85V16.46Zm-8.2,20.24a8.2,8.2,0,1,1,8.2-8.19h0A8.2,8.2,0,0,1,101.82,36.7Z"
      style="fill: #fff"
    />
    <polygon
      class="full-logo"
      points="144.57 40.3 140.95 40.29 135.45 25.07 129.96 40.29 126.33 40.3 118.07 17.96 121.69 16.63 128.12 34.01 133.64 18.73 137.27 18.73 142.79 34.01 149.22 16.63 152.84 17.96 144.57 40.3"
      style="fill: #fff"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="164.19"
    height="63.603"
    viewBox="0 0 164.19 63.603"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="0.533"
        cy="0.043"
        r="0.953"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#ff7a1d" />
        <stop offset="0.76" stop-color="#d94700" />
        <stop offset="1" stop-color="#f30" />
      </radialGradient>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#000c2b" />
        <stop offset="1" stop-color="#38383f" />
      </linearGradient>
    </defs>
    <g
      id="Logo_with_hat"
      data-name="Logo with hat"
      transform="translate(-9893.564 -16285.129)"
    >
      <g id="Logo-Christmas-400" transform="translate(9893.564 16297.423)">
        <g
          id="full_logo_2_"
          data-name="full_logo (2)"
          transform="translate(0 0)"
        >
          <path
            id="Path_2729"
            data-name="Path 2729"
            d="M14.8,41.276A14.449,14.449,0,0,1,.43,26.848v-1.97H4.34v1.97A10.467,10.467,0,1,0,14.8,16.391H12.816V12.43H14.8a14.428,14.428,0,1,1,0,28.846Z"
            transform="translate(0.017 1.549)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2624"
            data-name="Rectangle 2624"
            width="29.503"
            height="3.961"
            transform="translate(0 6.912) rotate(-13.55)"
            fill="#fff"
          />
          <path
            id="Path_2730"
            data-name="Path 2730"
            d="M36.748,41.131H32.787V20.351H30.93V16.4h5.818Z"
            transform="translate(2.359 1.773)"
            fill="#fff"
          />
          <path
            id="Path_2731"
            data-name="Path 2731"
            d="M36.7,12.471H32.74V8.521L36.7,8.5Z"
            transform="translate(2.477 0.608)"
            fill="#fff"
          />
          <path
            id="Path_2732"
            data-name="Path 2732"
            d="M61.63,16.4v3.3a12.366,12.366,0,1,0,0,18.122v7.132H43.457V48.92H65.632V16.4Zm-8.4,20.77a8.4,8.4,0,1,1,8.4-8.4h0A8.4,8.4,0,0,1,53.226,37.17Z"
            transform="translate(3.406 2.389)"
            fill="#fff"
          />
          <path
            id="Path_2733"
            data-name="Path 2733"
            d="M76.62,41.193a13.453,13.453,0,0,1-6.609-1.6l2.052-3.417a9.472,9.472,0,0,0,4.608,1.088,6.588,6.588,0,0,0,4.915-1.775,2.268,2.268,0,0,0,.544-1.96c-.185-1.16-1.026-2.114-4.72-2.986s-7.265-2.237-7.789-6.034a6.4,6.4,0,0,1,1.58-5.131,9.554,9.554,0,0,1,7.276-2.966,11.945,11.945,0,0,1,5.9,1.5l-2.052,3.407a8.066,8.066,0,0,0-3.9-.954,5.685,5.685,0,0,0-4.289,1.611,2.453,2.453,0,0,0-.646,1.991c.072.5.236,1.662,4.782,2.75,2.052.493,6.988,1.662,7.717,6.157A6.311,6.311,0,0,1,84.552,38C82.921,40.054,80.006,41.193,76.62,41.193Z"
            transform="translate(5.562 1.776)"
            fill="#fff"
          />
        </g>
        <g id="hat" transform="translate(101.926 4.692)">
          <rect
            id="Rectangle_2625"
            data-name="Rectangle 2625"
            width="29.121"
            height="36.55"
            transform="translate(0 0)"
            fill="none"
          />
        </g>
        <path
          id="w"
          d="M145.264,40.92l-3.715-.01-5.644-15.619L130.271,40.91l-3.725.01L118.07,17.995l3.715-1.365,6.6,17.835,5.665-15.68h3.725l5.665,15.68,6.6-17.835,3.715,1.365Z"
          transform="translate(10.44 1.774)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_10791"
        data-name="Group 10791"
        transform="translate(9987.983 16315.742)"
      >
        <g
          id="Group_10790"
          data-name="Group 10790"
          transform="translate(0 0.988)"
        >
          <path
            id="Path_3132"
            data-name="Path 3132"
            d="M8.762,0c5.088,0,8.629,3.891,8.762,11.042S13.982,23.606,8.762,23.606,0,17.981,0,11.042,3.674,0,8.762,0Z"
            transform="matrix(1, 0.017, -0.017, 1, 0.412, 0)"
            fill="url(#radial-gradient)"
          />
          <path
            id="Path_3125"
            data-name="Path 3125"
            d="M8.762,0c5.088,0,8.629,3.891,8.762,11.042S13.982,23.606,8.762,23.606,0,17.981,0,11.042,3.674,0,8.762,0Z"
            transform="matrix(1, 0.017, -0.017, 1, 17.501, 0)"
            fill="url(#radial-gradient)"
          />
        </g>
        <path
          id="Path_3124"
          data-name="Path 3124"
          d="M14.022,0C26.059,0,28.044,5.886,28.044,13.146S24.912,26.291,14.022,26.291,0,20.406,0,13.146,1.985,0,14.022,0Z"
          transform="translate(3.505 0.001)"
          fill="url(#radial-gradient)"
        />
        <g
          id="Group_10789"
          data-name="Group 10789"
          transform="translate(8.763 9.698)"
          style="mix-blend-mode: color-dodge; isolation: isolate"
        >
          <path
            id="Subtraction_5"
            data-name="Subtraction 5"
            d="M7.3,7.745A7.508,7.508,0,0,1,2.139,5.74c-.17-.16-.334-.329-.486-.5a1.056,1.056,0,0,0-.524-1.98,1.072,1.072,0,0,0-.609.188A6.449,6.449,0,0,1,0,.9,6.5,6.5,0,0,1,.062,0a5.956,5.956,0,0,0,4,4.324A1.043,1.043,0,0,0,4,4.677a1.071,1.071,0,0,0,2.127.162A9.4,9.4,0,0,0,7.3,4.912a8.981,8.981,0,0,0,3.654-.762,1.071,1.071,0,0,0,2.079-.35,1.044,1.044,0,0,0-.318-.753A5.162,5.162,0,0,0,14.544,0a6.493,6.493,0,0,1,.062.9A6.6,6.6,0,0,1,12.467,5.74a7.322,7.322,0,0,1-1.208.916,1.053,1.053,0,0,0,.024-.226,1.07,1.07,0,1,0-1.57.935A7.747,7.747,0,0,1,7.3,7.745Z"
            transform="translate(1.627 4.741)"
            fill="#ffcdcd"
            style="mix-blend-mode: color-dodge; isolation: isolate"
          />
          <path
            id="Path_3133"
            data-name="Path 3133"
            d="M10147.293,16751.145l2.457,2.9,4.022-4.914Z"
            transform="translate(-10136.329 -16749.133)"
            fill="#ffcdcd"
            style="mix-blend-mode: color-dodge; isolation: isolate"
          />
          <path
            id="Path_3134"
            data-name="Path 3134"
            d="M10139.595,16753.428l-2.534,4.555h4.9l-1.134-2.187Z"
            transform="translate(-10130.58 -16751.545)"
            fill="#ffcdcd"
            style="mix-blend-mode: color-dodge; isolation: isolate"
          />
          <path
            id="Path_3135"
            data-name="Path 3135"
            d="M10153.772,16751.145l-2.458,2.9-4.021-4.914Z"
            transform="translate(-10147.293 -16749.133)"
            fill="#ffcdcd"
            style="mix-blend-mode: color-dodge; isolation: isolate"
          />
        </g>
      </g>
      <g
        id="Group_10792"
        data-name="Group 10792"
        transform="translate(9968.622 16285.129)"
      >
        <g
          id="Group_10635"
          data-name="Group 10635"
          transform="translate(0 0)"
        >
          <g
            id="Group_10636"
            data-name="Group 10636"
            transform="translate(5.744 0) rotate(8)"
          >
            <path
              id="Path_3077"
              data-name="Path 3077"
              d="M54.6,25.117a38.4,38.4,0,0,0-5.3-1.286c-1.165-.236-2.311-.545-3.465-.832-.728-.181-1.455-.354-2.168-.578l-.11-.027s0,0,0-.006q-.325-.1-.645-.227c.027-1-1.491-4.274-3.606-6.69s-2.452-2.708-3.749-4A62.575,62.575,0,0,0,27.7,4.592c-2.651-1.878-5.531-2.8-8.5-4.029-.709-.293-1.854-.849-2.618-.387a6.1,6.1,0,0,0-1.913,2.766,38.7,38.7,0,0,1-2.12,4.065A25.581,25.581,0,0,1,8.912,11.3c-2.1,2.057-4.465,3.807-6.381,6.047C.869,19.29-.527,21.837.195,24.451c.632,2.292,3.175,3.461,5.387,3.19a3.433,3.433,0,0,0,1.571-.6,3.377,3.377,0,0,0,1.752-4.5A1.936,1.936,0,0,0,6.51,21.455a2.76,2.76,0,0,0-1.043.844,2.915,2.915,0,0,0-.276.831c-.087.283-.1.651.153.786a.128.128,0,0,0,.149-.033,1.012,1.012,0,0,0,.26-.357c.155-.368.314-.962.916-.861a.968.968,0,0,1,.615.542,1.582,1.582,0,0,1-.1,1.521,1.372,1.372,0,0,1-.31.415c0,.008,0,.017-.006.026a2.571,2.571,0,0,1-1.011.6l-.009.006a3.481,3.481,0,0,1-1.519-.067,2.72,2.72,0,0,1-2.223-2.637c.054-2.255,2.91-4.015,4.7-4.8,3.528-1.557,7.531-1.88,11.017-3.553.149.448.291.9.417,1.355.425,4.275.268,5.342,3.342,14.391-1.5,1.647-2.647,2.766-3.789,3.978-1.643,1.744-3.342,3.435-5.124,5.036a.168.168,0,0,0-.046.145.41.41,0,0,0-.007.16c0,.011,0,.022,0,.033s0,.043.008.069c.048.388,2.782,1.659,7.156,1.332C18.05,31.045,48.867,18.053,53.9,34.627,58.61,30.509,58.453,26.224,54.6,25.117Z"
              transform="translate(0 0)"
            />
          </g>
          <path
            id="Union_81"
            data-name="Union 81"
            d="M14.249,35.043a62.888,62.888,0,0,1,5.823-4.191c-1.2-5.287-.979-13.527-1.177-18.684,0-.041-.064,0-.065.028-.01.613-.027,1.227-.048,1.842-.122-.72-.315-1.429-.509-2.147-.238-.88-.487-2.659-1.37-3.177-.05-.029-.117.033-.146.065a3.25,3.25,0,0,0-.549,1.563c-.011.05.058.016.067-.014.3-1.057.568-1.744,1.1-.4a17.259,17.259,0,0,1,.525,1.754c.23.843.423,1.686.581,2.537-.281.086-2.341.312-2.539.344-.981.158-1.962.3-2.945.439a51.183,51.183,0,0,0-5.808.957c-2.345.605-5.772,2.411-5.8,5.213-.015,1.258,1.282,2.136,2.314,2.519a2.892,2.892,0,0,0,1.733.08,4.832,4.832,0,0,0,.677-.29c.016-.015.031-.029.045-.043A2,2,0,0,0,7.262,21.2a1.025,1.025,0,0,0-1.367-.809,1.554,1.554,0,0,0-.59.58s-.052.08-.114.156c-.09.113-.2.226-.22.1a1.391,1.391,0,0,1-.005-.248A1.6,1.6,0,0,1,7.7,20.221,2.562,2.562,0,0,1,8.4,21.978a2.277,2.277,0,0,1-.648,1.764,2.336,2.336,0,0,1-.7.663c-.383.474-1.669.582-2.074.59A4.632,4.632,0,0,1,.158,21.651C-.4,19.769.613,17.9,1.753,16.473c1.829-2.281,4.484-3.8,6.892-5.385a29.149,29.149,0,0,0,6.481-5.623c.885-1.064,1.566-2.26,2.339-3.4C18,1.27,18.628.047,19.738,0A4.041,4.041,0,0,1,21.63.746c2.793,1.563,5.472,2.908,7.845,5.109C31.779,8,41.067,17.107,42.164,25.4l.018.006c11.159,4.021,9.895,3.859,9.331,3.844-2.811-.461-1.461-.17-15.151,4.919S11.772,38.257,9.881,38.48c-.067.007-.135.018-.2.03A42.667,42.667,0,0,0,14.249,35.043Z"
            transform="translate(2.848 2.744)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_3083"
            data-name="Path 3083"
            d="M.171,21.308a12.643,12.643,0,0,1,4.08-6.094c1.934-1.739,4.13-3.148,6.108-4.833.854-.728,2.3-1.758,2.269-3.012-.013-.625,1.385-.638,1.993-.748-.389-.194-.917-.116-1.313-.307a.111.111,0,0,1-.041-.152c.249-.441.609-.8.838-1.254a16.414,16.414,0,0,0,.708-1.647A10.733,10.733,0,0,1,15.788.6a4.071,4.071,0,0,1,.544-.6c-.816.4-1.2,1.479-1.571,2.229-.607,1.237-1.115,2.516-1.843,3.693a29.162,29.162,0,0,1-5.636,6.47C5.116,14.3,2.7,16.171,1.206,18.685A7.45,7.45,0,0,0,0,22.442,6.908,6.908,0,0,1,.171,21.308Z"
            transform="translate(6.008 0.547) rotate(8)"
            fill="#35497e"
          />
          <path
            id="Path_3085"
            data-name="Path 3085"
            d="M14.353,1.427c.947.389,1.441,2.117,1.8,2.955.291.683.583,1.359.8,2.055-.064-.612-.132-1.222-.208-1.831,0-.028.052-.077.06-.037.332,1.844.564,3.708.813,5.565.151-1.1.411-2.176.6-3.279a5.867,5.867,0,0,0-.834-4.246c-.266-.426-.541-.849-.812-1.272A1.972,1.972,0,0,0,15.55.383c.215.465.555.865.768,1.335a4.8,4.8,0,0,1,.3,1.3c.017.114-.143.209-.2.085C15.818,1.866,14.555.239,13.087,0a14.2,14.2,0,0,1,.554,3.615c.071.865.276,1.877-.451,2.539a6.825,6.825,0,0,1-2.763,1.2c-1.294.377-2.6.723-3.87,1.176-2.347.837-5.148,2.148-6.12,4.626a5.182,5.182,0,0,0,.59,5.236A4.22,4.22,0,0,0,2.118,19a4.648,4.648,0,0,0,2.689.208c.4-.064,1.659-.35,1.972-.873a2.332,2.332,0,0,0,.6-.753,2.274,2.274,0,0,0,.4-1.837,2.559,2.559,0,0,0-.93-1.644,1.6,1.6,0,0,0-2.607,1.13,1.4,1.4,0,0,0,.04.246c.057.211.316-.346.293-.3a1.559,1.559,0,0,1,.5-.658,1.025,1.025,0,0,1,1.466.61,2,2,0,0,1-.791,2.378c-.012.017-.025.033-.038.05a4.858,4.858,0,0,1-.631.381,2.89,2.89,0,0,1-1.727.162C2.28,17.865.874,17.176.713,15.928.356,13.148,3.5,10.883,5.736,9.957A51.341,51.341,0,0,1,11.354,8.2q1.432-.408,2.856-.845c.192-.06,2.2-.569,2.466-.694-.275-.82-.583-1.628-.929-2.431a17.263,17.263,0,0,0-.763-1.664c-.71-1.259-.883-.541-1.03.547,0,.031-.068.074-.064.023a3.253,3.253,0,0,1,.326-1.625C14.241,1.476,14.3,1.405,14.353,1.427Z"
            transform="translate(5.737 8.048) rotate(8)"
            fill="#3b3b3b"
            style="mix-blend-mode: multiply; isolation: isolate"
          />
          <path
            id="Union_82"
            data-name="Union 82"
            d="M3.357,14.986a51.591,51.591,0,0,0,4.736-3.907l.386-.351A49.6,49.6,0,0,1,7.162,0a20.989,20.989,0,0,0,2.79,9.587L9.8,9.72l.226-.136A27.273,27.273,0,0,1,15.431,7c-.664.347-1.307.727-1.927,1.092a39.078,39.078,0,0,0-4.808,3.8,55.822,55.822,0,0,1-5,3.647,30.158,30.158,0,0,1-2.806,1.6c-.294.152-.591.294-.886.439C1.165,16.772,2.269,15.848,3.357,14.986Z"
            transform="translate(14.734 22.29)"
            fill="#35497e"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'JigSawLogo',
  components: {},
})
export default class JigSawLogo extends Vue {
  @Prop({ type: Boolean, default: true })
  fullLogo;

  @Prop({ type: Boolean, default: false })
  isHalloween: boolean;

  @Prop({ type: Boolean, default: false })
  isChristmas: boolean;

  get viewBox(): string {
    return this.fullLogo ? '0 0 152.83 48.09' : '0 0 29 48.09';
  }

  get logoHeight(): string {
    return !this.fullLogo ? '50.34' : null;
  }
}
</script>

<style lang="scss" scoped>

.full-logo {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 2s, linear;
}
.hide-full-logo {
  .full-logo {
    visibility: hidden;
    opacity: 1;
  }
}

.hide-christmas-logo {
  *:not(g) {
    @apply invisible opacity-0;
  }

  .small-logo {
    @apply visible opacity-100;
  }
}
</style>
