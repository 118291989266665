import JPoint from '@/core/common/JPoint';
import Vue, { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const elementData: {
  element: HTMLElement;
  handle: HTMLElement;
  container: HTMLElement;
  binding: DirectiveBinding;
  vnode: VNode;
  lastPosition: JPoint;
  relative: boolean;
}[] = [];

const startMove = (e: MouseEvent) => {
  const data = elementData.find((el) => el.handle == e.target);
  if (!data) return;
  e.preventDefault();

  data.lastPosition.x = e.clientX;
  data.lastPosition.y = e.clientY;
  window.addEventListener('mousemove', onMove);
  window.addEventListener('mouseup', stopMove);
};

const onMove = (e: MouseEvent) => {
  const data = elementData.find((el) => el.handle == e.target);
  if (!data) return;
  e.preventDefault();

  const el = data.container;
  const newPosition = new JPoint(
    el.offsetLeft - (data.lastPosition.x - e.clientX),
    el.offsetTop - (data.lastPosition.y - e.clientY)
  );
  data.lastPosition.x = e.clientX;
  data.lastPosition.y = e.clientY;

  if (data.relative) {
    const newPositionRelative = new JPoint(
      (100 / el.parentElement.clientWidth) * newPosition.x,
      (100 / el.parentElement.clientHeight) * newPosition.y
    );
    el.style.left = newPositionRelative.x + '%';
    el.style.top = newPositionRelative.y + '%';
    data.vnode.context[data.binding.expression](newPositionRelative);
  } else {
    el.style.left = newPosition.x + 'px';
    el.style.top = newPosition.y + 'px';
    data.vnode.context[data.binding.expression](newPosition);
  }
};

const stopMove = () => {
  window.removeEventListener('mouseup', stopMove);
  window.removeEventListener('mousemove', onMove);
};

Vue.directive('movable', {
  inserted: (el, binding, vnode) => {
    const data = elementData.find((o) => o.element == el);
    if (data) {
      if (binding.arg == 'handle') {
        data.handle.removeEventListener('mousedown', startMove);
        data.handle = data.element.querySelector(binding.value);
        data.handle.addEventListener('mousedown', startMove);
      } else if (binding.arg == 'container') {
        data.container = binding.value;
      } else if (binding.arg == 'relative') {
        data.relative = binding.value;
      }
    } else {
      elementData.push({
        element: el,
        handle: el,
        container: el,
        binding: binding,
        vnode: vnode,
        lastPosition: new JPoint(),
        relative: false,
      });
      el.addEventListener('mousedown', startMove);
    }
  },
  update: (el, binding, vnode) => {
    const data = elementData.find((o) => o.element == el);
    if (data) {
      if (binding.arg == 'container') {
        data.container = binding.value;
      }
    }
  },
  unbind: function (el: HTMLElement) {
    const data = elementData.find((o) => o.element == el);
    if (data) {
      elementData.splice(elementData.indexOf(data), 1);
      data.handle.removeEventListener('mousedown', startMove);
    }
  },
});
