import IGraphService from '@/v2/services/interfaces/IGraphService';
import { CSSProperties } from 'vue/types/jsx';
import IDocumentPaletteItem from './IDocumentPaletteItem';
import IPaletteBehaviour from './IPaletteBehaviour';
import {
  GraphEditorInputMode,
  IGraph,
  IInputModeContext,
  IModelItem,
  ItemDropInputMode,
  Point,
} from 'yfiles';

type ItemCreatorCallback = (
  context: IInputModeContext,
  graph: IGraph,
  dropData: any,
  dropTarget: IModelItem | null,
  dropLocation: Point
) => IDocumentPaletteItem;

export default abstract class PaletteItemBehaviourBase
  implements IPaletteBehaviour
{
  get style(): CSSProperties {
    return {
      cursor: 'grab',
    };
  }

  protected dropInputMode: ItemDropInputMode<IDocumentPaletteItem> | null =
    null;

  removeDropInputMode(geim: GraphEditorInputMode): void {
    if (this.dropInputMode) {
      geim.remove(this.dropInputMode);
    }
  }

  addDropInputMode(
    geim: GraphEditorInputMode,
    dropInputMode: ItemDropInputMode<IDocumentPaletteItem>,
    itemCreator: ItemCreatorCallback
  ): void {
    this.removeDropInputMode(geim);

    this.dropInputMode = dropInputMode;
    this.dropInputMode.itemCreator = itemCreator;

    geim.add(this.dropInputMode);
  }

  startDrag(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {}
  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {}
  doubleClick(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {}
  isSelected(item: IDocumentPaletteItem, graphService: IGraphService): boolean {
    return false;
  }
}
