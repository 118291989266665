import {
  BaseClass,
  IBoundsProvider,
  ICanvasContext,
  ICanvasObject,
  ICanvasObjectDescriptor,
  IHitTestable,
  IInputModeContext,
  ILabel,
  INode,
  IVisibilityTestable,
  IVisualCreator,
  Point,
  Rect,
  VoidVisualCreator,
} from 'yfiles';
import JigsawInteriorNodeLabelModelParameter from '../label-models/JigsawInteriorNodeLabelModelParameter';

export default class NodeLabelDescriptor extends BaseClass(
  ICanvasObjectDescriptor,
  IVisibilityTestable,
  IHitTestable
) {
  private label?: ILabel;

  getBoundsProvider(forUserObject: any): IBoundsProvider {
    if (!(forUserObject instanceof ILabel)) {
      return IBoundsProvider.EMPTY;
    }
    return forUserObject.style.renderer.getBoundsProvider(
      forUserObject,
      forUserObject.style
    );
  }

  getHitTestable(forUserObject: any): IHitTestable {
    if (!(forUserObject instanceof ILabel)) {
      return IHitTestable.NEVER;
    }
    this.label = forUserObject;
    return this;
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    if (!this.label) {
      return false;
    }
    if (
      this.label.owner instanceof INode &&
      this.label.layoutParameter instanceof
        JigsawInteriorNodeLabelModelParameter
    ) {
      if (
        !this.label.owner.style.renderer
          .getHitTestable(this.label.owner, this.label.owner.style)
          .isHit(context, location)
      ) {
        return false;
      }
    }
    return this.label.style.renderer
      .getHitTestable(this.label, this.label.style)
      .isHit(context, location);
  }

  getVisibilityTestable(forUserObject: any): IVisibilityTestable {
    if (!(forUserObject instanceof ILabel)) {
      return IVisibilityTestable.NEVER;
    }
    this.label = forUserObject;
    return this;
  }

  isVisible(context: ICanvasContext, rectangle: Rect): boolean {
    if (!this.label) {
      return false;
    }
    if (
      this.label.owner instanceof INode &&
      this.label.layoutParameter instanceof
        JigsawInteriorNodeLabelModelParameter
    ) {
      if (
        !this.label.owner.style.renderer
          .getVisibilityTestable(this.label.owner, this.label.owner.style)
          .isVisible(context, rectangle)
      ) {
        return false;
      }
    }
    return this.label.style.renderer
      .getVisibilityTestable(this.label, this.label.style)
      .isVisible(context, rectangle);
  }

  getVisualCreator(forUserObject: any): IVisualCreator {
    if (!(forUserObject instanceof ILabel)) {
      return VoidVisualCreator.INSTANCE;
    }
    return forUserObject.style.renderer.getVisualCreator(
      forUserObject,
      forUserObject.style
    );
  }

  isDirty(context: ICanvasContext, canvasObject: ICanvasObject): boolean {
    return true;
  }
}
