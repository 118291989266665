import ILegendOptionsDefinition from './ILegendOptionsDefinition';
import i18n from '@/core/plugins/vue-i18n';
import { LegendType } from './LegendType';
import { LayoutForViewOptions } from '@/components/DiagramLegend/LayoutForViewOptions';
import { ILegendLayoutOptionParams } from '@/components/DiagramLegend/ILegendLayoutOptionParams';
import { PageElementPosition } from '@/api/models';
import LegendLayoutUtils from '@/components/DiagramLegend/LegendLayoutUtils';

export default class LegendOptions implements ILegendOptionsDefinition {
  legendType: LegendType;
  layout: LayoutForViewOptions;
  header: string;
  headerEdit: string;
  // Legacy position - for backwards compatibility
  position?: PageElementPosition;

  constructor(
    args?: Partial<ILegendOptionsDefinition>,
    params?: ILegendLayoutOptionParams
  ) {
    this.legendType = args?.legendType ?? LegendType.Page;
    this.header = args?.header ?? i18n.t('LEGEND').toString();
    this.headerEdit = '';
    this.layout = LegendLayoutUtils.ensureValidLayout(args, params);
    this.position = args?.position;
  }

  clone(): LegendOptions {
    return LegendOptions.deserialize(this);
  }

  serialize(): ILegendOptionsDefinition {
    return {
      legendType: this.legendType,
      layout: this.layout,
      header: this.header,
      position: this.position,
    };
  }

  static deserialize(
    definition: ILegendOptionsDefinition,
    params?: ILegendLayoutOptionParams
  ): LegendOptions {
    const options: ILegendOptionsDefinition = {
      header: definition.header,
      legendType: definition.legendType ?? LegendType.Page,
      layout: definition.layout,
      position: definition.position,
    };

    LegendLayoutUtils.ensureValidPosition(options, params);

    return new LegendOptions(options, params);
  }
}
