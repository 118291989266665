import { DocumentDto, DocumentPageDto } from '@/api/models';
import { calculateHash } from '@/core/utils/common.utils';

export default class DocumentHashHelper {
  private static readonly hashPrefix = 'hash';

  public static storeCurrentDocumentHash(document: DocumentDto): string {
    const { pages, lastModificationTime, ...rest } = document;
    const hash = calculateHash(rest);
    this.storeDocumentHash(document.id, hash);
    return hash;
  }

  public static storeCurrentPageHash(page: DocumentPageDto): string {
    let pageToHash = page;
    if (page.diagram) {
      const { cacheKey, diagramViews, ...rest } = page.diagram;
      pageToHash = { ...page };
      pageToHash.diagram = rest;
    }

    const hash = calculateHash(pageToHash);
    this.storePageHash(pageToHash.id, hash);
    return hash;
  }

  public static getCurrentDocumentHash(document: DocumentDto): string {
    const { pages, lastModificationTime, ...rest } = document;
    return calculateHash(rest);
  }

  public static getCurrentPageHash(page: DocumentPageDto): string {
    let pageToHash = page;
    if (page.diagram) {
      const { cacheKey, diagramViews, ...rest } = page.diagram;
      pageToHash = { ...page };
      pageToHash.diagram = rest;
    }
    return calculateHash(pageToHash);
  }

  public static getStoredDocumentHash(documentId: number): string {
    const key = this.getDocumentHashKey(documentId);
    return sessionStorage.getItem(key);
  }

  public static storeDocumentHash(documentId: number, hash: string): void {
    const key = this.getDocumentHashKey(documentId);
    sessionStorage.setItem(key, hash);
  }

  public static getStoredPageHash(pageId: number): string {
    const key = this.getPageHashKey(pageId);
    return sessionStorage.getItem(key);
  }

  public static storePageHash(pageId: number, hash: string): void {
    const key = this.getPageHashKey(pageId);
    sessionStorage.setItem(key, hash);
  }

  public static getDocumentHashKey(documentId: number): string {
    return `${this.hashPrefix}.document.${documentId}`;
  }

  public static getPageHashKey(pageId: number): string {
    return `${this.hashPrefix}.page.${pageId}`;
  }

  public static clearAllHashes(): void {
    [...Object.keys(sessionStorage)].forEach((key) => {
      if (key.startsWith(this.hashPrefix)) {
        sessionStorage.removeItem(key);
      }
    });
  }
}
