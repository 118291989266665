<template>
  <div class="w-full h-1 my-4 bg-main-dark-20" />
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownDivider',
})
export default class AppDropdownDivider extends Vue {}
</script>
