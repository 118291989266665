import {
  IInputModeContext,
  Point,
  IEdgeStyle,
  PathBasedEdgeStyleRenderer,
} from 'yfiles';
import { isEdgeVisibleAtZoom } from './IsEdgeVisibleAtZoom';
import renderingConfig from '@/core/config/renderingConfig';

export class EdgeHitTestHelper {
  static isHit<TStyle extends IEdgeStyle>(
    context: IInputModeContext,
    location: Point,
    renderer: PathBasedEdgeStyleRenderer<TStyle>
  ): boolean {
    if (!isEdgeVisibleAtZoom(context)) {
      return false;
    }
    const path = renderer.getPath();
    if (!path) {
      return false;
    }
    let radius = context.hitTestRadius * renderingConfig.edgeHitRadiusModifier;
    if (context.zoom < 1) {
      radius *= context.zoom;
    }
    return path.pathContains(location, radius);
  }
}
