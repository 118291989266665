export function calculateAngle(
  anchor: { x: number; y: number },
  location: { x: number; y: number }
): number {
  return (
    ((-(
      Math.atan2(anchor.x - location.x, anchor.y - location.y) *
      (180 / Math.PI)
    ) %
      360) +
      360) %
    360
  );
}

export function calculateMode(arr: number[]): number {
  const mode = {};
  let max = 0,
    count = 0;

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (mode[item]) {
      mode[item]++;
    } else {
      mode[item] = 1;
    }

    if (count < mode[item]) {
      max = item;
      count = mode[item];
    }
  }
  return max;
}
