import Vue from 'vue';
import Vuex from 'vuex';
import settings from './settings.module';
import auth from './user.module';
import userActivity from './user-activity.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import svgPreview from './svg-preview.module';
import document, { DOCUMENT_NAMESPACE } from './document.module';
import documentAccess, {
  DOCUMENT_ACCESS_NAMESPACE,
} from './document-access.module';
import documentSearch, {
  DOCUMENT_SEARCH_NAMESPACE,
} from './document-search.module';
import stickyToolbar from './sticky-toolbar.module';
import themes, { THEMES_NAMESPACE } from './theme.module';
import palette, { PALETTE_NAMESPACE } from './palette.module';
import elementBehaviours, {
  ELEMENT_BEHAVIOURS_NAMESPACE,
} from './element-behaviour.module';
import licence from './licence.module';
import datapropertydefinitionsModule, {
  DATAPROPERTYDEFINITIONS_NAMESPACE,
} from './datapropertydefinitions.module';
import DataPropertyTagsModule, {
  DATA_PROPERTY_TAGS_NAMESPACE,
} from './datapropertytags.module';
import searchModule, { SEARCH_NAMESPACE } from './search.module';
import debugModule, { DEBUG_NAMESPACE } from './debug.module';
import globalModule, { GLOBAL_NAMESPACE } from './global.module';
import { PAGE_DESIGN_NAMESPACE } from './page-design.module';
import designControls, {
  STEPS_DESIGN_CONTROLS_NAMESPACE,
} from './steps-design-controls.module';
import pageDesign from './page-design.module';
import viewportModule, { VIEWPORT_NAMESPACE } from './viewport.module';
import entityType from './entitytypes.module';
import { ENTITY_TYPES_NAMESPACE } from './entitytypes.module';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    settings,
    auth,
    userActivity,
    htmlClass,
    config,
    breadcrumbs,
    svgPreview,
    stickyToolbar,
    [DOCUMENT_NAMESPACE]: document,
    [DOCUMENT_ACCESS_NAMESPACE]: documentAccess,
    [DOCUMENT_SEARCH_NAMESPACE]: documentSearch,
    [THEMES_NAMESPACE]: themes,
    [DATAPROPERTYDEFINITIONS_NAMESPACE]: datapropertydefinitionsModule,
    [DATA_PROPERTY_TAGS_NAMESPACE]: DataPropertyTagsModule,
    [PALETTE_NAMESPACE]: palette,
    [ELEMENT_BEHAVIOURS_NAMESPACE]: elementBehaviours,
    licence,
    [DEBUG_NAMESPACE]: debugModule,
    [SEARCH_NAMESPACE]: searchModule,
    [GLOBAL_NAMESPACE]: globalModule,
    [PAGE_DESIGN_NAMESPACE]: pageDesign,
    [STEPS_DESIGN_CONTROLS_NAMESPACE]: designControls,
    [VIEWPORT_NAMESPACE]: viewportModule,
    [ENTITY_TYPES_NAMESPACE]: entityType,
  },
});
