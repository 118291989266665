<template>
  <vue2Dropzone
    ref="vue2Dropzone"
    :id="elementId"
    :multiple="false"
    :options="mergedDropzoneOptions"
    @vdropzone-success="onUploaded"
    @vdropzone-error="$emit('error')"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { ZadarResponse } from '@/api/models';
import appConfig from '@/core/config/appConfig';
import { DropzoneOptions } from 'dropzone';
import { generateUuid } from '@/core/utils/common.utils';

@Component({
  name: 'ImportDropzone',
  components: {
    vue2Dropzone,
  },
})
class ImportDropzone extends Vue {
  @Prop({ type: Object, required: false })
  dropzoneOptions: DropzoneOptions;

  id = 'import-dropzone';

  $refs: {
    vue2Dropzone: typeof vue2Dropzone;
  };

  get elementId(): string {
    return generateUuid();
  }

  get mergedDropzoneOptions(): DropzoneOptions {
    return {
      url: appConfig.apiBaseUrl + '/api/services/app/imports/UploadFile',
      maxFilesize: 100,
      thumbnailWidth: 100,
      headers: { Authorization: `Bearer ${this.$auth.getCachedAccessToken()}` },
      ...this.dropzoneOptions,
    };
  }

  async onUploaded(file: File, response: ZadarResponse<string>): Promise<void> {
    if (!response?.result) {
      this.$emit('error');
      return;
    }

    this.$emit('success', { file, binaryObjectId: response.result });
  }

  mounted(): void {
    if (this.$refs.vue2Dropzone) {
      this.$refs.vue2Dropzone.removeAllFiles();
    }
  }
}

export default ImportDropzone;
</script>

<style lang="scss" scoped>
.vue-dropzone {
  border: 1px solid #dadbdf;
  background-color: #fbfbfc;
  border-radius: 4px;

  &:hover {
    background-color: #ecedef;
  }
}
</style>
