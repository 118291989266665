import { DocumentPaletteCategory, NodeSize } from '@/api/models';

export default [
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-standing-man outline.svg',
    Id: 90045,
    Name: 'PERSON_M',
    Width: 128.0,
    Height: 128.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-woman outline.svg',
    Id: 90055,
    Name: 'PERSON_F',
    Width: 128.0,
    Height: 128.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icon-group.svg',
    Id: 90001,
    Name: 'PEOPLE',
    Width: 180,
    Height: 128.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-home-64.svg',
    Id: 90036,
    Name: 'PROPERTY',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-factory-64.svg',
    Id: 90026,
    Name: 'FACTORY',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-airport-64.svg',
    Id: 90004,
    Name: 'AIRPLANE',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-bank-building-64.svg',
    Id: 90009,
    Name: 'BANK',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-tractor-64.svg',
    Id: 90049,
    Name: 'FARM',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-empty-flag-64.svg',
    Id: 90023,
    Name: 'FLAG',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-field-64.svg',
    Id: 90030,
    Name: 'LAND',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-bulldozer-64.svg',
    Id: 90012,
    Name: 'MACHINE',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-oil-pump-100.svg',
    Id: 90038,
    Name: 'OIL',
    Width: 50.0,
    Height: 50.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-company-64.svg',
    Id: 90020,
    Name: 'COMPANY',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-solar-energy-64.svg',
    Id: 90042,
    Name: 'SOLAR',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-cargo-ship-64.svg',
    Id: 90016,
    Name: 'SHIP',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.ClipArt,
    Subcategory: 'Clip Art',
    ImagePath: '/media/svg/icons8-wind-turbine-64.svg',
    Id: 90052,
    Name: 'WIND',
    Width: 64.0,
    Height: 64.0,
  },
  {
    Category: DocumentPaletteCategory.Annotate,
    Subcategory: 'Arrows',
    ImagePath: '/media/svg/icon-hatch-black.svg',
    Name: 'HATCH',
    Width: 64.0,
    Height: 64.0,
    DefaultSize: NodeSize.ExtraSmall,
  },
  {
    Category: DocumentPaletteCategory.Annotate,
    Subcategory: 'Arrows',
    ImagePath: '/media/svg/icon-hatch-red.svg',
    Name: 'HATCH_RED',
    Width: 64.0,
    Height: 64.0,
    DefaultSize: NodeSize.ExtraSmall,
  },
  {
    Category: DocumentPaletteCategory.Annotate,
    Subcategory: 'Arrows',
    ImagePath: '/media/svg/black-cross.svg',
    Name: 'BLACK_CROSS',
    Width: 128.0,
    Height: 128.0,
    DefaultSize: NodeSize.Medium,
  },
  {
    Category: DocumentPaletteCategory.Annotate,
    Subcategory: 'Arrows',
    ImagePath: '/media/svg/red-cross.svg',
    Name: 'RED_CROSS',
    Width: 160.0,
    Height: 160.0,
    DefaultSize: NodeSize.Medium,
  },
];
