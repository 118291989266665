import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames,
} from '@/core/common/DataPropertyDisplayType';
import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  IInputModeContext,
  ImageNodeStyle,
  INode,
  IRenderContext,
  Point,
  Rect,
  SimpleNode,
  Size,
  SvgVisual,
  Visual,
} from 'yfiles';
import HitResult from '../HitResult';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import JurisdictionDecorator, {
  JurisdictionUtils,
} from './JurisdictionDecorator';

export default class DataPropertiesDecorator extends JigsawNodeDecorator {
  public $class: string = 'DataPropertiesDecorator';
  public static INSTANCE: DataPropertiesDecorator =
    new DataPropertiesDecorator();
  /**
   * Dummy decoration node, used for layout positioning
   */
  public dummyDecorationNode: SimpleNode;
  /**
   * Image style used for rendering the indicator
   */
  public imageStyle: ImageNodeStyle;
  /**
   * Size  of indicator
   */
  public size: Size;

  private readonly imageSrc = '/media/svg/paper-clip.svg';

  constructor(options?: { size?: Size }) {
    super();
    this.size = options?.size ?? new Size(24, 18);
    /*setup */
    // dummy node for rendering
    this.dummyDecorationNode = new SimpleNode();
    // image style for rendering
    this.imageStyle = new ImageNodeStyle({
      image: this.imageSrc,
    });
    // set default dummy node layout
    this.dummyDecorationNode.layout = new Rect(new Point(0, 0), this.size);
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    this.dummyDecorationNode.layout = this.getLayout(node);
    let visual = this.imageStyle.renderer
      .getVisualCreator(this.dummyDecorationNode, this.imageStyle)
      .createVisual(context) as SvgVisual;

    return visual;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: SvgVisual
  ): Visual {
    if (!oldVisual) {
      return this.createVisual(context, node);
    }
    this.dummyDecorationNode.layout = this.getLayout(node);
    let visual = this.imageStyle.renderer
      .getVisualCreator(this.dummyDecorationNode, this.imageStyle)
      .updateVisual(context, oldVisual) as SvgVisual;

    return visual;
  }

  isVisible(renderContext: IRenderContext, node: INode): boolean {
    const isVisible = super.isVisible(renderContext, node);
    return isVisible && this.shouldBeVisible(node);
  }

  shouldBeVisible(node: INode): boolean {
    //return (node?.tag?.dataProperties?.length ?? 0) > 0; //this will be re-added once PBI 1395 is resolved
    return false;
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    return HitResult.NONE;
  }

  public getLayout(node: INode) {
    const nodeLayout = node.layout;
    let additionalOffset = 0;
    if (
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.Jurisdiction,
        DataPropertyDisplayType.Decorator
      )
    ) {
      additionalOffset = JurisdictionDecorator.INSTANCE.size.width;
    }

    let size = this.dummyDecorationNode.layout.toSize();
    const x =
      nodeLayout.x + nodeLayout.width * 0.95 - size.width - additionalOffset;
    const y = size.height * 0.5 + nodeLayout.y;

    return new Rect(x, y, size.width, size.height);
  }
}
