import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18n from '@/core/services/i18n.service';
// Localisation language list

import LanguageApiService from '@/api/LanguageApiService';

Vue.use(VueI18n);
const lang = i18n.getActiveLanguage();

//load in language file

// get current selected language

// Loaded before axios configuration is set so returns a 404

// Axios.get(
//   `localhost:44302/api/services/app/Language/GetLanguage?LanguageKey=${lang}`
// ).then((x) => {
//   console.log(x);
// });

// Create VueI18n instance with options
const vuei18n = new VueI18n({
  locale: lang, // set locale
  messages: {},
});

export default vuei18n;
