import humanizeString from 'humanize-string';
import arrows from './ArrowElementData';
import IDocumentPaletteItem from '../IDocumentPaletteItem';
import { IElementDataProvider } from './IElementDataProvider';
import ArrowPaletteBehaviour from '@/components/DiagramPalette/ArrowPaletteBehaviour';
import { DocumentPaletteCategory } from '@/api/models';

export default class ArrowElementDataProvider implements IElementDataProvider {
  getElements(): IDocumentPaletteItem[] {
    return arrows.map<IDocumentPaletteItem>((e) => {
      return {
        category: DocumentPaletteCategory.Annotate,
        subcategory: humanizeString(e.subcategory),
        img: e.imagePath,
        data: e,
        name: e.name,
        text: e.name,
        id: e.id,
        behaviour: ArrowPaletteBehaviour.INSTANCE,
        canDrag: true,
        style: e.style,
      };
    });
  }
}
