import { ApplicationPermissions } from '@/api/ApplicationPermissions';
import { CurrentAccount, CurrentUserProfileEditDto } from '@/api/models';
import ProfileApiService from '@/api/ProfileApiService';
import userConfig from '@/core/config/userConfig';
import { Module } from 'vuex';
import { UserRole } from '@/core/common/UserRole';
import LocalStorageService from '@/core/services/localstorage.service';
import AccountApiService from '@/api/AccountApiService';

// action types
export const GET_PROFILE = 'getProfile';
export const GET_ACCOUNT = 'getAccount';
export const CHANGE_USER_LANGUAGE = 'changeUserLanguage';
// mutation types
export const SET_PROFILE = 'setProfile';
export const SET_ACCOUNT = 'setAccount';
export const SET_USER_LANGUAGE = 'setUserLanguage';
export const SET_STEPS_LICENCE_REQUESTED = 'setStepsLicenceRequested';
// getters
export const GET_CURRENT_USER = 'currentUser';
export const GET_CURRENT_ACCOUNT = 'currentAccount';
export const GET_IS_DOCUMENTMANAGER = 'isDocumentManager';
export const GET_USER_PERMISSIONS = 'userPermissions';
export const GET_IS_STEP_DESIGNER = 'isStepDesigner';
export const GET_HAS_USER_SELECTED_LANGUAGE = 'hasUserSelectedLanguage';
export const GET_STEPS_LICENCE_REQUESTED = 'getStepsLicenceRequested';

const STEPS_LICENCE_REQUEST_LS_FLAG = 'stepsLicenceRequested';

interface State {
  user: CurrentUserProfileEditDto;
  account: CurrentAccount;
  requestedStepsLicence: boolean;
}

async function getInitialState(): Promise<State> {
  const requestStepsLicenceFlag = LocalStorageService.hasKey(
    STEPS_LICENCE_REQUEST_LS_FLAG
  )
    ? Boolean(LocalStorageService.getItem(STEPS_LICENCE_REQUEST_LS_FLAG))
    : false;
  return {
    user: null,
    account: null,
    requestedStepsLicence: requestStepsLicenceFlag,
  };
}

const userModule: Module<State, any> = {
  namespaced: false,
  state: await getInitialState(),
  getters: {
    [GET_CURRENT_USER](state) {
      return state.user;
    },
    [GET_CURRENT_ACCOUNT](state) {
      return state.account;
    },
    [GET_USER_PERMISSIONS](state) {
      return state.user.permissions;
    },
    [GET_IS_STEP_DESIGNER](state) {
      if (state.user?.roles?.length) {
        return !!state.user.roles.find((role) => {
          return role === UserRole.Admin || role === UserRole.SuperUser;
        });
      }
      return false;
    },
    [GET_IS_DOCUMENTMANAGER](state) {
      let isDocumentManager: boolean = false;
      if (state.user.permissions) {
        isDocumentManager = state.user.permissions.includes(
          ApplicationPermissions.DocumentsManager.key
        );
      }
      return isDocumentManager;
    },
    [GET_HAS_USER_SELECTED_LANGUAGE](state) {
      return !!state.user?.language;
    },
    [GET_STEPS_LICENCE_REQUESTED](state) {
      return state.requestedStepsLicence;
    },
  },
  actions: {
    [GET_PROFILE]({ commit, getters, dispatch }) {
      if (getters.currentUser) {
        return Promise.resolve();
      }
      return ProfileApiService.getCurrentUserProfileForEdit()
        .then(({ data }) => {
          commit(SET_PROFILE, data?.result);
          Object.assign(userConfig, data?.result);
        })
        .catch(() => {
          commit(SET_PROFILE, null);
        });
    },
    [SET_PROFILE](context, profile) {
      context.commit(SET_PROFILE, profile);
    },
    [GET_ACCOUNT]({ commit }) {
      return AccountApiService.getCurrentAccount()
        .then(({ data }) => {
          commit(SET_ACCOUNT, data?.result);
          Object.assign(userConfig, data?.result);
        })
        .catch(() => {
          commit(SET_ACCOUNT, null);
        });
    },
    [SET_ACCOUNT](context, account) {
      context.commit(SET_ACCOUNT, account);
    },
    [SET_STEPS_LICENCE_REQUESTED](context, flag) {
      LocalStorageService.setItem(STEPS_LICENCE_REQUEST_LS_FLAG, flag);
      context.commit(SET_STEPS_LICENCE_REQUESTED, flag);
    },

    [CHANGE_USER_LANGUAGE]({ state, commit }, payload: string) {
      return ProfileApiService.changeLanguage({
        language: payload,
      })
        .then(() => {
          commit(SET_USER_LANGUAGE, payload);
        })
        .catch(() => {
          commit(SET_USER_LANGUAGE, null);
        });
    },
  },

  mutations: {
    [SET_PROFILE](state, profile) {
      state.user = profile;
    },
    [SET_ACCOUNT](state, account) {
      state.account = account;
    },
    [SET_USER_LANGUAGE](state, language) {
      state.user.language = language;
    },
    [SET_STEPS_LICENCE_REQUESTED](state, flag) {
      state.requestedStepsLicence = flag;
    },
  },
};

export default userModule;
