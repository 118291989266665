import {
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  DocumentSubPageDto,
  HeaderFooterStyleDto,
  PageStyleDto,
} from '@/api/models';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import JSize from '@/core/common/JSize';
import ContentPagination from './ContentPagination';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import ExportOptions from './ExportOptions';
import ExportPage from './ExportPage';
import ExportPagePart from './ExportPagePart';
import ExportUtils from './ExportUtils';
import { ExportFormat } from './ExportFormat';

export default abstract class ExportPageBuilder {
  protected options: ExportOptions;

  protected get exportPage(): ExportPage {
    return this.options.metadata.currentPage;
  }

  protected get document(): DocumentDto {
    return this.options.document;
  }

  protected get page(): DocumentPageDto {
    return this.exportPage.page;
  }

  protected get pagePart(): ExportPagePart {
    return this.options.pagePart ?? ExportPagePart.All;
  }

  protected get pageType(): DocumentPageType {
    return this.page.pageType;
  }

  protected get contentType(): DocumentPageContentType {
    return this.page.contentType;
  }

  protected get contentColumns(): number {
    return this.page.contentColumns;
  }

  protected get subPageRef(): DocumentSubPageDto {
    const subPageIndex = this.exportPage.subPageIndex;
    if (subPageIndex === undefined || subPageIndex === null) {
      return null;
    }
    return this.page.subPageRefs?.find(
      (sp) => sp.subPageIndex === subPageIndex
    );
  }

  protected get pageStyle(): PageStyleDto {
    return this.document.pageStyle;
  }

  protected get headerStyle(): HeaderFooterStyleDto {
    return this.document.headerStyle;
  }

  protected get footerStyle(): HeaderFooterStyleDto {
    return this.document.footerStyle;
  }

  protected get diagramPosition(): number {
    return this.page.diagramPosition;
  }

  protected get diagramSize(): JSize {
    return ExportUtils.calculateBodyPartSize(
      this.document,
      this.page,
      'diagram',
      false,
      this.exportPage.subPageIndex
    );
  }

  protected get contentSize(): JSize {
    return ExportUtils.calculateBodyPartSize(
      this.document,
      this.page,
      'content',
      false,
      this.exportPage.subPageIndex
    );
  }

  protected get pageSize(): JSize {
    return new JSize(this.pageStyle.width, this.pageStyle.height);
  }

  protected get headerSize(): JSize {
    return new JSize(this.pageSize.width, this.headerHeight);
  }

  protected get footerSize(): JSize {
    return new JSize(this.pageSize.width, this.footerHeight);
  }

  protected get headerHeight(): number {
    if (!this.includeHeader) {
      return 0;
    }

    // on svg just half of border would cover content (as dom-to-svg works)
    return (
      (this.headerStyle.height ?? 0) -
      (this.headerStyle.borderWidth ?? 0) *
      (this.options.format !== ExportFormat.Svg ? 2 : 1)
    );
  }

  protected get footerHeight(): number {
    if (!this.includeFooter) {
      return 0;
    }

    // on svg just half of border would cover content (as dom-to-svg works)
    return (
      (this.footerStyle.height ?? 0) -
      (this.footerStyle.borderWidth ?? 0) *
      (this.options.format !== ExportFormat.Svg ? 2 : 1)
    );
  }

  protected get showTitle(): boolean {
    return this.subPageRef?.showTitle ?? this.page.showTitle;
  }

  protected get titleHeight(): number {
    if (!this.includeTitle) {
      return 0;
    }

    return ExportUtils.calculatePageTitleHeight(
      this.subPageRef ?? this.page,
      this.showTitle
    );
  }

  protected get includeHeader(): boolean {
    const headerContainsWidget = LayoutWidgetUtils.contentAreaContainsWidgets(
      this.page,
      DocumentContentArea.Header,
      true
    );
    return (
      this.document.hasSteps &&
      (this.page.showHeader || headerContainsWidget) &&
      this.contentType != DocumentPageContentType.Layout
    );
  }

  protected get includeFooter(): boolean {
    const footerContainsWidget = LayoutWidgetUtils.contentAreaContainsWidgets(
      this.page,
      DocumentContentArea.Footer,
      true
    );
    return (
      this.document.hasSteps &&
      (this.page.showFooter || footerContainsWidget) &&
      this.contentType !== DocumentPageContentType.Layout
    );
  }

  protected get includeTitle(): boolean {
    return (
      this.document.hasSteps &&
      this.contentType != DocumentPageContentType.Layout
    );
  }

  protected get includeBackground(): boolean {
    return (
      this.document.hasSteps &&
      this.contentType !== DocumentPageContentType.Layout
    );
  }

  protected get includeLogo(): boolean {
    return ExportUtils.shouldIncludeLogo(this.document, this.page);
  }

  protected get includeLegend(): boolean {
    return ExportUtils.shouldIncludeLegend(this.document, this.page);
  }

  protected get pageNumber(): number {
    const subpageNumber = Number.isInteger(this.exportPage.subPageIndex)
      ? this.exportPage.subPageIndex
      : 0;
    return (
      ContentPagination.getPageNumber(this.document, this.page) + subpageNumber
    );
  }

  constructor(options: ExportOptions) {
    this.options = options;
  }
}
