import {
  CloneThemeDto,
  CreateOrEditThemeDto,
  ElementType,
  GetThemeForViewDto,
  GetThemeOutput,
  PagedResultDtoOfGetThemeForViewDto,
  ThemeDto,
} from '@/api/models';
import ThemesApiService from '@/api/ThemesApiService';
import { Module } from 'vuex';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import appConfig from '@/core/config/appConfig';

// getters
export const GET_THEME_OUTPUT = 'getThemeOutput';

// mutations
export const SET_THEME_OUTPUT = 'setThemeOutput';

// action types
export const FETCH_THEME = 'fetchTheme';
export const GET_THEMES = 'getThemes';
export const GET_DEFAULT_THEME = 'getDefaultTheme';
export const DELETE_THEME = 'deleteTheme';
export const UPDATE_THEME = 'updateTheme';

export const THEMES_NAMESPACE = 'themes';
export const SET_DEFAULT_THEME = 'setDefaultTheme';
export const CREATE_THEME = 'createTheme';
export const CLONE_THEME = 'cloneTheme';

export const GET_THEME_PREVIEWS = 'getThemePreviews';
export const SET_THEME_PREVIEWS = 'setThemePreviews';

/**
 * The themes module a proxy services out to the API with some logic and has no internal state
 * It does not make sense to cache the themes and maintain them on the client as we use them so infrequently.
 *
 */
interface State {
  themeOutput: GetThemeOutput;
}

const themeModule: Module<State, any> = {
  namespaced: true,

  state: {
    themeOutput: null,
  },

  getters: {
    [GET_THEME_OUTPUT](state) {
      return state.themeOutput;
    },
  },

  mutations: {
    [SET_THEME_OUTPUT](state, payload: GetThemeOutput) {
      state.themeOutput = payload;
    },
  },

  actions: {
    async [FETCH_THEME](
      context,
      payload: {
        id: number;
        update?: boolean;
        ignoreError?: boolean;
        fallbackToDefault?: boolean;
      }
    ): Promise<GetThemeOutput> {
      try {
        const response = await ThemesApiService.getTheme(
          { id: payload.id },
          { ignoreError: payload.ignoreError }
        );

        context.commit(SET_THEME_OUTPUT, response.data.result);

        if (payload.fallbackToDefault && !response?.data?.result?.theme) {
          return await context.dispatch(GET_DEFAULT_THEME, payload);
        }

        return response.data.result;
      } catch (err) {
        if (payload.ignoreError) {
          return;
        }
        throw err;
      }
    },
    async [GET_THEMES](
      context,
      payload: {
        filter?: string;
        isDefault?: boolean;
        includeLogo?: boolean;
        sorting?: string;
        skipCount?: number;
        maxResultCount?: number;
      }
    ): Promise<PagedResultDtoOfGetThemeForViewDto> {
      const response = await ThemesApiService.getAll(payload);
      //NOTE: Do not commit these themes to the store.

      return response.data.result;
    },
    [GET_THEME_PREVIEWS](context) {
      return ThemesApiService.getAll({
        includeLogo: true,
        maxResultCount: appConfig.demo.maxThemes,
      }).then((response) => {
        return response.data.result.items;
      });
    },
    async [GET_DEFAULT_THEME](context) {
      const result = await ThemesApiService.getAll({
        isDefault: true,
        maxResultCount: 1,
      });
      if (!result?.data?.result?.totalCount) {
        throw 'Unable to locate default theme';
      }
      return await context.dispatch(FETCH_THEME, {
        id: result.data.result.items[0].theme.id,
      });
    },
    async [DELETE_THEME](context, payload: { id: number }) {
      const response = await ThemesApiService.delete(payload);
      EventBus.$emit(EventBusActions.THEME_DELETED, {
        themeId: payload.id,
      });
      return response.data;
    },
    async [UPDATE_THEME](context, payload: CreateOrEditThemeDto) {
      await ThemesApiService.createOrEdit(payload);
      return await context.dispatch(FETCH_THEME, {
        id: payload.id,
        update: true,
      });
    },
    async [CREATE_THEME](context, payload: { name: string }) {
      try {
        const defaultTheme: ThemeDto = (
          await context.dispatch(GET_DEFAULT_THEME)
        ).theme;
        const response = await ThemesApiService.clone(
          new CloneThemeDto(defaultTheme.id, payload.name)
        );
        if (response.status == 200) {
          const newTheme = response.data.result;
          newTheme.elements.forEach((el) => {
            el.systemOwned && el.elementType == ElementType.Node
              ? (el.inShapeCycle = true)
              : (el.inShapeCycle = false);
          });

          EventBus.$emit(EventBusActions.THEME_CREATED, {
            themeId: newTheme.id,
          });
          return newTheme.id;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async [CLONE_THEME](context, payload: number) {
      try {
        const result = await ThemesApiService.clone(new CloneThemeDto(payload));
        if (result.status == 200) {
          EventBus.$emit(EventBusActions.THEME_CLONED, {
            themeId: payload,
            newThemeId: result.data.result.id,
          });
          return result.data.result.id;
        }
      } catch (e) {
        console.error(e);
      }
    },
    [SET_DEFAULT_THEME](ctx, payload: { themeId: number }) {
      return ThemesApiService.setDefaultTheme(payload);
    },
  },
};
export default themeModule;
