<template>
  <span
    :class="iconClasses"
    :style="iconStyles"
    @click="$emit('click', $event)"
  />
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CSSProperties } from 'vue/types/jsx';

@Component({
  name: 'AppIcon',
})
export default class AppIcon extends Vue {
  @Prop({ required: true }) name: string;
  @Prop({ default: 24 }) size: string;
  /**
   * Can be used to make icons look the same as old font awesome icons
   */
  @Prop({ default: false, type: Boolean }) legacy: boolean;

  get iconClasses(): string {
    return `icon-${this.name}`;
  }

  get iconStyles(): CSSProperties {
    if (this.legacy) {
      return {
        fontSize: `1.1rem`,
        color: '#b5b5c3',
      };
    }
    return { fontSize: `${this.size}px` };
  }
}
</script>
