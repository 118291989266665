import {
  GeneralPath,
  IInputModeContext,
  INode,
  Insets,
  IRenderContext,
  Point,
  ShapeNodeStyleRenderer,
  SvgVisual,
  Visual,
} from 'yfiles';
import { NodeShape } from '@/api/models';
import config from '@/core/config/diagram.definition.config';
import SystemEntityTypes from '../services/corporate/SystemEntityTypes';

export class JigsawShapeNodeStyleRenderer extends ShapeNodeStyleRenderer {
  constructor(private nodeShape: NodeShape) {
    super();
  }

  protected configure(): void {
    this.setRadius();
    super.configure();
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    if (
      this.node.tag.name !== SystemEntityTypes.EDGE_TO_NOWHERE_NODE &&
      this.node.tag.name !== SystemEntityTypes.ARROW_NODE
    ) {
      return super.isHit(context, location);
    }

    const largerLayout = this.node.layout
      .toRect()
      .getEnlarged(new Insets(25, 25, 25, 25));

    return largerLayout.contains(location);
  }

  setRadius() {
    if (config.nodeRadiusFlexRatios[this.shape]) {
      this.roundRectArcRadius = Math.min(
        this.layout.width * config.nodeRadiusFlexRatios[this.shape],
        config.maxNodeEdgeRadius
      );
    }
  }

  get shape(): NodeShape {
    return this.nodeShape;
  }
}
