import CompositeParameter from '../parameters/CompositeParameter';
import ISearchParameter from '../parameters/ISearchParameter';
import AndSpecification from '../specifications/AndSpecification';
import ISpecification from '../specifications/ISpecification';
import MixedSpecification from '../specifications/MixedSpecification';
import OrSpecification from '../specifications/OrSpecification';
import Filter from './Filter';
import { FilterScope } from './FilterScope';
import FilterSerializer from './FilterSerializer';
import { FilterType } from './FilterType';
import IFilterDefinition from './IFilterDefinition';

export default class CompositeFilter extends Filter {
  public type: FilterType;
  public scope = FilterScope.Composite;
  public parameter: CompositeParameter;
  protected _specification: ISpecification;

  constructor(
    parameter: ISearchParameter,
    type: FilterType = FilterType.Include
  ) {
    super();
    this.type = type;
    this.parameter = parameter as CompositeParameter;
    const specifications = this.parameter.filters.map((f) => f.specification);
    if (this.parameter.compositionType == CompositionType.And) {
      this._specification = new AndSpecification(specifications);
    } else if (this.parameter.compositionType == CompositionType.Or) {
      this._specification = new OrSpecification(specifications);
    } else if (this.parameter.compositionType == CompositionType.Mixed) {
      this._specification = new MixedSpecification(specifications);
    }
  }

  public static decompose(filters: Filter[]): Filter[] {
    const flattenedFilters = [];
    for (const filter of filters) {
      if (filter.scope == FilterScope.Composite) {
        const innerFilters = (filter as CompositeFilter).parameter.filters;
        flattenedFilters.push(...this.decompose(innerFilters));
      } else {
        flattenedFilters.push(filter);
      }
    }
    return flattenedFilters;
  }

  public serialize(): IFilterDefinition {
    return {
      scope: FilterScope[this.scope],
      parameter: {
        filters: FilterSerializer.serialize(this.parameter.filters),
        compositionType: this.parameter.compositionType,
      },
      type: this.type,
    };
  }
}

export enum CompositionType {
  And,
  Or,
  Mixed,
}
