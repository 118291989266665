import {
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
} from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';
import { copyElementAttributes, htmlToElement } from '@/core/utils/html.utils';
import { Size } from 'yfiles';
import BackgroundDomService from '../BackgroundDomService';
import ContentPaginationItem from './ContentPaginationItem';
import ExportUtils from './ExportUtils';

export default class ContentPagination {
  public static readonly rootContainerClass = 'root-container';
  public static readonly chunkContainerClass = 'chunk-container';
  public static readonly innerContainerId = 'inner-container';
  public static readonly autoPageBreakClass = 'auto-page-break';
  public static readonly manualPageBreakClass = 'page-break';
  public static readonly manualPageBreakTypeAttribute = 'data-type';
  public static readonly pageIndexAttribute = 'data-index';
  public static readonly pageElementTag = 'page';
  public static readonly paragraphNodeName = 'P';
  public static readonly listNodeNames = ['UL', 'OL'];
  public static readonly listIdAttribute = 'ltid';
  public static readonly paragraphOverflowAttribute = 'plof';
  public static readonly elementOverflowAttribute = 'elof';
  public static readonly elementSplitIdAttribute = 'spid';

  // Paragraphs that span across more than two pages will be split in half
  public static readonly splitOverflowParagraphs = true;
  public static readonly splitParagraphsBetweenPages:
    | 'disabled'
    | 'words'
    | 'selection' = 'selection';
  public static readonly splitListsBetweenPages = true;

  public static isAutoPageBreak(node: Node): boolean {
    return (
      BackgroundDomService.isHtmlElement(node) &&
      node.classList.contains(this.autoPageBreakClass)
    );
  }

  public static isManualPageBreak(node: Node): boolean {
    return (
      node &&
      BackgroundDomService.isHtmlElement(node) &&
      node.classList.contains(this.manualPageBreakClass)
    );
  }

  public static movePage(
    contentHtml: string,
    sourcePageIndex: number,
    targetPageIndex: number
  ): string {
    if (sourcePageIndex == targetPageIndex) {
      return contentHtml;
    }

    const tempContainer = this.createTempContainer();
    tempContainer.innerHTML = contentHtml;

    const sourcePage: HTMLElement = tempContainer.querySelector(
      `${this.pageElementTag}[${this.pageIndexAttribute}="${sourcePageIndex}"]`
    );
    const targetPage: HTMLElement = tempContainer.querySelector(
      `${this.pageElementTag}[${this.pageIndexAttribute}="${targetPageIndex}"]`
    );

    if (!sourcePage || !targetPage) {
      return contentHtml;
    }

    // Insert target page before or after source (depending on direction)
    if (sourcePageIndex > targetPageIndex) {
      tempContainer.insertBefore(sourcePage, targetPage);
    } else {
      // if nextSibling is null - sourcePage will be inserted in the end of container
      tempContainer.insertBefore(sourcePage, targetPage.nextSibling);
    }

    // Surround both pages with manual page breaks
    if (
      sourcePageIndex > 0 &&
      !this.isManualPageBreak(sourcePage.previousSibling)
    ) {
      sourcePage.before(this.createManualPageBreak('outside'));
    }
    if (
      sourcePage.nextSibling &&
      !this.isManualPageBreak(sourcePage.nextSibling)
    ) {
      sourcePage.after(this.createManualPageBreak('outside'));
    }
    if (
      targetPageIndex > 0 &&
      !this.isManualPageBreak(targetPage.previousSibling)
    ) {
      targetPage.before(this.createManualPageBreak('outside'));
    }
    if (
      targetPage.nextSibling &&
      !this.isManualPageBreak(targetPage.nextSibling)
    ) {
      targetPage.after(this.createManualPageBreak('outside'));
    }

    // Update page index attributes
    tempContainer
      .querySelectorAll(this.pageElementTag)
      .forEach((page, index) => {
        page.setAttribute(this.pageIndexAttribute, index.toString());
      });

    if (this.isManualPageBreak(tempContainer.firstChild)) {
      tempContainer.firstChild.remove();
    }
    if (this.isManualPageBreak(tempContainer.lastChild)) {
      tempContainer.lastChild.remove();
    }

    // remove duplicated manual page breaks
    const containerChildren = tempContainer.children;
    Array.from(containerChildren).forEach((element, index) => {
      if (
        this.isManualPageBreak(element) &&
        containerChildren[index + 1] &&
        this.isManualPageBreak(containerChildren[index + 1])
      ) {
        element.remove();
      }
    });

    return tempContainer.innerHTML;
  }

  public static removePage(contentHtml: string, pageIndex: number): string {
    const tempContainer = this.createTempContainer();
    tempContainer.innerHTML = contentHtml;

    const page: HTMLElement = tempContainer.querySelector(
      `${this.pageElementTag}[${this.pageIndexAttribute}="${pageIndex}"]`
    );
    if (!page) {
      return contentHtml;
    }

    // If removing the last page in the document and last element on the previous page is
    // a manual page break - remove it as well (otherwise a new page will be created immediately)
    const nextPage: HTMLElement = tempContainer.querySelector(
      `${this.pageElementTag}[${this.pageIndexAttribute}="${pageIndex + 1}"]`
    );
    if (!nextPage) {
      const previousPage: HTMLElement = tempContainer.querySelector(
        `${this.pageElementTag}[${this.pageIndexAttribute}="${pageIndex - 1}"]`
      );
      if (this.isManualPageBreak(previousPage?.lastElementChild)) {
        previousPage.lastElementChild.remove();
      }
    }

    // Remove manual page break after the page (if exists)
    // Not sure if this is the correct behaviour
    if (this.isManualPageBreak(page.nextElementSibling)) {
      page.nextElementSibling.remove();
    }
    page.remove();

    // Update page index attributes
    tempContainer
      .querySelectorAll(this.pageElementTag)
      .forEach((page, index) => {
        page.setAttribute(this.pageIndexAttribute, index.toString());
      });

    return tempContainer.innerHTML;
  }

  public static mergePages(
    leftContentHtml: string,
    rightContentHtml: string
  ): string {
    leftContentHtml = this.ensurePagedContentHtml(leftContentHtml);
    rightContentHtml = this.ensurePagedContentHtml(rightContentHtml);
    return `${leftContentHtml}${rightContentHtml}`;
  }

  public static ensurePagedContentHtml(contentHtml: string): string {
    if (!contentHtml) {
      return `<${this.pageElementTag}></${this.pageElementTag}>`;
    } else if (!contentHtml.startsWith(`<${this.pageElementTag}`)) {
      return `<${this.pageElementTag}>${contentHtml}</${this.pageElementTag}>`;
    }
    return contentHtml;
  }

  public static getPageCount(page: DocumentPageDto): number {
    if (page.content && page.contentType == DocumentPageContentType.Html) {
      return this.getPageCountFromContent(page.content);
    }
    return 1;
  }

  public static getPageCountFromContent(content: string): number {
    if (content) {
      const rx = new RegExp(`</${this.pageElementTag}>`, 'gm');
      const match = content.match(rx);
      if (match) {
        return match.length;
      }
    }
    return 1;
  }

  public static getContinuousPageIndexes(contentHtml: string): number[][] {
    if (!contentHtml) {
      return [];
    }
    let currentPageIndex = 0;
    let currentGroup: number[] = [];
    const pageIndexes = [currentGroup];

    const tempContainer = this.createTempContainer();
    tempContainer.innerHTML = contentHtml;
    for (const element of tempContainer.children) {
      if (element.matches(this.pageElementTag)) {
        currentGroup.push(currentPageIndex);
        currentPageIndex++;
      }

      if (this.isManualPageBreak(element) || this.isAutoPageBreak(element)) {
        currentGroup = [];
        pageIndexes.push(currentGroup);
      }
    }
    return pageIndexes;
  }

  public static getPageNumber(
    document: DocumentDto,
    page: DocumentPageDto
  ): number {
    return document.pages.reduce((a, b) => {
      if (
        // TODO uncomment to exclude layout pages from page numbering
        //b.contentType == DocumentPageContentType.Layout ||
        document.pages.indexOf(b) >= document.pages.indexOf(page)
      ) {
        return a;
      } else {
        return a + ContentPagination.getPageCount(b);
      }
    }, 1);
  }

  /**
   * Splits page content into columns.
   * @param [pageHtml] Page html content represented as a string
   * @returns Array of column htmls
   */
  public static splitPageIntoColumns(
    pageHtml: string,
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number
  ): string[] {
    const tempContainer = this.createTempPageContainer(
      pageHtml,
      document,
      page,
      subPageIndex
    );
    BackgroundDomService.appendElement(tempContainer);

    let currentY: number = null;
    let currentColumnElement = BackgroundDomService.createElement('div');
    let columnElements = [currentColumnElement];

    for (const childNode of tempContainer.childNodes) {
      if (BackgroundDomService.isHtmlElement(childNode)) {
        const bounds = childNode.getBoundingClientRect();
        // Start a new column if Y has decreased
        if (currentY && currentY >= bounds.y) {
          currentColumnElement = BackgroundDomService.createElement('div');
          columnElements.push(currentColumnElement);
        }
        currentY = bounds.y;
      }
      currentColumnElement.append(childNode.cloneNode(true));
    }

    tempContainer.remove();
    return columnElements.map((el) => el.innerHTML);
  }

  /**
   * Splits paged content into separate page html chunks
   * Paged content is html which was already split before into <page></page> elements
   * Usually this will be the output from CKEditor
   * If in doubt use this function instead of splitRawContentIntoPages
   * @param [contentHtml] Paged html content represented as a string
   * @returns Page chunks
   */
  public static splitPagedContentIntoPages(contentHtml: string): string[] {
    if (!contentHtml) return [''];
    const regex = new RegExp(
      `<${this.pageElementTag}.*?>(.*?)</${this.pageElementTag}>`,
      'gm'
    );
    const match = [...contentHtml.matchAll(regex)];
    if (match.length > 0) {
      return match.map((m) => m[1]);
    } else {
      return [contentHtml];
    }
  }

  /**
   * Merges page html chunks back into single paged content html
   * @param [pages] Page chunks (output from splitPagedContentIntoPages)
   * @returns Content html
   */
  public static mergePagesIntoPagedContent(pages: string[]): string {
    if (!pages?.length) return '';
    return pages
      .map(
        (pageHtml, index) =>
          `<${this.pageElementTag} ${this.pageIndexAttribute}="${index}">${pageHtml}</${this.pageElementTag}>`
      )
      .join('');
  }

  /**
   * Calculate the raw (non-pages/processed) html content size based on its width
   * @param [contentHtml] Raw html content represented as a string
   * @returns Content size (width & height)
   */
  public static measureRawContentSize(
    contentHtml: string,
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number
  ): Size {
    const tempContainer = this.createTempPageContainer(
      this.prepareRawContent(contentHtml),
      document,
      page,
      subPageIndex
    );
    BackgroundDomService.appendElement(tempContainer);
    const size = tempContainer.getBoundingClientRect();
    tempContainer.remove();
    return new Size(size.width, size.height);
  }

  /**
   * Splits raw (non-paged/processed) html content into page-sized chunks.
   * Raw content is any html without the <page> elements
   * Usually this will be called from within the CKEditor via PageLayout Handler
   * @param [contentHtml] Raw html content represented as a string
   * @param [columns] Number of content columns
   * @returns Page chunks
   */
  public static splitRawContentIntoPages(
    contentHtml: string,
    columns: number,
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number
  ): ContentPaginationItem[] {
    const pageBodySize = ExportUtils.calculateBodyPartSize(
      document,
      page,
      'content',
      true,
      subPageIndex
    );
    const pageBodySizePixels = new Size(
      pageBodySize.width * ExportConfig.pointToPixelFactor,
      pageBodySize.height * ExportConfig.pointToPixelFactor
    );

    const tempContainer = this.createTempContainer(
      pageBodySizePixels.width,
      pageBodySizePixels.height
    );
    tempContainer.innerHTML = this.prepareRawContent(contentHtml);

    // Move elements of out their page containers
    tempContainer
      .querySelectorAll(this.pageElementTag)
      .forEach((page) => (page.outerHTML = page.innerHTML));

    BackgroundDomService.appendElement(tempContainer);

    // Split content container into chunk elements with max height of pageSize height
    const maxChunkSize = new Size(
      pageBodySizePixels.width,
      pageBodySizePixels.height
    );
    const columnGap = ExportUtils.calculateHtmlContentGap(document);
    const rootContainer = this.splitNodeIntoChunks(
      tempContainer,
      maxChunkSize,
      columns,
      columnGap,
      this.pageElementTag
    );

    // Extract the output child nodes into array of content pages
    const items: ContentPaginationItem[] = [];
    for (const childNode of rootContainer.childNodes) {
      const element = childNode as HTMLElement;
      const size = element.getBoundingClientRect();
      const page = new ContentPaginationItem();
      page.element = element;
      page.size = new Size(size.width, size.height);
      items.push(page);
    }

    // Remove unneeded containers from dom
    tempContainer.remove();
    rootContainer.remove();

    return items;
  }

  /**
   * Splits node into chunks of the specified max height.
   * Traverses recursively through the element tree adding nested elements
   * one by one into the container until max height is reached. When this happens,
   * creates a new container and repeats the process for the remaining elements
   * @param [parentNode] The node which will be split
   * @param [maxChunkSize] Maximum size of the chunk after which the page will be split
   * @param [columns] Number of content columns
   * @param [recursiveSplitSelector] Recursively split children of matched elements
   * @param [currentContainer] Internal container to add nested elements into
   * @returns Root container
   */
  private static splitNodeIntoChunks(
    parentNode: HTMLElement,
    maxChunkSize: Size,
    columns: number,
    columnGap: number,
    recursiveSplitSelector: string,
    currentContainer: HTMLElement = null
  ): HTMLElement {
    // Locate the root container to add chunks into
    let rootContainer =
      currentContainer != null
        ? this.getNodeParents(
            currentContainer,
            (n) =>
              BackgroundDomService.isHtmlElement(n) &&
              n.classList.contains(this.rootContainerClass)
          )[0].parentElement
        : null;

    // Create a new root container and a nested chunk container if empty
    if (!rootContainer) {
      rootContainer = this.createRootContainer(parentNode);
      currentContainer = this.createChunkContainer(
        rootContainer,
        maxChunkSize,
        columns,
        columnGap
      );
    }

    // Merge back paragraphs which were split between pages
    if (this.splitParagraphsBetweenPages != 'disabled') {
      const splitParagraphs = [
        ...parentNode.querySelectorAll(
          `${this.paragraphNodeName}[${this.elementSplitIdAttribute}]`
        ),
      ];
      const mergedParagraphs = [];
      for (const firstParagraph of splitParagraphs) {
        if (
          !firstParagraph.parentElement ||
          mergedParagraphs.includes(firstParagraph)
        ) {
          continue;
        }
        mergedParagraphs.push(firstParagraph);

        const spid = firstParagraph.getAttribute(this.elementSplitIdAttribute);
        const secondParagraph = splitParagraphs.find(
          (p) =>
            p != firstParagraph &&
            !mergedParagraphs.includes(p) &&
            p.getAttribute(this.elementSplitIdAttribute) == spid
        );

        if (secondParagraph) {
          this.mergeParagraphs(firstParagraph, secondParagraph);
          mergedParagraphs.push(secondParagraph);
        }
      }
    }

    // Merge back lists which were split between pages
    if (this.splitListsBetweenPages) {
      const splitLists = [
        ...parentNode.querySelectorAll(
          `${this.pageElementTag} > ${this.listNodeNames.join(',')}[${
            this.listIdAttribute
          }]`
        ),
      ];
      const mergedLists = [];
      for (const firstList of splitLists) {
        if (!firstList.parentElement || mergedLists.includes(firstList)) {
          continue;
        }
        mergedLists.push(firstList);

        const ltid = firstList.getAttribute(this.listIdAttribute);
        const secondList = splitLists.find(
          (l) =>
            l != firstList &&
            !mergedLists.includes(l) &&
            l.getAttribute(this.listIdAttribute) == ltid
        );

        if (secondList) {
          this.mergeLists(firstList, secondList);
          mergedLists.push(secondList);
        }
      }
    }

    // Recursively traverse through all the child nodes of the current node
    const childNodes = [...parentNode.childNodes];
    for (let i = 0; i < childNodes.length; i++) {
      let childNode = childNodes[i];
      const isAutoPageBreakNode = this.isAutoPageBreak(childNode);

      // Ignore auto page break elements as they will get reapplied/reset
      if (isAutoPageBreakNode) {
        continue;
      }
      const isManualPageBreakNode = this.isManualPageBreak(childNode);
      let chunkContainer = rootContainer.lastElementChild as HTMLElement;

      // Current container has been moved into the next chunk
      if (!chunkContainer.contains(currentContainer) && currentContainer.id) {
        currentContainer = chunkContainer.querySelector(
          '#' + currentContainer.id
        );
      }
      // If current container is not set or not part of the current chunk, reset it to current chunk
      if (!currentContainer || !chunkContainer.contains(currentContainer)) {
        currentContainer = chunkContainer;
      }

      if (
        childNode.hasChildNodes() &&
        BackgroundDomService.isHtmlElement(childNode) &&
        childNode.matches(recursiveSplitSelector) &&
        !isManualPageBreakNode
      ) {
        const innerContainer = this.createInnerContainer(
          rootContainer,
          currentContainer,
          childNode
        );
        // Split child node into chunks recursively
        this.splitNodeIntoChunks(
          childNode,
          maxChunkSize,
          columns,
          columnGap,
          recursiveSplitSelector,
          innerContainer
        );

        // Remove inner container when empty (happens when all the content went into the next chunk)
        if (!innerContainer.hasChildNodes()) {
          innerContainer.remove();
        }
      } else {
        currentContainer.append(childNode);

        const nonPageBreakNodes = [...chunkContainer.childNodes].filter(
          (n) => !this.isAutoPageBreak(n) && !this.isManualPageBreak(n)
        );
        const isChunkContainerOverflowing = (): boolean =>
          chunkContainer.scrollHeight > Math.ceil(maxChunkSize.height) ||
          (columns > 0 &&
            chunkContainer.scrollWidth > Math.ceil(maxChunkSize.width));
        const canSplitNode = (node: Node) =>
          (this.splitParagraphsBetweenPages != 'disabled' &&
            node.nodeName == this.paragraphNodeName) ||
          (this.splitListsBetweenPages &&
            this.listNodeNames.includes(node.nodeName));

        const canBreakPage =
          currentContainer.childElementCount > 1 ||
          (currentContainer.childElementCount == 1 &&
            canSplitNode(currentContainer.firstElementChild));

        // Current chunk content overflows max size or manual page break, create a new chunk
        if (
          canBreakPage &&
          nonPageBreakNodes.length > 0 &&
          (isManualPageBreakNode || isChunkContainerOverflowing())
        ) {
          // Split paragraphs or lists between pages
          if (canSplitNode(childNode) && isChunkContainerOverflowing()) {
            const sourceElement = childNode as HTMLElement;
            let splitItems: Element[];
            if (sourceElement.nodeName == this.paragraphNodeName) {
              const splitFunction =
                this.splitParagraphsBetweenPages == 'words'
                  ? this.splitParagraphIntoLinesUsingWords
                  : this.splitParagraphIntoLinesUsingSelection;

              splitItems = splitFunction.bind(this)(
                sourceElement.outerHTML,
                columns,
                columnGap,
                maxChunkSize
              ) as Element[];
            } else if (this.listNodeNames.includes(sourceElement.nodeName)) {
              splitItems = [...sourceElement.querySelectorAll(':scope > li')];
            }
            if (splitItems.length > 1) {
              const prevPageElement = this.createSplitElement(sourceElement);
              const nextPageElement = this.createSplitElement(sourceElement);
              chunkContainer.append(prevPageElement);
              sourceElement.remove();

              let isOverflowing = false;
              for (const item of splitItems) {
                if (!isOverflowing) {
                  prevPageElement.append(item);
                  if (isChunkContainerOverflowing()) {
                    isOverflowing = true;
                  }
                }
                if (isOverflowing) {
                  nextPageElement.append(item);
                }
              }
              if (prevPageElement.childElementCount > 0) {
                childNode = nextPageElement;
              } else {
                prevPageElement.remove();
              }
            }
          }

          // If manual page break node is overflowing, create a chunk container just to hold its visual
          if (isManualPageBreakNode && isChunkContainerOverflowing()) {
            currentContainer = this.createChunkContainer(
              rootContainer,
              maxChunkSize,
              columns,
              columnGap
            );
            currentContainer.append(childNode);
          }

          const previousContainer = currentContainer;

          // Create a new chunk container inside the root container
          chunkContainer = this.createChunkContainer(
            rootContainer,
            maxChunkSize,
            columns,
            columnGap
          );
          currentContainer = chunkContainer;

          if (!isManualPageBreakNode) {
            // Get all node parents up to the chunk container level
            const parents = this.getNodeParents(
              childNode,
              (n) =>
                BackgroundDomService.isHtmlElement(n) &&
                n.classList.contains(this.chunkContainerClass)
            );

            // Recreate the original dom structure of the child node in the new chunk
            for (const parent of parents) {
              // Create a new inner container with the same id as the original (split into two, preserving the id)
              const innerContainer = this.createInnerContainer(
                rootContainer,
                currentContainer,
                parent as HTMLElement
              );
              currentContainer = innerContainer;
            }

            // Move child node into the newly created chunk
            currentContainer.append(childNode);

            // If element node is still overflowing, mark it will a special attribute
            if (isChunkContainerOverflowing()) {
              if (
                this.splitOverflowParagraphs &&
                childNode.nodeName == this.paragraphNodeName
              ) {
                (childNode as HTMLParagraphElement).setAttribute(
                  ContentPagination.paragraphOverflowAttribute,
                  'true'
                );
              } else {
                (childNode as HTMLElement).setAttribute(
                  ContentPagination.elementOverflowAttribute,
                  'true'
                );
              }
            }
          }

          // Remove margin & padding from previous container as this is the last
          // element on a previous page and these no longer apply
          previousContainer.style.paddingBottom = '0';
          previousContainer.style.marginBottom = '0';
        }
      }
    }

    return rootContainer;
  }

  /**
   * Splits paragraph into lines based on the specified max width
   * @param [paragraphHtml]  Paragraph html content represented as a string
   * @param [columns] Number of content columns
   * @param [columnGap] Gap between columns in points
   * @param [maxSize] Maximum size of the of paragraph after which it will be split
   * @param [prioritize] Accuracy: more precise splitting, but much slower on larger paragraphs
   * @returns Array of paragraph lines
   */
  public static splitParagraphIntoLinesUsingWords(
    paragraphHtml: string,
    columns: number,
    columnGap: number,
    maxSize: Size,
    prioritize: 'accuracy' | 'performance' | 'auto' = 'auto'
  ): Element[] {
    const wordElementTag = 'w';
    const lineElementTag = 'ln';
    const positionDiffThreshold = 5; // Difference in word positions to start a new line

    // With auto, choose accuracy over performance when paragraph contains any styling (span, strong, etc. tags)
    if (prioritize == 'auto') {
      prioritize = /<\/(?!p>)\w+>/.test(paragraphHtml)
        ? 'accuracy'
        : 'performance';
    }

    // Wrap each paragraph word in a <w></w> element
    const wrappedParagraphHtml = paragraphHtml.replace(
      /(?<!(<\/?[^>]*|&[^;]*))(<br>|\s*[^\s<]+\s*)/g,
      `$1<${wordElementTag}>$2</${wordElementTag}>`
    );

    const tempContainer = this.createTempContainer(
      maxSize.width,
      maxSize.height
    );
    if (columns > 0) {
      tempContainer.style.columnCount = columns.toString();
      tempContainer.style.columnFill = 'auto';
      tempContainer.style.columnGap = columnGap + 'pt';
      tempContainer.style.widows = '1';
      tempContainer.style.orphans = '1';
    }
    BackgroundDomService.appendElement(tempContainer);

    const lines: Element[] = [];
    let currentLine: Element = null;
    let previousPos: DOMRect = null;
    let previousWord: Element = null;
    let words: HTMLCollectionOf<Element>;

    if (prioritize == 'accuracy') {
      const wrappedParagraphElement = htmlToElement(wrappedParagraphHtml);
      words = wrappedParagraphElement.getElementsByTagName(wordElementTag);
      currentLine = BackgroundDomService.createElement(lineElementTag);
      lines.push(currentLine);
      tempContainer.append(currentLine);
    } else {
      words = tempContainer.getElementsByTagName(wordElementTag);
      tempContainer.innerHTML = wrappedParagraphHtml;
    }

    const cloneWord = (word: Element): Element => {
      return this.recreateParentStructure(
        word.cloneNode(true),
        word.parentElement
      ) as Element;
    };

    const shouldStartNewLine = (
      word: Element,
      currentPos: DOMRect,
      previousPos: DOMRect
    ): boolean => {
      const positionDiff = previousPos.bottom - currentPos.bottom;
      return (
        Math.abs(positionDiff) > positionDiffThreshold ||
        (word.firstElementChild?.tagName == 'BR' &&
          previousWord?.parentElement?.tagName != 'SPAN' &&
          previousWord?.firstElementChild != null)
      );
    };

    // Loop through each paragraph word
    for (const word of words) {
      // Ignore CKEditor filler lines
      // https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_filler.html
      if (/^\u2060+$/gu.test(word.innerHTML)) {
        continue;
      }
      if (prioritize == 'accuracy') {
        const clonedWord = cloneWord(word);
        currentLine.append(clonedWord);
        const currentPos = clonedWord.getBoundingClientRect();
        // If current word is a line break or its position is significantly different from the last
        // then start a new line and move the current word into it
        if (previousPos && shouldStartNewLine(word, currentPos, previousPos)) {
          currentLine = BackgroundDomService.createElement(lineElementTag);
          currentLine.append(clonedWord);
          lines.push(currentLine);
          tempContainer.append(currentLine);
        }
        previousPos = currentPos;
      } else {
        const currentPos = word.getBoundingClientRect();
        // If current word is a line break or its position is significantly different from the last
        // then start a new line
        if (!previousPos || shouldStartNewLine(word, currentPos, previousPos)) {
          currentLine = BackgroundDomService.createElement(lineElementTag);
          lines.push(currentLine);
        }
        previousPos = currentPos;
        const clonedWord = cloneWord(word);
        currentLine.append(clonedWord);
      }

      previousWord = word;
    }

    // Remove unneeded temp container from dom
    tempContainer.remove();
    return lines;
  }

  /**
   * Splits paragraph into lines based on the specified max width
   * @param [paragraphHtml]  Paragraph html content represented as a string
   * @param [columns] Number of content columns
   * @param [columnGap] Gap between columns in points
   * @param [maxSize] Maximum size of the of paragraph after which it will be split
   * @returns Array of paragraph lines
   */
  public static splitParagraphIntoLinesUsingSelection(
    paragraphHtml: string,
    columns: number,
    columnGap: number,
    maxSize: Size
  ): Element[] {
    const lineElementTag = 'ln';
    const tempContainer = this.createTempContainer(
      maxSize.width,
      maxSize.height
    );
    if (columns > 0) {
      tempContainer.style.columnCount = columns.toString();
      tempContainer.style.columnFill = 'auto';
      tempContainer.style.columnGap = columnGap + 'pt';
      tempContainer.style.widows = '1';
      tempContainer.style.orphans = '1';
    }
    tempContainer.innerHTML = paragraphHtml;
    BackgroundDomService.appendElement(tempContainer);

    const paragraph = tempContainer.firstElementChild as HTMLParagraphElement;
    const selection = BackgroundDomService.window.getSelection();
    selection.removeAllRanges();
    const selectionRange = new Range();
    selectionRange.setStart(paragraph, 0);
    selection.addRange(selectionRange);

    const lines: Element[] = [];
    while (paragraph.innerText.trim().length > 0) {
      // Extend selection to the end of the line to get the line contents
      // This won't include any whitespace at the end of the line
      selection.modify('extend', 'forward', 'lineboundary');

      const lineContents = selection.getRangeAt(0).cloneContents();
      const line = BackgroundDomService.createElement(lineElementTag);
      if (!/^[ \u00A0\u2060]*$/m.test(lineContents.textContent)) {
        // If line has content, recreate its parent structure to preserve styles and add to the list of lines
        let lineContainer = selection.getRangeAt(0).commonAncestorContainer;
        if (lineContainer.nodeType != Node.ELEMENT_NODE) {
          lineContainer = lineContainer.parentElement;
        }
        const styledLineContents = this.recreateParentStructure(
          lineContents,
          lineContainer as Element
        );
        line.append(styledLineContents);
      } else {
        // If there is no content, this means it's a line break, add it as <br>
        line.append(BackgroundDomService.createElement('BR'));
      }
      lines.push(line);

      // Extend selection again for another character in case there is a whitespace at the end of the line
      // And then remove the whole line with whitespace (if present) from the paragraph
      selection.modify('extend', 'forward', 'character');
      selection.getRangeAt(0).deleteContents();
    }

    // Remove unneeded temp container from dom
    tempContainer.remove();
    return lines;
  }

  /**
   * Merges two paragraphs into a single paragraph
   * @returns Single paragraph
   */
  public static mergeParagraphs(
    firstParagraph: Element,
    secondParagraph: Element
  ): Element {
    if (this.splitParagraphsBetweenPages == 'words') {
      // Need to replace first/last &nbsp; with whitespace as that's what CKEditor is doing with its merge operation
      const replaced = this.replaceLastNbspWithWhitespace(firstParagraph);
      if (!replaced) {
        this.replaceFirstNbspWithWhitespace(secondParagraph);
      }
      firstParagraph.innerHTML =
        firstParagraph.innerHTML + secondParagraph.innerHTML;
    } else {
      firstParagraph.innerHTML =
        firstParagraph.innerHTML + ' ' + secondParagraph.innerHTML;
    }
    firstParagraph.removeAttribute(this.elementSplitIdAttribute);
    secondParagraph.remove();
    return firstParagraph;
  }

  /**
   * Merges two lists into a single list
   * @returns Single list element
   */
  public static mergeLists(firstList: Element, secondList: Element): Element {
    firstList.innerHTML = firstList.innerHTML + secondList.innerHTML;
    secondList.remove();
    return firstList;
  }

  /**
   * Merges page chunks back into single raw (non-paged/processed) content element
   * @param [items] Page chunks (output from splitContentIntoPages)
   * @returns Content element
   */
  public static mergePagesIntoRawContent(
    items: ContentPaginationItem[]
  ): HTMLElement {
    const tempContainer = BackgroundDomService.createElement('div');
    for (const item of items) {
      for (const node of item.element.childNodes) {
        const clonedNode = node.cloneNode(true);
        this.mergeNodes(tempContainer, clonedNode);
      }
    }
    tempContainer
      .querySelectorAll(`[id^='${this.innerContainerId}']`)
      .forEach((element) => {
        element.removeAttribute('id');
      });
    return tempContainer;
  }

  private static mergeNodes(parentNode: Node, targetNode: Node): void {
    if (
      !BackgroundDomService.isHtmlElement(parentNode) ||
      !BackgroundDomService.isHtmlElement(targetNode) ||
      !targetNode.id ||
      !targetNode.id.startsWith(this.innerContainerId)
    ) {
      parentNode.appendChild(targetNode);
      return;
    }

    const sourceNode = parentNode.querySelector('#' + targetNode.id);
    if (sourceNode) {
      for (const childNode of targetNode.childNodes) {
        this.mergeNodes(sourceNode, childNode);
      }
    } else {
      parentNode.appendChild(targetNode);
    }
  }

  private static createTempPageContainer(
    html: string,
    document: DocumentDto,
    page: DocumentPageDto,
    subPageIndex: number
  ): HTMLElement {
    const pageBodySize = ExportUtils.calculateBodyPartSize(
      document,
      page,
      'content',
      true,
      subPageIndex
    );
    const pageBodySizePixels = new Size(
      pageBodySize.width * ExportConfig.pointToPixelFactor,
      pageBodySize.height * ExportConfig.pointToPixelFactor
    );

    const tempContainer = this.createTempContainer(
      pageBodySizePixels.width,
      pageBodySizePixels.height
    );

    tempContainer.innerHTML = html ?? '';
    if (page.contentColumns > 0) {
      const columnGap = ExportUtils.calculateHtmlContentGap(document);
      tempContainer.style.columnCount = page.contentColumns.toString();
      tempContainer.style.columnFill = 'auto';
      tempContainer.style.columnGap = columnGap + 'pt';
      tempContainer.style.widows = '1';
      tempContainer.style.orphans = '1';
    }

    return tempContainer;
  }

  private static createTempContainer(
    width?: number,
    height?: number
  ): HTMLElement {
    const tempContainer = BackgroundDomService.createElement('div');
    tempContainer.className = ExportConfig.pageContentClass;
    tempContainer.style.width = width ? width + 'px' : null;
    tempContainer.style.height = height ? height + 'px' : null;
    tempContainer.style.position = 'absolute';
    return tempContainer;
  }

  private static createRootContainer(parentNode: HTMLElement): HTMLElement {
    const rootContainer = BackgroundDomService.createElement('div');
    copyElementAttributes(parentNode, rootContainer);
    rootContainer.classList.add(this.rootContainerClass);
    rootContainer.style.height = '';
    BackgroundDomService.appendElement(rootContainer);
    return rootContainer;
  }

  private static createChunkContainer(
    rootContainer: HTMLElement,
    maxSize: Size,
    columns: number,
    columnGap: number
  ): HTMLElement {
    const chunkContainer = BackgroundDomService.createElement('div');
    chunkContainer.className = this.chunkContainerClass;
    chunkContainer.style.width = maxSize.width + 'px';
    chunkContainer.style.height = maxSize.height + 'px';
    if (columns > 0) {
      chunkContainer.style.columnCount = columns.toString();
      chunkContainer.style.columnFill = 'auto';
      chunkContainer.style.columnGap = columnGap + 'pt';
      chunkContainer.style.widows = '1';
      chunkContainer.style.orphans = '1';
    }
    rootContainer.append(chunkContainer);
    return chunkContainer;
  }

  private static createInnerContainer(
    rootContainer: HTMLElement,
    parentContainer: HTMLElement,
    parentNode: HTMLElement
  ): HTMLElement {
    // Create a new container and copy all the original attributes
    const innerContainer = BackgroundDomService.createElement(
      parentNode.nodeName
    );
    copyElementAttributes(parentNode, innerContainer);
    let id = parentNode.id;
    if (!id) {
      // Set id to the next available one
      const nextId =
        rootContainer.querySelectorAll(`[id^="${this.innerContainerId}"]`)
          .length + 1;
      id = `${this.innerContainerId}-${nextId}`;
    }
    innerContainer.id = id;
    parentContainer.append(innerContainer);
    return innerContainer;
  }

  private static createManualPageBreak(
    type: 'inside' | 'outside'
  ): HTMLElement {
    const pageBreak = BackgroundDomService.createElement('div');
    pageBreak.className = this.manualPageBreakClass;
    pageBreak.setAttribute(this.manualPageBreakTypeAttribute, type);
    return pageBreak;
  }

  private static createSplitElement(sourceElement: HTMLElement): HTMLElement {
    const splitElement = BackgroundDomService.createElement(
      sourceElement.tagName
    );
    copyElementAttributes(sourceElement, splitElement);
    return splitElement;
  }

  /**
   * Get all node parents up to the stop condition
   * @param node
   * @param [stopCondition] When to stop traversing up the element tree
   * @returns
   */
  private static getNodeParents(
    node: Node,
    stopCondition: (n: Node) => boolean
  ): Node[] {
    const parents: Node[] = [];
    while (node.parentNode && !stopCondition(node.parentNode)) {
      node = node.parentNode;
      parents.splice(0, 0, node);
    }
    return parents;
  }

  /**
   * Recreate node's structure using parent element as template to preserve the styles
   */
  private static recreateParentStructure(
    node: Node,
    parent: Element,
    stopNodeName: string = this.paragraphNodeName
  ): Node {
    while (parent && parent.nodeName != stopNodeName) {
      const container = parent.cloneNode();
      container.appendChild(node);
      node = container;
      parent = parent.parentElement;
    }
    return node;
  }

  /**
   * Prepare raw editor content for splitting & measuring
   * @param [contentHtml] Raw editor output
   */
  private static prepareRawContent(contentHtml: string): string {
    if (!contentHtml) {
      return '';
    }
    if (this.splitParagraphsBetweenPages == 'words') {
      // Remove all line breaks to avoid interfering with white-space: break-spaces
      contentHtml = contentHtml.replaceAll('\n', '');
    }
    return contentHtml;
  }

  /**
   * Replaces first occurence of &nbsp; in paragraph with whitespace (' ')
   */
  private static replaceFirstNbspWithWhitespace(paragraph: Element): boolean {
    if (paragraph.textContent[0] == String.fromCharCode(160)) {
      paragraph.innerHTML = paragraph.innerHTML.replace(/&nbsp;/, ' ');
      return true;
    }
    return false;
  }

  /**
   * Replaces last occurence of &nbsp; in paragraph with whitespace (' ')
   */
  private static replaceLastNbspWithWhitespace(paragraph: Element): boolean {
    if (
      paragraph.textContent[paragraph.textContent.length - 1] ==
      String.fromCharCode(160)
    ) {
      paragraph.innerHTML = paragraph.innerHTML.replace(
        /&nbsp;(?!.*&nbsp;)/,
        ' '
      );
      return true;
    }
    return false;
  }
}
