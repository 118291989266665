export enum DefaultColors {
  BLUE = '#4985e7',
  RED = '#FF0309',
  ORANGE = '#FF980C',
  BLACK = '#000000',
  WHITE = '#FFFFFF',
  LIGHT_GREY = '#E3E3E3',
  GREY = '#B3B3B3',
  TRANSPARENT = 'TRANSPARENT',
  TURQUOISE = '#71e8e4',
}
