<template>
  <div>
    <transition
      enter-active-class="transition duration-200"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transform duration-100 transition"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="value"
        class="pointer-events-auto fixed inset-0 z-100 h-full w-full bg-black bg-opacity-50"
        @click="[closeOnBackdrop && backdropClick()]"
      />
    </transition>

    <!-- TODO: move transition to it's own component -->
    <transition
      enter-active-class="transform duration-200 delay-100 transition"
      enter-class="opacity-0 -translate-y-24"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transform duration-100 transition"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-24"
    >
      <div
        v-show="value"
        class="pointer-events-none fixed inset-0 z-100 m-auto flex h-full w-fit items-start justify-center overflow-y-hidden"
      >
        <div class="relative mt-96 flex flex-col items-center">
          <div
            class="modal__main pointer-events-auto relative rounded-8 bg-white p-36 shadow-md"
            :style="modalStyles"
            @click.stop
          >
            <div class="mb-24 flex items-center">
              <AppIcon
                v-if="showIcon"
                :name="iconName"
                size="32"
                :class="iconClass"
                class="-mt-2 mr-16"
              />
              <h2 class="flex-grow text-26">{{ title }}</h2>
            </div>

            <div :class="bodyClasses">
              <VNodeWrapper
                v-if="isContextVNode || isContextRenderFunction"
                :value="getValue()"
              />
              <div v-else>
                {{ text }}
              </div>
            </div>

            <div class="mt-24 flex w-full items-center justify-end">
              <template v-if="customButtons">
                <AppButton
                  v-for="(button, index) in customButtons"
                  :key="index"
                  size="lg"
                  :variant="button.variant"
                  :full-width="button.fullWidth"
                  class="mr-12 last:mr-0"
                  @click="onActionButtonClick(button.action)"
                >
                  {{ button.text }}
                </AppButton>
              </template>

              <template v-else>
                <AppButton
                  size="lg"
                  :variant="okVariant"
                  class="mr-12"
                  @click="ok"
                >
                  {{ okText }}
                </AppButton>
                <AppButton v-if="isConfirm" size="lg" @click="close">
                  {{ denyText }}
                </AppButton>
              </template>
            </div>
          </div>

          <!-- Seasonal Theme addons -->
          <img
            v-if="isChristmas"
            class="christmas-socks absolute -bottom-96"
            src="/christmas-media/christmas-socks.svg"
          />

          <img
            v-if="isThanksgiving"
            class="absolute -top-24 left-16 w-56"
            src="/thanksgiving-media/thanksgiving-decoration-1.svg"
          />

          <lottie-player
            v-if="isHalloween && value"
            src="/halloween-media/jigsaw-spider-animation.json"
            background="transparent"
            speed="1"
            class="pointer-events-none absolute top-full h-174 w-174"
            autoplay
          ></lottie-player>
          <!-- Seasonal Theme addons end -->
        </div>
      </div>
    </transition>
  </div>
  <!-- :class="[{ 'animate-wiggle': isDisallowAnimation }, modalClasses]" -->
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TMsgBoxTypes, ICustomButtons } from './index';
import { isVNode } from '@/core/utils/common.utils';
import i18n from '@/core/plugins/vue-i18n';
import { Getter } from 'vuex-class';
import {
  GET_CHRISTMAS_THEME,
  GET_HALLOWEEN_THEME,
  GET_THANKSGIVING_THEME,
  GLOBAL_NAMESPACE,
} from '@/core/services/store/global.module';
import { VNode } from 'vue';
import { CSSProperties } from 'vue/types/jsx';

const iconClasses = {
  info: 'text-accent-purple',
  warning: 'text-warning-orange',
  success: 'text-success-green',
  error: 'text-danger-red',
  confirm: 'text-danger-red',
};

const iconTypes = {
  info: 'notification-info',
  warning: 'notification-warning',
  success: 'notification-success',
  error: 'notification-error',
  confirm: 'notification-alert',
};

const confirmButtonText = () => i18n.t('YES').toString();
const rejectButtonText = () => i18n.t('NO').toString();

@Component({
  name: 'Confirm',
})
export default class Confirm extends Vue {
  @Prop({ default: 'info' })
  type: TMsgBoxTypes;

  @Prop({ required: true })
  title: string;

  @Prop({ required: true })
  text: string | Function;

  @Prop({ default: rejectButtonText })
  denyText: string;

  @Prop({ default: confirmButtonText })
  okText: string;

  @Prop({ default: 'purple' })
  okVariant: string;

  @Prop({ default: false })
  closeOnEsc: boolean;

  @Prop({ default: false })
  closeOnBackdrop: boolean;

  @Prop({ default: null })
  promise: { resolve: (value: boolean | string) => void };

  @Prop({ default: '' })
  bodyClasses: string;

  @Prop({ default: 560 })
  minWidth: string | number;

  @Prop({ default: 560 })
  maxWidth: string | number;

  @Prop({ default: 560 })
  isConfirm: string | number;

  @Prop({ default: null })
  customButtons: ICustomButtons[];

  @Prop({ default: true })
  showIcon: boolean;

  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isHalloween: boolean;

  @Getter(GET_THANKSGIVING_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isThanksgiving: boolean;

  @Getter(GET_CHRISTMAS_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isChristmas: boolean;

  value = false;

  get iconClass(): string {
    return this.isConfirm ? iconClasses.confirm : iconClasses[this.type];
  }

  get iconName(): string {
    return this.isConfirm ? iconTypes.confirm : iconTypes[this.type];
  }

  get isContextVNode(): boolean {
    return isVNode(this.text);
  }

  get isContextRenderFunction(): boolean {
    return typeof this.text === 'function';
  }

  get modalStyles(): CSSProperties {
    return {
      minWidth: `${this.minWidth}px`,
      maxWidth: `${this.maxWidth}px`,
    };
  }

  backdropClick(): void {
    this.close();
  }

  ok(): void {
    this.value = false;
    setTimeout(() => {
      this.promise.resolve(true);
    }, 100);
  }

  close(): void {
    this.value = false;
    setTimeout(() => {
      this.promise.resolve(false);
    }, 100);
  }

  onActionButtonClick(action: string): void {
    this.value = false;
    setTimeout(() => {
      this.promise.resolve(action);
    }, 100);
  }

  mounted(): void {
    this.$nextTick(() => {
      this.value = true;
    });

    const escHandler = (evt: KeyboardEvent) => {
      if (!this.closeOnEsc) return;

      if (evt.key === 'Escape' && this.value) {
        this.close();
      }
    };

    document.addEventListener('keydown', escHandler);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler);
    });
  }

  getValue(): string | VNode {
    if (this.isContextVNode) {
      return this.text as string;
    } else if (
      this.isContextRenderFunction &&
      typeof this.text === 'function'
    ) {
      return this.text(this.$createElement);
    }
  }
}
</script>
