﻿import Vue from 'vue';

import appConsts from '../config/appConsts';

import i18n from '@/core/plugins/vue-i18n';
import { INode, IEdge, SvgVisual, SvgVisualGroup } from 'yfiles';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

import { measureText } from './html.utils';
import { randomNumber } from './common.utils';
import DiagramUtils from './DiagramUtils';

import {
  CreateOrEditDataPropertyDto,
  DataPropertyDefinitionDto,
  DataPropertyDefinitionItemDto,
  DataPropertyDto,
  DataPropertyType,
  ThemeDataPropertyItemDto,
} from '@/api/models';
import {
  DATAPROPERTYDEFINITIONS_NAMESPACE,
  GET_ALL_DATAPROPERTYDEFINITIONS,
} from '@/core/services/store/datapropertydefinitions.module';

import JurisdictionDecorator from '../styles/decorators/JurisdictionDecorator';

export enum StaticDataPropertyNames {
  Jurisdiction = 'JURISDICTION',
  State = 'STATE',
}

export default class DataPropertyUtils {
  public static getDataPropertiesOptionColor(color: string): string {
    const colors = [
      '#DDFAC7',
      '#C2FAF4',
      '#F5DAFD',
      '#C1C5F7',
      '#EAF5C6',
      '#C7FADD',
      '#C2E4FA',
      '#FAC7E8',
      '#F7C1C1',
      '#FAECC7',
    ];

    const index = color ? colors.indexOf(color) : -1;
    const nextColorIndex = (index + 1) % colors.length;

    return colors[nextColorIndex];
  }

  public static applyThemeDataPropertyItemStyle(
    item: DataPropertyDefinitionItemDto,
    themeDataPropertyItems: ThemeDataPropertyItemDto[]
  ): DataPropertyDefinitionItemDto {
    let clonedItem = cloneDeep(item);
    if (themeDataPropertyItems) {
      const themeDataPropertyItemStyle = themeDataPropertyItems.find(
        (x) => x.dataPropertyDefinitionItemId == clonedItem.id
      );

      if (themeDataPropertyItemStyle) {
        clonedItem.itemValue = themeDataPropertyItemStyle.itemValue;
        clonedItem.imageData = themeDataPropertyItemStyle.imageData;
        clonedItem.itemInitials = themeDataPropertyItemStyle.itemInitials;
        clonedItem.customized = true;
      }
    }

    return clonedItem;
  }

  public static getDisplayValue(
    value: any,
    definition: DataPropertyDefinitionDto,
    fallbackValue = '...'
  ): string {
    const displayValueArray = DataPropertyUtils.getDisplayValueAsArray(
      value,
      definition
    );
    if (displayValueArray.length > 0) {
      return displayValueArray.join(', ');
    }
    return fallbackValue;
  }

  public static getDisplayValueAsArray(
    value: any,
    definition: DataPropertyDefinitionDto
  ): string[] {
    if (definition == null) {
      return [value];
    }
    let displayValue = null;

    let isOptionsControl = DataPropertyUtils.isOptionsControl(
      definition.controlType
    );
    let isMultiSelectControl = DataPropertyUtils.isMultiSelectControl(
      definition.controlType
    );

    let isPeopleControl = DataPropertyUtils.isPeopleControl(
      definition.controlType
    );
    if (isOptionsControl && value) {
      if (
        definition.controlType == DataPropertyType.Text ||
        definition.controlType == DataPropertyType.Number ||
        definition.controlType == DataPropertyType.Date
      ) {
        if (typeof value === 'boolean') {
          displayValue = i18n.t(value.toString().toUpperCase());
        } else {
          displayValue = value;
        }
      } else if (isMultiSelectControl || isPeopleControl) {
        let definitionValues = definition.dataPropertyDefinitionItems;
        if (Array.isArray(value)) {
          displayValue = value.map(
            (val) => definitionValues.find((x) => x.id == val).itemValue
          );
        } else {
          displayValue = definitionValues.find((x) => x.id == value)?.itemValue;
        }
      } else if (value) {
        displayValue = definition.dataPropertyDefinitionItems.find(
          (x) => x.id == value
        )?.itemValue;
      }
    } else {
      displayValue = value;
    }

    if (!displayValue) {
      return [];
    } else if (!Array.isArray(displayValue)) {
      return [displayValue];
    }
    return displayValue;
  }

  public static getDefinitionItemByDefinitionId(
    node: INode,
    definitionId: number
  ): DataPropertyDefinitionItemDto {
    let dp = node.tag.dataProperties.find(
      (dp) => dp.dataPropertyDefinitionId == definitionId
    );
    if (!dp) return null;

    let dpd = DataPropertyUtils.getDefinition(dp.dataPropertyDefinitionId);
    return dpd.dataPropertyDefinitionItems.find((i) => i.id == dp.value);
  }

  public static getDefinition(
    dataPropertyDefinitionId: number
  ): DataPropertyDefinitionDto {
    return DataPropertyUtils.getAllDefinitions()?.find(
      (d) => d.id == dataPropertyDefinitionId
    );
  }

  public static getAllDefinitions(): DataPropertyDefinitionDto[] {
    return Vue.$globalStore.getters[
      `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${GET_ALL_DATAPROPERTYDEFINITIONS}`
    ];
  }

  public static getDiagramNodeIdOrNull(selectedElement): number | null {
    return selectedElement instanceof INode ? selectedElement.tag?.id : null;
  }

  public static getDiagramEdgeIdOrNull(selectedElement): number | null {
    return selectedElement instanceof IEdge ? selectedElement.tag?.id : null;
  }

  public static hasChildren(
    dataPropertyDefinitionItem: DataPropertyDefinitionItemDto
  ): boolean {
    return this.getAllDefinitions().some((x) =>
      x.dataPropertyDefinitionItems.some(
        (p) => p.parentItemId == dataPropertyDefinitionItem.id
      )
    );
  }

  public static getChildren(
    dataPropertyDefinitionId: number,
    dataPropertyDefinitionItem: DataPropertyDefinitionItemDto
  ): DataPropertyDefinitionItemDto[] {
    const definition = this.getAllDefinitions().find(
      (x) => x.id == dataPropertyDefinitionId
    );
    if (!definition) {
      return [];
    }

    return definition.dataPropertyDefinitionItems.filter(
      (x) => x.parentItemId == dataPropertyDefinitionItem.id
    );
  }

  public static getDefinitionItemByDataPropertyName(
    node: INode,
    dataPropertyName: string
  ): DataPropertyDefinitionItemDto {
    let element = node.tag.dataProperties
      .map((dp) => {
        return {
          dp: dp,
          dpd: DataPropertyUtils.getDefinition(dp.dataPropertyDefinitionId),
        };
      })
      .find((x) => x.dpd?.label === dataPropertyName);

    if (element) {
      const definitionItem = element.dpd.dataPropertyDefinitionItems.find(
        (definitionItem) => definitionItem.id == element.dp.value
      );
      return definitionItem;
    }
    return null;
  }

  public static getValueByDataPropertyName(
    node: INode,
    dataPropertyName: string
  ): string {
    const dpDefinition = this.getDefinitionItemByDataPropertyName(
      node,
      dataPropertyName
    );
    if (dpDefinition) {
      return dpDefinition?.itemValue;
    }
    return null;
  }

  public static getImageByDataPropertyName(
    node: INode,
    dataPropertyName: string
  ): string {
    const dpDefinition = this.getDefinitionItemByDataPropertyName(
      node,
      dataPropertyName
    );
    if (dpDefinition) {
      return dpDefinition?.imageData;
    }
    return null;
  }

  public static getDataPropertyFromNode(
    node: INode,
    definitionId: number
  ): DataPropertyDto | CreateOrEditDataPropertyDto {
    const dataProperties = node.tag.dataProperties;
    if (!dataProperties || dataProperties.length == 0) {
      return null;
    }
    return dataProperties.find(
      (x) => x.dataPropertyDefinitionId == definitionId
    );
  }

  public static createStateInitialsCircleSvgVisual(
    stateInitials: string,
    size: number,
    fontSize: number, //originally 10,
    circleX: number, //originally 15
    circleY: number, //originally 9.5
    textOffsetX: number, // originally 2.25
    textOffsetY: number, // originally 4.5
    strokeWidth: number = 1
  ): SvgVisual {
    const circleSvg = JurisdictionDecorator.INSTANCE.createCircleSVG(
      size,
      strokeWidth
    );
    const stateInitialsSvg = DiagramUtils.createSvgFromText(
      stateInitials,
      fontSize
    );

    const fontFamily = 'Arial';
    const html = `<div style="font-size: ${fontSize}px;font-family:${fontFamily}">${stateInitials}</div>`;
    const textSize = measureText(html);

    SvgVisual.setTranslate(circleSvg, circleX, circleY);

    SvgVisual.setTranslate(
      stateInitialsSvg,
      circleX - textSize.width / textOffsetX,
      circleY + textSize.height / textOffsetY
    );

    const circleSvgVisual = new SvgVisual(circleSvg);
    const stateInitialsSvgVisual = new SvgVisual(stateInitialsSvg);

    const group = new SvgVisualGroup();
    group.add(circleSvgVisual);
    group.add(stateInitialsSvgVisual);

    return group;
  }

  public static isOptionsControl(type: DataPropertyType): boolean {
    switch (+type) {
      case DataPropertyType.Dropdown:
      case DataPropertyType.MultiSelect:
      case DataPropertyType.Text:
      case DataPropertyType.Number:
      case DataPropertyType.Date:
      case DataPropertyType.People:
        return true;
    }
    return false;
  }

  public static isVisualizedDataProperty(
    dataProperty: DataPropertyDto | CreateOrEditDataPropertyDto
  ): boolean {
    //IsHidden and IsIncluded may be undefined, but when not, we should obey
    if (
      dataProperty?.isHidden ||
      (!isNil(dataProperty?.isIncluded) && !dataProperty.isIncluded)
    ) {
      return false;
    }
    return (
      this.isSymbolDataPropertyDefinition(
        dataProperty.dataPropertyDefinitionId
      ) ||
      this.isJurisidictionOrStateDataPropertyDefinition(
        dataProperty.dataPropertyDefinitionId
      )
    );
  }

  public static isJurisidictionOrStateDataPropertyDefinition(
    dataPropertyDefinition: number
  ): boolean {
    return (
      dataPropertyDefinition == appConsts.JURISDICTION_DEFINITION_ID ||
      dataPropertyDefinition == appConsts.STATE_DEFINITION_ID
    );
  }

  public static isSymbolDataPropertyDefinition(
    dataPropertyDefinitionId: number
  ): boolean {
    return (
      dataPropertyDefinitionId === appConsts.CIRCLE_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.CROSS_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.DAGGER_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.PLUS_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.SQUARE_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.STAR_1_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.STAR_2_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.STAR_3_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.STATE_DEFINITION_ID ||
      dataPropertyDefinitionId === appConsts.TRIANGLE_DEFINITION_ID
    );
  }

  public static isMultiSelectControl(type: DataPropertyType): boolean {
    switch (+type) {
      case DataPropertyType.MultiSelect:
      case DataPropertyType.People:
        return true;
    }
    return false;
  }

  public static isPeopleControl(type: DataPropertyType): boolean {
    return type === DataPropertyType.People;
  }

  public static isDataPropertyDefinitionItemIncluded(
    dpCollection: DataPropertyDto[],
    dataPropertyDefinitionItemId: number,
    dataPropertyDefinitions: DataPropertyDefinitionDto[]
  ): boolean {
    let definitionItem: DataPropertyDefinitionItemDto = null;
    return dpCollection.some((dp) => {
      /* lookup datapropertydefinition */
      const dpd = dataPropertyDefinitions.find(
        (dpd) => dpd.id == dp.dataPropertyDefinitionId
      );
      /* when dp is not jurisdiction */
      if (typeof dp.value == 'boolean') {
        return (
          dpd.dataPropertyDefinitionItems[0].id == dataPropertyDefinitionItemId
        );
      } else {
        definitionItem = dpd?.dataPropertyDefinitionItems.find(
          (i) => i.id == dp.value
        );
        return definitionItem?.id == dataPropertyDefinitionItemId;
      }
    });
  }

  public static filterDataProperties(
    search: string,
    definitions: DataPropertyDefinitionItemDto[]
  ): DataPropertyDefinitionItemDto[] {
    const searchTerm = search?.trim().toLowerCase();
    if (search.length) {
      return definitions.filter(
        (item) =>
          item?.aliases?.toLowerCase().includes(searchTerm) ||
          item.itemValue.toLowerCase().includes(searchTerm)
      );
    } else {
      return definitions;
    }
  }
}
