import {
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder,
  Class,
  ILabel,
  ILabelModelParameter,
  IOrientedRectangle,
  OrientedRectangle,
  ILookup,
  BaseClass,
  IEnumerable,
  List,
  INode,
  Point,
  Size,
} from 'yfiles';
import TextBoxLabelModelParameter from './TextBoxLabelModelParameter';

export default class TextBoxLabelModel extends BaseClass<
  ILabelModel,
  ILabelModelParameterProvider,
  ILabelModelParameterFinder
>(ILabelModel, ILabelModelParameterProvider, ILabelModelParameterFinder) {
  lookup<T extends any>(type: Class<T>): T {
    if (
      type === ILabelModelParameterProvider.$class ||
      type === ILabelModelParameterFinder.$class
    ) {
      return this as unknown as T;
    }
    return null;
  }

  getGeometry(
    label: ILabel,
    layoutParameter: ILabelModelParameter
  ): IOrientedRectangle {
    const ownerNode = label.owner;
    if (!(ownerNode instanceof INode)) {
      return IOrientedRectangle.EMPTY;
    }
    const nodeLayout = ownerNode.layout.toRect().toMutableRectangle();

    const labelWidth = nodeLayout.width;
    const labelHeight = nodeLayout.height;
    const anchor = new Point(nodeLayout.x, nodeLayout.y + labelHeight);
    return new OrientedRectangle(
      anchor.x,
      anchor.y,
      labelWidth,
      labelHeight,
      nodeLayout.upX,
      nodeLayout.upY
    );
  }

  getContext(label: ILabel, parameter: ILabelModelParameter): ILookup {
    return ILookup.EMPTY;
  }

  createDefaultParameter(): ILabelModelParameter {
    return new TextBoxLabelModelParameter(this);
  }

  getParameters(
    label: ILabel,
    model: ILabelModel
  ): IEnumerable<ILabelModelParameter> {
    return new List<ILabelModelParameter>();
  }

  findBestParameter(
    label: ILabel,
    model: ILabelModel,
    layout: IOrientedRectangle
  ): ILabelModelParameter {
    return new TextBoxLabelModelParameter(this);
  }
}
