import {
  NotificationTypes,
  RealtimeMessageDto,
  UsersRealtimeMessageDto,
} from '@/api/models';
import appConfig from '@/core/config/appConfig';
import SignalRHelper from './SignalRHelper';
import { TNotificationTypes } from '@/components/shared/AppNotification';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import { notify } from '@/components/shared/AppNotification';

type InvokeMethods = 'SendMessageToAll' | 'SendMessageToUsers';
type OnHandlers = 'RealTimeMessage';

class RealTimeMessageService {
  private signalRHelper: SignalRHelper<InvokeMethods, OnHandlers>;

  public start(): void {
    this.signalRHelper = new SignalRHelper<InvokeMethods, string>(
      appConfig.realTime.document.endpoint
    );
    this.signalRHelper.start();
    this.signalRHelper.on('RealTimeMessage', this.onRealTimeMessage);
  }

  private onRealTimeMessage(message: any): void {
    this.notify(message);
    this.message(message);
  }

  private mapMessageTypeToNotificationType(
    notificationType: NotificationTypes
  ): TNotificationTypes {
    switch (notificationType) {
      case NotificationTypes.Success:
        return 'success';
      case NotificationTypes.Info:
        return 'info';
      case NotificationTypes.Warning:
        return 'warning';
      case NotificationTypes.Error:
        return 'error';
    }
  }

  private notify(message: RealtimeMessageDto): void {
    notify({
      title: message.title,
      type: this.mapMessageTypeToNotificationType(message.notificationType),
    });
  }

  private message(message: RealtimeMessageDto): void {
    EventBus.$emit(EventBusActions.REALTIME_MESSAGE, message);
  }

  public SendMessageToAll(message: RealtimeMessageDto): void {
    this.signalRHelper.invoke('SendMessageToAll', message);
  }

  public SendMessageToUsers(message: UsersRealtimeMessageDto): void {
    this.signalRHelper.invoke('SendMessageToUsers', message);
  }
}
const instance = new RealTimeMessageService();
export default instance;
