import {
  DiagramDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  DocumentDto,
  DocumentPageDto,
} from '@/api/models';
import IPageSyncItem from './IPageSyncItem';
import { PageSyncChangeFinder } from './PageSyncChangeFinder';
import DocumentService from '../document/DocumentService';
import cloneDeep from 'lodash/cloneDeep';
import { notify } from '@/components/shared/AppNotification';
import i18n from '@/core/plugins/vue-i18n';
import PageSyncChange from './PageSyncChange';
import { PageSyncChangeType } from './PageSyncChangeType';
import SerializedDiagramUtils from './SerializedDiagramUtils';
import IDisposable from '@/core/common/IDisposable';
import { PageSyncCommand } from './PageSyncCommand';
import { PageSyncCommandType } from './PageSyncCommandType';
import { generateUuid } from '@/core/utils/common.utils';
import FeaturesService from '../FeaturesService';
import { Features } from '@/core/common/Features';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import IGraphService from '@/v2/services/interfaces/IGraphService';

export default class PageSyncContext implements IDisposable {
  public isDisposed: boolean;
  public document: DocumentDto;
  public page: DocumentPageDto;
  public items: IPageSyncItem[] = [];
  public pendingCommands: PageSyncCommand[] = [];
  public appliedCommands: PageSyncCommand[] = [];
  public sourceNodeSnapshots: DiagramNodeDto[] = [];
  private _enabled = false;
  private _active = false;
  private _graphService: IGraphService;

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    if (!value) {
      this.active = false;
    }
    this._enabled = value;
  }

  public get active(): boolean {
    return this._active;
  }

  public set active(newValue: boolean) {
    if (!this.active && newValue) {
      this.activate();
    } else if (this.active && !newValue) {
      this.deactivate();
    }
    this._active = newValue;
  }

  constructor(
    document: DocumentDto,
    page: DocumentPageDto,
    graphService: IGraphService
  ) {
    this.document = document;
    this.page = page;
    this._graphService = graphService;
    this.reset();
  }

  public dispose(): void {
    if (this.isDisposed) return;
    this.active = false;
    this.enabled = false;
    this.isDisposed = true;
  }

  private activate(): void {
    this.refreshItems();
    EventBus.$emit(EventBusActions.DOCUMENT_PAGES_SYNCED, [this.page]);
  }

  private deactivate(): void {
    const isModified = this.items.some(
      (syncItem) => syncItem.isModifiedInCurrentContext
    );
    const modifiedPages: DocumentPageDto[] = [];
    if (isModified) {
      this.items.forEach((i) => {
        if (i.isModifiedInCurrentContext) {
          i.targetPage.diagram.nodes.forEach((n) => (n.highlight = false));
          i.targetPage.diagram.edges.forEach((e) => (e.highlight = false));
          modifiedPages.push(i.targetPage);
          i.isModifiedInCurrentContext = false;
        }
      });
      notify({
        title: i18n.t('CHANGES_MIRRORED_TO_DUPLICATES'),
      });
    }

    // Reset current page
    this._graphService.graph.nodes.forEach((n) => (n.tag.highlight = false));
    this._graphService.graph.edges.forEach((e) => (e.tag.highlight = false));
    this.page.diagram.nodes.forEach((n) => (n.highlight = false));
    this.page.diagram.edges.forEach((e) => (e.highlight = false));
    modifiedPages.push(this.page);
    EventBus.$emit(EventBusActions.DOCUMENT_PAGES_SYNCED, modifiedPages);

    this.refreshItems();
  }

  public reset(): void {
    const group = DocumentService.getCommonDiagramGroupFromPage(
      this.document,
      this.page
    );
    if (
      !DocumentService.isReadOnly &&
      FeaturesService.hasFeature(Features.Mirroring)
    ) {
      const isInCommonGroupWithDescendants =
        group && group.lastCommonDiagram.id != this.page.diagramId;
      this.enabled = isInCommonGroupWithDescendants;
    } else {
      this.enabled = false;
    }
    this.active = false;
  }

  public refreshCommands(oldDiagram: DiagramDto, newDiagram: DiagramDto): void {
    const nodeCommands = this.refreshNodeCommands(oldDiagram, newDiagram);
    const edgeCommands = this.refreshEdgeCommands(oldDiagram, newDiagram);
    const newCommands: PageSyncCommand[] = [...nodeCommands, ...edgeCommands];

    if (newCommands.length > 0) {
      this.updateAppliedCommands(newCommands);
    }

    this.pendingCommands = newCommands;
  }

  private refreshItems(): void {
    this.clearCommands();
    this.items = [];
    const commonNodes = DocumentService.getCommonNodesForCurrentDiagram(
      null,
      null,
      false
    );
    this.setSourceNodeSnapshots(commonNodes);

    if (this.sourceNodeSnapshots.length > 0) {
      for (const page of this.document.pages) {
        if (!page.diagram || page == this.page) {
          continue;
        }
        const targetNodeSnapshots = commonNodes
          .filter((n) => page.diagram.nodes.includes(n))
          .map((n) => cloneDeep(n));

        const targetEdgeSnapshots = SerializedDiagramUtils.findEdgesByNodes(
          page.diagram.edges,
          targetNodeSnapshots
        ).map((e) => cloneDeep(e));

        if (targetNodeSnapshots.length > 0) {
          this.items.push({
            active: false,
            targetPage: page,
            targetNodeSnapshots: targetNodeSnapshots,
            targetEdgeSnapshots: targetEdgeSnapshots,
            isModifiedInCurrentContext: false,
          });
        }
      }
    }
  }

  private updateAppliedCommands(newCommands: PageSyncCommand[]): void {
    newCommands.forEach((command) => {
      const existingCommand = this.appliedCommands.find(
        (c) => c.id === command.id
      );
      if (!existingCommand) {
        this.appliedCommands.push(command);
      } else {
        const changes = command.changes.filter(
          (change) => !existingCommand.changes.some((ch) => ch.equals(change))
        );
        existingCommand.changes.push(...changes);
        existingCommand.updatedItem = command.updatedItem;
      }
    });
  }

  private setSourceNodeSnapshots(commonNodes: DiagramNodeDto[]): void {
    this.sourceNodeSnapshots = commonNodes
      .filter((n) => this.page.diagram.nodes.includes(n))
      .map((n) => cloneDeep(n));
  }

  private clearCommands(): void {
    this.appliedCommands = [];
    this.pendingCommands = [];
  }

  private refreshNodeCommands(
    oldDiagram: DiagramDto,
    newDiagram: DiagramDto
  ): PageSyncCommand[] {
    const newCommands: PageSyncCommand[] = [];

    for (const oldNode of oldDiagram.nodes) {
      let changes: PageSyncChange[] = [];
      const newNode = newDiagram.nodes.find((n) => n.uuid === oldNode.uuid);

      changes = PageSyncChangeFinder.findNodeChanges(oldNode, newNode);

      if (changes.length > 0) {
        const sourceNodeSnapshot = this.sourceNodeSnapshots.find(
          (n) => n.uuid === oldNode.uuid
        );
        newCommands.push({
          // SourceNodeSnapshot must be kept until the end of editing
          type: PageSyncCommandType.NodePageSyncCommand,
          originalItem: sourceNodeSnapshot ?? oldNode,
          updatedItem: newNode,
          changes: changes,
          id: newNode?.uuid ?? oldNode.uuid,
        });

        if (this.active) {
          this.setNodeHighlight(newNode);
        }
      }
    }

    // Check if new nodes were added
    const newNodes = PageSyncChangeFinder.findAddedNodes(
      oldDiagram,
      newDiagram
    );
    for (const newNode of newNodes) {
      newCommands.push({
        type: PageSyncCommandType.NodePageSyncCommand,
        originalItem: null,
        updatedItem: newNode,
        changes: [new PageSyncChange(PageSyncChangeType.AddNode)],
        // Add command should stay separate and have unique id
        id: generateUuid(),
      });

      if (this.active) {
        this.setNodeHighlight(newNode);
      }
    }

    return newCommands;
  }

  private refreshEdgeCommands(
    oldDiagram: DiagramDto,
    newDiagram: DiagramDto
  ): PageSyncCommand[] {
    const newCommands: PageSyncCommand[] = [];
    for (const oldEdge of oldDiagram.edges) {
      let changes: PageSyncChange[] = [];
      const newEdge = newDiagram.edges.find((e) => e.uuid === oldEdge.uuid);

      changes = PageSyncChangeFinder.findEdgeChanges(oldEdge, newEdge);

      if (changes.length > 0) {
        newCommands.push({
          type: PageSyncCommandType.EdgePageSyncCommand,
          originalItem: oldEdge,
          updatedItem: newEdge,
          changes: changes,
          id: newEdge?.uuid ?? oldEdge.uuid,
        });
        if (this.active) {
          this.setEdgeHighlight(newEdge);
        }
      }
    }

    // Check if new edges were added
    const newEdges = PageSyncChangeFinder.findAddedEdges(
      oldDiagram,
      newDiagram
    );
    for (const newEdge of newEdges) {
      newCommands.push({
        type: PageSyncCommandType.EdgePageSyncCommand,
        originalItem: null,
        updatedItem: newEdge,
        changes: [new PageSyncChange(PageSyncChangeType.AddEdge)],
        // Add command should stay separate and have unique id
        id: generateUuid(),
      });
      if (this.active) {
        this.setEdgeHighlight(newEdge);
      }
    }

    return newCommands;
  }

  private setNodeHighlight(item: DiagramNodeDto): void {
    if (!item) {
      return;
    }
    this._graphService.graph.nodes.find(
      (n) => n.tag.uuid === item.uuid
    ).tag.highlight = true;
    item.highlight = true;
  }

  private setEdgeHighlight(item: DiagramEdgeDto): void {
    if (!item) {
      return;
    }
    this._graphService.graph.edges.find(
      (e) => e.tag.uuid === item.uuid
    ).tag.highlight = true;
    item.highlight = true;
  }
}
