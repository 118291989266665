export const LogoutConfirmationConfig = {
  content: 'LOGGING_OUT_WILL_ALSO_CLOSE',
  config: {
    title: 'LOGOUT_QUESTIONMARK',
    size: 'sm',
    buttonSize: 'sm',
    okVariant: 'success',
    okTitle: 'YES',
    cancelTitle: 'NO',
    footerClass: 'p-2',
    hideHeaderClose: false,
    centered: true,
  },
};
