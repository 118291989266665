import axios, {
    AxiosResponse
} from 'axios';
import {
    DataPropertyDefinitionDto,
    ZadarResponse,
    DataPropertyValueScope,
    GetDataPropertyDefinitionsByDocumentIdOutput,
    GetDataPropertyDefinitionForViewDto,
    GetDataPropertyDefinitionForEditOutput,
    CreateOrEditDataPropertyDefinitionDto
} from './models'
export default class DataPropertyDefinitionsApiService {
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DataPropertyDefinitionDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getDataPropertyDefinitionsByScope(params ? : {
        scope ? : DataPropertyValueScope
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DataPropertyDefinitionDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/GetDataPropertyDefinitionsByScope`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDataPropertyDefinitionsByDocumentId(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDataPropertyDefinitionsByDocumentIdOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/GetDataPropertyDefinitionsByDocumentId`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDataPropertyDefinitionForView(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDataPropertyDefinitionForViewDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/GetDataPropertyDefinitionForView`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDataPropertyDefinitionForEdit(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetDataPropertyDefinitionForEditOutput >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/GetDataPropertyDefinitionForEdit`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static createOrEdit(body: CreateOrEditDataPropertyDefinitionDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/CreateOrEdit`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static create(body: CreateOrEditDataPropertyDefinitionDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/Create`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static update(body: CreateOrEditDataPropertyDefinitionDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/Update`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static delete(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DataPropertyDefinitions/Delete`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
}