import DataPropertyDefinitionsApiService from '@/api/DataPropertyDefinitionsApiService';
import {
  DataPropertyDefinitionDto,
  DataPropertyValueScope,
} from '@/api/models';
import groupBy from 'lodash/groupBy';
import { Module } from 'vuex';
import i18n from '@/core/plugins/vue-i18n';
// action types
export const SET_DATAPROPERTYDEFINITIONS = 'setDataPropertyDefinitions';
export const SET_DATAPROPERTYDEFINITIONS_FOR_VALUE_SCOPE =
  'setDataPropertyDefinitionsByValueScope';
export const LOAD_ALL_DATAPROPERTYDEFINITIONS =
  'loadAllDataPropertyDefinitions';
export const LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID =
  'loadDataPropertyDefinitionsByDocumentId';
export const DATAPROPERTYDEFINITIONS_NAMESPACE = 'dataPropertyDefinitions';
export const GET_ALL_DATAPROPERTYDEFINITIONS = 'getDataPropertyDefinitions';
export const GET_FORMATTED_ALL_DATAPROPERTYDEFINITIONS =
  'getFormattedAllDataPropertyDefinitions';
export const ADD_DATAPROPERTYDEFINITION = 'addDataPropertyDefinition';
export const DELETE_DATAPROPERTYDEFINITION = 'deleteDataPropertyDefinition';
export const GET_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID =
  'getDataPropertyDefinitionsByDocumentId';
export const GET_DOCUMENT_DATAPROPERTYDEFINITIONS =
  'getDocumentDataPropertyDefinitions';
export const GET_DECORATOR_DATAPROPERTYDEFINITIONS =
  'getDecoratorDataPropertyDefinitions';
export const GET_ENTITY_TYPE_DATAPROPERTYDEFINITIONS =
  'getEntityTypePropertyDefinitions';
export const GET_SYSTEM_DATAPROPERTYDEFINITIONS =
  'getSystemDataPropertyDefinitions';
export const LOAD_DATAPROPERTYDEFINITIONS_BY_VALUE_SCOPE =
  'loadDataPropertyDefinitionsByValueScope';
export const CREATE_DATAPROPERTYDEFINITION = 'createDataPropertyDefinition';
export const UPDATE_DATAPROPERTYDEFINITION = 'updateDataPropertyDefinition';
interface State {
  systemDefinitions: DataPropertyDefinitionDto[];
  decoratorDefinitions: DataPropertyDefinitionDto[];
  documentDefinitions: DataPropertyDefinitionDto[];
  entityTypeDefinition: DataPropertyDefinitionDto[];
}

const datapropertydefinitionsModule: Module<State, any> = {
  namespaced: true,
  state: {
    systemDefinitions: [],
    decoratorDefinitions: [],
    documentDefinitions: [],
    entityTypeDefinition: [],
  },
  getters: {
    [GET_ALL_DATAPROPERTYDEFINITIONS](state) {
      return [
        ...state.systemDefinitions,
        ...state.decoratorDefinitions,
        ...state.documentDefinitions,
        ...state.entityTypeDefinition,
      ];
    },
    [GET_FORMATTED_ALL_DATAPROPERTYDEFINITIONS](state) {
      const data = [
        ...state.systemDefinitions,
        ...state.decoratorDefinitions,
        ...state.documentDefinitions,
        ...state.entityTypeDefinition,
      ];

      return data.map((el) => {
        const label =
          el.valueScope === DataPropertyValueScope.System
            ? i18n.t(el.label).toString()
            : el.label;

        return { ...el, label };
      });
    },
    [GET_DOCUMENT_DATAPROPERTYDEFINITIONS](state) {
      return state.documentDefinitions;
    },
    [GET_SYSTEM_DATAPROPERTYDEFINITIONS](state) {
      return state.systemDefinitions;
    },
    [GET_DECORATOR_DATAPROPERTYDEFINITIONS](state) {
      return state.decoratorDefinitions;
    },
    [GET_ENTITY_TYPE_DATAPROPERTYDEFINITIONS](state) {
      return state.entityTypeDefinition;
    },
  },
  actions: {
    //You probably don't want to use this! Use LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID instead
    async [LOAD_ALL_DATAPROPERTYDEFINITIONS](context) {
      const response = await DataPropertyDefinitionsApiService.getAll();
      context.commit(SET_DATAPROPERTYDEFINITIONS, response.data.result);
      return response.data.result;
    },
    async [LOAD_DATAPROPERTYDEFINITIONS_BY_DOCUMENT_ID](
      context,
      payload: number
    ) {
      const response =
        await DataPropertyDefinitionsApiService.getDataPropertyDefinitionsByDocumentId(
          { id: payload }
        );

      if (response.status == 200) {
        context.commit(
          SET_DATAPROPERTYDEFINITIONS,
          response.data.result.definitions
        );
        return response.data.result.definitions;
      }
    },
    async [LOAD_DATAPROPERTYDEFINITIONS_BY_VALUE_SCOPE](
      context,
      scope: DataPropertyValueScope
    ) {
      const response =
        await DataPropertyDefinitionsApiService.getDataPropertyDefinitionsByScope(
          {
            scope: scope,
          }
        );

      if (response.status == 200) {
        context.commit(
          SET_DATAPROPERTYDEFINITIONS_FOR_VALUE_SCOPE,
          response.data.result
        );
      }
    },
    async [CREATE_DATAPROPERTYDEFINITION](
      context,
      payload: DataPropertyDefinitionDto
    ) {
      const response = await DataPropertyDefinitionsApiService.create(payload);

      if (response.status == 200) {
        const definitionId = response.data.result;
        context.commit(ADD_DATAPROPERTYDEFINITION, {
          ...payload,
          id: definitionId,
        });
        return definitionId;
      }
    },
    async [DELETE_DATAPROPERTYDEFINITION](context, payload) {
      const result = await DataPropertyDefinitionsApiService.delete({
        id: payload.id,
      });
      if (result.status === 200) {
        context.commit(DELETE_DATAPROPERTYDEFINITION, payload.id);
      }
    },
    async [UPDATE_DATAPROPERTYDEFINITION](context, payload) {
      const result = await DataPropertyDefinitionsApiService.update(payload);
      if (result.status === 200) {
        const updatedDataPropertyDefinitionResponse =
          await DataPropertyDefinitionsApiService.getDataPropertyDefinitionForView(
            { id: payload.id }
          );
        const updatedDataPropertyDefinition =
          updatedDataPropertyDefinitionResponse.data.result
            .dataPropertyDefinition;

        context.commit(
          UPDATE_DATAPROPERTYDEFINITION,
          updatedDataPropertyDefinition
        );

        return updatedDataPropertyDefinition;
      }
    },
  },
  mutations: {
    [SET_DATAPROPERTYDEFINITIONS](state, payload: DataPropertyDefinitionDto[]) {
      const definitionsGroupedByScope = groupBy(payload, (d) => d.valueScope);
      let decoratorDefinitions = [];
      let documentDefinitions = [];
      let systemDefinitions = [];
      let entityTypeDefinition = [];
      for (const definitionGroup in definitionsGroupedByScope) {
        switch (parseInt(definitionGroup)) {
          case DataPropertyValueScope.Decorator:
            decoratorDefinitions = definitionsGroupedByScope[definitionGroup];
            break;
          case DataPropertyValueScope.Diagram:
            documentDefinitions = definitionsGroupedByScope[definitionGroup];
            break;
          case DataPropertyValueScope.System:
            systemDefinitions = definitionsGroupedByScope[definitionGroup];
            break;
          case DataPropertyValueScope.EntityType:
            entityTypeDefinition = definitionsGroupedByScope[definitionGroup];
            break;
          default:
            throw 'Unknown data property definition value scope';
        }
      }
      state.decoratorDefinitions = decoratorDefinitions;
      state.documentDefinitions = documentDefinitions;
      state.systemDefinitions = systemDefinitions;
      state.entityTypeDefinition = entityTypeDefinition;
    },

    [SET_DATAPROPERTYDEFINITIONS_FOR_VALUE_SCOPE](
      state,
      payload: DataPropertyDefinitionDto[]
    ) {
      const valueScope = payload?.[0]?.valueScope;
      if (typeof valueScope !== 'number') {
        return;
      }

      if (payload.some((definition) => definition.valueScope != valueScope)) {
        throw 'Definitions do not share a common value scope';
      }

      switch (valueScope) {
        case DataPropertyValueScope.Decorator:
          state.decoratorDefinitions = payload;
          break;
        case DataPropertyValueScope.Diagram:
          state.documentDefinitions = payload;
          break;
        case DataPropertyValueScope.System:
          state.systemDefinitions = payload;
          break;
        case DataPropertyValueScope.EntityType:
          state.entityTypeDefinition = payload;
          break;
        default:
          throw 'Unknown data property definition value scope';
      }
    },

    [ADD_DATAPROPERTYDEFINITION](state, payload: DataPropertyDefinitionDto) {
      switch (payload.valueScope) {
        case DataPropertyValueScope.Decorator:
          state.decoratorDefinitions.push(payload);
          break;
        case DataPropertyValueScope.Diagram:
          state.documentDefinitions.push(payload);
          break;
        case DataPropertyValueScope.System:
          state.systemDefinitions.push(payload);
          break;
        case DataPropertyValueScope.EntityType:
          state.entityTypeDefinition.push(payload);
          break;
        default:
          throw 'Unknown data property definition value scope';
      }
    },
    [DELETE_DATAPROPERTYDEFINITION](state, payload: number) {
      const definitions = [
        ...state.decoratorDefinitions,
        ...state.documentDefinitions,
        ...state.systemDefinitions,
        ...state.entityTypeDefinition,
      ];

      const filteredDefinitions = definitions.filter(
        (definition) => definition.id != payload
      );
      this.commit(
        `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${SET_DATAPROPERTYDEFINITIONS}`,
        filteredDefinitions
      );
    },

    [UPDATE_DATAPROPERTYDEFINITION](state, payload: DataPropertyDefinitionDto) {
      const definitions = [
        ...state.decoratorDefinitions,
        ...state.documentDefinitions,
        ...state.systemDefinitions,
        ...state.entityTypeDefinition,
      ];

      // update definition
      const dataPropertyIndex = definitions.findIndex(
        (dp) => dp.id === payload.id
      );
      if (dataPropertyIndex > -1) {
        definitions[dataPropertyIndex] = payload;
      }

      // add child definitions
      if (payload.childDefinitions.length) {
        const newChildDefinitionsItems = payload.childDefinitions.filter(
          (childDefinition) =>
            definitions.some(
              (definition) => definition.id !== childDefinition.id
            )
        );

        if (newChildDefinitionsItems.length) {
          definitions.push(...newChildDefinitionsItems);
        }
      }

      this.commit(
        `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${SET_DATAPROPERTYDEFINITIONS}`,
        definitions
      );
    },
  },
};
export default datapropertydefinitionsModule;
