<template>
  <div
    class="w-full ring-0 border border-main-dark-20 rounded-4 cursor-pointer focus-within:ring-2 focus-within:ring-accent-purple hover:border-accent-purple transition overflow-hidden"
    :class="{ 'ring-2': isDragover }"
    @drop.prevent="onDrop"
    @dragover.prevent="onDragover"
    @dragleave="onDragleave"
  >
    <label
      :for="_uid"
      class="relative flex gap-8 justify-between w-full pl-12 py-6 whitespace-nowrap m-0 cursor-pointer"
    >
      <span>{{ labelText }}</span>

      <span
        class="absolute inset-y-0 right-0 px-12 py-6 bg-main-dark-10 text-main-dark"
      >
        Browse
      </span>
    </label>
    <input
      :key="resetKey"
      :id="_uid"
      type="file"
      class="absolute -z-1 opacity-0 appearance-none"
      v-bind="$attrs"
      :accept="accept"
      @input="inputfileHandler"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

type ComputedAcceptType = {
  rx: RegExp;
  prop: string;
};

@Component({
  name: 'AppFileInput',
})
export default class AppFileInput extends Vue {
  @Prop({ default: null }) value: File | FileList;
  @Prop({ default: '' }) label: string;
  @Prop({ default: '' }) accept: string;

  private files: FileList = null;
  private isDragover: boolean = null;
  private resetKey: number = 0;

  get fileNamePreview(): string | null {
    if (!this.files) return null;
    const fileNames = [...this.files].map((f) => f.name);

    return fileNames.length > 1 ? fileNames.join(', ') : fileNames[0];
  }

  get labelText(): string {
    if (this.isDragover) {
      return this.$t('DROP_FILE_HERE');
    }

    return (
      this.fileNamePreview ||
      this.label ||
      this.$t('CHOOSE_A_FILE_OR_DROP_IT_HERE')
    );
  }

  get computedAccept(): ComputedAcceptType[] {
    const accept = (this.accept || '')
      .trim()
      .split(/[,\s]+/)
      .filter((x) => x);

    if (accept.length === 0) {
      return null;
    }

    return accept.map((extOrType) => {
      let prop = 'name';
      let startMatch = '^';
      let endMatch = '$';
      if (/^\..+/.test(extOrType)) {
        startMatch = '';
      } else {
        prop = 'type';
        if (/\/\*$/.test(extOrType)) {
          endMatch = '.+$';
          extOrType = extOrType.slice(0, -1);
        }
      }

      extOrType = extOrType.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      const rx = new RegExp(`${startMatch}${extOrType}${endMatch}`);
      return { rx, prop };
    });
  }

  @Watch('files')
  handleFileChange(files: FileList) {
    if (files?.length === 1) {
      this.$emit('input', files[0]);
    } else {
      this.$emit('input', files);
    }
  }

  fileHandler(files: FileList) {
    this.files = files;
  }

  inputfileHandler(evt: Event) {
    const files: FileList = (<HTMLInputElement>evt.target).files;

    this.fileHandler(files);
  }

  isFileValid(file: File): boolean {
    if (!file) {
      return false;
    }

    const accept = this.computedAccept;
    return accept ? accept.some((a) => a.rx.test(file[a.prop])) : true;
  }

  isFilesValid(fiels: FileList): boolean {
    return [...fiels].every((file) => this.isFileValid(file));
  }

  onDrop(e: DragEvent) {
    this.isDragover = false;
    const files: FileList = e.dataTransfer.files;

    if (!this.isFilesValid(files)) {
      return;
    }

    this.fileHandler(files);
  }

  onDragover(e) {
    if (this.isDragover) return;
    this.isDragover = true;
  }

  onDragleave() {
    if (!this.isDragover) return;
    this.isDragover = false;
  }

  reset() {
    this.resetKey++;
    this.files = null;
  }
}
</script>
