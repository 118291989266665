<template>
  <ul class="inline-flex cursor-default" @click="$emit('click', $event)">
    <li
      v-for="(user, i) in userList"
      :key="i"
      class="inline-flex items-center justify-center leading-none uppercase rounded-full -mr-4 last:mr-0"
      :class="[
        sizeClass,
        backgroundColor,
        fontColor,
        {
          'ring-2 ring-white': !noBorder,
        },
      ]"
      :style="{
        ...(!backgroundColor
          ? {
              backgroundColor: isPrimitive
                ? randomColor()
                : randomColor(user.id),
            }
          : null),
      }"
    >
      {{ isPrimitive ? user : user.abbr }}
    </li>
  </ul>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isPrimitive, seedRandom } from '@/core/utils/common.utils';

type TUserId = number | null;
type TSize = 'default' | 'lg';

const SizeVariants = {
  default: 'w-24 h-24 text-base',
  lg: 'w-32 h-32 text-14',
  xl: 'w-36 h-36 text-14',
};

@Component({
  name: 'AppUserStack',
})
export default class AppUserStack extends Vue {
  @Prop({ required: true })
  users: any[];

  @Prop({ default: 'name' })
  nameKey: string;

  @Prop({ default: 'surname' })
  surnameKey: string;

  @Prop({ default: 'userId' })
  idKey: string;

  @Prop({ default: 3, type: Number })
  limit: number;

  @Prop({ default: false, type: Boolean })
  noBorder: boolean;

  @Prop({ default: 'default', type: String })
  size: TSize;

  @Prop({ default: null, type: String })
  backgroundColor: string;

  @Prop({ default: 'text-white', type: String })
  fontColor: string;

  get isPrimitive() {
    return isPrimitive(this.users[0]);
  }

  get sizeClass() {
    return SizeVariants[this.size];
  }

  get userList() {
    if (this.isPrimitive) {
      return this.users.map((user) => {
        const [name, surname] = user.split(' ');

        return `${name[0]}${surname ? surname[0] : ''}`;
      });
    }

    return this.users
      .filter((user) => user[this.nameKey] && user[this.surnameKey])
      .map((user) => {
        return {
          abbr: `${user[this.nameKey][0]}${user[this.surnameKey][0]}`,
          id: user?.[this.idKey],
        };
      })
      .slice(0, this.limit);
  }

  randomColor(userId: TUserId = null) {
    const seed = userId ? seedRandom(userId) : Math.random();

    const hue = Math.floor(seed * 360);
    const saturation = Math.floor(seed * 101) + '%';
    const lightness = '60%';
    return 'hsl(' + hue + ', ' + saturation + ', ' + lightness + ')';
  }
}
</script>
