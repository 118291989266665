import { DocumentPageContentType, DocumentPageDto } from '@/api/models';
import LegendAsImageProvider from './additional-element-providers/LegendAsImageProvider';
import LogoAsImageProvider from './additional-element-providers/LogoAsImageProvider';
import ExportOptions from './ExportOptions';
import ExportPageElement from './ExportPageElement';
import { ExportPageElementType } from './ExportPageElementType';
import ExportUtils from './ExportUtils';

export default class ExportPage {
  page: DocumentPageDto;
  /**
   * Index of the subpage to export (for pages with content overlows)
   */
  subPageIndex?: number;
  /**
   * Additional elements to include with the export e.g. logo and legend
   */
  additionalElements: ExportPageElement[] = [];

  constructor(page: DocumentPageDto, subPageIndex?: number) {
    this.page = page;
    this.subPageIndex = subPageIndex;
  }

  public async generateAdditionalElements(
    options: ExportOptions,
    types: ExportPageElementType[] = null
  ): Promise<void> {
    // Logo
    if (
      (!types || types.includes(ExportPageElementType.Logo)) &&
      ExportUtils.shouldIncludeLogo(options.document, this.page)
    ) {
      const logoProvider = new LogoAsImageProvider(options, this);
      const logoElements = await logoProvider.get();
      if (logoElements) {
        this.additionalElements.push(...logoElements);
      }
    }

    // Diagram legend
    if (
      (!types || types.includes(ExportPageElementType.Legend)) &&
      ExportUtils.shouldIncludeLegend(options.document, this.page)
    ) {
      const legendProvider = new LegendAsImageProvider(options, this);
      const legendElements = await legendProvider.get();
      if (legendElements) {
        const subPageRef = this.page.subPageRefs?.find(
          (r) => r.subPageIndex == this.subPageIndex
        );
        const diagramId = subPageRef?.diagramId ?? this.page.diagramId;
        const legendElement = legendElements.find(
          (e) =>
            (e.key == diagramId ||
              this.page.contentType == DocumentPageContentType.MasterLegend) &&
            e.type == ExportPageElementType.Legend
        );
        if (legendElement) {
          this.additionalElements.push(legendElement);
        }
      }
    }
  }
}
