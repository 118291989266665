import {
  IEnumerable,
  INode,
  LayoutGraph,
  LayoutGraphAdapter,
  LayoutStageBase,
  YNode,
  YPoint,
} from 'yfiles';

import { BuildDirection } from '@/core/services/graph/BuildDirection';

import { calculateAngle, calculateMode } from '@/core/utils/math.utils';
export default class QuickBuildLayoutAlgorithm extends LayoutStageBase {
  private newNode: INode;
  private newYNode: YNode;

  private sourceNode: INode;
  private sourceYNode: YNode;

  private horizontalBuildDistance: number;
  private verticalBuildDistance: number;
  private buildDirection: BuildDirection;
  constructor(
    sourceNode: INode,
    newNode: INode,
    horizontalBuildDistance: number,
    verticalBuildDistance: number,
    buildDirection: BuildDirection
  ) {
    super();

    this.sourceNode = sourceNode;
    this.newNode = newNode;
    this.horizontalBuildDistance = horizontalBuildDistance;
    this.verticalBuildDistance = verticalBuildDistance;
    this.buildDirection = buildDirection;
  }
  applyLayout(graph: LayoutGraph): void {
    // setup
    this.sourceYNode = this.findNode(graph, this.sourceNode);
    this.newYNode = this.findNode(graph, this.newNode);

    // collect all neighbors
    const neighbors = this.getNeighbors(graph);
    // there is no neighbors, place first
    if (neighbors.size == 0) {
      this.placeFirstNode(graph);
      return;
    }

    // select less busy side
    const side = this.selectSide(graph, neighbors);

    // collect axis values for placement
    const axisValues = this.getAxisValues(graph, neighbors);
    // get x, y
    const x = this.getX(graph, side, neighbors, axisValues);
    const y = this.getY(graph, side, neighbors, axisValues);

    // set node center point
    graph.setCenter(this.newYNode, new YPoint(x, y));
  }

  private placeFirstNode(graph: LayoutGraph): void {
    const sourceBounds = graph.getBoundingBox(this.sourceYNode);
    const newNodeSize = graph.getSize(this.newYNode);
    let newLocation: YPoint = null;
    switch (this.buildDirection) {
      case 'up':
        newLocation = new YPoint(
          sourceBounds.x + sourceBounds.width / 2,
          sourceBounds.y - this.verticalBuildDistance - newNodeSize.height / 2
        );
        break;
      case 'down':
        newLocation = new YPoint(
          sourceBounds.x + sourceBounds.width / 2,
          sourceBounds.y +
            sourceBounds.height +
            this.verticalBuildDistance +
            newNodeSize.height / 2
        );
        break;
      case 'left':
        newLocation = new YPoint(
          sourceBounds.x - this.horizontalBuildDistance - newNodeSize.width / 2,
          sourceBounds.y + sourceBounds.height / 2
        );
        break;
      case 'right':
        newLocation = new YPoint(
          sourceBounds.x +
            sourceBounds.width +
            this.horizontalBuildDistance +
            newNodeSize.width / 2,
          sourceBounds.y + sourceBounds.height / 2
        );
        break;
    }
    graph.setCenter(this.newYNode, newLocation);
  }

  private getX(
    graph: LayoutGraph,
    side: Side,
    neighbors: IEnumerable<YNode>,
    axisValues: number[]
  ): number {
    if (this.buildDirection == 'left' || this.buildDirection == 'right') {
      return calculateMode(axisValues);
    }
    const newNodeSize = graph.getSize(this.newYNode);

    if (side == 'right') {
      return (
        Math.max(...neighbors.map((node) => this.getMaxX(graph, node))) +
        newNodeSize.width / 2 +
        this.horizontalBuildDistance
      );
    } else if (side == 'left') {
      return (
        Math.min(...neighbors.map((node) => graph.getLocation(node).x)) -
        newNodeSize.width / 2 -
        this.horizontalBuildDistance
      );
    }
  }

  private getY(
    graph: LayoutGraph,
    side: Side,
    neighbors: IEnumerable<YNode>,
    axisValues: number[]
  ): number {
    if (this.buildDirection == 'up' || this.buildDirection == 'down') {
      return calculateMode(axisValues);
    }
    const newNodeSize = graph.getSize(this.newYNode);
    if (side == 'below') {
      return (
        Math.max(...neighbors.map((node) => this.getMaxY(graph, node))) +
        newNodeSize.height / 2 +
        this.verticalBuildDistance
      );
    } else if (side == 'above') {
      return (
        Math.min(...neighbors.map((node) => graph.getLocation(node).y)) -
        newNodeSize.height / 2 -
        this.verticalBuildDistance
      );
    }
  }

  private getMaxX(graph: LayoutGraph, node: YNode): number {
    const bounds = graph.getBoundingBox(node);
    return bounds.x + bounds.width;
  }

  private getMaxY(graph: LayoutGraph, node: YNode): number {
    const bounds = graph.getBoundingBox(node);
    return bounds.y + bounds.height;
  }

  private getAxisValues(
    graph: LayoutGraph,
    neighbors: IEnumerable<YNode>
  ): number[] {
    switch (this.buildDirection) {
      case 'up':
      case 'down':
        return neighbors.map((node) => graph.getCenterY(node)).toArray();
      case 'left':
      case 'right':
        return neighbors.map((node) => graph.getCenterX(node)).toArray();
    }
  }

  private selectSide(graph: LayoutGraph, neighbors: IEnumerable<YNode>): Side {
    switch (this.buildDirection) {
      case 'up':
      case 'down': {
        const sourceCenterX = graph.getCenterX(this.sourceYNode);
        const leftCount = neighbors.filter(
          (node) => graph.getCenterX(node) < sourceCenterX
        ).size;
        const rightCount = neighbors.size - leftCount;

        return leftCount < rightCount ? 'left' : 'right';
      }
      case 'left':
      case 'right': {
        const sourceCenterY = graph.getCenterY(this.sourceYNode);
        const aboveCount = neighbors.filter(
          (node) => graph.getCenterY(node) < sourceCenterY
        ).size;
        const belowCount = neighbors.size - aboveCount;

        return aboveCount < belowCount ? 'above' : 'below';
      }
    }
  }

  /**
   *
   * @param graph Returns a list of neighbor nodes to the source based on the given build direction.
   * @returns
   */
  private getNeighbors(graph: LayoutGraph): IEnumerable<YNode> {
    // always ignore the new node

    const neighborsBase = this.sourceYNode.neighbors.filter(
      (d) => d != this.newYNode
    );
    if (neighborsBase.size == 0) {
      return neighborsBase;
    }

    const sourceNodeBounds = graph.getBoundingBox(this.sourceYNode);
    const sourceYNode = graph.getCenter(this.sourceYNode);
    const newNodeSize = graph.getSize(this.newYNode);
    const buildSide = directionToSide(this.buildDirection);

    // attempt to determine where the node "should" be placed
    // we ignore all neighbor that don't match this value
    // this prevents the 2nd+ node following the 1st node if the 1st node was moved.
    let expectedAxisCenter: number = null;
    let getExpectedAxisValue: (node: YNode) => number = null;
    if (this.buildDirection == 'up' || this.buildDirection == 'down') {
      if (this.buildDirection == 'up') {
        expectedAxisCenter =
          sourceNodeBounds.y -
          this.verticalBuildDistance -
          newNodeSize.height / 2;
      } else {
        expectedAxisCenter =
          sourceNodeBounds.y +
          sourceNodeBounds.height +
          this.verticalBuildDistance +
          newNodeSize.height / 2;
      }
      getExpectedAxisValue = (node): number => graph.getCenterY(node);
    } else {
      if (this.buildDirection == 'left') {
        expectedAxisCenter =
          sourceNodeBounds.x -
          this.horizontalBuildDistance -
          newNodeSize.width / 2;
      } else {
        expectedAxisCenter =
          sourceNodeBounds.x +
          sourceNodeBounds.width +
          this.horizontalBuildDistance +
          newNodeSize.width / 2;
      }
      getExpectedAxisValue = (node): number => graph.getCenterX(node);
    }

    const neighbors = neighborsBase
      .filter((neighbor) => {
        const edgeConnection = neighbor.getEdge(this.sourceYNode);

        const egdePointAbs =
          edgeConnection.source == this.sourceYNode
            ? graph.getSourcePointAbs(edgeConnection)
            : graph.getTargetPointAbs(edgeConnection);
        const portSide = getSide(sourceYNode, egdePointAbs);

        return portSide == buildSide;
      })
      .filter((neighbor) => {
        const value = getExpectedAxisValue(neighbor);
        //#29323 - Temporary work around
        // QS ClassicTreeLayout places y value 1 over the expected.
        // expected: 168
        // actual: 169
        return Math.abs(value - expectedAxisCenter) < 2;
      });
    return neighbors;
  }

  private findNode(graph: LayoutGraph, nodeToFind: INode): YNode {
    const origNodeDp = graph.getDataProvider(
      LayoutGraphAdapter.ORIGINAL_NODE_DP_KEY
    )!;
    for (const node of graph.nodes) {
      if (origNodeDp.get(node) === nodeToFind) {
        return node;
      }
    }
  }
}

function getSide(anchor: YPoint, location: YPoint): Side {
  // angle in degrees
  const angle = calculateAngle(anchor, location);

  if (angle > 45 && angle <= 135) {
    return 'right';
  } else if (angle > 135 && angle <= 225) {
    return 'below';
  } else if (angle > 225 && angle <= 315) {
    return 'left';
  } else if ((angle > 315 && angle <= 360) || (angle >= 0 && angle <= 45)) {
    return 'above';
  }

  throw 'Unknown side';
}

function directionToSide(direction: BuildDirection): Side {
  switch (direction) {
    case 'up':
      return 'above';
    case 'down':
      return 'below';
    case 'right':
    case 'left':
      return direction;
  }
}

export type Side = 'above' | 'below' | 'right' | 'left';
