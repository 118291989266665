<template>
  <div
    class="app-range relative min-w-max"
    :class="{
      'is-disabled opacity-40 grayscale filter': disabled,
      'rotate-180': reverse,
    }"
    :style="[cssVars, size]"
  >
    <div class="app-range--wrap relative z-1">
      <input
        v-model="valueAt"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        :disabled="disabled"
        @input="handleInput"
        @mousedown="mousedownHandler('a')"
        @change="changeHandler"
      />
      <input
        v-if="multiple"
        v-model="valueBt"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        :disabled="disabled"
        @input="handleInput"
        @mousedown="mousedownHandler('b')"
        @change="changeHandler"
      />
    </div>
    <div
      class="app-range--track absolute left-0 top-1/2 h-4 -translate-y-1/2 transform rounded-full bg-main-dark-20"
    />
    <AppFlyout
      :class="{ '-top-6 rotate-180': reverse }"
      ref="flyoutTooltip"
      placement="top"
      offset="6"
      class="absolute top-0"
      :append-to-body="appendTooltipToBody"
      :style="offsetStyles"
    >
      <span slot="activator" class="app-range--tooltip-ghost-element" />
      <span slot="popper" class="rounded-4 bg-main-dark px-6 py-4 text-white">
        {{ currentThumbValue }}
      </span>
    </AppFlyout>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import AppFlyout from './AppFlyout.vue';

type RangeHandlers = 'a' | 'b';

@Component({
  name: 'AppRange',
})
export default class AppRange extends Vue {
  @Prop({ default: 0, type: [Number, Array] })
  value: number | number[];

  @Prop({ default: false, type: Boolean })
  multiple: boolean;

  @Prop({ default: 0, type: [String, Number] })
  min: string | number;

  @Prop({ default: 100, type: [String, Number] })
  max: string | number;

  @Prop({ default: 1, type: [String, Number] })
  step: string | number;

  @Prop({ default: false, type: Boolean })
  appendTooltipToBody: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  reverse: boolean;

  @Prop({
    default: 240,
    type: [String, Number],
    validator: (value) => +value >= 100,
  })
  width: string | number;

  @Prop({
    default: 16,
    type: [String, Number],
  })
  thumbSize: string | number;

  $refs: {
    flyoutTooltip: AppFlyout;
  };

  currentActiveThumb: RangeHandlers = 'a';

  get valueAt(): number {
    return this.multiple ? this.value[0] : this.value;
  }

  set valueAt(value) {
    let toEmit: number | number[] = +value;

    if (this.multiple) {
      toEmit = (this.value as number[]).slice();
      toEmit[0] = +value;
    }

    this.$emit('input', toEmit);
  }

  get valueBt(): number {
    return this.multiple ? this.value[1] : this.min;
  }
  set valueBt(value) {
    let toEmit: number[] = (this.value as number[]).slice();
    toEmit[1] = +value;

    this.$emit('input', toEmit);
  }

  get currentThumbValue(): number {
    if (this.multiple) {
      return this.currentActiveThumb === 'a' ? this.valueAt : this.valueBt;
    }

    return this.valueAt;
  }

  get cssVars(): Record<string, number> {
    return {
      '--a': +this.valueAt,
      '--b': +this.valueBt,
      '--min': +this.min,
      '--max': +this.max,
      '--width': +this.width,
      '--height': +this.thumbSize,
    };
  }

  get size(): { width: string; height: string } {
    return {
      width: `${this.width}px`,
      height: `${this.thumbSize}px`,
    };
  }

  get offsetStyles(): { left: string } {
    const diff = +this.max - +this.min;
    const uw = +this.width - +this.thumbSize;
    const offset = ((this.currentThumbValue - +this.min) / diff) * uw;

    return { left: `${offset}px` };
  }

  handleInput(): void {
    this.$refs.flyoutTooltip.show();
  }

  mousedownHandler(slider: RangeHandlers): void {
    this.currentActiveThumb = slider;
    this.$refs.flyoutTooltip.show();
  }

  changeHandler(): void {
    this.$emit('change', this.value);
  }

  mouseupHandler(): void {
    this.$refs.flyoutTooltip.hide();
  }
}
</script>

<style lang="scss" scoped>
@mixin track() {
  @apply h-full w-full bg-none;
}

@mixin thumb() {
  width: calc(var(--height) * 1px);
  height: calc(var(--height) * 1px);
  @apply pointer-events-auto cursor-pointer rounded-full border-2 border-solid border-black bg-white shadow-sm;
}

.app-range {
  --dif: calc(var(--max) - var(--min));
  --radius: calc(0.5 * var(--height) * 1px);
  --useful-width: calc((var(--width) - var(--height)) * 1px);

  &.is-disabled {
    &::before {
      @apply absolute inset-0 z-10 cursor-not-allowed;
      content: '';
    }
  }

  &--wrap {
    width: calc(var(--width) * 1px);
    @apply grid bg-transparent;

    &::before,
    &::after {
      @apply rounded-full bg-accent-purple;
      grid-column: 1 / span 2;
      grid-row: 3;
      content: '';
    }

    &::before {
      height: 4px;
      margin-top: calc((var(--height) - 4) / 2 * 1px);
      margin-left: calc(
        var(--radius) + (var(--a) - var(--min)) / var(--dif) *
          var(--useful-width)
      );
      width: calc((var(--b) - var(--a)) / var(--dif) * var(--useful-width));
    }

    &::after {
      height: 4px;
      margin-top: calc((var(--height) - 4) / 2 * 1px);
      margin-left: calc(
        var(--radius) + (var(--b) - var(--min)) / var(--dif) *
          var(--useful-width)
      );
      width: calc((var(--a) - var(--b)) / var(--dif) * var(--useful-width));
    }
  }

  &--track {
    left: var(--radius);
    width: calc(var(--width) * 1px - var(--height) * 1px);
  }

  &--tooltip-ghost-element {
    width: calc(var(--height) * 1px);
    height: calc(var(--height) * 1px);
  }
}

input[type='range'] {
  width: calc(var(--width) * 1px);

  &::-webkit-slider-runnable-track,
  &::-webkit-slider-thumb,
  & {
    -webkit-appearance: none;
  }

  grid-column: 1 / span 2;
  grid-row: 3;
  background: none;
  @apply pointer-events-none left-0 top-0 z-1 m-0 focus:z-10;

  &::-webkit-slider-runnable-track {
    @include track;
  }
  &::-moz-range-track {
    @include track;
  }

  &::-webkit-slider-thumb {
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
}
</style>
