<template>
  <transition
    enter-active-class="transition overflow-hidden"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition overflow-hidden"
    leave-class="opacity-0"
    leave-to-class="opacity-0"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'AppTransitionExpand',
})
export default class AppTransitionExpand extends Vue {
  enter(element: HTMLElement) {
    const width = getComputedStyle(element).width;

    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';

    const height = getComputedStyle(element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = '0px';

    // Force repaint to make sure the animation is triggered correctly.
    getComputedStyle(element).height;

    requestAnimationFrame(() => {
      element.style.height = height;
    });
  }

  afterEnter(element: HTMLElement) {
    element.style.height = 'auto';
  }

  leave(element: HTMLElement) {
    const height = getComputedStyle(element).height;

    element.style.height = height;

    // Force repaint to make sure the animation is triggered correctly.
    getComputedStyle(element).height;

    requestAnimationFrame(() => {
      element.style.height = '0px';
    });
  }
}
</script>

<style scoped>
* > * {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;

  @apply transition-height;
}
</style>
