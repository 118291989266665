import {
  DataExportDataPropertyDto,
  DataExportNodeDto,
  FileAttachmentDto,
} from '@/api/models';
import ElementSvgRenderUtils from '@/core/utils/ElementSvgRenderUtils';
import { INode } from 'yfiles';
import FileAttachmentDataExporter from './FileAttachmentDataExporter';

export default class NodeDataExporter {
  public static getData(
    node: INode,
    dataProperties: DataExportDataPropertyDto[],
    attachments: FileAttachmentDto[]
  ): DataExportNodeDto {
    const dto = new DataExportNodeDto(node.tag?.uuid, node.tag?.annotationType, node.tag?.entityTypeId);
    dto.text = node.labels.firstOrDefault()?.text ?? node.tag?.name;
    dto.name = node.tag?.name;
    dto.symbol = ElementSvgRenderUtils.createIconFromNode(node);

    dto.properties = dataProperties;

    dto.attachments = [];
    for (let attachment of attachments) {
      if (
        !attachment.dataPropertyDefinitionId &&
        !attachment.dataPropertyUuid &&
        !attachment.dataPropertyValue
      ) {
        const attachmentDto = FileAttachmentDataExporter.getData(attachment);
        dto.attachments.push(attachmentDto);
      }
    }

    return dto;
  }
}
