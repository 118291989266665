export default class FileSaveSupport {
  /**
   * Saves the file to the file system using the HTML5 File download
   *
   * @param {string} fileContent The file contents to be saved.
   * @param {string} fileName The default filename for the downloaded file.
   * @return {Promise} A promise which resolves when the save operation is complete.
   */
  static save(blob: Blob, fileName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (FileSaveSupport.isFileConstructorAvailable()) {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve();
        return;
      }

      reject(
        new Error(
          'File save failed: Save operation is not supported by the browser.'
        )
      );
    });
  }

  static isFileConstructorAvailable(): boolean {
    // Test whether required functions exist
    if (
      typeof window.URL !== 'function' ||
      typeof window.Blob !== 'function' ||
      typeof window.File !== 'function'
    ) {
      return false;
    }
    // Test whether the constructor works as expected
    try {
      // eslint-disable-next-line no-new
      new File(['Content'], 'fileName', {
        type: 'image/png',
        lastModified: Date.now(),
      });
    } catch (ignored) {
      return false;
    }
    // Everything is available
    return true;
  }
}
