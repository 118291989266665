import axios, {
    AxiosResponse
} from 'axios';
import {
    ZadarResponse,
    ImportResultDto,
    ProcessFileRequestDto,
    ImportedFileResultDto,
    ProcessImportedFileDto,
    PagedResultDtoOfImportDto,
    ImportDefinitionDto
} from './models'
export default class ImportsApiService {
    public static getBinaryObject(params ? : {
        id ? : string
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/GetBinaryObject`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static uploadFile(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < string >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/UploadFile`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static processUploadedFile(body: ProcessFileRequestDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ImportResultDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/ProcessUploadedFile`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static importFile(body: ProcessImportedFileDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ImportedFileResultDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/ImportFile`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getImports(params ? : {
        filter ? : string,
        isPublic ? : boolean,
        recent ? : boolean,
        sorting ? : string,
        skipCount ? : number,
        maxResultCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfImportDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/GetImports`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getForExport(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ImportDefinitionDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/GetForExport`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < ImportDefinitionDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static createDefinitionFromFile(params ? : {
        shouldImportTheme ? : boolean
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/Imports/CreateDefinitionFromFile`,
            method: 'post',
            params: params,
            clientConfig: clientConfig
        })
    }
}