import { Module } from 'vuex';
import { UserDto } from '@/api/models';
import { DOCUMENT_NAMESPACE, GET_DOCUMENT } from './document.module';
import IModifyAccessOption from '../../../view/pages/document/modals/share-document-modal/IModifyAccessOption';

interface State {
  notFinishedInvitations: INotFinishedInvitation;
}

export interface INotFinishedInvitation {
  invites: UserDto[];
  option: IModifyAccessOption;
  message: string;
  approved: boolean;
}

export const DOCUMENT_ACCESS_NAMESPACE = 'documentAccess';
export const GET_IS_NOT_FINISHED_INVITATION = 'getIsNotFinishedInvitation';
export const GET_NOT_FINISHED_INVITATION = 'getNotFinishedInvitation';
export const SET_NOT_FINISHED_INVITATION = 'setNotFinishedInvitation';

const documentAccessModule: Module<State, any> = {
  namespaced: true,
  state: {
    notFinishedInvitations: {
      invites: [],
      option: null,
      message: '',
      approved: true,
    },
  },

  getters: {
    [GET_IS_NOT_FINISHED_INVITATION](state) {
      return state.notFinishedInvitations.invites.length;
    },
    [GET_NOT_FINISHED_INVITATION](state) {
      return state.notFinishedInvitations;
    },
  },

  mutations: {
    [SET_NOT_FINISHED_INVITATION](state, payload) {
      if (!payload) {
        payload = {
          invites: [],
          option: {},
          message: '',
          approved: true,
        };
      }

      state.notFinishedInvitations = payload;
    },
  },
};

export default documentAccessModule;
