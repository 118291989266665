// Lets keep as string enum for now - that's way easier to debug commands
export enum PageSyncChangeType {
  EditNodeLabel = 'EditNodeLabel',
  ChangeNodeType = 'ChangeNodeType',
  ChangeNodeLayout = 'ChangeNodeLayout',
  ChangeNodeFill = 'ChangeNodeFill',
  ChangeNodeStrokeFill = 'ChangeNodeStrokeFill',
  ChangeNodeStrokeThickness = 'ChangeNodeStrokeThickness',
  ChangeNodeStrokeDashStyle = 'ChangeNodeStrokeDashStyle',
  ChangeNodeAngle = 'ChangeNodeAngle',
  ChangeEdgePort = 'ChangeEdgePort',
  ChangeEdgeSourcePort = 'ChangeEdgeSourcePort',
  ChangeEdgeTargetPort = 'ChangeEdgeTargetPort',
  ChangeEdgeBend = 'ChangeEdgeBend',
  ChangeEdgeHeight = 'ChangeEdgeHeight',
  AddEdge = 'AddEdge',
  DeleteEdge = 'DeleteEdge',
  ChangeNodeSize = 'ChangeNodeSize',
  ChangeNodeLocation = 'ChangeNodeLocation',
  DeleteNode = 'DeleteNode',
  AddNode = 'AddNode',
  ChangeNodeLabelData = 'ChangeNodeLabelData',
  EditEdgeLabel = 'EditEdgeLabel',
  ChangeEdgeLabelData = 'ChangeEdgeLabelData',
  ChangeJurisdiction = 'ChangeJurisdiction',
  ChangeCountryState = 'ChangeCountryState',
  ChangeNodeDataPropertyStyleState = 'ChangeNodeDataPropertyStyleState',
  ChangeJurisdictionDisplayTypeNodeLabel = 'ChangeJurisdictionDisplayTypeNodeLabel',
  ChangeJurisdictionDisplayTypeDecorator = 'ChangeJurisdictionDisplayTypeDecorator',
  ChangeCountryStateDisplayTypeDecorator = 'ChangeCountryStateDisplayTypeDecorator',
  ChangeCountryStateDisplayTypeNodeLabel = 'ChangeCountryStateDisplayTypeNodeLabel',
  ChangeJurisdictionLocation = 'ChangeJurisdictionLocation',
  ChangeEdgeType = 'ChangeEdgeType',
  ChangeEdgeStrokeFill = 'ChangeEdgeStrokeFill',
  ChangeEdgeStrokeThickness = 'ChangeEdgeStrokeThickness',
  ChangeEdgeStrokeDashStyle = 'ChangeEdgeStrokeDashStyle',
  ChangeEdgeSourceArrowType = 'ChangeEdgeSourceArrowType',
  ChangeEdgeTargetArrowType = 'ChangeEdgeTargetArrowType',
  ChangeEdgeBridgeState = 'ChangeEdgeBridgeState',
  ChangeEdgePortDirection = 'ChangeEdgePortDirection',
  ChangeEdgeDisplayOrder = 'ChangeEdgeDisplayOrder',
  ChangeNodeDisplayOrder = 'ChangeNodeDisplayOrder',
}
