import {
  DiagramDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  DocumentPageType,
  FlipbookState,
} from '@/api/models';
import { mergeRects } from '@/core/utils/common.utils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { Rect } from 'yfiles';
import DiagramLayoutHelper from '../graph/DiagramLayoutHelper';

export default class CommonDiagramsGroup {
  public pageType: DocumentPageType;
  private diagrams: DiagramDto[] = [];

  constructor(pageType: DocumentPageType) {
    this.pageType = pageType;
  }

  public get allNodes(): DiagramNodeDto[] {
    return this.diagrams.flatMap((d) => d.nodes);
  }

  public get allEdges(): DiagramEdgeDto[] {
    return this.diagrams.flatMap((d) => d.edges);
  }

  public get size(): number {
    return this.diagrams.length;
  }

  public get commonDiagrams(): DiagramDto[] {
    return [...this.diagrams];
  }

  public get flipbookEnabledDiagrams(): DiagramDto[] {
    return this.diagrams.filter(
      (d) => d.flipbookState == FlipbookState.Enabled
    );
  }

  public get firstCommonDiagram(): DiagramDto {
    return this.diagrams[0];
  }

  public get lastCommonDiagram(): DiagramDto {
    return this.diagrams[this.diagrams.length - 1];
  }

  public get bounds(): Rect {
    const contentRects = this.flipbookEnabledDiagrams.map((d) => {
      const rect = DiagramLayoutHelper.getContentRectFromLayout(d.layout, true);
      return new Rect(
        rect.x - d.layout.offsets.x,
        rect.y - d.layout.offsets.y,
        rect.width,
        rect.height
      );
    });
    return mergeRects(contentRects);
  }

  public add(diagram: DiagramDto): void {
    this.diagrams.push(diagram);
  }

  public remove(diagram: DiagramDto): void {
    this.diagrams.splice(this.diagrams.indexOf(diagram), 1);
  }

  public contains(diagramId: number): boolean {
    return this.diagrams.some((d) => d.id == diagramId);
  }

  public containsWithFlipbook(diagramId: number): boolean {
    return this.flipbookEnabledDiagrams.some((d) => d.id == diagramId);
  }

  /**
   * Determines whether the diagram shares any node(s) in common with the group
   */
  public sharesCommonNodes(diagram: DiagramDto): boolean {
    return DiagramUtils.sharesCommonNodes(this.diagrams, diagram);
  }
}
