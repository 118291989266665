import {
  GraphObstacleProvider,
  IEdge,
  IRenderContext,
  IGraph,
  FilteredGraphWrapper,
  GeneralPath,
} from 'yfiles';

/**
 * A custom GraphObstacleProvider that allows filtering the edges under consideration.
 */
export class FilteringGraphObstacleProvider extends GraphObstacleProvider {
  constructor(private edgePredicate?: ((edge: IEdge) => boolean) | undefined) {
    super();
  }

  protected getGraph(context: IRenderContext): IGraph | null {
    const graph = super.getGraph(context);
    const filteredGraphWrapper = new FilteredGraphWrapper(
      graph,
      () => true,
      this.edgePredicate
    );
    return filteredGraphWrapper;
  }

  getObstacles(context: IRenderContext): GeneralPath | null {
    return super.getObstacles(context);
  }
}
