import { Module } from 'vuex';
import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';
import ArrowElementDataProvider from '@/components/DiagramPalette/DataProviders/ArrowElementDataProvider';
import ShapeElementDataProvider from '@/components/DiagramPalette/DataProviders/ShapeElementDataProvider';
import SvgElementDataProvider from '@/components/DiagramPalette/DataProviders/SvgElementDataProvider';
import ThemedElementsDataProvider from '@/components/DiagramPalette/DataProviders/ThemedElementsDataProvider';
import DividingLineDataProvider from '@/components/DiagramPalette/DataProviders/DividingLineDataProvider';
import CustomClipArtDataProvider from '@/components/DiagramPalette/DataProviders/CustomClipArtDataProvider';
// action types
export const GET_PALETTE_ITEMS = 'getPaletteItems';
export const REFRESH_PALETTE = 'refreshPalette';
export const SET_PALETTE_ITEMS = 'setPaletteItems';
export const PALETTE_NAMESPACE = 'palette';
export const SET_SELECTED_ITEM = 'setSelectedItem';
export const GET_SELECTED_ITEM = 'getSelectedItem';

const dataProviders = [
  ThemedElementsDataProvider,
  SvgElementDataProvider,
  ArrowElementDataProvider,
  ShapeElementDataProvider,
  DividingLineDataProvider,
  CustomClipArtDataProvider,
];
interface State {
  paletteItems: IDocumentPaletteItem[];
  selectedItem: IDocumentPaletteItem | null;
}
const paletteModule: Module<State, any> = {
  namespaced: true,
  state: {
    paletteItems: [],
    selectedItem: null,
  },
  getters: {
    [GET_PALETTE_ITEMS](state) {
      return state.paletteItems;
    },
    [GET_SELECTED_ITEM](state) {
      return state.selectedItem;
    },
  },
  actions: {
    async [REFRESH_PALETTE](context) {
      let allItems = [];
      // load each data provider in turn

      for (const dpClass of dataProviders) {
        // initialise the class
        const dp = new dpClass();
        // invoke the getElements function, this can return a promise data
        const elementsOrPromise = await dp.getElements();
        if (elementsOrPromise instanceof Promise) {
          // if handling a promise, commit to store in a call back
          let items = await elementsOrPromise;
          if (items && items.length > 0) {
            allItems.push(...items);
          }
        } else {
          // commit data to store
          if (elementsOrPromise && elementsOrPromise.length > 0) {
            allItems.push(...elementsOrPromise);
          }
        }
      }
      context.commit(SET_PALETTE_ITEMS, allItems);
      return allItems;
    },
  },
  mutations: {
    [SET_PALETTE_ITEMS](state, payload) {
      if (!payload) {
        return;
      } // combine existing store items with new items.

      state.paletteItems = payload ?? [];
    },
    [SET_SELECTED_ITEM](state, payload: IDocumentPaletteItem | null) {
      state.selectedItem = payload;
    },
  },
};

export default paletteModule;
