import {
  BaseClass,
  Fill,
  INodeStyle,
  INodeStyleRenderer,
  Stroke,
} from 'yfiles';

import JigsawPathNodeStyleRendererImpl from './JigsawPathNodeStyleRendererImpl';
import { JigsawPathShape } from '@/api/models';

export default class JigsawPathShapeNodeStyle extends BaseClass<INodeStyle>(
  INodeStyle
) {
  public readonly fill: Fill;
  public readonly stroke: Stroke;
  public readonly shape: JigsawPathShape;
  constructor(options?: {
    shape?: JigsawPathShape | null;
    fill?: Fill;
    stroke?: Stroke;
  }) {
    super();
    this.fill = options?.fill ?? null;
    this.shape = options?.shape;
    this.stroke = options?.stroke ?? null;
  }

  get renderer(): INodeStyleRenderer {
    return JigsawPathNodeStyleRendererImpl.INSTANCE;
  }

  clone(): any {
    return new JigsawPathShapeNodeStyle({
      fill: this.fill ? this.fill.clone() : null,
      stroke: this.stroke ? this.stroke.clone() : null,
      shape: this.shape,
    });
  }
}
