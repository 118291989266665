import ISearchParameter from './ISearchParameter';

export default class DataPropertyParameter implements ISearchParameter {
  public dataPropertyDefinitionId: number;
  public dataPropertyValue: any;

  constructor(dataPropertyDefinitionId: number, dataPropertyValue: any) {
    this.dataPropertyDefinitionId = dataPropertyDefinitionId;
    this.dataPropertyValue = dataPropertyValue;
  }

  public equals(other: ISearchParameter): boolean {
    return (
      other instanceof DataPropertyParameter &&
      other.dataPropertyDefinitionId == this.dataPropertyDefinitionId &&
      other.dataPropertyValue == this.dataPropertyValue
    );
  }
}
