import {
  DiagramDto,
  DiagramViewDto,
  DocumentDto,
  DocumentView,
} from '@/api/models';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { GraphComponent, GraphEditorInputMode, Point, Rect } from 'yfiles';
import PrintPreviewInputMode from './input-modes/print-preview/PrintPreviewInputMode';
import ZoomService from './ZoomService';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT_VIEW,
  UPDATE_DIAGRAM_VIEW_POINT,
  UpdateDiagramViewPoint,
  GET_SELECTED_DIAGRAM,
  SET_DOCUMENT_VIEW,
  AutoZoomState,
  GET_DOCUMENT,
} from '../store/document.module';
import DocumentService from '../document/DocumentService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import {
  GET_IS_FOCUS_MODE,
  SET_IS_FOCUS_MODE,
  VIEWPORT_NAMESPACE,
} from '../store/viewport.module';
import IDisposable from '@/core/common/IDisposable';
import { oncePageVisible } from '@/core/utils/common.utils';
export default class ViewPortService implements IDisposable {
  public static $class: string = 'ViewPortService';
  private _viewportChangedListener = this.onViewportChanged.bind(this);
  private _isSwitchingDocumentView: boolean = false;
  public get isSwitchingDocumentView(): boolean {
    return this._isSwitchingDocumentView;
  }
  /**
   * Becuase there are several ways into PrintPreview, some gestures flick between WL -> PP or PL -> PP.
   * Other gestures then switch back to automatially, this property provides a way for those gestures to flick back to a view
   * that was in use prior to toggling to PrintPreview.
   * You can use switchToPreviousView()
   */
  private _previousDocumentView: DocumentView = null;
  constructor(private _graphService: IGraphService) {
    this._graphService.graphComponent.addViewportChangedListener(
      this._viewportChangedListener
    );
  }

  private get _graphComponent(): GraphComponent {
    return this._graphService.graphComponent;
  }

  private get _zoomService(): ZoomService {
    return this._graphService.getService<ZoomService>(ZoomService.$class);
  }

  private get _printPreviewInputMode(): PrintPreviewInputMode {
    return (this._graphComponent.inputMode as GraphEditorInputMode)
      .getSortedModes()
      .find((d) => d instanceof PrintPreviewInputMode) as PrintPreviewInputMode;
  }

  private get _document(): DocumentDto {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
  }

  private get _documentView(): DocumentView {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`
    ];
  }

  private get _diagram(): DiagramDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_DIAGRAM}`
    ];
  }

  public get isFocusMode(): boolean {
    return Vue.$globalStore.getters[
      `${VIEWPORT_NAMESPACE}/${GET_IS_FOCUS_MODE}`
    ];
  }

  public pageLoaded(): void {
    this._zoomService.onDiagramCreated();
  }

  /**
   * This method can only be invoked successfully if we're currently in PrintPreview
   */
  public switchToPreviousDocumentView(): void {
    if (this._previousDocumentView == null) {
      throw '_previousDocumentView has no value';
    }
    this.switchDocumentView(this._previousDocumentView ?? DocumentView.Print);
  }
  /**
   *
   * @param newDocumentView The document layout to set
   * @returns
   */
  public switchDocumentView(newDocumentView: DocumentView): void {
    if (!this._diagram) {
      return;
    }

    //Step documents must use print view, unless focus mode is on
    if (this._document.hasSteps && !this.isFocusMode) {
      newDocumentView = DocumentView.Print;
    }
    console.debug(`Switching to ${DocumentView[newDocumentView]}`);
    this._isSwitchingDocumentView = true;
    try {
      const viewPoint = this.getViewPoint(this._diagram, newDocumentView);

      if (!viewPoint?.location) {
        this._zoomService.onDiagramCreated();
      } else {
        this._graphComponent.zoom = viewPoint.zoom;
        this._graphComponent.viewPoint = new Point(
          viewPoint.location.x,
          viewPoint.location.y
        );
      }
      if (newDocumentView === DocumentView.PrintPreview) {
        this._previousDocumentView = this.getDocumentView();
        let rect: Rect = null;
        // load existing
        if (
          DocumentService.selectedPage?.diagram?.diagramViews.printPreview?.data
        ) {
          const printPreviewView =
            DocumentService.selectedPage.diagram.diagramViews.printPreview;
          rect = new Rect(
            printPreviewView.data.exportRect.location.x,
            printPreviewView.data.exportRect.location.y,
            printPreviewView.data.exportRect.size.width,
            printPreviewView.data.exportRect.size.height
          );
        }

        this._printPreviewInputMode.activate(rect);
      } else {
        this._previousDocumentView = null;
        this._printPreviewInputMode.deactivate();
      }

      this.setDocumentView(newDocumentView);
      oncePageVisible(() => {
        setTimeout(() => {
          this._zoomService.fitCurrentDiagram({ force: true });
        });
      });

      // for steps documents that are in WebView, we have to ensure focus mode is turned on.
      if (this._document.hasSteps && newDocumentView == DocumentView.Web) {
        this.setIsFocusMode(true);
      }
      if (newDocumentView != DocumentView.Web) {
        EventBus.$emit(EventBusActions.DOCUMENT_TOGGLE_GRAPH_OVERVIEW, false);
        this.setIsFocusMode(false);
      }
    } finally {
      this._isSwitchingDocumentView = false;
    }
  }

  private setDocumentView(documentView: DocumentView): void {
    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_VIEW}`,
      documentView
    );
  }

  private setIsFocusMode(isFocusMode: boolean): void {
    Vue.$globalStore.dispatch(
      `${VIEWPORT_NAMESPACE}/${SET_IS_FOCUS_MODE}`,
      isFocusMode
    );
  }

  private getDocumentView(): DocumentView {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`
    ] as DocumentView;
  }

  private getAutoZoomStateForView(documentView: DocumentView): AutoZoomState {
    switch (documentView) {
      case DocumentView.Web:
        return AutoZoomState.Off;
      case DocumentView.Print:
        return AutoZoomState.On;
      case DocumentView.PrintPreview:
        return AutoZoomState.Disabled;
    }

    throw `unknown DocumentView ${documentView}`;
  }
  private getViewPoint(
    diagram: DiagramDto,
    documentView: DocumentView
  ): DiagramViewDto {
    switch (documentView) {
      case DocumentView.Web:
        return diagram.diagramViews?.web;
      case DocumentView.Print:
        return diagram.diagramViews?.print;
      case DocumentView.PrintPreview:
        return diagram.diagramViews?.printPreview;
    }

    throw `unknown DocumentView ${documentView}`;
  }

  private updateDiagramViewPoint(payload: UpdateDiagramViewPoint): void {
    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${UPDATE_DIAGRAM_VIEW_POINT}`,
      payload
    );
  }

  public toggleFocusMode(): void {
    if (this._documentView == DocumentView.PrintPreview) {
      console.warn('Unable to toggle focus mode while in PrintPreview');
      return;
    }
    if (this._documentView == DocumentView.Print) {
      this.setIsFocusMode(true);
      this.switchDocumentView(DocumentView.Web);
    } else if (this.isFocusMode) {
      this.setIsFocusMode(false);
      this.switchDocumentView(DocumentView.Print);
    }
  }

  /* Event Handlers */

  private onViewportChanged(gc: GraphComponent): void {
    if (this._isSwitchingDocumentView) {
      return;
    }

    const exportRect = this._printPreviewInputMode.exportRect;
    let data = null;
    if (this._documentView == DocumentView.PrintPreview && exportRect) {
      data = {
        exportRect: {
          location: {
            x: exportRect.x,
            y: exportRect.y,
          },
          size: {
            width: exportRect.width,
            height: exportRect.height,
          },
        },
      };
    }

    this.updateDiagramViewPoint({
      activeView: this._documentView,
      location: this._graphComponent.viewPoint,
      zoom: this._graphComponent.zoom,
      data: data,
    });
  }

  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) {
      return;
    }
    this.isDisposed = true;
    this._graphService.graphComponent.removeViewportChangedListener(
      this._viewportChangedListener
    );
  }
}
