import JRect from '@/core/common/JRect';
import ImageLayoutItem from './ImageLayoutItem';
import TextBoxLayoutItem from './TextBoxLayoutItem';
import JSize from '@/core/common/JSize';
import { measureText } from '@/core/utils/html.utils';
import LayoutItem from './LayoutItem';
import { TextTemplateByPresetArgs, TPreset } from '../TextElementPresets';
import LayoutUtils from '../LayoutUtils';
import PageNumberLayoutItem from './PageNumberLayoutItem';
import WidgetLayoutItem from './WidgetLayoutItem';
import { DocumentDto, PageElementPosition } from '@/api/models';
import { WidgetPreset } from '@/view/pages/administration/steps-designer/StepsDesignerTypes';
import StepsDesignerConfig from '@/core/config/stepsDesignerConfig';
import DateLayoutItem from './DateLayoutItem';
import i18nService from '@/core/services/i18n.service';
import LayoutWidgetUtils from '../LayoutWidgetUtils';
import { ICreateWidgetParams } from './ICreateWidgetParams';
import ExportConfig from '@/core/config/ExportConfig';

export type TDirection = 'forward' | 'backward';

export default class LayoutItemUtils {
  public static createTextItem(
    items: LayoutItem[],
    item: TextBoxLayoutItem,
    size?: JSize,
    textPresetData?: TextTemplateByPresetArgs
  ): void {
    item.html =
      item.html || LayoutUtils.getDefaultHtmlForTextBoxItem(textPresetData);

    if (textPresetData?.preset) {
      item.preset = textPresetData?.preset;
    } else {
      item.preset = 'normal';
    }

    if (!size) {
      // Convert to points and add extra size to fit text in container
      size = measureText(item.html).divide(ExportConfig.pointToPixelFactor);
      size.width += item.padding * 2;
      size.height += item.padding;
    } else {
      item.keepInitialSize = true;
      item.resized = true;
    }

    item.layout = new JRect(
      item.layout.x,
      item.layout.y,
      size.width,
      size.height
    );

    const biggestZIndex = LayoutUtils.biggestZIndex(items);
    item.zIndex = biggestZIndex + 1;
    items.push(item);
  }

  public static createImageItem(
    items: LayoutItem[],
    item: ImageLayoutItem
  ): void {
    const biggestZIndex = LayoutUtils.biggestZIndex(items);
    item.zIndex = biggestZIndex + 1;
    items.push(item);
  }

  public static createPageNumberItem(
    items: LayoutItem[],
    item: PageNumberLayoutItem
  ): void {
    const biggestZIndex = LayoutUtils.biggestZIndex(items);
    item.zIndex = biggestZIndex + 1;
    if (!item.presetId) {
      item.presetId = StepsDesignerConfig.get.pageNumberPresets[0].id;
    }
    const preset = StepsDesignerConfig.get.pageNumberPresets.find(
      (p) => p.id === item.presetId
    );
    item.html =
      item.html === preset.template
        ? LayoutWidgetUtils.getDefaultHtmlForWidgetItem(preset.template)
        : item.html;
    const itemSize = LayoutUtils.getElementMeasurements(item);
    item.setLayout(
      new JRect(item.layout.x, item.layout.y, itemSize.width, itemSize.height)
    );
    items.push(item);
  }

  public static createDateItem(
    items: LayoutItem[],
    item: DateLayoutItem
  ): void {
    const biggestZIndex = LayoutUtils.biggestZIndex(items);
    item.zIndex = biggestZIndex + 1;

    const activeLanguage = i18nService.getActiveLanguage().toLowerCase();
    if (!item.presetId) {
      item.presetId =
        StepsDesignerConfig.get.dateFormatPresets[activeLanguage][0].id;
    }
    const preset = (
      StepsDesignerConfig.get.dateFormatPresets[
        activeLanguage
      ] as Array<WidgetPreset>
    ).find((p) => p.id === item.presetId);
    item.html = LayoutWidgetUtils.getDefaultHtmlForWidgetItem(preset.template);

    const itemSize = LayoutUtils.getElementMeasurements(item);
    item.setLayout(
      new JRect(item.layout.x, item.layout.y, itemSize.width, itemSize.height)
    );
    items.push(item);
  }

  public static deleteItem(items: LayoutItem[], item: LayoutItem): void {
    const index = items.indexOf(item);
    if (index === -1) {
      return;
    }
    items.splice(index, 1);
  }

  public static updateItemLocation(
    item: WidgetLayoutItem,
    position: PageElementPosition,
    containerSize: JSize,
    forced: boolean = false
  ): void {
    if (item.moved && !forced) {
      return;
    }

    const itemSize = item.layout.toSize();
    const itemLocation = LayoutUtils.getPositionCoordinates(
      position,
      itemSize,
      containerSize
    );
    const layout = new JRect(
      itemLocation.x,
      itemLocation.y,
      itemSize.width,
      itemSize.height
    );
    item.setLayout(layout);
  }

  public static createDefaultDateItem(
    document: DocumentDto,
    layoutItems: LayoutItem[],
    params: ICreateWidgetParams
  ): void {
    const {
      widgetPreset = LayoutWidgetUtils.datePresetsArray[0],
      widgetPosition,
    } = params;
    const item = new DateLayoutItem({
      hidden: false,
      html: widgetPreset.template,
      presetId: widgetPreset.id,
      position: widgetPosition,
      format: widgetPreset.format,
    });
    LayoutItemUtils.createDateItem(layoutItems, item);
    LayoutItemUtils.updateItemLocation(
      item,
      widgetPosition,
      LayoutUtils.getContentAreaSize(
        document,
        LayoutUtils.getContentAreaByPageElementPosition(widgetPosition)
      )
    );
  }

  public static createDefaultPageNumberItem(
    document: DocumentDto,
    layoutItems: LayoutItem[],
    params: ICreateWidgetParams
  ): void {
    const {
      widgetPreset = LayoutWidgetUtils.pageNumberPresetsArray[0],
      widgetPosition,
    } = params;

    const item = new PageNumberLayoutItem({
      hidden: false,
      html: widgetPreset.template,
      presetId: widgetPreset.id,
      position: widgetPosition,
    });
    LayoutItemUtils.createPageNumberItem(layoutItems, item);
    LayoutItemUtils.updateItemLocation(
      item,
      widgetPosition,
      LayoutUtils.getContentAreaSize(
        document,
        LayoutUtils.getContentAreaByPageElementPosition(widgetPosition)
      )
    );
  }
}
