import {
  ClientAutoSaveSettingsDto,
  SessionTimeOutSettingsEditDto,
} from '@/api/models';
import { Idle, NotIdle } from 'idlejs';
import { EventBus, EventBusActions } from '../events/eventbus.service';

export default class IdleManager {
  isDisposed: boolean;
  sessionIdle: Idle;
  autoSaveNotIdle: NotIdle;
  autoSaveSettings: ClientAutoSaveSettingsDto;

  public static INSTANCE: IdleManager = new IdleManager();

  constructor() {
    this.autoSaveNotIdle = new NotIdle();
    this.sessionIdle = new Idle();
  }

  restart() {
    this.sessionIdle.restart();
  }

  stopAutoSave() {
    if (this.autoSaveSettings.isEnabled) {
      this.autoSaveNotIdle.stop();
    }
  }

  startAutoSave() {
    if (this.autoSaveSettings.isEnabled) {
      this.autoSaveNotIdle.start();
    }
  }

  setAutoSaveSettings(autoSaveSettings: ClientAutoSaveSettingsDto) {
    this.autoSaveSettings = autoSaveSettings;
    this.setAutoSaveInterval();
  }

  setAutoSaveInterval() {
    this.autoSaveNotIdle.stop();
    if (this.autoSaveSettings.isEnabled) {
      this.autoSaveNotIdle
        .whenInteractive()
        .within(this.autoSaveSettings.timeOutSeconds, 1000)
        .do(() => EventBus.$emit(EventBusActions.DOCUMENT_AUTO_SAVE));
    }
  }

  setSessionIdle(session: SessionTimeOutSettingsEditDto) {
    this.sessionIdle.stop();
    if (session.isEnabled) {
      this.sessionIdle
        .whenNotInteractive()
        .within(session.timeOutSeconds, 1000)
        .do(() => EventBus.$emit(EventBusActions.APPLICATION_IDLE_ONIDLE))
        .start();
    }
  }
}
