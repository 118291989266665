import { DocumentPageDto } from '@/api/models';
import ExportConfig from '@/core/config/ExportConfig';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';

import { htmlToElement } from '@/core/utils/html.utils';
import DocumentService from '../document/DocumentService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_SELECTED_PAGE,
  SET_SELECTED_SUBPAGE_INDEX,
} from '../store/document.module';
import ContentPagination from './ContentPagination';
import ExportUtils from './ExportUtils';
import PageElementData from './PageElementData';
import FeaturesService from '../FeaturesService';
import { Features } from '@/core/common/Features';
import { Editor } from '@ckeditor/ckeditor5-core';

export default class PageLayoutHandler {
  public static readonly elementIdAttribute = 'plid';
  public static readonly isUnlinkedDiagramAvailable = false;

  private static _paginationEnabled: boolean;
  public static get paginationEnabled(): boolean {
    if (this._paginationEnabled === undefined) {
      this._paginationEnabled = FeaturesService.hasFeature(
        Features.ContinuationSheets
      );
    }
    return this._paginationEnabled;
  }

  /**
   * Called by the editor page layout plugin whenever a new subpage is added
   */
  public static onPageAdded(editor: Editor, subPageIndex: number): void {
    const selectedPage = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ] as DocumentPageDto;
    let selectedPageContent = editor.getData();
    selectedPageContent =
      PageLayoutHandler.sanitizeEditorOutput(selectedPageContent);
    const subPageCount =
      ContentPagination.getPageCountFromContent(selectedPageContent);

    if (!this.isUnlinkedDiagramAvailable) {
      return;
    }

    for (const ref of [...(selectedPage?.subPageRefs ?? [])]) {
      if (ref.subPageIndex == editor.subPageIndex) {
        // Unlink diagram on a new subpage if parent diagram is also unlinked
        const existingRef = selectedPage.subPageRefs.find(
          (r) => r.subPageIndex == subPageIndex
        );
        if (existingRef) {
          existingRef.diagramId = ref.diagramId;
        } else {
          selectedPage.subPageRefs.push({
            page: ref.page,
            pageId: ref.pageId,
            diagram: ref.diagram,
            diagramId: ref.diagramId,
            subPageIndex: editor.subPageIndex + 1,
            headerLayout: ref.headerLayout,
            footerLayout: ref.footerLayout,
            backgroundLayout: ref.backgroundLayout,
            titleHeight: ref.titleHeight,
            maxTitleHeight: ref.maxTitleHeight,
            titleLayout: ref.titleLayout,
            showTitle: ref.showTitle,
          });
        }
        EventBus.$emit(EventBusActions.DIAGRAM_UNLINKED);
      } else if (
        ref.subPageIndex > editor.subPageIndex &&
        ref.subPageIndex != subPageCount - 1
      ) {
        ref.subPageIndex++;
      }
    }
  }

  /**
   * Called by the editor page layout plugin whenever a page is moved
   */
  public static onPageMoved(
    editor: Editor,
    sourcePageIndex: number,
    targetPageIndex: number
  ): void {
    // TODO implement as needed
  }

  /**
   * Called by the editor page layout plugin whenever a subpage is removed
   */
  public static onPageRemoved(editor: Editor, subPageIndex: number): void {
    if (subPageIndex == editor.subPageIndex) {
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_SELECTED_SUBPAGE_INDEX}`,
        subPageIndex - 1
      );
    }

    const selectedPage = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ] as DocumentPageDto;

    if (!this.isUnlinkedDiagramAvailable) {
      return;
    }
    // Shift unlinked diagrams up
    if (selectedPage.subPageRefs) {
      const hasFirstPageRef = selectedPage.subPageRefs.some(
        (r) => r.subPageIndex == 0
      );
      if (!hasFirstPageRef) {
        for (const ref of selectedPage.subPageRefs) {
          if (ref.subPageIndex >= editor.subPageIndex) {
            ref.subPageIndex--;
          }
        }
      }
    }
  }

  /**
   * Called by the editor page layout plugin whenever a an element is inserted into the
   * background subpage (subpage that is not currently selected)
   */
  public static onPageJumped(editor: Editor, pageIndex: number): void {
    const isFocused = editor.editing.view.document.isFocused;

    if (!isFocused) {
      return;
    }

    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_SELECTED_SUBPAGE_INDEX}`,
      pageIndex
    );
  }

  /**
   * Called by the editor page layout plugin before an empty page is about to be removed
   */
  public static canRemovePage(editor: Editor, pageIndex: number): boolean {
    if (pageIndex == 0) {
      return false;
    }

    // Prevent removing pages with unlinked diagrams
    const selectedPage = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ] as DocumentPageDto;

    const subPageRef = selectedPage?.subPageRefs?.find(
      (r) => r.subPageIndex == pageIndex
    );

    if (!subPageRef) {
      return true;
    } else {
      const refCount = selectedPage.subPageRefs.filter(
        (r) => r.diagramId == subPageRef?.diagramId
      ).length;
      // Allow removing pages with unlinked diagrams if it spans over multiple pages
      return refCount > 1;
    }
  }

  /**
   * Return true to add / remove whitespace when merging / splitting paragraphs
   */
  public static paragraphsWhiteSpace(): boolean {
    return ContentPagination.splitParagraphsBetweenPages == 'selection';
  }

  /**
   * Should return the current subpage index to render within the editor
   * Return null to render all subpages
   */
  public static getPageIndex(editor: Editor): number | null {
    return editor.subPageIndex ?? 0;
  }

  /**
   * Split editor content into subpages and return last element id of each page
   * It is used by the page layout plugin to determine where to insert automatic page breaks
   */
  public static splitContent(editor: Editor): PageElementData[] {
    if (!this.paginationEnabled) {
      return [];
    }
    const editorDomRoot = editor.editing?.view?.getDomRoot();
    if (!editor.pageId || !editorDomRoot) {
      return null;
    }
    const page = DocumentService.currentDocument.pages.find(
      (p) => p.id == editor.pageId
    );
    if (!page) {
      return null;
    }
    const selectedPage = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ] as DocumentPageDto;

    // Can't use editor.getData() as it returns the processed view (strips filler elements)
    // We need raw WYSIWYG content directly from the editor for pagination to work correctly
    // https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_filler.html
    const contentHtml = this.fixEditorOutputTables(editorDomRoot.innerHTML);
    const paginatedContent = ContentPagination.splitRawContentIntoPages(
      contentHtml,
      editor.columns ?? 0,
      DocumentService.currentDocument,
      page,
      editor.subPageIndex
    );
    const data: PageElementData[] = [];

    for (let i = 0; i < paginatedContent.length; i++) {
      const item = paginatedContent[i];
      const elements = item.element.querySelectorAll(
        `[${this.elementIdAttribute}]`
      );
      if (elements.length === 0) {
        data.push(null);
        continue;
      }

      const lastPageElement = elements[elements.length - 1];
      const lastPageElementData: PageElementData = {
        elementId: lastPageElement.getAttribute(this.elementIdAttribute),
        overflow: lastPageElement.hasAttribute(
          ContentPagination.paragraphOverflowAttribute
        ),
      };
      data.push(lastPageElementData);

      // Find all the split paragraps on the page and set splitOffset to the paragraph content size
      if (i < paginatedContent.length - 1) {
        const splitParagraph = paginatedContent[i + 1].element.querySelector(
          `[${this.elementIdAttribute}="${lastPageElementData.elementId}"]`
        );
        if (splitParagraph) {
          // Ignore CKEditor filler characters
          // https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_filler.html
          const content = lastPageElement.textContent.replaceAll(
            /\u2060/gm,
            ''
          );
          const contentLength = content.length;

          if (ContentPagination.splitParagraphsBetweenPages == 'words') {
            const lineBreaksCount =
              lastPageElement.getElementsByTagName('br').length;
            lastPageElementData.splitOffset = contentLength + lineBreaksCount;
          } else {
            const lineCount = lastPageElement.childElementCount;
            lastPageElementData.splitOffset = contentLength + lineCount;
          }
        }
      }

      if (
        page == selectedPage &&
        lastPageElement.hasAttribute(ContentPagination.elementOverflowAttribute)
      ) {
        EventBus.$emit(EventBusActions.DOCUMENT_PAGE_CONTENT_OVERFLOW);
      }
    }

    if (
      selectedPage.subPageRefs?.length > 0 &&
      this.isUnlinkedDiagramAvailable
    ) {
      // Always generate enough pages to display all the unlinked diagrams
      const maxRefSubPageIndex = Math.max(
        ...selectedPage.subPageRefs
          .filter((r) => !!r.diagram)
          .map((r) => r.subPageIndex)
      );
      for (let i = data.length; i <= maxRefSubPageIndex; i++) {
        data.push(null);
      }
    }

    return data;
  }

  /**
   * Set page size dynamically based on editor context
   * Used by page layout plugin to set the subpage sizes correctly
   */
  public static getPageSize(editor: Editor): {
    width: number;
    height: number;
  } {
    if (!editor.pageId) {
      return null;
    }
    const page = DocumentService.currentDocument.pages.find(
      (p) => p.id == editor.pageId
    );
    if (!page) {
      return null;
    }
    const pageBodySize = ExportUtils.calculateBodyPartSize(
      DocumentService.currentDocument,
      page,
      'content',
      true,
      editor.subPageIndex
    );
    const size = {
      width: pageBodySize.width * ExportConfig.pointToPixelFactor,
      height: pageBodySize.height * ExportConfig.pointToPixelFactor,
    };
    return size;
  }

  /**
   * Whether to enable page layout plugin for the editor
   */
  public static isPageLayoutEnabled(editor: Editor): boolean {
    return !!editor.pageId;
  }

  /**
   * Remove all pagination-related elements (page, autopagebreak) before passing content into the editor
   * @param [contentHtml] Raw content
   */
  public static sanitizeEditorInput(contentHtml: string): string {
    if (
      !contentHtml ||
      !contentHtml.startsWith(`<${ContentPagination.pageElementTag}`)
    ) {
      return contentHtml;
    }
    const contentElement = htmlToElement(`<div>${contentHtml}</div>`);
    // Remove manual page break if it's the first or last element on page (not supported)
    if (ContentPagination.isManualPageBreak(contentElement.firstElementChild)) {
      contentElement.firstElementChild.remove();
    }
    if (ContentPagination.isManualPageBreak(contentElement.lastElementChild)) {
      contentElement.lastElementChild.remove();
    }
    // Move elements out of their page containers
    contentElement
      .querySelectorAll(ContentPagination.pageElementTag)
      .forEach((page) => (page.outerHTML = page.innerHTML));
    // Remove all auto page breaks elements
    contentElement
      .querySelectorAll('.' + ContentPagination.autoPageBreakClass)
      .forEach((pageBreak) => pageBreak.remove());
    return contentElement.innerHTML;
  }

  /**
   * Remove all temp editor elements from editor output
   * @param [contentHtml] Raw editor output
   */
  public static sanitizeEditorOutput(contentHtml: string): string {
    if (
      !contentHtml ||
      !contentHtml.startsWith(`<${ContentPagination.pageElementTag}`)
    ) {
      return contentHtml;
    }

    // Move all tables out of <figure> tags
    contentHtml = this.fixEditorOutputTables(contentHtml);
    // Remove all auto page breaks elements
    const rx = new RegExp(
      `<div class="${ContentPagination.autoPageBreakClass}"></div>`,
      'gm'
    );
    contentHtml = contentHtml.replace(rx, '');
    // Remove element id attributes
    contentHtml = contentHtml.replace(/ plid="\w+"/gm, '');
    // Remove the rest of zero width spaces
    contentHtml = contentHtml.replaceAll(ZERO_WIDTH_SPACE, '');
    return contentHtml;
  }

  /**
   * Move all tables out of <figure> tags
   */
  private static fixEditorOutputTables(contentHtml: string): string {
    return contentHtml.replace(
      /<figure[^>]*>.*?(<table[^>]*>.*?<\/table>).*?<\/figure>/g,
      '$1'
    );
  }
}
