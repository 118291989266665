import { IElementDataProvider } from './IElementDataProvider';
import ClipArtPaletteBehaviour from '../ClipArtPaletteBehaviour';
import IDocumentPaletteItem from '../IDocumentPaletteItem';
import { DocumentPaletteCategory, DocumentPaletteItemDto } from '@/api/models';
import { ensureFullUri } from '@/core/utils/common.utils';
import DocumentPaletteItemsApiService from '@/api/DocumentPaletteItemsApiService';

export default class CustomClipArtDataProvider implements IElementDataProvider {
  async getElements(): Promise<IDocumentPaletteItem[]> {
    try {
      const items = await (
        await DocumentPaletteItemsApiService.getAll()
      ).data.result;

      return items.map((item: DocumentPaletteItemDto) => {
        return {
          category: DocumentPaletteCategory.CustomClipArt,
          subcategory: item.subcategory,
          img: ensureFullUri(item.path),
          data: item,
          name: item.name,
          text: item.name,
          id: item.id,
          behaviour: ClipArtPaletteBehaviour.INSTANCE,
          canDrag: true,
        };
      });
    } catch (err) {
      console.error('Error trying to load custom clipart');
      console.error(err);
    }

    return [];
  }
}
