<template>
  <div class="flex items-center opacity-40">
    <AppFlyout ref="versionFlyout" placement="right">
      <template #activator>
        <i class="las la-info-circle text-white text-16 mr-8 cursor-pointer" />
      </template>

      <div slot="popper" class="p-4 bg-white shadow-sm rounded-4">
        {{ appVersion }} {{ appReleaseDate }}
      </div>
    </AppFlyout>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import appInfo from '@/app-info.json';
import AppFlyout from '@/components/shared/AppFlyout.vue';
import {
  GET_IS_COMPACT_MENU,
  GET_IS_MENU_EXTENDED,
  GLOBAL_NAMESPACE,
} from '@/core/services/store/global.module';
import { Getter } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppVersion extends Vue {
  $refs!: {
    versionFlyout: AppFlyout;
  };

  /* C O M P U T E D */
  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_COMPACT_MENU}`)
  compactMenu: boolean;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_MENU_EXTENDED}`)
  menuExtended: boolean;

  get appVersion(): string {
    return appInfo.version;
  }

  get appReleaseDate(): string {
    return appInfo.buildDate;
  }

  @Watch('menuExtended')
  menuExtendedChanged(newValue: boolean): void {
    if (this.compactMenu && !newValue) {
      this.$refs.versionFlyout.hide();
    }
  }
}
</script>
