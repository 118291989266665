<template>
  <transition-group
    tag="div"
    enter-active-class="transform duration-200 transition delay-100"
    enter-class="opacity-0 -translate-y-0 translate-x-20"
    enter-to-class="translate-y-0 opacity-100 translate-x-0"
    leave-active-class="absolute w-full transform duration-300 transition"
    leave-class="opacity-100 translate-x-0"
    leave-to-class="opacity-0 translate-x-20"
    move-class="transition transform duration-200 delay-100"
  >
    <Notification
      v-for="item in notifications"
      :key="item.id"
      v-bind="item"
      class="mb-16"
    />
  </transition-group>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import Notification from './Notification.vue';

import { list } from './index';

@Component({
  name: 'AppNotification',
  components: { Notification },
})
export default class AppNotification extends Vue {
  list = list;

  // notification to be displayed
  get notifications() {
    return [...list.list].reverse().slice(0, list.displayCount);
  }
}
</script>
