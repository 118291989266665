import {
  IInputModeContext,
  Point,
  IEdgeStyle,
  PolylineEdgeStyleRenderer,
  Visual,
  IRenderContext,
  SvgVisual,
  IHitTestable,
  IEdge,
  ICanvasContext,
  Rect,
  GeneralPath,
  PolylineEdgeStyle,
  IBend,
} from 'yfiles';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { isViewPortChanging } from '@/core/services/graph/input-modes/JigsawMoveViewportInputMode';
import GraphElementsComparer from '@/core/utils/GraphElementsComparer';
import { isEdgeVisibleAtZoom } from './IsEdgeVisibleAtZoom';
import { PolyLineRenderCache } from './PolyLineRenderCache';
import { EdgeHitTestHelper } from './EdgeHitTestHelper';
import { RenderCacheKey } from '@/core/styles/SvgRenderUtils';
import renderingConfig from '@/core/config/renderingConfig';
import AutomationUtils, {
  SvgElementTypes,
} from '../../analytics/AutomationUtils';
import {
  DefaultBridgeManager,
  VerticalOnOwnershipBridgeManager,
} from '../bridging/NonExclusiveBridgeManager';
export class JigsawPolylineEdgeStyleRenderer extends PolylineEdgeStyleRenderer {
  //@ts-ignore
  get addBridges(): boolean {
    return this.edge.tag?.style?.bridge ?? false;
  }

  getHitTestable(edge: IEdge, style: IEdgeStyle): IHitTestable {
    const hitTestable = super.getHitTestable(edge, style);
    if (hitTestable) {
      this.configure();
      return hitTestable;
    }
    return null;
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    if (isViewPortChanging(context) || !isEdgeVisibleAtZoom(context)) {
      return false;
    }
    return EdgeHitTestHelper.isHit(context, location, this);
  }

  isVisible(context: ICanvasContext, rectangle: Rect): boolean {
    if (isViewPortChanging(context) || !isEdgeVisibleAtZoom(context)) {
      return false;
    }

    return super.isVisible(context, rectangle);
  }

  createRenderDataCache(
    context: IRenderContext,
    edge: IEdge
  ): PolyLineRenderCache {
    const polylineEdgeStyle = edge.style as PolylineEdgeStyle;
    const pathGenerator = (): GeneralPath => {
      let path = new GeneralPath();
      path.moveTo(edge.sourcePort!.location);
      edge.bends.forEach((bend: IBend): void => {
        path.lineTo(bend.location);
      });

      path.lineTo(edge.targetPort!.location);
      // apply smooth
      if (polylineEdgeStyle.smoothingLength > 0) {
        path = path.createSmoothedPath(polylineEdgeStyle.smoothingLength);
      }

      if (this.addBridges) {
        path = this.createPathWithBridges(context, path, edge);
      }

      return path;
    };

    return {
      stroke: polylineEdgeStyle.stroke,
      path: pathGenerator(),
      zoom: context.zoom,
      sourceArrow: this.getSourceArrow(),
      targetArrow: this.getTargetArrow(),
      isIncluded: edge.tag?.isIncluded ?? true,
      equals: (
        self: PolyLineRenderCache,
        other: PolyLineRenderCache
      ): boolean =>
        self.isIncluded == other.isIncluded &&
        self.zoom == other.zoom &&
        GraphElementsComparer.strokesEqual(self.stroke, other.stroke) &&
        GraphElementsComparer.arrowsEqual(
          self.sourceArrow,
          other.sourceArrow
        ) &&
        GraphElementsComparer.arrowsEqual(
          self.targetArrow,
          other.targetArrow
        ) &&
        self.path.hasSameValue(other.path),
    };
  }

  render(
    context: IRenderContext,
    edge: IEdge,
    container: SVGGElement,
    cache: PolyLineRenderCache
  ): void {
    // store information with the visual on how we created it
    (container as any)[RenderCacheKey] = cache;

    // Fill for non-selected state
    let path = cache.path;
    if (context.zoom > renderingConfig.arrowVisibilityThreshold) {
      const sourceArrow = this.getArrowVisual(edge, cache, true, context);
      const targetArrow = this.getArrowVisual(edge, cache, false, context);

      if (sourceArrow) {
        container.appendChild(sourceArrow);
      }

      if (targetArrow) {
        container.appendChild(targetArrow);
      }
    }
    if (context.zoom > renderingConfig.edgeCroppingThreshold) {
      path = this.cropPath(cache.path);
    }
    const svgPath = path.createSvgPath();
    svgPath.setAttribute('fill', 'none');

    cache.stroke?.applyTo(svgPath, context);

    DiagramUtils.updateSvgFilteredStyles(this.edge, svgPath);

    container.appendChild(svgPath);
  }

  private getArrowVisual(
    edge: IEdge,
    cache: PolyLineRenderCache,
    sourceEnd: boolean,
    context: IRenderContext
  ): SVGElement {
    var arrow = sourceEnd ? cache.sourceArrow : cache.targetArrow;
    if (!arrow) {
      return;
    }
    var arrowAnchor = sourceEnd
      ? this.getSourceArrowAnchor(arrow)
      : this.getTargetArrowAnchor(arrow);
    if (!arrowAnchor) {
      return;
    }
    var arrowVisual = arrow
      .getVisualCreator(edge, true, arrowAnchor.point, arrowAnchor.vector)
      .createVisual(context) as SvgVisual;
    return arrowVisual?.svgElement;
  }

  createVisual(context: IRenderContext): Visual | null {
    // This implementation creates a CanvasContainer and uses it for the rendering of the edge.
    const g = window.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );
    // Get the necessary data for rendering of the edge
    const cache = this.createRenderDataCache(context, this.edge);
    // Render the edge
    this.render(context, this.edge, g, cache);

    AutomationUtils.attachAutomationIdToSvg(g, SvgElementTypes.Edge);
    return new SvgVisual(g);
  }

  updateVisual(context: IRenderContext, oldVisual: SvgVisual): Visual {
    const container = oldVisual.svgElement as SVGGElement;
    // get the data with which the oldvisual was created
    const oldCache = (container as any)[RenderCacheKey];
    // get the data for the new visual
    const newCache = this.createRenderDataCache(context, this.edge);

    // check if something changed
    if (newCache.equals(newCache, oldCache)) {
      // nothing changed, return the old visual
      return oldVisual;
    }
    // something changed - re-render the visual
    while (container.hasChildNodes()) {
      // remove all children
      container.removeChild(container.firstChild!);
    }
    this.render(context, this.edge, container, newCache);

    AutomationUtils.attachAutomationIdToSvg(
      oldVisual.svgElement,
      SvgElementTypes.Edge
    );

    return oldVisual;
  }

  isInBox(context: IInputModeContext, rectangle: Rect): boolean {
    if (!isEdgeVisibleAtZoom(context)) {
      return false;
    }
    return super.isInBox(context, rectangle);
  }

  private createPathWithBridges(
    context: IRenderContext,
    path: GeneralPath,
    edge: IEdge
  ): GeneralPath {
    // NOTE:Disabling vertical bridge manager until we can support vertical bridges in PPT export (Task 28658)
    //
    // const doNotConsiderForBridge =
    //   edge?.tag?.name === 'OWNERSHIP' && edge?.bends?.size <= 2;
    // if (doNotConsiderForBridge) {
    //   return path;
    // }

    const defaultBridgeManager = this.edge.lookup(DefaultBridgeManager.$class);
    // const specialBridgeManager = this.edge.lookup(
    //   VerticalOnOwnershipBridgeManager.$class
    // );

    if (defaultBridgeManager === null) {
      return path;
    }

    let modPath = defaultBridgeManager.addBridges(context, path) ?? path;
    //modPath = specialBridgeManager.addBridges(context, modPath) ?? modPath;
    return modPath;
  }
}
