<template>
  <div class="inline-flex w-full flex-col" :class="!fullWidth && 'max-w-280'">
    <label v-if="label" :for="inputId" :class="inputFieldClasses">
      {{ label }}
    </label>

    <div
      class="inline-flex items-center ring-1 transition focus-within:z-1 focus-within:ring-2"
      :class="[inputClasses, containerOffsetClass, roundedClass]"
    >
      <!-- Prepend items -->
      <slot v-if="isPrependSlot" name="prepend" :clear="clear"></slot>
      <span
        v-if="!isPrependSlot && prependIconClass"
        class="pl-12 text-20 text-main-dark-40"
        :class="prependIconClass"
      />

      <!-- Input -->
      <input
        :id="inputId"
        v-mask="mask"
        ref="input"
        v-model="modelValue"
        :type="isPasswordShown ? 'text' : type"
        v-bind="$attrs"
        v-on="listeners"
        class="w-full min-w-1 flex-shrink rounded-4 bg-transparent outline-none"
        :class="[padding, fontSizeClass, inputFieldClasses, inputCustomClasses]"
        :disabled="disabled"
        :readonly="isReadonly"
        @focus="focusHandler"
        @blur="blurHandler"
      />

      <!-- Append items -->
      <template v-if="clearable">
        <button
          v-if="!isEmpty"
          class="group mr-4 inline-flex rounded-4 p-4 transition focus-within:bg-accent-purple hover:bg-main-dark-05"
          @click="clear"
        >
          <AppIcon
            name="close"
            class="text-main-dark-40 transition group-hover:text-main-dark"
          />
        </button>
      </template>

      <template v-else>
        <span
          v-if="isPassword"
          class="cursor-pointer pr-12 text-20 text-main-dark-60"
          :class="isPasswordShown ? 'icon-hide' : 'icon-show'"
          @click="isPasswordShown = !isPasswordShown"
        />

        <template v-else>
          <slot v-if="isAppendSlot" name="append" :clear="clear"></slot>
          <span
            v-if="!isAppendSlot && appendIconClass"
            class="pr-12 text-20 text-main-dark-40"
            :class="appendIconClass"
          />
        </template>
      </template>
    </div>

    <slot name="error-text">
      <p v-if="errorText" class="mb-0 ml-16 mt-4 text-base text-danger-red">
        {{ errorText }}
      </p>
    </slot>
  </div>
</template>

<script lang="ts">
import { VNode } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';

type inputSizes = 'xs' | 'sm' | 'md' | 'lg';

const sizeClasses = {
  xs: 'h-24 max-h-24',
  sm: 'h-32 max-h-32',
  md: 'h-40 max-h-40',
  lg: 'h-52 max-h-52',
};

@Component({
  name: 'AppInput',
  inheritAttrs: false,
})
export default class AppInput extends Vue {
  @Prop({ required: true })
  value: string;

  @Prop({ default: null })
  label: string | null;

  @Prop({ default: null })
  mask: string | null;

  @Prop({ default: null })
  id: string | number;

  @Prop({ default: 'text' })
  type: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  readonly: boolean;

  @Prop({ default: false, type: Boolean })
  autoFill: boolean;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  @Prop({ default: '' })
  errorText: string;

  @Prop({ default: false, type: Boolean })
  isError: boolean;

  @Prop({ default: null })
  appendIcon: string;

  @Prop({ default: null })
  prependIcon: string;

  @Prop({ default: false, type: Boolean })
  borderless: boolean;

  @Prop({ default: false, type: Boolean })
  noOutline: boolean;

  @Prop({ default: false, type: Boolean })
  clearable: boolean;

  @Prop({ default: 'md', type: String })
  size: inputSizes;

  @Prop({ default: 'px-16', type: String })
  padding: string;

  @Prop({ default: 'bg-transparent' })
  backgroundColor: string;

  @Prop({ default: false, type: Boolean })
  freeSize: boolean;

  @Prop({ default: 'text-14', type: String })
  fontSizeClass: string;

  @Prop({ default: 'ring-main-dark-20', type: String })
  borderClass: string;

  @Prop({ default: 'p-4 m-2', type: String })
  containerOffsetClass: string;

  @Prop({ default: 'rounded-4', type: String })
  roundedClass: string;

  @Prop({
    default: 'text-main-dark-40 cursor-not-allowed select-none',
    type: String,
  })
  disabledTextClass: string;

  @Prop({
    default: 'ring-main-dark-10 cursor-not-allowed',
    type: String,
  })
  disabledInputClass: string;

  @Prop({ default: '', type: String })
  inputCustomClasses: string;

  $refs: {
    input: HTMLInputElement;
  };

  private _uid: string;
  inputId: string | number;
  isPasswordShown = false;
  private isFocused = false;

  // v-model
  get modelValue(): string {
    return this.value;
  }

  set modelValue(value: string) {
    this.$emit('input', value);
  }

  get listeners(): {
    [x: string]: Function | Function[];
  } {
    const { input, ...listeners } = this.$listeners; // exclude `input` listener
    return listeners;
  }

  get isPassword(): boolean {
    return this.type === 'password';
  }

  get isPrependSlot(): VNode[] {
    return this.$slots.prepend;
  }

  get isAppendSlot(): VNode[] {
    return this.$slots.append;
  }

  get appendIconClass(): string {
    return this.appendIcon && `icon-${this.appendIcon}`;
  }
  get prependIconClass(): string {
    return this.prependIcon && `icon-${this.prependIcon}`;
  }

  get isReadonly(): boolean {
    return (!this.autoFill && !this.isFocused) || this.readonly;
  }

  get isEmpty(): boolean {
    return !this.modelValue.trim();
  }

  get outlineClass(): string {
    return this.noOutline
      ? 'focus-within:transparent'
      : 'focus-within:ring-accent-purple';
  }

  get inputClasses(): string[] {
    return [
      this.backgroundColor,
      this.borderless ? 'ring-transparent' : this.borderClass,
      this.disabled && this.disabledInputClass,
      this.errorText || this.isError ? 'ring-danger-red' : this.outlineClass,
      this.freeSize ? '' : sizeClasses[this.size],
    ];
  }
  get inputFieldClasses(): string {
    return !this.disabled ? 'text-main-dark' : this.disabledTextClass;
  }

  created(): void {
    this.inputId = this.id || this._uid;
  }

  focusHandler(): void {
    this.isFocused = true;
  }
  blurHandler(): void {
    this.isFocused = false;
  }

  focus(): void {
    this.$nextTick(() => {
      this.$refs.input?.focus();
    });
  }

  blur() {
    this.$nextTick(() => {
      this.$refs.input?.blur();
    });
  }

  select() {
    this.$nextTick(() => {
      this.$refs.input?.select();
    });
  }

  clear(): void {
    this.$emit('input', '');
  }
}
</script>
