export enum PortalNames {
  DocumentToolbar = 'DocumentToolbar',
  PageLists = 'PageLists',
  PagesListToolbar = 'PagesListToolbar',
  PopUps = 'PopUps',
  SidebarLayouts = 'SidebarLayouts',
  Debug = 'Debug',
  QuickPanel = 'QuickPanel',
  LayoutEditorHandles = 'LayoutEditorHandles',
  DiagramControls = 'DiagramControls',
  DataPanel = 'DataPanel',
  DataPanelAddEditProperty = 'DataPanelAddEditProperty',
}
