import IManageApiService from '@/api/IManageApiService';
import axios from 'axios';
import appConfig from '@/core/config/appConfig';
import { EventBus, EventBusActions } from './events/eventbus.service';
import appInfo from '@/app-info.json';
import AuthService from './auth/AuthService';
import i18nService from './i18n.service';
import i18n from '@/core/plugins/vue-i18n';
import IManageAuthService from './auth/IManageAuthService';
import httpStatusCodes from '../config/httpStatusCodes';
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    axios.defaults.baseURL = appConfig.apiBaseUrl;

    axios.interceptors.request.use(
      async (request) => {
        if (AuthService.isLoggedIn) {
          const accessToken = await AuthService.getAccessToken();
          request.headers.common['Authorization'] = 'Bearer ' + accessToken;
          const acceptLanguage = `${i18nService.getActiveLanguage()},${
            i18nService.defaultLanguage
          };q=0.9`;
          request.headers.common['Accept-Language'] = acceptLanguage;
        }
        return request;
      },
      (error) => {
        console.error(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.versionCheck(response.headers);
        this.blockedRequestCheck(response.headers);
        return response;
      },
      async (error) => {
        if (error.response.status !== 403) {
          console.error(error);
        }

        const ignoreError = error.config?.clientConfig?.ignoreError ?? false;
        if (ignoreError) {
          return;
        }

        const hasResponse = error?.response;

        if (hasResponse) {
          this.versionCheck(error.response.headers);
          this.blockedRequestCheck(error.response.headers);
          if (error.response.status !== 403) {
            console.error(
              `API Error [${error.response.status}] @ ${error.config.url}`
            );
          }
        }

        let defaultPromise = new Promise((resolve, reject) => {
          reject(error);
        });

        // Anything other than a 401 is not what we are trying to deal with
        // only on a 401 should we get passed this
        //
        if (!hasResponse) {
          return defaultPromise;
        }

        if (error.response.status !== httpStatusCodes.UNAUTHORIZED) {
          return defaultPromise;
        }

        // Process IManage 401's
        if (
          error.response.status == httpStatusCodes.UNAUTHORIZED &&
          error?.response?.config?.url?.includes('/api/services/app/IManage/')
        ) {
          await IManageAuthService.login();
        }
        // Try to refresh access token on 401 response
        await AuthService.refreshAccessToken();
      }
    );
  },

  versionCheck(headers) {
    if (headers['appversion'] != appInfo.version) {
      const payload = {
        title: i18n.t('APPLICATION_UPDATED_TITLE'),
        message: i18n.t('APPLICATION_UPDATED_MESSAGE'),
        refresh: true,
        navigate: 'landing',
      };
      EventBus.$emit(EventBusActions.APPLICATION_ERROR, payload);
    }
  },
  blockedRequestCheck(headers) {
    if (!headers['jresponse']) {
      const payload = {
        title: i18n.t('POTENTIAL_NETWORK_ERROR_TITLE'),
        message: i18n.t('POTENTIAL_NETWORK_ERROR_MESSAGE'),
        refresh: false,
        navigate: 'landing',
      };
      EventBus.$emit(EventBusActions.APPLICATION_ERROR, payload);
    }
  },
};

export default ApiService;
