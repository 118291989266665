import {
  INodeStyle,
  IRenderContext,
  INode,
  Visual,
  SvgVisualGroup,
  SvgVisual,
  IInputModeContext,
  Rect,
  Point,
  GeneralPath,
  VoidNodeStyle,
  ICanvasContext,
  NodeStyleBase,
  IRectangle,
  GraphEditorInputMode,
} from 'yfiles';
import JigsawNodeDecorator from './decorators/JigsawNodeDecorator';
import SvgRenderUtils, { RenderCacheKey } from '@/core/styles/SvgRenderUtils';
import { isViewPortChanging } from '@/core/services/graph/input-modes/JigsawMoveViewportInputMode';
import AutomationUtils, {
  SvgElementTypes,
} from '@/core/services/analytics/AutomationUtils';
import FilterDecorators from './decorators/FilterDecorators';
import IndicatorDecorators from './decorators/IndicatorDecorators';
import JurisdictionDecorator from './decorators/JurisdictionDecorator';
import HighlightDecorator from './decorators/HighlightDecorator';
import DiagramUtils from '../utils/DiagramUtils';
import SvgDefs from '../services/graph/SvgDefs';
import { intersects } from '../utils/common.utils';
import GroupNodeStyle from './GroupNodeStyle';
import TextBoxNodeStyle from './TextBoxNodeStyle';
import DividingLineNodeStyle from './DividingLineNodeStyle';
import MovableDecorator from './decorators/MovableDecorator';
import JigsawTextEditorInputMode from '../services/graph/input-modes/text-editor/JigsawTextEditorInputMode';
import { AnnotationType } from '../common/AnnotationType';

const VisualGroupNames = {
  Base: 'BASE-VISUAL',
  Decorator: 'DECORATOR-VISUAL',
  Selection: 'SELECTION-VISUAL',
};
/**
 * Jigsaw node style
 */

interface IRenderCache {
  layout: IRectangle;
}
export default class JigsawNodeStyle extends NodeStyleBase {
  private _baseStyle: INodeStyle;

  public get hasDecorators(): boolean {
    return this._decorators != null && this._decorators.length > 0;
  }
  private _decorators: JigsawNodeDecorator[] = [];
  public get decorators(): JigsawNodeDecorator[] {
    return [...this._decorators];
  }
  public get baseStyle(): INodeStyle {
    return this._baseStyle;
  }
  /**
   * Creates an instance of jigsaw node style.
   * @param baseStyle
   * @param [decorators] An array of decorators to attach to the node
   */
  constructor(baseStyle: INodeStyle, decorators?: JigsawNodeDecorator[]) {
    super();
    this._baseStyle = baseStyle;

    // Defining a standard set of decorators to apply to a node, if decorators are supplied then no defaults should be applied
    this._decorators = decorators ?? [
      IndicatorDecorators.INSTANCE,
      FilterDecorators.INSTANCE,
      JurisdictionDecorator.INSTANCE,
      HighlightDecorator.INSTANCE,
    ];
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    if (this._baseStyle instanceof VoidNodeStyle) {
      return null;
    }

    let baseVisual = this.baseStyle.renderer
      .getVisualCreator(node, this.baseStyle)
      .createVisual(context);
    let group = new SvgVisualGroup();
    SvgRenderUtils.addToGroup(
      group,
      baseVisual as SvgVisual,
      VisualGroupNames.Base
    );
    let decoratorVisualGroup = SvgRenderUtils.createDecorationSvgVisual(
      context,
      node,
      this._decorators
    );
    SvgRenderUtils.addToGroup(
      group,
      decoratorVisualGroup,
      VisualGroupNames.Decorator
    );

    DiagramUtils.updateSvgFilteredStyles(
      node,
      group.svgElement,
      SvgDefs.definitions.SEARCH_BLUR
    );

    let svgElement = (baseVisual as SvgVisual).svgElement;
    AutomationUtils.attachAutomationIdToSvg(svgElement, SvgElementTypes.Node);
    group[RenderCacheKey] = this.createCache(node);
    return group;
  }

  private createCache(node: INode): IRenderCache {
    return {
      layout: node.layout,
    };
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: SvgVisual,
    node: INode
  ): SvgVisual {
    if (this._baseStyle instanceof VoidNodeStyle) {
      return null;
    }

    if (isViewPortChanging(context)) {
      return oldVisual;
    }

    let oldVisualGroup = oldVisual as SvgVisualGroup;
    let baseVisual = oldVisualGroup.children.get(0);
    const newBaseVisual = this.baseStyle.renderer
      .getVisualCreator(node, this.baseStyle)
      .updateVisual(context, baseVisual) as SvgVisual;

    if (newBaseVisual != baseVisual) {
      SvgRenderUtils.setVisualName(newBaseVisual, VisualGroupNames.Base);
      oldVisualGroup.children.set(0, newBaseVisual);
      AutomationUtils.attachAutomationIdToSvg(
        baseVisual.svgElement,
        SvgElementTypes.Node
      );
    }

    // update
    let oldDecoratorGroup = oldVisualGroup.children.elementAt(
      1
    ) as SvgVisualGroup;

    const decoratorGroup = SvgRenderUtils.updateDecorationSvgVisual(
      context,
      oldDecoratorGroup,
      node,
      this._decorators
    );

    if (oldDecoratorGroup != decoratorGroup) {
      oldVisualGroup.children.set(1, decoratorGroup);
    }
    DiagramUtils.updateSvgFilteredStyles(
      node,
      oldVisualGroup.svgElement,
      SvgDefs.definitions.SEARCH_BLUR
    );

    return (node[RenderCacheKey] = oldVisualGroup);
  }

  isHit(
    inputModeContext: IInputModeContext,
    location: Point,
    node: INode
  ): boolean {
    if (
      this._baseStyle instanceof GroupNodeStyle ||
      this._baseStyle instanceof TextBoxNodeStyle
    ) {
      return this._baseStyle.isHit(inputModeContext, location, node);
    }

    if (
      this._baseStyle instanceof DividingLineNodeStyle ||
      (node.tag.isAnnotation &&
        node.tag.annotationType === AnnotationType.ArrowHead)
    ) {
      return this._baseStyle.renderer
        .getHitTestable(node, this._baseStyle)
        .isHit(inputModeContext, location);
    }
    return node.layout.contains(location);
  }

  isInBox(context: IInputModeContext, rectangle: Rect, node: INode): boolean {
    // return only box containment test of baseStyle - we don't want the decoration to be marquee selectable
    return this.baseStyle.renderer
      .getMarqueeTestable(node, this.baseStyle)
      .isInBox(context, rectangle);
  }

  getIntersection(node: INode, inner: Point, outer: Point): Point | null {
    return this.baseStyle.renderer
      .getShapeGeometry(node, this.baseStyle)
      .getIntersection(inner, outer);
  }

  isInside(node: INode, location: Point): boolean {
    // return only inside test of baseStyle
    return this.baseStyle.renderer
      .getShapeGeometry(node, this.baseStyle)
      .isInside(location);
  }

  getOutline(node: INode): GeneralPath {
    return this.baseStyle.renderer
      .getShapeGeometry(node, this.baseStyle)
      .getOutline();
  }

  isVisible(context: ICanvasContext, rectangle: Rect, node: INode): boolean {
    return intersects(node.layout, rectangle);
  }

  clone(): any {
    const nodeStyle = new JigsawNodeStyle(this.baseStyle.clone());
    nodeStyle._decorators = [...this._decorators];
    return nodeStyle;
  }

  getBounds(context: ICanvasContext, node: INode): Rect {
    const nodeBounds = this.baseStyle.renderer
      .getBoundsProvider(node, this.baseStyle)
      .getBounds(context)
      .toMutableRectangle();

    // getBounds is not being called for edited labels
    // so we need to include them in the owner node's bounds instead
    // (there may be a better way of doing this)
    const textEditorInputMode = (
      context.canvasComponent.inputMode as GraphEditorInputMode
    )?.textEditorInputMode as JigsawTextEditorInputMode;
    if (
      textEditorInputMode?.editing &&
      node.labels.includes(textEditorInputMode.label)
    ) {
      nodeBounds.add(textEditorInputMode.label.layout.bounds);
    }

    if (this._decorators) {
      for (const decorator of this._decorators) {
        if (!(decorator instanceof MovableDecorator)) {
          continue;
        }
        const decoratorBounds = decorator.getLayout(node);
        nodeBounds.add(decoratorBounds);
      }
    }
    return nodeBounds.toRect();
  }

  addDecorator(decorator: JigsawNodeDecorator): void {
    this._decorators.push(decorator);
  }
  removeDecorator(decoratorType: string): void {
    let existingDecoratorIndex = this._decorators.findIndex(
      (x) => x.$class === decoratorType
    );
    if (existingDecoratorIndex >= 0) {
      this._decorators.splice(existingDecoratorIndex, 1);
    }
  }
  removeAllDecorators(): void {
    this._decorators = [];
  }
}
