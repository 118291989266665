import {
  DashStyleDto,
  DashStyleType,
  FillDto,
  FontDto,
  StrokeDto
} from '@/api/models';
import JInsets from '../common/JInsets';
import JSize from '../common/JSize';
import GroupNodeStyle from '../styles/GroupNodeStyle';

// IMPORTANT: All the measurements are in points (pt) unless stated otherwise
export default {
  // TODO may not be needed
  // usablePageAreaMargins: new JSize(7, 0), // Margins to apply to page size (usable page area size in PDFMake is slighly smaller)
  usablePageAreaMargins: new JSize(0, 0), // Margins to apply to page size (usable page area size in PDFMake is slighly smaller)
  minDiagramSize: new JSize(100, 100), // Minimum size of the exported diagram canvas
  pageLogoMargin: 5,
  pageContentClass: 'document-page-content',
  diagramContentClass: 'diagram-content',
  outerPageContainerClass: 'outer-page-container',
  pageContainerClass: 'page-container',
  webViewClass: 'web-view',
  innerBodyContainerClass: 'inner-body-container',
  documentContainerClass: 'document-container',
  tooltipCssClasses: 'text-14 text-white bg-main-dark p-16',
  graphOverlayClass: 'graph-overlay',
  minSplitRatio: 0.2,
  defaultDividerStyle: new StrokeDto(
    2,
    new FillDto('#4747ff'),
    new DashStyleDto([], 0, DashStyleType.Dash)
  ),
  minDiagramPadding: new JInsets(4), // (For split and diagram page types) minimum padding for the diagram
  minHtmlContentColumnGap: 10, // Minimum gap between two columns in a Text | Text page (in points)
  maxHtmlContentColumnGap: 400, // Max gap between two columns in a Text | Text page (in points)
  minHtmlContentPadding: new JInsets(4), // (For split and content page types) minimum padding for the html content
  pptContentPaddingAdjustment: new JInsets(-1.6), // Adjust padding for PowerPoint exports to match HTML word wrapping
  maxDiagramLogoSize: new JSize(112.5, 45),
  diagramLegendScale: {
    extraExtraLarge: 1.5,
    extraLarge: 1.2,
    large: 1.1,
    medium: 1,
    small: 0.9,
    extraSmall: 0.8
  },
  diagramLegendSymbolImageScale: 3,
  masterLegendScaleMultiplier: 1.2,
  defaultContentFontStyle: new FontDto(
    12,
    'Arial',
    'normal',
    'normal',
    'none',
    '#000000'
  ),
  innerDiagramMargins: new JInsets(12), // Inner diagram svg margins (in px)
  /**
   * Gap between columns in Text | Text pages
   */
  /**
   * Scale of the exported page thumbnail image
   */
  pageThumbScale: 0.2,
  /**
   * Scale of the exported page design thumbnail image
   */
  pageDesignThumbScale: 0.3,
  /**
   * Scale of the images within the exported thumbnail
   */
  thumbImagesScale: 0.2,
  /**
   * Scale of the images within the page design thumbnail
   */
  pageDesignThumbnailImagesScale: 0.2,
  /**
   * Html attribute name used to store the original (unscaled) image url
   */
  thumbOriginalImageKeyHtmlAttribute: 'alt',
  /**
   * Svg attribute name used to store the original (unscaled) image url
   * Needs to be different as dom-to-svg doesn't support custom attributes
   */
  thumbOriginalImageKeySvgAttribute: 'aria-label',
  /**
   * Because of pdfkit we need to use points for all measurements instead of pixels
   * The measurement can be converted from pixels to points using this multiplier (72dpi <=> 96dpi)
   */
  pointToPixelFactor: 96 / 72,
  /**
   * 1cm = 28.3465
   */
  centimeterToPointFactor: 28.3465,

  /**
   * 1inch = 72point
   */
  inchToPointFactor: 72,
  /**
   * 1x50 px blank image to fix an issue with text vertical alignment in header/footer
   */
  blankImageUrl:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAxCAQAAACxZguvAAAADklEQVR42mNkYGAcZAgADu4AMsQuEYIAAAAASUVORK5CYII=',
  /**
   * Html tag name of the placeholder element
   */
  placeholderTagName: 'PLACEHOLDER',
  /**
   * Placeholder element name attribute that helps to identify each element
   */
  placeholderNameAttributeKey: 'name',
  /**
   * Available values of placeholder element name attribute
   */
  placeholderNameAttributeValues: {
    pageNumber: 'pageNumber',
    totalPages: 'totalPages'
  },
  /**
   * Activates the pdfmake playground mode
   */
  pdfmakePlaygroundMode: false,
  /**
   * Attribute used to flag exported low-detail diagram SVGs
   */
  lowDetailDiagramAttribute: 'data-low-detail-diagram',
  /**
   * Attribute used to flag exported low-detail background SVGs
   */
  lowDetailBackgroundAttribute: 'data-low-detail-background',
  /**
   * Font family to use for unsupported characters
   * Known issue with PDFKit: https://github.com/foliojs/pdfkit/issues/201
   */
  fallbackFontName: 'Symbola',
  japaneseFallbackFontName: 'Meiryo',
  /**
   * Regex to use for finding and replacing unsupported characters
   */
  fallbackFontRegex: /(?![\u2013])[\u2000-\uFFFF]+/g,
  japaneseFallbackFontRegex:
    /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u3400-\u4DBF]+/g,
  defaultLineHeight: 1.2
};
