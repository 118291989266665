<template>
  <div class="w-full">
    <slot
      :add-tag="addTag"
      :tags="tagsSync"
      :remove-tag="removeTag"
      :input-handlers="inputHandlers"
    >
      <div
        class="h-max flex items-center flex-wrap gap-8 w-full py-8 px-16 ring-1 ring-main-dark-20 focus-within:ring-2 focus-within:ring-accent-purple rounded-4 transition"
      >
        <span
          v-for="tag in tagsSync"
          :key="tag"
          class="inline-flex items-center gap-8 px-8 py-2 text-black bg-main-dark-20 rounded-4"
        >
          <span class="whitespace-nowrap">{{ tag }}</span>

          <AppIcon
            name="close"
            size="12"
            class="cursor-pointer"
            @click="removeTag(tag)"
          />
        </span>

        <input
          v-model.trim="newTagModel"
          ref="tagInput"
          type="text"
          placeholder="Add tag..."
          class="min-w-200 h-22 flex-grow flex-shrink-0 outline-none"
          @keyup.enter.prevent="addTag"
        />
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppInputTags',
})
export default class AppInputTags extends Vue {
  @Prop({ required: true })
  value: string[];

  newTagModel: string = '';

  $refs: {
    tagInput: HTMLInputElement;
  };

  get tagsSync(): string[] {
    return this.value || [];
  }
  set tagsSync(newTags: string[]) {
    this.$emit('input', newTags);
  }

  get inputHandlers() {
    return {
      // implement rest if needed
      keydown: this.onInputKeydown,
    };
  }

  onInputKeydown(evt: KeyboardEvent) {
    const { code, target } = evt;

    if (code === 'Enter') {
      this.tryAddTag((target as HTMLInputElement).value);
    }
  }

  addTag(tag: string = null): void {
    if (typeof tag === 'string') {
      this.tryAddTag(tag);
    }

    if (this.newTagModel) {
      this.tryAddTag(this.newTagModel);
    }
  }

  removeTag(tagToRemove): void {
    this.tagsSync = this.tagsSync.filter((tag) => tag !== tagToRemove);
  }

  private tryAddTag(tag): void {
    if (!tag.trim()) {
      return;
    }

    const isNew = !this.tagsSync?.includes(tag);

    if (isNew) {
      this.tagsSync = [...this.tagsSync, tag];

      if (this.$refs.tagInput) {
        this.newTagModel = '';
        this.$refs.tagInput.focus();
      }
    }
  }
}
</script>
