import {
  BaseClass,
  Edge,
  IEdge,
  ILayoutAlgorithm,
  LayoutGraph,
  LayoutGraphAdapter,
  LayoutStageBase,
  Mapper,
  YList,
} from 'yfiles';

export class PreserveFixedInLayoutEdges extends BaseClass<LayoutStageBase>(
  LayoutStageBase
) {
  constructor(coreLayout: ILayoutAlgorithm) {
    super(coreLayout);
  }
  applyLayout(graph: LayoutGraph): void {
    const originalEdgeDpKey = graph.getDataProvider(
      LayoutGraphAdapter.ORIGINAL_EDGE_DP_KEY
    );

    // save bends for fixed edges
    const fixedEdges = graph.edges.filter(
      (item) => (originalEdgeDpKey.get(item) as IEdge).tag.isFixedInLayout
    );

    const pointMap = new Mapper<Edge, YList>();

    for (const fixedEdge of fixedEdges) {
      pointMap.set(fixedEdge, graph.getPointList(fixedEdge));
    }

    // apply the layout algorithm
    this.applyLayoutCore(graph);

    // restore the bends for fixed edges.
    for (const fixedEdge of fixedEdges) {
      const points = pointMap.get(fixedEdge);
      graph.setPoints(fixedEdge, points);
    }
  }
}
