import { IEdge, IGraph, IModelItem, Mapper } from 'yfiles';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import IDiagramTypeHelper, { IEdgeRouting } from '../IDiagramTypeHelper';
import DiagramUtils from '@/core/utils/DiagramUtils';
import IEdgeTag from '@/core/common/IEdgeTag';
import INodeTag, { INodeQuickStartData } from '@/core/common/INodeTag';
import {
  EdgeStyleDto,
  ElementType,
  INodeStyleDto,
  RelationshipType,
  ThemeElementDto,
} from '@/api/models';
import {
  DOCUMENT_NAMESPACE,
  GET_THEME_ELEMENT_BY_NAME,
} from '../store/document.module';
import { ModelItemRelationship } from '@/components/DataLinks/IRelationshipProvider';
import { generateUuid } from '@/core/utils/common.utils';
import SystemEntityTypes from './SystemEntityTypes';

export default abstract class CorporateDiagramHelperBase
  implements IDiagramTypeHelper
{
  public isDisposed: boolean;
  public routing: IEdgeRouting = null;
  public edgeNameMapping: Mapper<RelationshipType, string> = null;

  public abstract get graph(): IGraph;
  public abstract dispose(): void;
  public abstract getModelItemRelationships(
    primaryItem: IModelItem,
    items: IModelItem[]
  ): ModelItemRelationship[];
  public abstract init(...args: any[]): void;

  public edgeIs(edge: IEdge, edgeType: RelationshipType): boolean {
    return edge.tag.relationshipType == edgeType;
  }

  createEdgeTagFromThemeElement(themeElement: ThemeElementDto): IEdgeTag {
    const tag = this.createDefaultEdgeTag(themeElement);

    if (themeElement.relationshipType === RelationshipType.Contract) {
      tag.busid = generateUuid();
    }
    tag.name = themeElement.name;
    tag.placeholderText = themeElement?.displayName;
    tag.relationshipType = themeElement.relationshipType;
    tag.entityTypeId = themeElement.defaultEntityTypeId;
    return tag;
  }

  createEdgeTag(edgeType: RelationshipType): IEdgeTag {
    const edgeName = this.edgeNameMapping.get(edgeType);
    const themeElement = this.getThemeElementByName(edgeName, ElementType.Edge);
    return DiagramUtils.createNewEdgeTag(
      edgeName,
      themeElement.style,
      themeElement.relationshipType
    );
  }

  createQuickBuildEdgeTag(): IEdgeTag {
    const themeElement = this.getThemeElementByName(
      this.edgeNameMapping.get(RelationshipType.Ownership),
      ElementType.Edge
    );
    return DiagramUtils.createNewEdgeTag(
      SystemEntityTypes.EDGE_OWNERSHIP,
      cloneDeep(themeElement?.style),
      themeElement.relationshipType
    );
  }

  createQuickBuildNodeTag(): INodeTag {
    const element = this.getThemeElementByName(
      SystemEntityTypes.NODE_CORPORATE,
      ElementType.Node
    );
    return DiagramUtils.createNewNodeTag(
      SystemEntityTypes.NODE_CORPORATE,
      element?.style
    );
  }

  initializeQuickStartData(): INodeQuickStartData {
    return {
      layoutId: 0,
      direction: 'root',
      layer: 0,
      siblingRank: 0,
    };
  }

  private getThemeElementByName(
    name: string,
    elementType: ElementType
  ): ThemeElementDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
    ](name, elementType) as ThemeElementDto;
  }

  getDefaultEdgeStyle(): EdgeStyleDto {
    return null;
  }

  getDefaultNodeStyle(): INodeStyleDto {
    return this.getThemeElementByName(
      SystemEntityTypes.NODE_CORPORATE,
      ElementType.Node
    )?.style;
  }
  createDefaultNodeTag(style?: INodeStyleDto): INodeTag {
    return DiagramUtils.createNewNodeTag(
      SystemEntityTypes.NODE_CORPORATE,
      style ?? this.getDefaultNodeStyle()
    );
  }

  createDefaultEdgeTag(themeElement?: ThemeElementDto): IEdgeTag {
    return DiagramUtils.createNewEdgeTag(
      themeElement?.name ?? SystemEntityTypes.EDGE_OWNERSHIP,
      themeElement?.style ?? this.getDefaultEdgeStyle()
    );
  }

  edgeTypeChanged(edge: IEdge): void {
    if (edge.tag.relationshipType == RelationshipType.Contract) {
      edge.tag.busid = generateUuid();
    }
  }
}
