<template>
  <MountingPortal mountTo="#popups" append bail>
    <div>
      <transition
        enter-active-class="transition duration-200"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transform duration-100 transition"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="value"
          class="pointer-events-auto fixed inset-0 z-50 h-full w-full bg-black bg-opacity-50"
          @click="[closeOnBackdrop && backdropClick()]"
          :data-automation-id="closeOnBackdrop ? closeAutomationId : null"
        />
      </transition>

      <!-- TODO: move transition to it's own component -->
      <transition
        enter-active-class="transform duration-200 delay-100 transition"
        enter-class="opacity-0 translate-y-10"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transform duration-100 transition"
        leave-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-10"
      >
        <div
          v-show="value"
          class="pointer-events-none fixed inset-0 z-50 m-auto flex h-full items-center justify-center"
          :class="{
            'inset-24': fullScreen,
            'overflow-y-hidden': contentVerticalScroll,
          }"
        >
          <AppWrapper
            v-if="!contentOnly"
            :tag="fullScreen ? false : 'div'"
            class="relative flex flex-col"
          >
            <div
              class="modal__main pointer-events-auto relative flex h-auto max-h-full flex-col rounded-8 bg-white pb-24 pt-36 shadow-md"
              :style="mainModalStyles"
              :class="{
                modalClasses,
                'overflow-y-auto': contentVerticalScroll,
              }"
            >
              <!-- Loading -->
              <AppLoadingOverlay class="rounded-8" :loading="loading" />

              <div class="mb-24 flex items-center px-36">
                <slot name="header">
                  <h2 class="flex-grow text-26">
                    {{ title }}
                  </h2>
                </slot>
                <button
                  v-if="showHideButton"
                  class="flex-grow-0"
                  @click="handleClose"
                  :data-automation-id="closeAutomationId"
                >
                  <AppIcon name="close" />
                </button>
              </div>
              <div
                class="flex flex-grow flex-col px-36"
                :class="{ 'overflow-y-auto': contentVerticalScroll }"
              >
                <slot />
              </div>
              <div
                v-if="$scopedSlots.footer"
                :class="footerClasses"
                class="mt-24 border-t-1 border-main-dark-10 px-36 pt-24"
              >
                <slot name="footer" :close="handleClose" />
              </div>
            </div>

            <template v-if="!fullScreen">
              <img
                v-if="isChristmas"
                class="absolute -left-24 -top-34"
                src="/christmas-media/christmass-plant.svg"
              />

              <img
                v-if="isThanksgiving"
                class="absolute -top-24 left-16 w-56"
                src="/thanksgiving-media/thanksgiving-decoration.svg"
              />

              <lottie-player
                v-if="isHalloween && value"
                src="/halloween-media/jigsaw-spider-animation.json"
                background="transparent"
                speed="1"
                class="pointer-events-none absolute right-0 top-full h-174 w-174"
                autoplay
              ></lottie-player>
            </template>
          </AppWrapper>

          <!-- Uses this slot for contentOnly, removing parent layers -->
          <slot v-else />
        </div>
      </transition>
    </div>
    <!-- :class="[{ 'animate-wiggle': isDisallowAnimation }, modalClasses]" -->
  </MountingPortal>
</template>

<script lang="ts">
import {
  GET_HALLOWEEN_THEME,
  GET_THANKSGIVING_THEME,
  GET_CHRISTMAS_THEME,
  GLOBAL_NAMESPACE,
} from '@/core/services/store/global.module';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CSSProperties } from 'vue/types/jsx';

@Component({
  name: 'AppModal',
})
export default class AppModal extends Vue {
  @Prop({ required: true })
  value: boolean;

  @Prop({ default: 'Modal title' })
  title: string;

  @Prop({ default: true })
  closeOnEsc: boolean;

  @Prop({ default: true })
  closeOnBackdrop: boolean;

  @Prop({ default: true })
  showHideButton: boolean;

  @Prop({ default: null })
  beforeClose: (fn: any) => Promise<boolean>;

  @Prop({ default: 560 })
  minWidth: string | number;

  @Prop({ default: 760 })
  maxWidth: string | number;

  @Prop({ default: 'rounded-8' })
  modalClasses: string;

  @Prop({ default: 'p-36' })
  modalPaddingClasses: string;

  @Prop({ default: false })
  loading: boolean;

  @Prop({ default: true })
  contentVerticalScroll: boolean;

  @Prop({ default: '' })
  footerClasses: string;

  @Prop({ default: false, type: Boolean })
  fullScreen: boolean;

  @Prop({ default: false, type: Boolean })
  contentOnly: boolean;

  @Prop({ required: false })
  modalStyles: CSSProperties;

  @Prop({ required: false, default: null })
  closeAutomationId: string;

  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isHalloween: boolean;

  @Getter(GET_THANKSGIVING_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isThanksgiving: boolean;

  @Getter(GET_CHRISTMAS_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isChristmas: boolean;

  get mainModalStyles(): CSSProperties {
    const modalStyles = this.modalStyles ? this.modalStyles : {};

    if (this.fullScreen) {
      return {
        height: 'calc(100% - 48px)', // 48 because each side has 24px offset
        minWidth: '100%',
        maxWidth: '100%',
        ...modalStyles,
      };
    }

    return {
      minWidth: `${this.minWidth}px`,
      maxWidth: `${this.maxWidth}px`,
      ...modalStyles,
    };
  }

  handleClose(): void {
    if (!this.beforeClose) {
      this.close();
      return;
    }

    if (typeof this.beforeClose === 'function') {
      new Promise((resolve, reject) => {
        this.beforeClose({ close: resolve, keep: reject });
      })
        .then(() => {
          console.debug('Closed');
          this.close();
        })
        .catch(() => {
          console.debug('Closing denied');
        });
    }
  }

  backdropClick(): void {
    this.handleClose();
  }

  close(): void {
    this.$emit('input', false);
    this.$emit('closed');
  }

  mounted(): void {
    const escHandler = (evt: KeyboardEvent): void => {
      if (!this.closeOnEsc) return;

      if (evt.key === 'Escape' && this.value) {
        this.handleClose();
      }
    };

    document.addEventListener('keydown', escHandler);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler);
    });
  }
}
</script>
