import CKEditorUtils from '@/core/utils/CKEditorUtils';
import IEditorConfig from '../../common/IEditorConfig';
import ConfigurationBuilder from './ckEditorConfigurationBuilder';
import CommonEditorConfig from './commonEditorConfig';

class BasicEditorConfig implements IEditorConfig {
  getConfig() {
    const config = {
      removePlugins: ['Link', 'LinkUI', 'ExtendedLink'],
      toolbar: {
        onDropdownOpen: CKEditorUtils.onDropdownOpen,
        shouldNotGroupWhenFull: true,
        items: [
          'fontFamily',
          'fontSize',
          '|',
          'specialCharacters',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'alignment',
          '-',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          '|',
          'fontColor',
          'fontBackgroundColor',
          '|',
          // TODO hidden for 2.9
          // 'headingDropdown',
          'lineHeight',
          'customIndentBlock',
          'customOutdentBlock',
        ],
      },
    };

    const mergedConfig = ConfigurationBuilder.configure(
      CommonEditorConfig.getConfig(),
      config
    );
    return mergedConfig;
  }
}

let instance = new BasicEditorConfig();
export default instance;
