const digitsRegEx = /^\d+$/;
export default class Collection<T> {
  constructor(private readonly items: T[]) {
    return new Proxy(this, {
      get(target, key: string): T {
        if (typeof key === 'symbol') {
          return target[key];
        }
        return digitsRegEx.test(key) ? target.items[key] : target[key];
      },
    });
  }
  public indexOf(searchElement: T, fromIndex?: number): number {
    return this.items.indexOf(searchElement, fromIndex);
  }
  public find(
    predicate: (value: T, index: number, obj: T[]) => unknown,
    thisArg?: any
  ): T | undefined {
    return this.items.find(predicate, thisArg);
  }
  public every(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ): boolean {
    return this.items.every(predicate, thisArg);
  }
  public some(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ): boolean {
    return this.items.some(predicate, thisArg);
  }

  public forEach(
    callbackfn: (value: T, index: number, array: T[]) => void,
    thisArg?: any
  ): void {
    return this.items.forEach(callbackfn, thisArg);
  }
  public map<U>(
    callbackfn: (value: T, index: number, array: T[]) => U,
    thisArg?: any
  ): U[] {
    return this.items.map(callbackfn, thisArg);
  }
  public filter(
    predicate: (value: T, index: number, array: T[]) => unknown,
    thisArg?: any
  ): T[] {
    return this.items.filter(predicate, thisArg);
  }
  public push(...items: T[]): void {
    this.items.push(...items);
  }

  public reduce<U>(
    callbackfn: (
      previousValue: U,
      currentValue: T,
      currentIndex: number,
      array: T[]
    ) => U,
    initialValue: U
  ): U {
    return this.items.reduce(callbackfn, initialValue);
  }

  public get length(): number {
    return this.items.length;
  }
}
