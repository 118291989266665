<template>
  <AppModal
    v-model="showModal"
    :close-on-backdrop="false"
    :title="$t('Message')"
  >
    <!-- H E A D E R -->
    <template #header>
      <div class="flex-grow">
        <h2 class="text-26">
          {{ title() }}
        </h2>
      </div>
    </template>
    <!-- C O N T E N T -->
    <AppInput
      v-model.trim="$v.messageInput.$model"
      :placeholder="$t('Message')"
      :error-text="
        !$v.messageInput.required
          ? $t('Message')
          : !$v.messageInput.minLength
          ? $t('THIS_VALUE_IS_TOO_SHORT')
          : !$v.messageInput.maxLength
          ? $t('MESSAGE_CANT_INCLUDE_MORE_THAN_X_CHARACTERS', [maxValueLength])
          : ''
      "
      ref="messageInput"
      auto-fill
      full-width
    />

    <!-- F O O T E R -->
    <template #footer="{ close }">
      <AppButton class="mr-8" @click="close">
        {{ $t('CANCEL') }}
      </AppButton>
      <AppButton
        variant="purple"
        :disabled="$v.messageInput.$error"
        @click="sendMessage"
      >
        {{ $t('SEND') }}
      </AppButton>
    </template>
  </AppModal>
</template>
<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';

import {
  NotificationTypes,
  RealtimeMessageType,
  UsersRealtimeMessageDto,
} from '@/api/models';
import { validationMixin } from 'vuelidate';
import { Validations } from 'vuelidate-property-decorators';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import AppInput from '@/components/shared/AppInput.vue';
import { EventBusActions } from '@/core/services/events/eventbus.service';

@Component({
  name: 'MessageUsersModal',
})
export default class MessageUsersModal extends Mixins(validationMixin) {
  messageInput: string = '';
  loading: boolean = false;
  minValueLength = 3;
  maxValueLength = 124;
  showModal = false;
  user: any = {};

  $refs: {
    messageInput: AppInput;
  };

  eventBusActionsHandlers: Array<{ action: string; method: Function }> = [
    {
      action: EventBusActions.MESSAGE_USER,
      method: this.openModal,
    },
  ];

  @Validations()
  validations = {
    messageInput: {
      required,
      minLength: minLength(this.minValueLength),
      maxLength: maxLength(this.maxValueLength),
    },
  };

  title() {
    return (
      this.$t('Message') +
      ` ${this.user.name} ${this.user.surname} (${this.user.emailAddress}`
    );
  }

  openModal(user: any) {
    this.user = user;
    this.showModal = true;
    this.messageInput = '';
  }

  async sendMessage() {
    const message: UsersRealtimeMessageDto = {
      notificationType: NotificationTypes.Info,
      title: this.messageInput,
      messageType: RealtimeMessageType.Message,
      userIds: [this.user.id],
      payload: JSON.stringify(this.user),
    };
    //await RealTimeMessageApiService.sendMessageToUsers(message);
    this.showModal = false;
  }
}
</script>
