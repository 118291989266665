import Vue from 'vue';
import { IGraph } from 'yfiles/typings/yfiles-api-npm';
import { DiagramDto, DocumentDto, DocumentPageDto } from '@/api/models';
import DiagramChangeHandler from '@/core/services/graph/DiagramChangeHandler';
import IDiagramLegend from './IDiagramLegend';
import IDiagramLegendProps from './IDiagramLegendProps';
import ILegendDefinition from './ILegendDefinition';
import ILegendItemDefinition from './ILegendItemDefinition';
import { ILegendLayoutOptionParams } from './ILegendLayoutOptionParams';
import LegendGenerator from './LegendGenerator';
import LegendItem from './LegendItem';
import { LegendItemType } from './LegendItemType';
import LegendOptions from './LegendOptions';
import LegendSerializer from './LegendSerializer';
import { LegendType } from './LegendType';
import JSize from '@/core/common/JSize';
import ExportPage from '@/core/services/export/ExportPage';
import LegendAsImageProvider from '@/core/services/export/additional-element-providers/LegendAsImageProvider';
import { ExportFormat } from '@/core/services/export/ExportFormat';
import {
  convertSvgElementToDataUrl,
  ImageResult,
} from '@/core/utils/common.utils';

// IMPORTANT: DiagramLegend has to be lazy-loaded, otherwise it would break the dependency chain
const diagramLegendImport = (): Promise<any> =>
  import('@/components/DiagramLegend/DiagramLegend.vue');

export default class LegendUtils {
  // In 2.27, the property order in the stroke DTO is different from 2.30, and we get different hashes.
  // Because in 2.30 keys were created for the new stroke DTO structure, we need to compare
  // the key against both hashes.
  public static getSavedItemDefinition(data: {
    itemsDefinition: ILegendItemDefinition[];
    type: LegendItemType;
    name: string;
    key: string;
    legacyKey?: string;
  }): ILegendItemDefinition {
    if (!data.itemsDefinition) return null;
    return data.itemsDefinition.find(
      (i) =>
        i.type === data.type &&
        (i.key === data.key || (data.legacyKey && i.key === data.legacyKey)) &&
        (!i.name ||
          !data.name ||
          i.name.toLocaleLowerCase() === data.name.toLocaleLowerCase())
    );
  }

  public static generateDefinitionFromGraph(
    graph: IGraph,
    params: ILegendLayoutOptionParams,
    oldDefinition?: ILegendDefinition
  ): ILegendDefinition {
    const generator = new LegendGenerator(graph, oldDefinition);
    const items = generator.generate(!oldDefinition);
    const options = oldDefinition?.options
      ? LegendOptions.deserialize(oldDefinition.options)
      : new LegendOptions(null, params);
    return this.generateDefinitionFromItems(items, options);
  }

  public static generateDefinitionFromItems(
    items: LegendItem[],
    options: LegendOptions
  ): ILegendDefinition {
    const serializedItems = Array.from(new Set(items))
      .filter((i) => !!i)
      .map((i) => i.serialize());

    return <ILegendDefinition>{
      options: options.serialize(),
      items: serializedItems.filter((i) => !i.isExternal),
      displayItems:
        options.legendType != LegendType.Page ? serializedItems : null,
    };
  }

  public static async regenerateDiagramLegendFromGraph(
    document: DocumentDto,
    page: DocumentPageDto,
    diagram: DiagramDto,
    graph: IGraph,
    params: ILegendLayoutOptionParams
  ): Promise<void> {
    if (!diagram.legend) {
      return;
    }
    const definition = LegendSerializer.deserializeDefinition(diagram.legend);
    diagram.legend = LegendSerializer.serializeDefinition(
      this.generateDefinitionFromGraph(graph, params, definition)
    );
    DiagramChangeHandler.invalidateDiagramCache(diagram);
    DiagramChangeHandler.invalidateDiagramCache(diagram);
  }

  public static async createLegendComponent(
    props: IDiagramLegendProps
  ): Promise<IDiagramLegend> {
    const legend = await diagramLegendImport();

    // Dynamically instantiate DiagramLegend Vuejs component
    const legendClass = Vue.extend(legend.default);
    const legendInstance = new legendClass({
      store: Vue.$globalStore,
      propsData: props,
    }) as IDiagramLegend;

    return legendInstance.$mount();
  }

  public static async generateLegendImage(
    document: DocumentDto,
    page: DocumentPageDto
  ): Promise<ImageResult> {
    const legendElement = await this.generateLegendImageSVG(document, page);

    const legendImage = convertSvgElementToDataUrl(legendElement);

    const legendSize = new JSize(
      Number(legendElement.getAttribute('width')),
      Number(legendElement.getAttribute('height'))
    );

    return {
      src: legendImage,
      width: legendSize.width,
      height: legendSize.height,
    };
  }

  public static async generateLegendImageSVG(
    document: DocumentDto,
    page: DocumentPageDto
  ): Promise<SVGElement> {
    const exportPage = new ExportPage(page);
    const legendProvider = new LegendAsImageProvider(
      {
        document: document,
        pages: [exportPage],
        format: ExportFormat.Svg,
      } as any,
      exportPage
    );
    const legendExport = (await legendProvider.get())[0];
    const legendElement = await legendExport.toSvgAsync();
    legendExport.destroy();

    return legendElement;
  }
}
