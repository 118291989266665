import {
  DiagramDto,
  DocumentDto,
  DocumentPageDto,
  QuickStartState,
} from '@/api/models';
import IDisposable from '@/core/common/IDisposable';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import debounce from 'lodash/debounce';
import { GraphComponent } from 'yfiles';
import CacheType from '../caching/CacheType';
import CachingService from '../caching/CachingService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT,
  GET_SELECTED_DIAGRAM,
  GET_SELECTED_PAGE,
  UPDATE_SELECTED_DIAGRAM,
} from '../store/document.module';
import DiagramLayoutHelper from './DiagramLayoutHelper';
import QuickBuildService from './quick-build.service';

import DiagramWriter from './serialization/diagram-writer.service';

class ChangeQueueItem {
  constructor(public eventBusAction: string, public params: any[]) {}

  public equals(other: ChangeQueueItem): boolean {
    return (
      other.eventBusAction == this.eventBusAction &&
      other.params.length === this.params.length &&
      other.params.every((param, index) => param === this.params[index])
    );
  }
}

/**
 * This service should be used to handle most diagram change events
 * Diagram is automatically serialized prior to events firing
 * Duplicate events (same eventBusAction and params) are filtered out
 * All change events are queued, filtered and debounced to improve performance
 */
export default class DiagramChangeHandler implements IDisposable {
  public static $class: string = 'DiagramChangeHandler';
  private readonly MIN_RUN_QUEUE_INTERVAL = 300;

  public isDisposed: boolean;

  private get graphComponent(): GraphComponent {
    return this.graphService.graphComponent;
  }

  private changeEventQueue: ChangeQueueItem[] = [];

  private get currentDocument(): DocumentDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`
    ] as DocumentDto;
  }

  private get selectedPage(): DocumentPageDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ] as DocumentPageDto;
  }

  private get selectedDiagram(): DiagramDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_DIAGRAM}`
    ] as DiagramDto;
  }

  constructor(private graphService: IGraphService) {
    this.graphService = graphService;
  }

  /**
   * Perform additional caching tasks on a diagram after it's been changed
   * E.g. update the cache key (used by the CachingService) and remove old cache entries
   */
  public static invalidateDiagramCache(diagram: DiagramDto): void {
    const date = new Date().getTime();
    diagram.cacheKey = `${diagram.id}-${date}`;
    CachingService.removeByCacheType(CacheType.CommonDiagramNodes);
    CachingService.removeByCacheType(CacheType.SharesCommonDiagramNodes);
    CachingService.removeByCacheType(CacheType.DiagramsWithCommonNodes);
    CachingService.removeByCacheType(CacheType.DiagramLayout);
    CachingService.removeByCacheType(CacheType.LegendDefinition);
  }

  public dispose(): void {
    if (this.isDisposed) return;
    this.emptyChangeEventQueue();
    this.diagramChangedDebounced.cancel();
    this.isDisposed = true;
  }

  /**
   * This method acts a proxy for EventBus.$emit
   * It queues an event and fires it with debounce, serializing the diagram before firing
   * Events with the same params already in the queue will be filtered out
   */
  public enqueueChangeEvent(eventBusAction?: string, ...params: any[]): void {
    if (eventBusAction) {
      const change = new ChangeQueueItem(eventBusAction, params);
      this.addChangeEventToQueue(change);
    }
    this.diagramChangedDebounced();
  }

  /**
   * This method acts a proxy for EventBus.$emit
   * It will fire an event immediately, serializing the diagram before firing
   * Events with the same params already in the queue will be filtered out
   */
  public processChangeEvent(eventBusAction?: string, ...params: any[]): void {
    if (eventBusAction) {
      const change = new ChangeQueueItem(eventBusAction, params);
      this.addChangeEventToQueue(change);
    }
    this.diagramChanged();
  }

  public serializeSelectedDiagram(): void {
    if (!this.selectedDiagram) {
      console.warn('No diagram available for serialization');
      return;
    }
    const oldDiagram = { ...this.selectedDiagram };
    const updatedDiagram = this.serializeDiagram(
      this.selectedPage,
      this.selectedDiagram,
      this.graphComponent
    );

    Vue.$globalStore.commit(
      `${DOCUMENT_NAMESPACE}/${UPDATE_SELECTED_DIAGRAM}`,
      updatedDiagram
    );

    DiagramChangeHandler.invalidateDiagramCache(updatedDiagram);
    EventBus.$emit(EventBusActions.DIAGRAM_CHANGED, oldDiagram, updatedDiagram);
  }

  public serializeDiagram(
    page: DocumentPageDto,
    diagram: DiagramDto,
    graphComponent: GraphComponent
  ): DiagramDto {
    if (diagram.id != graphComponent.graph.tag.diagramId) {
      console.error(
        'Diagram id mismatch when trying to serialize diagram from graph'
      );
      return diagram;
    }
    DiagramLayoutHelper.recalculateDefaultDiagramLayout(
      this.currentDocument,
      page,
      diagram
    );

    // Capture State of Quick Build Parent Child Relationships
    const quickBuildService = this.graphService?.getService<QuickBuildService>(
      QuickBuildService.$class
    );
    if (quickBuildService?.quickStartState === QuickStartState.InProgress) {
      diagram.quickStartStateData = quickBuildService.getQuickStartStateData();
    }

    return DiagramWriter.fromGraphComponent(graphComponent, diagram);
  }

  private addChangeEventToQueue(change: ChangeQueueItem): void {
    const existingChange = this.changeEventQueue.find((i) => i.equals(change));
    if (existingChange) {
      existingChange.params = change.params;
    } else {
      this.changeEventQueue.push(change);
    }
  }

  private runChangeEventQueue(): void {
    const queue = this.changeEventQueue;
    this.emptyChangeEventQueue();
    for (const change of queue) {
      EventBus.$emit(change.eventBusAction, ...change.params);
    }
  }

  private emptyChangeEventQueue(): void {
    this.changeEventQueue = [];
  }

  private diagramChanged(): void {
    if (this.isDisposed) {
      return;
    }

    this.serializeSelectedDiagram();
    this.runChangeEventQueue();
  }

  private diagramChangedDebounced = debounce(() => {
    this.diagramChanged();
  }, this.MIN_RUN_QUEUE_INTERVAL);
}
