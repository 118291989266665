import JRect from '@/core/common/JRect';
import ImageLayoutItem from './ImageLayoutItem';
import LayoutItem from './LayoutItem';
import { LayoutItemType } from './LayoutItemType';
import TextBoxLayoutItem from './TextBoxLayoutItem';
import IImageLayoutTypeOptions from './IImageLayoutItemOptions';
import ITextBoxLayoutItemOptions from './ITextBoxLayoutItemOptions';
import PageNumberLayoutItem from './PageNumberLayoutItem';
import IPageNumberLayoutItemOptions from './IPageNumberLayoutItemOptions';
import DateLayoutItem from './DateLayoutItem';
import IDateLayoutItemOptions from './IDateLayoutItemOptions';

type ItemOptions =
  | IImageLayoutTypeOptions
  | ITextBoxLayoutItemOptions
  | IPageNumberLayoutItemOptions;

export default class LayoutItemFactory {
  public static getItem<T extends LayoutItem>(
    type: LayoutItemType,
    layout: JRect,
    options?: ItemOptions
  ): T {
    let item: LayoutItem = null;

    switch (type) {
      case LayoutItemType.Image:
        item = new ImageLayoutItem(options as IImageLayoutTypeOptions);
        break;
      case LayoutItemType.Text:
        item = new TextBoxLayoutItem(options as ITextBoxLayoutItemOptions);
        break;
      case LayoutItemType.PageNumber:
        item = new PageNumberLayoutItem(
          options as IPageNumberLayoutItemOptions
        );
        break;
      case LayoutItemType.Date:
        item = new DateLayoutItem(options as IDateLayoutItemOptions);
        break;
      default:
        throw Error(`Unrecognized layout item type: ${type}`);
    }

    // Create instance of Rect from plain js object
    if (layout && !(layout instanceof JRect)) {
      layout = new JRect(
        (layout as any).x,
        (layout as any).y,
        (layout as any).width,
        (layout as any).height
      );
    }
    item.layout = layout;
    return item as T;
  }
}
