<template>
  <div class="inline-flex items-center px-1">
    <input
      type="radio"
      class="appearance-none text-24 outline-none"
      :class="inputClasses"
      :name="name"
      :checked="isChecked"
      :id="inputId"
      :disabled="disabled"
      @change="$emit('change', value)"
    />

    <label :for="inputId" class="ml-8 mb-0 text-14" :class="labelClasses">
      <slot />
    </label>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
  name: 'AppRadio',
})
export default class AppRadio extends Vue {
  @Model('change', { type: [String, Number] })
  readonly modelValue!: string | number;

  @Prop({ required: true, type: String })
  name: string;

  @Prop({ default: null, type: [String, Number] })
  value: string | number;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  id: string | number;

  private _uid: string;
  inputId: string | number;

  get isChecked() {
    return this.modelValue === this.value;
  }

  get inputClasses() {
    return [
      this.isChecked ? 'icon-radio-checked' : 'icon-radio-unchecked',
      !this.disabled
        ? 'text-accent-purple hover:text-accent-purple-90 focus:text-accent-purple-80'
        : 'text-main-dark-20 cursor-not-allowed',
    ];
  }

  get labelClasses() {
    return !this.disabled
      ? 'text-main-dark'
      : 'text-main-dark-20 cursor-not-allowed select-none';
  }

  created() {
    this.inputId = this.id || this._uid;
  }
}
</script>
