import {
  IInputModeContext,
  INode,
  IRectangle,
  IRenderContext,
  Point,
  SvgVisual,
  SvgVisualGroup,
  Visual,
} from 'yfiles';

import Vue from 'vue';
import {
  DOCUMENT_SEARCH_NAMESPACE,
  GET_SEARCHED_ITEM,
} from '@/core/services/store/document-search.module';

import HitResult from '../HitResult';
import JigsawNodeDecorator from './JigsawNodeDecorator';

enum CornerLocations {
  TopLeft = 0,
  TopRight = 1,
  BottomRight = 2,
  BottomLeft = 3,
}

export default class HighlightDecorator implements JigsawNodeDecorator {
  public $class = 'HighlightDecorator';
  public static INSTANCE: HighlightDecorator = new HighlightDecorator();

  public lineSize = 15;
  public lineOffset = 5;
  public lineColor = '#000';
  public lineWidth = 1;
  public spaceBetweenLines = 2;

  constructor() {}

  get searchedNode(): INode {
    return Vue.$globalStore.getters[
      `${DOCUMENT_SEARCH_NAMESPACE}/${GET_SEARCHED_ITEM}`
    ];
  }

  isVisible(renderContext: IRenderContext, node: INode): boolean {
    return this.searchedNode?.tag.uuid === node.tag.uuid;
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    const layout = node.layout;

    let visualGroup = new SvgVisualGroup();

    const corners = [
      CornerLocations.TopLeft,
      CornerLocations.TopRight,
      CornerLocations.BottomLeft,
      CornerLocations.BottomRight,
    ];

    corners.forEach((c) => {
      const corner = this.generateCorner(c, layout);

      visualGroup.add(corner);
    });

    return visualGroup;
  }
  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual {
    return oldVisual;
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    // indicators are not clickable
    return HitResult.NONE;
  }

  public generateCorner(
    location: CornerLocations,
    layout: IRectangle
  ): SvgVisual {
    let cornerSvg: SVGGElement = null;

    switch (location) {
      case CornerLocations.TopLeft:
        cornerSvg = this.generateTopLeftCorner(layout);
        break;
      case CornerLocations.TopRight:
        cornerSvg = this.generateTopRightCorner(layout);
        break;
      case CornerLocations.BottomLeft:
        cornerSvg = this.generateBottomLeftCorner(layout);
        break;
      case CornerLocations.BottomRight:
        cornerSvg = this.generateBottomRightCorner(layout);
        break;
      default:
        break;
    }

    return new SvgVisual(cornerSvg);
  }

  public generateTopLeftCorner(layout: IRectangle): SVGGElement {
    const xWithOffset = layout.x - this.lineOffset;
    const yWithOffset = layout.y - this.lineOffset;

    const xDifferOffset = this.lineWidth / 2;

    const container = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    const horizontalInnerLine = this.generateSvgLine(
      xWithOffset - xDifferOffset,
      yWithOffset,
      xWithOffset + this.lineSize,
      yWithOffset
    );
    const verticalInnerLine = this.generateSvgLine(
      xWithOffset,
      yWithOffset,
      xWithOffset,
      yWithOffset + this.lineSize
    );

    const horizontalOuterLine = this.generateSvgLine(
      xWithOffset - xDifferOffset - this.spaceBetweenLines,
      yWithOffset - this.spaceBetweenLines,
      xWithOffset + this.lineSize,
      yWithOffset - this.spaceBetweenLines
    );
    const verticalOuterLine = this.generateSvgLine(
      xWithOffset - this.spaceBetweenLines,
      yWithOffset - this.spaceBetweenLines,
      xWithOffset - this.spaceBetweenLines,
      yWithOffset + this.lineSize
    );

    container.appendChild(horizontalInnerLine);
    container.appendChild(verticalInnerLine);
    container.appendChild(horizontalOuterLine);
    container.appendChild(verticalOuterLine);

    return container;
  }

  public generateTopRightCorner(layout: IRectangle): SVGGElement {
    const xWithOffset = layout.x + this.lineOffset + layout.width;
    const yWithOffset = layout.y - this.lineOffset;

    const xDifferOffset = this.lineWidth / 2;

    const container = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    const horizontalInnerLine = this.generateSvgLine(
      xWithOffset + xDifferOffset,
      yWithOffset,
      xWithOffset - this.lineSize,
      yWithOffset
    );
    const verticalInnerLine = this.generateSvgLine(
      xWithOffset,
      yWithOffset,
      xWithOffset,
      yWithOffset + this.lineSize
    );

    const horizontalOuterLine = this.generateSvgLine(
      xWithOffset + xDifferOffset + this.spaceBetweenLines,
      yWithOffset - this.spaceBetweenLines,
      xWithOffset - this.lineSize,
      yWithOffset - this.spaceBetweenLines
    );
    const verticalOuterLine = this.generateSvgLine(
      xWithOffset + this.spaceBetweenLines,
      yWithOffset - this.spaceBetweenLines,
      xWithOffset + this.spaceBetweenLines,
      yWithOffset + this.lineSize
    );

    container.appendChild(horizontalInnerLine);
    container.appendChild(verticalInnerLine);
    container.appendChild(horizontalOuterLine);
    container.appendChild(verticalOuterLine);

    return container;
  }

  public generateBottomLeftCorner(layout: IRectangle): SVGGElement {
    const xWithOffset = layout.x - this.lineOffset;
    const yWithOffset = layout.y + this.lineOffset + layout.height;

    const xDifferOffset = this.lineWidth / 2;

    const container = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    const horizontalInnerLine = this.generateSvgLine(
      xWithOffset - xDifferOffset,
      yWithOffset,
      xWithOffset + this.lineSize,
      yWithOffset
    );
    const verticalInnerLine = this.generateSvgLine(
      xWithOffset,
      yWithOffset,
      xWithOffset,
      yWithOffset - this.lineSize
    );

    const horizontalOuterLine = this.generateSvgLine(
      xWithOffset - xDifferOffset - this.spaceBetweenLines,
      yWithOffset + this.spaceBetweenLines,
      xWithOffset + this.lineSize,
      yWithOffset + this.spaceBetweenLines
    );
    const verticalOuterLine = this.generateSvgLine(
      xWithOffset - this.spaceBetweenLines,
      yWithOffset + this.spaceBetweenLines,
      xWithOffset - this.spaceBetweenLines,
      yWithOffset - this.lineSize
    );

    container.appendChild(horizontalInnerLine);
    container.appendChild(verticalInnerLine);
    container.appendChild(horizontalOuterLine);
    container.appendChild(verticalOuterLine);

    return container;
  }

  public generateBottomRightCorner(layout: IRectangle): SVGGElement {
    const xWithOffset = layout.x + this.lineOffset + layout.width;
    const yWithOffset = layout.y + this.lineOffset + layout.height;

    const xDifferOffset = this.lineWidth / 2;

    const container = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    const horizontalInnerLine = this.generateSvgLine(
      xWithOffset + xDifferOffset,
      yWithOffset,
      xWithOffset - this.lineSize,
      yWithOffset
    );
    const verticalInnerLine = this.generateSvgLine(
      xWithOffset,
      yWithOffset,
      xWithOffset,
      yWithOffset - this.lineSize
    );

    const horizontalOuterLine = this.generateSvgLine(
      xWithOffset + xDifferOffset + this.spaceBetweenLines,
      yWithOffset + this.spaceBetweenLines,
      xWithOffset - this.lineSize,
      yWithOffset + this.spaceBetweenLines
    );
    const verticalOuterLine = this.generateSvgLine(
      xWithOffset + this.spaceBetweenLines,
      yWithOffset + this.spaceBetweenLines,
      xWithOffset + this.spaceBetweenLines,
      yWithOffset - this.lineSize
    );

    container.appendChild(horizontalInnerLine);
    container.appendChild(verticalInnerLine);
    container.appendChild(horizontalOuterLine);
    container.appendChild(verticalOuterLine);

    return container;
  }

  public generateSvgLine(x1: number, y1: number, x2: number, y2: number) {
    const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');

    line.setAttribute('x1', x1.toString());
    line.setAttribute('y1', y1.toString());
    line.setAttribute('x2', x2.toString());
    line.setAttribute('y2', y2.toString());
    line.setAttribute('stroke', this.lineColor);
    line.setAttribute('stroke-width', this.lineWidth.toString());

    return line;
  }
}
