import appConfig from '@/core/config/appConfig';
import { GraphComponent, IGraph } from 'yfiles';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import ExportOptions from './ExportOptions';
import ExportProviderFactory from './providers/ExportProviderFactory';
import FileSaveSupport from './FileSaveSupport';
import DiagramDataExportService from './data/DiagramDataExportService';
import b64toBlob from '../graph/b64ToBlob';
import {
  DataExportDocumentDto,
  DataExportDiagramDto,
  DocumentProfileHistoryReason,
} from '@/api/models';
import DiagramExportApiService from '@/api/DiagramExportApiService';
import { ExportFormat } from './ExportFormat';
import SvgExportProvider from './providers/SvgExportProvider';
import { ExportType } from './ExportType';
import BackgroundGraphService from '../graph/BackgroundGraphService';
import printJS from 'print-js';
import AnalyticsService from '../analytics/AnalyticsService';
import { AnalyticsEvent } from '../analytics/AnalyticsEvent';
import IExportProvider from './providers/IExportProvider';
import { blobToBase64 } from '@/core/utils/common.utils';
import IExportResult from './providers/IExportResult';
import { waitDocumentHasFocus } from '@/core/utils/html.utils';
import ExportUtils from './ExportUtils';
import DocumentService from '../document/DocumentService';
import Vue from 'vue';
import { GLOBAL_NAMESPACE, SET_GLOBAL_LOADING } from '../store/global.module';
import { notify } from '@/components/shared/AppNotification';
import i18n from '@/core/plugins/vue-i18n';
import { ExportPageElementType } from './ExportPageElementType';
import ThumbnailService from './thumb/ThumbnailService';
import DocumentProfilingApiService from '@/api/DocumentProfilingApiService';

export default class ExportService {
  public static dataExportService: DiagramDataExportService =
    new DiagramDataExportService();

  public static async export(options: ExportOptions): Promise<Blob | string> {
    options.showNotification &&
      Vue.$globalStore.commit(
        `${GLOBAL_NAMESPACE}/${SET_GLOBAL_LOADING}`,
        true
      );
    if (options?.document && options.type != ExportType.PageThumbnail) {
      DocumentService.prepareDocumentForSaving(options.document);
    }
    EventBus.$emit(
      EventBusActions.DOCUMENT_EXPORT_STARTED,
      options.showNotification
    );

    options.metadata = {
      currentPage: options.pages[0],
      currentPageNumber: 1,
    };

    let exportResult: IExportResult = null;
    try {
      exportResult = await this.exportBlob(options);
    } finally {
      this.destroyAdditionalElements(options);
      EventBus.$emit(EventBusActions.DOCUMENT_EXPORT_FINISHED);
      await this.createDocumentProfileReason(options);
      options.showNotification &&
        Vue.$globalStore.commit(
          `${GLOBAL_NAMESPACE}/${SET_GLOBAL_LOADING}`,
          false
        );
    }
    return exportResult?.result;
  }

  private static async createDocumentProfileReason(
    options: ExportOptions
  ): Promise<void> {
    if (
      !options.document?.id ||
      !appConfig.documentProfiling.isEnabled ||
      options.type === ExportType.PageThumbnail
    ) {
      return;
    }
    let reason: DocumentProfileHistoryReason;
    if (options.print) {
      reason = DocumentProfileHistoryReason.Printed;
    } else {
      switch (options.format) {
        case ExportFormat.Pdf:
          reason = DocumentProfileHistoryReason.Pdf;
          break;
        case ExportFormat.PowerPoint:
          reason = DocumentProfileHistoryReason.Ppt;
          break;
        case ExportFormat.Svg:
          reason = DocumentProfileHistoryReason.Svg;
          break;
        case ExportFormat.Visio:
          reason = DocumentProfileHistoryReason.Visio;
          break;
        case ExportFormat.Png:
          reason = DocumentProfileHistoryReason.Png;
          break;
        default:
          return;
      }
    }
    await DocumentProfilingApiService.create({
      documentId: options.document.id,
      reason: reason,
    });
    
  }

  private static async exportBlob(
    options: ExportOptions
  ): Promise<IExportResult> {
    let exportResult: IExportResult;
    if (options.type == ExportType.Document) {
      exportResult = await ExportService.exportDocument(options);
    } else if (options.type == ExportType.PageThumbnail) {
      exportResult = await ExportService.exportPageThumbnail(options);
    } else if (options.type == ExportType.Graph) {
      exportResult = await ExportService.exportGraph(options);
    }

    if (options.withData) {
      exportResult = await ExportService.exportDocumentWithData(
        options,
        exportResult,
        ExportProviderFactory.getProvider(options.format)
      );
    }

    if (options.clipboard) {
      const data = exportResult.result as any;
      const item = new ClipboardItem({
        [exportResult.mimeType]: data,
      });
      try {
        await waitDocumentHasFocus(15000);
        await navigator.clipboard.write([item]);

        notify({
          title: i18n.t('EXPORT_TO_CLIPBOARD_SUCCESS'),
          type: 'success',
        });
        AnalyticsService.trackEvent(AnalyticsEvent.DocumentExported);
      } catch (e) {
        console.error(e);
        notify({
          title: i18n.t('EXPORT_TO_CLIPBOARD_FAILED'),
          type: 'error',
        });
      }
    } else if (options.download) {
      const fileName = options.document.name
        ? `${options.document.name}.${exportResult.fileExtension}`
        : `document.${exportResult.fileExtension}`;
      await FileSaveSupport.save(exportResult.result as Blob, fileName);
      AnalyticsService.trackEvent(AnalyticsEvent.DocumentExported);
    } else if (options.print) {
      const pdfUrl = URL.createObjectURL(exportResult.result as Blob);
      try {
        printJS({ printable: pdfUrl, type: 'pdf', showModal: true });
      } catch (error) {
        EventBus.$emit(EventBusActions.DOCUMENT_PREVIEW);
      }
      AnalyticsService.trackEvent(AnalyticsEvent.DocumentPrinted);
    }

    return exportResult;
  }

  private static async exportDocument(
    options: ExportOptions
  ): Promise<IExportResult> {
    const exportProvider = ExportProviderFactory.getProvider(options.format);
    return exportProvider.exportDocument(options);
  }

  private static async exportPageThumbnail(
    options: ExportOptions
  ): Promise<IExportResult> {
    if (!options.metadata.currentPage) {
      throw 'No page to export';
    }
    return {
      fileExtension: 'svg',
      mimeType: 'image/svg+xml',
      result: (await ThumbnailService.generateThumbnail(options)) as string,
    };
  }

  private static async exportGraph(
    options: ExportOptions
  ): Promise<IExportResult> {
    if (!options.metadata.currentPage) {
      throw 'No page to export';
    }
    const exportPage = options.metadata.currentPage;
    const sourceGraph = BackgroundGraphService.createGraph(
      exportPage.page.diagram
    );
    const graphComponent: GraphComponent = ExportUtils.copyGraphComponent(
      sourceGraph,
      options.withFilters,
      options.format,
      options.lowDetailDiagram
    );

    const exportProvider = ExportProviderFactory.getProvider(options.format);

    const exportBlob = await exportProvider.exportGraphAsBlob(
      options,
      graphComponent
    );
    graphComponent.cleanUp();

    return exportBlob;
  }

  public static async exportGraphAsSvg(
    options: ExportOptions,
    sourceGraph: IGraph,
    withFilters: boolean,
    lowDetail: boolean,
    additionalElementTypes: ExportPageElementType[] = []
  ): Promise<IExportResult> {
    const graphComponent: GraphComponent = ExportUtils.copyGraphComponent(
      sourceGraph,
      withFilters,
      ExportFormat.Svg,
      lowDetail,
      options.withHighlight
    );

    const exportProvider = ExportProviderFactory.getProvider(
      ExportFormat.Svg
    ) as SvgExportProvider;

    const exportResult = await exportProvider.exportGraphAsString(
      options,
      graphComponent,
      additionalElementTypes
    );
    graphComponent.cleanUp();
    return exportResult;
  }

  private static async exportDocumentWithData(
    options: ExportOptions,
    exportResult: IExportResult,
    exportProvider: IExportProvider
  ): Promise<IExportResult> {
    const exportBase64 = await blobToBase64(exportResult.result as Blob);

    let payload: DataExportDocumentDto = {
      documentId: options.document.id,
      documentName: options.document.name,
      documentFile: exportBase64,
      documentFileName: `document.${exportResult.fileExtension}`,
      documentMimeType: exportResult.mimeType,
      fileExtension: exportResult.fileExtension,
      diagrams: [],
    };
    for (const page of options.pages) {
      if (page.page?.diagram) {
        const exportPage = page;
        const sourceGraph = BackgroundGraphService.createGraph(
          exportPage.page.diagram
        );
        const data = ExportService.dataExportService.export(
          sourceGraph,
          options
        );

        const dataExport = new DataExportDiagramDto(
          exportPage.page.diagram.id,
          options.document.name,
          data.nodes,
          data.edges
        );

        payload.diagrams.push(dataExport);
      }
    }

    const response = await DiagramExportApiService.documentDataExport(payload);

    return {
      mimeType: 'application/zip',
      fileExtension: 'zip',
      size: exportResult.size,
      result: b64toBlob(response.data.result),
    };
  }

  private static destroyAdditionalElements(options: ExportOptions): void {
    for (const exportPage of options.pages) {
      if (exportPage.additionalElements) {
        for (const el of exportPage.additionalElements) {
          el.destroy();
        }
        exportPage.additionalElements = [];
      }
    }
  }
}
