import {
  LayoutStageBase,
  LayoutGraph,
  LayoutKeys,
  ILayoutAlgorithm,
  YList,
} from 'yfiles';

export default class JigsawStraightLineEdgeRouter extends LayoutStageBase {
  /**
   *
   */
  constructor(coreLayout: ILayoutAlgorithm) {
    super(coreLayout);
  }
  applyLayout(graph: LayoutGraph): void {
    this.applyLayoutCore(graph);
    const affectedEdgesDataProvider = graph.getDataProvider(
      LayoutKeys.AFFECTED_EDGES_DP_KEY
    );
    for (const edge of graph.edges!) {
      if (!affectedEdgesDataProvider.getBoolean(edge)) {
        continue;
      }
      graph.setPoints(edge, new YList());
    }
  }
}
