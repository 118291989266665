import {
  IRenderContext,
  INode,
  Visual,
  IInputModeContext,
  Point,
  ICanvasContext,
} from 'yfiles';
import HitResult from '../HitResult';
/**
 * Jigsaw node decorator
 * Node Decorators should container no state and could be used as singletons.
 */
export default abstract class JigsawNodeDecorator {
  abstract $class: string;
  /**
   * Creates the visual, can be null
   * @param context
   * @param node the node to create the visual for
   * @returns visual
   */
  abstract createVisual(context: IRenderContext, node: INode): Visual;
  /**
   * Updates the visual, can be null
   * @param context
   * @param node
   * @param oldVisual, the previous visual returned by @method createVisual
   * @returns visual
   */
  abstract updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: Visual
  ): Visual;
  abstract isHit(
    context: IInputModeContext,
    location: Point,
    node: INode
  ): HitResult;

  isVisible(context: ICanvasContext, node: INode): boolean {
    return true;
  }
}
