import { ApplicationPermissions } from './api/ApplicationPermissions';
import { Features } from './core/common/Features';
import { UserRole } from './core/common/UserRole';
import { RouterParams } from './core/config/routerParams';

const Home = () => import('@/view/pages/hub/home/Home.vue');
const AllDocumentsPage = () =>
  import('@/view/pages/hub/all-documents/AllDocuments.vue');
const DocumentsAccessRequests = () =>
  import(
    '@/view/pages/hub/documents-access-requests/DocumentsAccessRequests.vue'
  );
const DocumentPreview = () =>
  import('@/view/pages/hub/document-preview/DocumentPreview.vue');
const DocumentManager = () =>
  import('@/view/pages/hub/document-manager/DocumentManager.vue');
const Favourites = () => import('@/view/pages/hub/favourites/Favourites.vue');
const Templates = () => import('@/view/pages/hub/templates/Templates.vue');
const DeletedFiles = () =>
  import('@/view/pages/hub/deleted-files/DeletedFiles.vue');
const IManage = () => import('@/view/pages/imanage/IManage.vue');
const DocumentProfiling = () =>
  import('@/view/pages/documentprofiling/DocumentProfiling.vue');
const DataProperties = () =>
  import('@/view/pages/hub/data-properties/DataProperties.vue');

const RefreshTokenPage = () => import('@/view/pages/RefreshTokenPage.vue');

export const RouteNames = {
  home: 'landing',
  allDocuments: 'all-documents',
  favourites: 'favourites',
  shared: 'shared',
  templates: 'templates',
  deletedFiles: 'deleted',
  accessRequests: 'access-requests',
  preview: 'preview',
  documentManager: 'document-manager',
  themes: 'hub-themes',
  documentDetails: 'document-details',
  stepsDesign: 'steps-design',
  stepsDesignEditor: 'steps-design-editor',
  hubThemeDetails: 'hub-theme-details',
  documentThemeDetails: 'document-theme-details',
  resetPassword: 'reset-password',
  recoverPassword: 'recover-password',
  iManageCallback: 'imanage-callback',
  imanage: 'imanage',
  documentProfiling: 'document-profiling',
  diagram: 'diagram',
  documentThemes: 'document-themes',
  dataProperties: 'data-properties',
  importDefinition: 'import-definition',
};
const routes = [
  {
    path: '/sidebyside',
    component: () => import('@/v2/components/DebugSideBySide.vue'),
  },
  {
    path: '/documents',
    component: () => import('@/view/pages/document/DocumentLayout.vue'),
    redirect: RouteNames.home,
    children: [
      {
        path: ':' + RouterParams.documentId + `/:${RouterParams.pageNumber}?`,
        components: {
          default: () => import('@/view/pages/document/DocumentDetails.vue'),
          side: () =>
            import('@/view/pages/document/sidebar/DocumentSideBar.vue'),
        },
        props: {
          default: (route) => {
            return {
              documentId: Number(route.params[RouterParams.documentId]),
              pageNumber: Number(route.params[RouterParams.pageNumber]),
            };
          },
        },
        children: [
          {
            path: '',
            name: RouteNames.documentDetails,
          },
          {
            name: RouteNames.documentThemes,
            path: 'themes',
            props: {
              query: {
                page: '1',
              },
            },
            components: {
              sidepanel: () =>
                import('@/view/pages/administration/ThemesList.vue'),
            },
            meta: {
              openSidePanel: true,
              commonName: 'themes',
            },
          },
          {
            name: RouteNames.documentThemeDetails,
            path: 'themes/:' + RouterParams.themeId,
            components: {
              sidepanel: () =>
                import('@/view/pages/administration/ThemeDetails.vue'),
            },
            props: {
              sidepanel: (route) => {
                return {
                  id: Number(route.params[RouterParams.themeId]),
                };
              },
            },
            meta: {
              openSidePanel: true,
              commonName: 'theme-details',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/hub',
    component: () => import('@/view/layout/AppLayout.vue'),
    children: [
      // New Pages
      {
        path: '/',
        name: RouteNames.home,
        component: Home,
        meta: {
          skipPadding: true,
        },
      },
      {
        path: `/all-documents/:${RouterParams.folderId}?`,
        name: RouteNames.allDocuments,
        component: AllDocumentsPage,
      },
      {
        path: `/favourites/:${RouterParams.folderId}?`,
        name: RouteNames.favourites,
        component: Favourites,
      },
      // Temporary disabled
      // {
      //   path: '/templates',
      //   name: RouteNames.templates,
      //   component: Templates,
      // },
      {
        path: '/deleted',
        name: RouteNames.deletedFiles,
        component: DeletedFiles,
      },
      {
        path: '/imanage',
        name: RouteNames.imanage,
        component: IManage,
      },
      {
        path: '/document-profiling',
        name: RouteNames.documentProfiling,
        component: DocumentProfiling,
      },
      {
        path: '/access-requests',
        name: RouteNames.accessRequests,
        component: DocumentsAccessRequests,
      },
      {
        path: '/preview/:' + RouterParams.documentId,
        name: RouteNames.preview,
        component: DocumentPreview,
      },
      {
        path: '/document-manager',
        name: RouteNames.documentManager,
        component: DocumentManager,
      },
      // New Pages End
      {
        path: 'themes',
        name: RouteNames.themes,
        props: {
          query: {
            page: '1',
          },
        },
        meta: {
          commonName: 'themes',
        },
        component: () => import('@/view/pages/administration/ThemesList.vue'),
      },
      {
        path: 'import',
        name: 'hub-import',
        props: {
          query: {
            page: '1',
          },
        },
        meta: {
          commonName: 'import',
          features: {
            value: [Features.Import, Features.ImportExcel],
            allRequired: false,
          },
          permissions: {
            value: [
              ApplicationPermissions.ImportDefinitionsRead,
              ApplicationPermissions.ImportDefinitionsEdit,
            ],
            allRequired: false,
          },
        },
        component: () => import('@/view/pages/hub/import/ImportsPreview.vue'),
      },
      {
        path: 'import-definition/:' + RouterParams.importDefinitionId,
        name: RouteNames.importDefinition,
        meta: {
          features: {
            value: [Features.ImportExcel, Features.ImportExcelBuilder],
            allRequired: true,
          },
          permissions: {
            value: [
              ApplicationPermissions.ImportDefinitionsRead,
              ApplicationPermissions.ImportDefinitionsEdit,
            ],
            allRequired: false,
          },
        },
        props: (route) => ({
          id: Number(route.params[RouterParams.importDefinitionId]),
        }),
        component: () =>
          import('@/view/pages/hub/import/ImportDefinitionBuilder.vue'),
      },
      {
        name: RouteNames.hubThemeDetails,
        path: 'themes/:' + RouterParams.themeId,
        meta: {
          commonName: 'theme-details',
        },
        component: () => import('@/view/pages/administration/ThemeDetails.vue'),
        props: (route) => ({
          id: Number(route.params[RouterParams.themeId]),
        }),
      },

      // Steps design
      {
        path: '/steps-design',
        name: RouteNames.stepsDesign,
        meta: {
          features: {
            value: [Features.Steps, Features.StepsDesign],
            allRequired: true,
          },
          roles: {
            value: [UserRole.Admin, UserRole.SuperUser],
            allRequired: false,
          },
        },
        components: {
          default: () =>
            import(
              '@/view/pages/administration/steps-designer/StepsDesigner.vue'
            ),
        },
      },
      // Steps design editor
      {
        path: `/steps-design-editor/:${RouterParams.stepsDesignSetUuid}`,
        name: RouteNames.stepsDesignEditor,
        components: {
          default: () =>
            import(
              '@/view/pages/administration/steps-designer/StepsDesignerEditor.vue'
            ),
        },
        props: (route) => ({
          id: Number(route.params[RouterParams.stepsDesignSetUuid]),
        }),
      },
      // Data Properties
      {
        path: 'data-properties',
        name: RouteNames.dataProperties,
        meta: {
          commonName: 'data-properties',
          permissions: {
            value: [ApplicationPermissions.EntityType],
            allRequired: false,
          },
          features: {
            value: [Features.DataFull],
            allRequired: true,
          },
        },
        component: DataProperties,
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('@/view/pages/auth/Login.vue'),
        meta: {
          anonymous: true,
        },
      },
    ],
  },
  {
    path: '/logout',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: 'logout',
        path: '/logout',
        component: () => import('@/view/pages/auth/Logout.vue'),
        meta: {
          commonName: 'logout',
          anonymous: false,
        },
      },
    ],
  },
  {
    path: '/reset-password',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: RouteNames.resetPassword,
        path: '/reset-password',
        component: () => import('@/view/pages/auth/ResetPassword.vue'),
        meta: {
          anonymous: true,
        },
      },
    ],
  },
  {
    path: '/recover-password',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        name: RouteNames.recoverPassword,
        path: '/recover-password',
        component: () => import('@/view/pages/auth/RecoverPassword.vue'),
        meta: {
          anonymous: true,
        },
      },
    ],
  },
  {
    path: '/token-refresh',
    name: 'token-refresh',
    component: RefreshTokenPage,
    meta: {
      anonymous: true,
    },
  },
  {
    name: RouteNames.diagram,
    path: '/diagram/:documentId',
    component: () => import('@/view/pages/Diagram.vue'),
    props: (route) => {
      return { documentId: Number(route.params.documentId) };
    },
  },
  {
    path: '*',
    redirect: '/hub',
    meta: {
      anonymous: true,
    },
  },
];

export default routes;
