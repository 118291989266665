import { Module } from 'vuex';

export const VIEWPORT_NAMESPACE = 'viewport';
export const SET_IS_FOCUS_MODE = 'setIsFocusMode';
export const GET_IS_FOCUS_MODE = 'getIsFocusMode';
interface State {
  isFocusMode: boolean;
}

const viewportMode: Module<State, any> = {
  namespaced: true,
  state: {
    isFocusMode: false,
  },
  getters: {
    [GET_IS_FOCUS_MODE](state) {
      return state.isFocusMode;
    },
  },
  actions: {
    [SET_IS_FOCUS_MODE](context, payload) {
      context.commit(SET_IS_FOCUS_MODE, payload);
    },
  },
  mutations: {
    [SET_IS_FOCUS_MODE](state, payload) {
      state.isFocusMode = payload;
    },
  },
};

export default viewportMode;
