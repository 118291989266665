import axios, {
    AxiosResponse
} from 'axios';
import {
    UserDto,
    ZadarResponse,
    DocumentAccessDto,
    DocumentAccessRequestDto,
    GetPendingAccessRequestsOutputDto,
    RequestDocumentAccessOutputDto,
    RequestDocumentAccessDto,
    GrantDocumentAccessDto,
    ChangeDocumentAccessDto,
    ChangeDocumentIsPublicDto,
    ChangeDocumentDefaultAccessLevelDto
} from './models'
export default class DocumentAccessApiService {
    public static getUsersForAccess(params ? : {
        filter ? : string,
        limit ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < UserDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/GetUsersForAccess`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentAccess(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentAccessDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/GetDocumentAccess`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getDocumentAccessRequests(params ? : {
        documentId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentAccessRequestDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/GetDocumentAccessRequests`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getPendingAccessRequests(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < GetPendingAccessRequestsOutputDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/GetPendingAccessRequests`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static requestDocumentAccess(body: RequestDocumentAccessDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < RequestDocumentAccessOutputDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/RequestDocumentAccess`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static grantDocumentAccess(body ? : GrantDocumentAccessDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/GrantDocumentAccess`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static revokeDocumentAccess(body ? : ChangeDocumentAccessDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/RevokeDocumentAccess`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static rejectDocumentAccessRequest(body ? : ChangeDocumentAccessDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/RejectDocumentAccessRequest`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static changeDocumentOwner(body: ChangeDocumentAccessDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/ChangeDocumentOwner`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static changeDocumentIsPublic(body: ChangeDocumentIsPublicDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/ChangeDocumentIsPublic`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static changeDocumentDefaultAccessLevel(body: ChangeDocumentDefaultAccessLevelDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentAccess/ChangeDocumentDefaultAccessLevel`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}