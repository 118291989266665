export enum UndoRedoActions {
  UNDO_THEME_SELECT = 'Undo Theme Select',
  REDO_THEME_SELECT = 'Redo Theme Select',
  UNDO_APPLY_THEME = 'Undo Apply Theme',
  REDO_APPLY_THEME = 'Redo Apply Theme',
  UNDO_QUICK_BUILD = 'Undo quick build',
  REDO_QUICK_BUILD = 'Redo quick build',
  UNDO_CHANGE_LABEL_STYLE = 'Change Label Style',
  REDO_CHANGE_LABEL_STYLE = 'Change Label Style',
}
