// action types

import { Module } from 'vuex';

const localStorageKey = 'debugEnabled';
export const DEBUG_NAMESPACE = 'debug';
export const GET_DEBUG_ENABLED = 'getDebugEnabled';
export const SET_DEBUG_ENABLED = 'setDebugEnabled';

interface IState {
  enabled: boolean;
}
const debugModule: Module<IState, any> = {
  namespaced: true,
  state: {
    enabled: localStorage.getItem('debugEnabled') == 'true',
  },
  getters: {
    [GET_DEBUG_ENABLED](state) {
      return state.enabled;
    },
  },
  actions: {
    [SET_DEBUG_ENABLED]: function (ctx, enabled) {
      localStorage.setItem(localStorageKey, enabled);
      ctx.commit(SET_DEBUG_ENABLED, enabled);
    },
  },
  mutations: {
    [SET_DEBUG_ENABLED]: function (state, payload: boolean) {
      state.enabled = payload;
    },
  },
};

export default debugModule;
