<template>
  <div
    class="notification relative flex items-start px-16 py-12 gap-x-12 rounded-8 bg-white pointer-events-auto"
    @mouseenter="toggleTimer(true)"
    @mouseleave="toggleTimer(false)"
    @click.stop
  >
    <AppIcon :name="iconName" :class="iconClass" />

    <component v-if="component" :is="component" @close="close(true)" />

    <template v-else>
      <div class="flex flex-col flex-grow gap-y-4">
        <h4 class="line-clamp-3 text-16 font-semibold">
          {{ title }}
        </h4>
        <p v-if="text" class="line-clamp-3 text-14">{{ text }}</p>
      </div>

      <AppIcon
        name="close"
        size="16"
        class="cursor-pointer"
        @click="close(true)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import Timer from './timer';

const iconClasses = {
  info: 'text-main-dark',
  warning: 'text-warning-orange',
  success: 'text-accent-cyan',
  error: 'text-danger-red',
};

const iconTypes = {
  info: 'notify-info',
  warning: 'notify-warning',
  success: 'notify-success',
  error: 'notify-error',
};

@Component({
  name: 'Notification',
  inheritAttrs: false,
})
export default class Notification extends Vue {
  @Prop({ required: true })
  type: string;

  @Prop({ default: null })
  title: string;

  @Prop({ default: null })
  text: string;

  @Prop({ default: 4000, type: Number })
  duration: number;

  @Prop({ default: null })
  promise: { resolve: (boolean) => boolean };

  @Prop({ default: null })
  component: Function;

  timer: Timer<boolean>;

  get iconClass(): string {
    return iconClasses[this.type];
  }

  get iconName(): string {
    return iconTypes[this.type];
  }

  close(isClosed: boolean = false): void {
    this.timer.stop();
    this.timer = null;

    this.promise.resolve(isClosed);
  }

  toggleTimer(pause: boolean): void {
    if (this.timer) pause ? this.timer.pause() : this.timer.resume();
  }

  mounted(): void {
    this.timer = new Timer(this.close, this.duration || 4000);
  }

  beforeDestroy(): void {
    if (this.timer) {
      this.timer.stop();
    }
    this.timer = null;
  }
}
</script>

<style lang="scss" scoped>
.notification {
  width: 377px;
  max-width: 377px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.06),
    0px 8px 8px 0px rgba(0, 0, 0, 0.09), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
}
</style>
