import {
  convertUrlToDataUrl,
  ensureFullUri,
  scaleImage,
} from '@/core/utils/common.utils';
import LayoutItem from './Items/LayoutItem';
import JSize from '@/core/common/JSize';
import LayoutItemFactory from './Items/LayoutItemFactory';
import { LayoutItemType } from './Items/LayoutItemType';
import ImageLayoutItem from './Items/ImageLayoutItem';
import BackgroundDomService from '@/core/services/BackgroundDomService';
import ExportConfig from '@/core/config/ExportConfig';
import HtmlLayoutItem from './Items/HtmlLayoutItem';
import LayoutUtils from './LayoutUtils';
import WidgetLayoutItem from './Items/WidgetLayoutItem';

export default class LayoutSerializer {
  public static serializeToJson(items: LayoutItem[]): string {
    return JSON.stringify(this.serializeItems(items));
  }

  public static serializeItems(items: LayoutItem[]): any[] {
    const serializedItems = items
      .filter((i) => !i.system)
      .map((i) => i.serialize());

    return serializedItems;
  }

  public static deserializeFromJson(json: string): LayoutItem[] {
    if (!json) {
      return [];
    }

    try {
      const rawItems = JSON.parse(json) as LayoutItem[];
      const items: LayoutItem[] = [];
      rawItems.forEach((rawItem) => {
        const item = LayoutItemFactory.getItem(
          rawItem.type,
          rawItem.layout,
          rawItem as any
        );
        item.resized = rawItem.resized;
        item.zIndex = rawItem.zIndex;
        item.hidden = rawItem.hidden;
        item.moved = rawItem.moved;
        item.movementConstraints = rawItem.movementConstraints;
        items.push(item);
      });
      items.sort((a, b) => a.order - b.order);
      return items;
    } catch (ex: any) {
      console.error(ex);
      return [];
    }
  }

  public static async serializeToHtml(
    items: LayoutItem[],
    imageScale = 1,
    contentSize?: JSize
  ): Promise<string> {
    let container = BackgroundDomService.createElement('div');
    container.classList.add('document-page-content', 'layout-content');
    for (let item of items) {
      let element: HTMLElement = null;
      let size = new JSize(item.layout.width, item.layout.height);
      switch (item.type) {
        case LayoutItemType.Image: {
          const imageItem = item as ImageLayoutItem;
          await LayoutUtils.ensureImageElementSrc(imageItem);
          const imageUrl = ensureFullUri(imageItem.imageSrc);
          const img = BackgroundDomService.createElement(
            'img'
          ) as HTMLImageElement;

          if (imageScale != 1) {
            const scaledImageResult = await scaleImage(imageUrl, (img) => {
              const relativeScale =
                img.width /
                (item.layout.width * ExportConfig.pointToPixelFactor);
              const scale = imageScale / relativeScale;
              // min scale = 0.1, max scale = 1
              return scale > 0.1 ? (scale < 1 ? scale : 1) : 0.1;
            });
            img.setAttribute(
              ExportConfig.thumbOriginalImageKeyHtmlAttribute,
              scaledImageResult.cacheKey
            );
            img.src = scaledImageResult.src;
          } else {
            img.src = await convertUrlToDataUrl(imageUrl);
          }
          element = img;
          break;
        }

        case LayoutItemType.Date:
        case LayoutItemType.PageNumber:
        case LayoutItemType.Text: {
          const textItem = item as HtmlLayoutItem;
          const div = BackgroundDomService.createElement('div');
          div.innerHTML = textItem.html;
          div.style.textAlign = 'initial';
          div.style.wordBreak = 'break-all';

          if (item.type === LayoutItemType.PageNumber) {
            LayoutUtils.applyRotation(div, item as WidgetLayoutItem);
          }

          element = div;
          break;
        }
      }

      element.style.position = 'absolute';
      element.style.left = item.layout.x + 'pt';
      element.style.top = item.layout.y + 'pt';
      element.style.width = size.width + 'pt';
      element.style.height = size.height + 'pt';
      element.style.padding = item.padding + 'pt';
      if (item.hidden) {
        element.style.display = 'none';
      }
      container.appendChild(element);
    }
    if (contentSize) {
      container.style.width = contentSize.width + 'pt';
      container.style.height = contentSize.height + 'pt';
    }
    return container.outerHTML;
  }
}
