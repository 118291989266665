import {
  GraphComponent,
  GraphMLSupport,
  IEdge,
  IGraph,
  INode,
  Insets,
  IRenderContext,
  Rect,
  ShapeNodeShape,
  SimpleNode,
  Size,
  SvgVisualGroup,
} from 'yfiles';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import {
  convertImageSrcToPng,
  convertSvgToImage,
  fitRectIntoBounds,
  getImageSize,
  RgbaToHex,
  splitArrayIntoChunks,
} from '@/core/utils/common.utils';
import {
  AttachmentType,
  CompositeNodeStyleDto,
  NodeGroupDto,
  DiagramDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  DocumentDto,
  DocumentPageContentType,
  DocumentPageDto,
  DocumentPageType,
  DocumentView,
  FillDto,
  ImageNodeStyleDto,
  InsetsDto,
  LayoutDefinitionDto,
  LayoutDto,
  NodeShape,
  NodeSize,
  NodeVisualType,
  PageElementPosition,
  PowerPointDiagramEdgeDto,
  PowerPointDiagramNodeDto,
  PowerPointExportDto,
  PowerPointExportSlideDto,
  ShapeNodeStyleDto,
  StrokeDto,
  TCellContentType,
  TCellDto,
  TCellOptionsDto,
  TColumnDto,
  TColumnOptionsDto,
  TDataDto,
  TImageCellOptionsDto,
  TRowDto,
  TRowOptionsDto,
  CellAlignment,
} from '@/api/models';
import DiagramExportApiService from '@/api/DiagramExportApiService';
import DecorationStateManager from '@/core/services/DecorationStateManager';
import IndicatorDecorators, {
  IndicatorState,
} from '@/core/styles/decorators/IndicatorDecorators';
import JurisdictionDecorator, {
  JurisdictionDecorationState,
} from '@/core/styles/decorators/JurisdictionDecorator';
import { AnnotationType } from '@/core/common/AnnotationType';
import DiagramUtils from '@/core/utils/DiagramUtils';
import CompositeNodeStyle from '@/core/styles/composite/CompositeNodeStyle';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import IExportProvider from './IExportProvider';
import ExportOptions from '@/core/services/export/ExportOptions';
import b64toBlob from '@/core/services/graph/b64ToBlob';
import ExportUtils from '@/core/services/export/ExportUtils';
import { ExportFormat } from '@/core/services/export/ExportFormat';
import BackgroundGraphService from '@/core/services/graph/BackgroundGraphService';
import ContentPagination from '@/core/services/export/ContentPagination';
import DataPropertiesDecorator from '@/core/styles/decorators/DataPropertiesDecorator';
import ExportPage from '@/core/services/export/ExportPage';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import INodeLabelData from '@/core/services/graph/serialization/INodeLabelData';
import IExportResult from './IExportResult';
import ILegendDefinition from '@/components/DiagramLegend/ILegendDefinition';
import ExportConfig from '@/core/config/ExportConfig';
import cloneDeep from 'lodash/cloneDeep';
import ZoomService from '@/core/services/graph/ZoomService';
import EdgePortGenerator from '@/core/services/EdgePortGenerator';
import diagramConfig from '@/core/config/diagram.definition.config';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import JPoint from '@/core/common/JPoint';
import JSize from '@/core/common/JSize';
import LegendAsImageProvider from '@/core/services/export/additional-element-providers/LegendAsImageProvider';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import DocumentService from '../../document/DocumentService';
import JInsets from '@/core/common/JInsets';
import LayoutItem from '@/components/LayoutEditor/Items/LayoutItem';
import { ZERO_WIDTH_SPACE } from '@/core/utils/CKEditorUtils';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import { LayoutForView } from '@/components/DiagramLegend/LayoutForViewOptions';
import GroupingVisual, {
  GroupOptions,
} from '../../graph/grouping/GroupingVisual';
import { DiagramSize } from '@/view/pages/document/DiagramSize';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT_VIEW,
} from '@/core/services/store/document.module';
import Vue from 'vue';
import { encodeHtml } from '@/core/utils/html.utils';
import { HtmlStylesToInlineOptions } from '../HtmlStylesToInlineOptions';

type IPageLayoutDefinitions = {
  headerLayout?: LayoutDefinitionDto;
  footerLayout?: LayoutDefinitionDto;
  backgroundLayout?: LayoutDefinitionDto;
  titleLayout?: LayoutDefinitionDto;
  bodyLayout?: LayoutDefinitionDto;
};

export default class PowerPointExportProvider implements IExportProvider {
  private _fileExtension = 'pptx';
  private _mimeType = 'application/vnd.ms-powerpoint';

  // In pixels
  private _defaultLegendTableOptions = {
    imageColumnWidth: 30,
    imageColumnImageWidth: 25,
    labelColumnWidth: 140,
    headerRowHeight: 30,
    headerFontSize: 0,
    rowHeight: 30,
    minImageColumnWidth: 8,
    minLabelColumnWidth: 16,
    minRowHeight: 8,
    imageHeight: 30,
  };
  private groupingVisual: GroupingVisual;

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    throw new Error('Not supported');
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    const request = await this.getExportRequest(options);
    const result = await this.getExport(request);

    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      result: b64toBlob(result, this._mimeType),
    };
  }

  private async getExportRequest(
    options: ExportOptions
  ): Promise<PowerPointExportDto> {
    const logo = options.document?.attachments?.find(
      (a) => a.attachmentType == AttachmentType.Logo
    );
    const totalPages = DocumentService.getTotalPagesCount();

    const request: PowerPointExportDto = {
      slides: [],
      headerStyle: options.document.hasSteps
        ? options.document.headerStyle
        : null,
      footerStyle: options.document.hasSteps
        ? options.document.footerStyle
        : null,
      logoFileAttachmentId: logo?.fileAttachment?.fileId,
      logoPosition: options.document.logoPosition,
      pageStyle: options.document.pageStyle,
      defaultDiagramFont: {
        fontSize: diagramConfig.defaultFontSize,
        fontFamily: diagramConfig.defaultFontFamily,
        fontWeight: diagramConfig.defaultFontWeight,
        fontStyle: diagramConfig.defaultFontStyle,
        textDecoration: diagramConfig.defaultTextDecoration,
      },
      defaultContentFont: ExportConfig.defaultContentFontStyle,
    };

    let pageNumber = 0;
    for (const exportPage of options.pages) {
      pageNumber++;
      options.metadata.currentPage = exportPage;
      let slideRequest = {} as PowerPointExportSlideDto;

      const includeLegend = ExportUtils.shouldIncludeLegend(
        options.document,
        exportPage.page
      );

      if (exportPage.page.contentType === DocumentPageContentType.Layout) {
        const slideRequest = await this.getPowerPointExportSlideDto(options);
        const layoutDefinitions = await this.getLayouts(
          options.document,
          exportPage
        );
        slideRequest.bodyLayout = layoutDefinitions.bodyLayout;
        slideRequest.documentPageType = DocumentPageType.Content;
        slideRequest.documentPageContentType = DocumentPageContentType.Layout;
        slideRequest.diagramPosition = null;
        request.slides.push(slideRequest);
      } else if (exportPage.page.contentType == DocumentPageContentType.Html) {
        const subPagesContent = await this.getHtmlPageContent(
          options.document,
          exportPage.page,
          exportPage.subPageIndex
        );

        for (let index = 0; index < subPagesContent.length; index++) {
          if (index > 0) {
            pageNumber++;
          }
          const diagram =
            exportPage.page.subPageRefs?.find((x) => x.subPageIndex == index)
              ?.diagram ?? exportPage.page.diagram;

          const sourceGraph = diagram
            ? BackgroundGraphService.createGraph(diagram)
            : null;
          const subPageSlideRequest = await this.getPowerPointExportSlideDto(
            options,
            sourceGraph,
            index
          );

          if (includeLegend) {
            subPageSlideRequest.tables = [
              await this.legendToTable(
                options,
                JSON.parse(diagram.legend),
                subPageSlideRequest.pageMargins
              ),
            ];
          }

          const layoutDefinitions = await this.getLayouts(
            options.document,
            exportPage,
            index
          );
          subPageSlideRequest.headerLayout = layoutDefinitions.headerLayout;
          subPageSlideRequest.footerLayout = layoutDefinitions.footerLayout;
          subPageSlideRequest.backgroundLayout =
            layoutDefinitions.backgroundLayout;
          subPageSlideRequest.titleLayout = layoutDefinitions.titleLayout;
          subPageSlideRequest.forceReservePageTitleSpace =
            this.forceReservePageTitleSpace(
              options.document,
              exportPage,
              index
            );

          const layoutItems = [
            ...subPageSlideRequest.headerLayout.items,
            ...subPageSlideRequest.footerLayout.items,
          ] as LayoutItem[];
          LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
            layoutItems,
            pageNumber,
            totalPages,
            exportPage.page.layoutType,
            false
          );
          request.slides.push({
            ...subPageSlideRequest,
            htmlContent: subPagesContent[index],
            slideNumber: pageNumber,
          });
        }
      } else if (
        exportPage.page.contentType == DocumentPageContentType.MasterLegend
      ) {
        const slideRequest = await this.getPowerPointExportSlideDto(options);

        const layoutDefinitions = await this.getLayouts(
          options.document,
          exportPage
        );
        slideRequest.headerLayout = layoutDefinitions.headerLayout;
        slideRequest.footerLayout = layoutDefinitions.footerLayout;
        slideRequest.backgroundLayout = layoutDefinitions.backgroundLayout;
        slideRequest.titleLayout = layoutDefinitions.titleLayout;
        slideRequest.bodyLayout = layoutDefinitions.bodyLayout;

        const layoutItems = [
          ...slideRequest.headerLayout.items,
          ...slideRequest.footerLayout.items,
        ] as LayoutItem[];
        LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
          layoutItems,
          pageNumber,
          totalPages,
          exportPage.page.layoutType,
          false
        );

        slideRequest.tables = [
          await this.legendToTable(
            options,
            JSON.parse(exportPage.page.content),
            slideRequest.pageMargins
          ),
        ];

        request.slides.push(slideRequest);
      } else {
        const sourceGraph = exportPage.page.diagram
          ? BackgroundGraphService.createGraph(exportPage.page.diagram)
          : null;
        slideRequest = await this.getPowerPointExportSlideDto(
          options,
          sourceGraph
        );
        if (includeLegend) {
          const legendDefinition = JSON.parse(
            exportPage.page.diagram.legend
          ) as ILegendDefinition;
          /* Now we need to inline all the images*/
          for (const item of legendDefinition.items) {
            item.symbol = (await convertImageSrcToPng(item.symbol, false)).src;
          }
          slideRequest.showLegend = includeLegend;
          slideRequest.tables = [
            await this.legendToTable(
              options,
              JSON.parse(exportPage.page.diagram.legend),
              slideRequest.pageMargins
            ),
          ];
        }

        const layoutDefinitions = await this.getLayouts(
          options.document,
          exportPage
        );
        slideRequest.headerLayout = layoutDefinitions.headerLayout;
        slideRequest.footerLayout = layoutDefinitions.footerLayout;
        slideRequest.backgroundLayout = layoutDefinitions.backgroundLayout;
        slideRequest.titleLayout = layoutDefinitions.titleLayout;

        if (options.document.hasSteps) {
          slideRequest.slideNumber = pageNumber;
          const layoutItems = [
            ...slideRequest.headerLayout.items,
            ...slideRequest.footerLayout.items,
          ] as LayoutItem[];
          LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
            layoutItems,
            pageNumber,
            totalPages,
            exportPage.page.layoutType,
            false
          );
        }
        request.slides.push(slideRequest);
      }
    }
    return request;
  }

  private async getLayouts(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): Promise<IPageLayoutDefinitions> {
    const pageWidth = document.pageStyle.width;

    if (exportPage.page.contentType == DocumentPageContentType.Layout) {
      const bodyLayout = (await LayoutUtils.cropLayoutItems(
        exportPage.page.bodyLayout,
        new JSize(pageWidth, document.pageStyle.height)
      )) as LayoutItem[];

      return {
        bodyLayout: {
          items: bodyLayout,
          width: pageWidth,
          height: document.pageStyle.height,
        },
      };
    }

    const titleHeight = this.getPageTitleHeight(document, exportPage, index);

    const headerLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageHeaderLayout(document, exportPage, index),
      new JSize(pageWidth, document.headerStyle.height)
    );
    const titleLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageTitleLayout(document, exportPage, index),
      new JSize(pageWidth, titleHeight)
    );
    const footerLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageFooterLayout(document, exportPage, index),
      new JSize(pageWidth, document.footerStyle.height)
    );
    const backgroundLayoutItems = await LayoutUtils.cropLayoutItems(
      this.getPageBackgroundLayout(document, exportPage, index),
      new JSize(pageWidth, document.pageStyle.height)
    );
    let bodyLayoutItems: LayoutItem[];
    if (exportPage.page.contentType == DocumentPageContentType.MasterLegend)
      bodyLayoutItems = await LayoutUtils.cropLayoutItems(
        this.getPageBodyLayout(document, exportPage),
        new JSize(pageWidth, document.pageStyle.height)
      );

    return {
      headerLayout: {
        items: headerLayoutItems,
        width: pageWidth,
        height: document.headerStyle.height ?? 0,
      },
      footerLayout: {
        items: footerLayoutItems,
        width: pageWidth,
        height: document.footerStyle.height ?? 0,
      },
      backgroundLayout: {
        items: backgroundLayoutItems,
        width: pageWidth,
        height: document.pageStyle.height,
      },
      titleLayout: {
        items: titleLayoutItems,
        width: pageWidth,
        height: titleHeight,
      },
      bodyLayout: {
        items: bodyLayoutItems,
        width: pageWidth,
        height: document.pageStyle.height,
      },
    };
  }

  private setLegendDefaults(legendElement: HTMLElement): void {
    const legendItem = legendElement.querySelector('.legend-item');
    const imageColumnWidth =
      (legendItem.querySelector('.item-symbol')?.clientWidth ??
        this._defaultLegendTableOptions.imageColumnWidth) *
      ExportConfig.pointToPixelFactor;
    const imageColumnImageWidth =
      (legendItem.querySelector('img')?.clientWidth ??
        this._defaultLegendTableOptions.imageColumnImageWidth) *
      ExportConfig.pointToPixelFactor;
    const rowHeight =
      legendItem.clientHeight ??
      this._defaultLegendTableOptions.rowHeight *
        ExportConfig.pointToPixelFactor;
    const imageHeight =
      (legendItem.querySelector('.item-symbol')?.clientHeight ??
        this._defaultLegendTableOptions.imageHeight) *
      ExportConfig.pointToPixelFactor;

    let header = legendElement.querySelector('.legend-header');
    let headerRowHeight = header?.clientHeight ?? 0;
    let headerFontSize = this._defaultLegendTableOptions.headerFontSize;

    if (header) {
      const style = getComputedStyle(header);
      headerFontSize = Math.ceil(
        parseFloat(style.getPropertyValue('font-size')) /
          ExportConfig.pointToPixelFactor
      );

      const separator = legendElement.querySelector(
        '.diagram-legend-separator'
      );
      if (separator) {
        const style = getComputedStyle(separator);
        const margin = Math.ceil(
          parseFloat(style.marginTop) + parseFloat(style.marginBottom)
        );

        if (Number.isInteger(margin)) {
          headerRowHeight += margin;
        }
        headerRowHeight += separator.clientHeight;
      }
    }

    this._defaultLegendTableOptions = {
      ...this._defaultLegendTableOptions,
      imageColumnImageWidth,
      imageColumnWidth,
      headerRowHeight,
      headerFontSize,
      rowHeight,
      imageHeight,
    };
  }

  private async getSizeData(
    options: ExportOptions,
    pageSize: JSize
  ): Promise<{
    rowHeightData: number[];
    columnWidthData: number[];
    fontSize: number;
    layout: LayoutForView;
    isPrintView: boolean;
  }> {
    const rowHeightData: number[] = [];
    const columnWidthData: number[] = [];

    const provider = new LegendAsImageProvider(
      options,
      options.metadata.currentPage
    );
    const isWebView =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`] ===
      DocumentView.Web;
    const { legendContainer, legendInstance } = await provider.renderLegend(
      options.metadata.currentPage.page,
      options.metadata.currentPage.page.diagram,
      DiagramSize.Large,
      isWebView ? fitRectIntoBounds(provider.getParentSize(), pageSize) : null
    );

    let parentSize = isWebView ? legendInstance.parentSize : pageSize;
    const borderSize = ExportConfig.pointToPixelFactor;
    const layout: LayoutForView = {
      ...legendInstance.layout,
      position: new JPoint(
        legendInstance.layout.position.x * parentSize.width + borderSize,
        legendInstance.layout.position.y * parentSize.height + borderSize
      ),
      size: new JSize(
        legendInstance.layout.size.width * parentSize.width + borderSize * 2,
        legendInstance.layout.size.height * parentSize.height + borderSize * 2
      ),
    };

    let row = 0;
    let col = 0;

    this.setLegendDefaults(legendContainer as HTMLElement);

    // Compute the width of each row and each column based on every table cell;
    for (const legendItem of legendContainer.querySelectorAll('.legend-item')) {
      if (col === legendInstance.columnCount) {
        col = 0;
        row += 1;
      }

      columnWidthData[col] = Math.max(
        columnWidthData[col] || 0,
        legendItem.clientWidth -
          this._defaultLegendTableOptions.imageColumnWidth
      );

      rowHeightData[row] = Math.max(
        rowHeightData[row] || 0,
        legendItem.clientHeight -
          (legendItem.clientHeight -
            this._defaultLegendTableOptions.imageHeight) /
            2
      );

      col += 1;
    }

    const legendItemLabel = legendContainer.querySelector('.legend-item-label');
    const fontSize = Math.ceil(
      parseFloat(
        getComputedStyle(legendItemLabel).getPropertyValue('font-size')
      ) / ExportConfig.pointToPixelFactor
    );

    legendContainer.remove();
    legendInstance.$destroy();

    return {
      rowHeightData,
      columnWidthData,
      fontSize,
      layout,
      isPrintView: legendInstance.isPrintView,
    };
  }
  private async legendToTable(
    options: ExportOptions,
    legend: ILegendDefinition,
    pageMargins: InsetsDto
  ): Promise<TDataDto> {
    if (!legend) {
      return null;
    }

    const availablePageSize = this.calculateAvailablePageSize(
      options,
      pageMargins
    );

    // Not scaled sizes
    let { rowHeightData, columnWidthData, fontSize, layout, isPrintView } =
      await this.getSizeData(options, availablePageSize);

    const headerFontSize = this._defaultLegendTableOptions.headerFontSize;

    const padding =
      (this._defaultLegendTableOptions.imageColumnWidth -
        this._defaultLegendTableOptions.imageColumnImageWidth) /
      2;

    const imageColumnOptions: TColumnOptionsDto = {
      width: this._defaultLegendTableOptions.imageColumnWidth,
      insets: new InsetsDto(0, padding, 0, padding),
    };

    const legendTableWidth =
      columnWidthData.reduce((total, currentValue) => total + currentValue) +
      imageColumnOptions.width * columnWidthData.length; // also add image columns width;
    let legendTableHeight =
      rowHeightData.reduce((total, currentValue) => total + currentValue) +
      rowHeightData[0]; // + 1 row as table header;

    // Remove table rows that do not fit within the available page size
    if (legendTableHeight > availablePageSize.height) {
      let index = 0;
      let currentHeight = rowHeightData[0]; // + 1 row as table header;
      for (let i = 0; i < rowHeightData.length; ++i) {
        if (currentHeight >= availablePageSize.height) {
          index = i;
          break;
        }
        currentHeight += rowHeightData[i];
      }
      rowHeightData.splice(index);
      legendTableHeight = currentHeight;
    }

    const scaleMultiplier = this.calculateLegendTableScaleMultiplier(
      availablePageSize,
      legendTableWidth,
      legendTableHeight
    );

    rowHeightData = rowHeightData.map((x) => Math.floor(x * scaleMultiplier));
    columnWidthData = columnWidthData.map((x) =>
      Math.floor(x * scaleMultiplier)
    );

    const tableColumns = columnWidthData.length;
    const tableRows = rowHeightData.length;

    // Must be integer otherwise ppt export will be broken
    fontSize = Math.round(
      (fontSize ?? diagramConfig.defaultFontSize) * scaleMultiplier
    );

    const defaultLabelCellOptions: TCellOptionsDto = {
      fontSize,
    };

    // Must be integer otherwise ppt export will be broken
    imageColumnOptions.width = Math.floor(
      imageColumnOptions.width * scaleMultiplier
    );
    imageColumnOptions.insets = new InsetsDto(
      0,
      imageColumnOptions.insets.left * scaleMultiplier,
      0,
      imageColumnOptions.insets.right * scaleMultiplier
    );
    const data: TDataDto = {
      isLegend: true,
      rows: [],
      columns: [],
      position: isPrintView
        ? layout?.staticPosition ?? PageElementPosition.TopLeft
        : legend.options.layout[DocumentView.Web].staticPosition,
    };

    for (let i = 0; i < tableColumns; i++) {
      data.columns.push(
        new TColumnDto(imageColumnOptions),
        new TColumnDto({
          width: Math.floor(
            columnWidthData[i] * scaleMultiplier ||
              this._defaultLegendTableOptions.labelColumnWidth * scaleMultiplier
          ),
        })
      );
    }

    if (headerFontSize) {
      const headerRow: TRowDto = {
        options: {
          height: Math.round(
            this._defaultLegendTableOptions.headerRowHeight * scaleMultiplier
          ),
          isHeaderRow: true,
        } as TRowOptionsDto,
        cells: [
          {
            content: {
              options: {
                fontSize: headerFontSize,
                fontFamily: defaultLabelCellOptions.fontFamily,
                backgroundColour: defaultLabelCellOptions.backgroundColour,
                colour: defaultLabelCellOptions.colour,
              },
              contentType: TCellContentType.String,
              data: encodeHtml(legend.options.header),
            },
            horizontalMerge: false,
            gridSpan: tableColumns * 2,
          },
        ],
      };

      for (let i = 0; i < tableColumns * 2 - 1; i++) {
        headerRow.cells.push({
          content: {
            options: defaultLabelCellOptions,
            contentType: TCellContentType.String,
            data: '',
          },
          horizontalMerge: false,
          gridSpan: 0,
        });
      }

      data.rows.push(headerRow);
    }

    data.location = layout.position;

    let legendItems = legend.items.filter((x) => x.show);
    if (options.withFilters) {
      legendItems = legend.items.filter((x) => !x.isFiltered);
    }

    const legendItemsTable = splitArrayIntoChunks(
      legendItems,
      tableColumns,
      tableRows
    );

    for (const [itemRowIndex, itemRow] of legendItemsTable.entries()) {
      const row: TRowDto = {
        options: {
          height: Math.floor(
            rowHeightData[itemRowIndex] ||
              this._defaultLegendTableOptions.rowHeight * scaleMultiplier
          ),
        } as TRowOptionsDto,
        cells: [] as any[],
      };

      for (const item of itemRow) {
        const { src, width, height } = await convertImageSrcToPng(
          item.symbol,
          true
        );

        const imageCellOptions = new TImageCellOptionsDto(
          width * scaleMultiplier,
          height * scaleMultiplier,
          fontSize,
          undefined,
          undefined,
          undefined,
          CellAlignment.Center
        );
        row.cells.push(
          {
            content: {
              contentType: TCellContentType.PngImage,
              data: src,
              options: imageCellOptions,
            },
          },
          {
            content: {
              options: {
                ...defaultLabelCellOptions,
                alignment: CellAlignment.Center,
              },
              contentType: TCellContentType.String,
              data: encodeHtml(item.label),
            },
          }
        );
      }
      data.rows.push(row);
    }
    const lastRowCellCount = data.rows[data.rows.length - 1].cells.length;
    if (lastRowCellCount < tableColumns * 2) {
      data.rows[data.rows.length - 1].cells[lastRowCellCount - 1].gridSpan =
        tableColumns * 2 - lastRowCellCount + 1;
      for (let i = 0; i < tableColumns * 2 - lastRowCellCount; i++) {
        data.rows[data.rows.length - 1].cells.push(
          new TCellDto({
            options: defaultLabelCellOptions,
            contentType: TCellContentType.String,
            data: '',
          })
        );
      }
    }

    return data;
  }

  // Powerpoint Export does not currently support full table layout in headers and footers so we need
  // to convert to something it will handle. Extract <td> data to <p>
  private getPageHeaderLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const subPageRef = DocumentService.subPageHeaderFooterLayoutAvailable
        ? exportPage.page.subPageRefs?.find((sp) => sp.subPageIndex === index)
        : null;
      return subPageRef?.headerLayout
        ? subPageRef.headerLayout
        : exportPage.page.headerLayout;
    }
  }

  private getPageFooterLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const subPageRef = DocumentService.subPageHeaderFooterLayoutAvailable
        ? exportPage.page.subPageRefs?.find((sp) => sp.subPageIndex === index)
        : null;
      return subPageRef?.footerLayout
        ? subPageRef.footerLayout
        : exportPage.page.footerLayout;
    }
  }

  private getPageTitleLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const subPageRef = exportPage.page.subPageRefs?.find(
        (sp) => sp.subPageIndex === index
      );
      return subPageRef ? subPageRef.titleLayout : exportPage.page.titleLayout;
    }
  }

  private getPageBackgroundLayout(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): string {
    if (document.hasSteps) {
      const subPageRef = DocumentService.subPageHeaderFooterLayoutAvailable
        ? exportPage.page.subPageRefs?.find((sp) => sp.subPageIndex === index)
        : null;
      return subPageRef?.backgroundLayout
        ? subPageRef.backgroundLayout
        : exportPage.page.backgroundLayout;
    }
  }

  private getPageBodyLayout(
    document: DocumentDto,
    exportPage: ExportPage
  ): string {
    if (document.hasSteps) {
      return exportPage.page.bodyLayout;
    }
  }

  private getPageTitleHeight(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): number {
    if (document.hasSteps) {
      const subPageRef = exportPage.page.subPageRefs?.find(
        (sp) => sp.subPageIndex === index
      );
      return subPageRef
        ? ExportUtils.calculatePageTitleHeight(subPageRef, subPageRef.showTitle)
        : ExportUtils.calculatePageTitleHeight(
            exportPage.page,
            exportPage.page.showTitle
          );
    }
    return 0;
  }

  private forceReservePageTitleSpace(
    document: DocumentDto,
    exportPage: ExportPage,
    index?: number
  ): boolean {
    if (document.hasSteps) {
      const subPageRef = exportPage.page.subPageRefs?.find(
        (sp) => sp.subPageIndex === index
      );
      return subPageRef
        ? subPageRef.maxTitleHeight > 0
        : exportPage.page.maxTitleHeight > 0;
    }
    return false;
  }

  private async getPowerPointExportSlideDto(
    options: ExportOptions,
    graph: IGraph = null,
    subPageIndex: number = null
  ): Promise<PowerPointExportSlideDto> {
    const document = options.document;
    const page = options.metadata.currentPage.page;
    const margins = ExportUtils.calculatePageMargins(document, page);
    const pageMargins = new InsetsDto(
      Math.round(margins.top),
      Math.round(margins.left),
      Math.round(margins.bottom),
      Math.round(margins.right)
    );

    const showHeader =
      page.showHeader ||
      (LayoutWidgetUtils.contentAreaContainsWidgets(
        page,
        DocumentContentArea.Header,
        true
      ) &&
        page.contentType != DocumentPageContentType.Layout);
    const showFooter =
      page.showFooter ||
      (LayoutWidgetUtils.contentAreaContainsWidgets(
        page,
        DocumentContentArea.Footer,
        true
      ) &&
        page.contentType != DocumentPageContentType.Layout);

    const subPageRef = page.subPageRefs?.find(
      (sp) => sp.subPageIndex === subPageIndex
    );
    const showTitle =
      (subPageRef?.showTitle ?? page.showTitle) &&
      page.contentType != DocumentPageContentType.Layout;

    const forceReservePageTitleSpace =
      page.contentType != DocumentPageContentType.Layout &&
      this.forceReservePageTitleSpace(
        document,
        options.metadata.currentPage,
        subPageIndex
      );

    const includeLegend = ExportUtils.shouldIncludeLegend(
      options.document,
      page
    );

    const exportSlide: PowerPointExportSlideDto = {
      pageMargins: pageMargins,
      documentPageType: page.pageType,
      documentPageContentType: page.contentType,
      diagramPosition: page.diagramPosition,
      htmlContentColumns: page.contentColumns,
      htmlContentColumnGap: ExportUtils.calculateHtmlContentGap(document),
      slideWidth: Math.round(
        document.pageStyle.width * ExportConfig.pointToPixelFactor
      ),
      slideHeight: Math.round(
        document.pageStyle.height * ExportConfig.pointToPixelFactor
      ),
      slideNumber: 0,
      showHeader: showHeader,
      showFooter: showFooter,
      showTitle: showTitle,
      showLegend: includeLegend,
      showLogo: ExportUtils.shouldIncludeLogo(document, page),
      showDivider: page.showDivider,
      nodes: [],
      edges: [],
      forceReservePageTitleSpace: forceReservePageTitleSpace,
    };

    if (page.contentType == DocumentPageContentType.Html) {
      exportSlide.htmlContentPadding = ExportUtils.calculatePadding(
        document,
        page,
        'htmlContent'
      ).getEnlarged(ExportConfig.pptContentPaddingAdjustment);
    }

    if (graph) {
      const graphComponent = ExportUtils.copyGraphComponent(
        graph,
        options.withFilters,
        ExportFormat.PowerPoint,
        options.lowDetailDiagram
      );

      let context = graphComponent.createRenderContext();
      this.assignPortDirections(graphComponent.graph);

      const diagram = new DiagramDto(false, 0);
      diagram.groupSettings = {
        margin:
          page.diagram.groupSettings?.margin ??
          diagramConfig.groupingDefaults.margin,
        glueExtent:
          page.diagram.groupSettings?.glueExtent ??
          diagramConfig.groupingDefaults.glueExtent,
        minimumBridgeSize:
          page.diagram.groupSettings?.minimumBridgeSize ??
          diagramConfig.groupingDefaults.minimumBridgeSize,
      };

      DiagramWriter.fromGraphComponent(graphComponent, diagram);

      if (diagram.nodes.some((node) => node.groupUuid)) {
        this.addGroupings(exportSlide, context, diagram);
      }
      for (const diagramNodeDto of diagram.nodes) {
        const elementIndex = diagram.nodes.indexOf(diagramNodeDto);
        const graphNode = this.getNode(graphComponent, diagramNodeDto);
        (diagramNodeDto as PowerPointDiagramNodeDto).children = [];

        switch (diagramNodeDto.style.visualType) {
          case NodeVisualType.JigsawPathShape:
          case NodeVisualType.Shape:
            if (graphNode.tag.annotationType != AnnotationType.Logos) {
              (diagramNodeDto as PowerPointDiagramNodeDto).svgPath =
                graphNode.style.renderer
                  .getShapeGeometry(graphNode, graphNode.style)
                  .getOutline()
                  .createSvgPathData();
            }
            break;
          case NodeVisualType.Composite: {
            const children = [];
            const compositeStyle =
              diagramNodeDto.style as CompositeNodeStyleDto;

            compositeStyle.styleDefinitions.forEach((definition, index) => {
              const currentNode = cloneDeep(diagramNodeDto);

              const shapeNodeStyleDto = {
                ...definition.nodeStyle,
              } as ShapeNodeStyleDto;

              shapeNodeStyleDto.visualType = NodeVisualType.Shape;
              currentNode.style = shapeNodeStyleDto;

              const currentStyle = (
                (graphNode.style as JigsawNodeStyle)
                  .baseStyle as CompositeNodeStyle
              ).styleDefinitions[index].nodeStyle;

              const dummyNode = new SimpleNode();
              dummyNode.layout = graphNode.layout;

              const newInsets = CompositeNodeStyle.calculateInsets(
                definition.insets as Insets,
                dummyNode
              ) as Insets;

              dummyNode.layout = dummyNode.layout
                .toRect()
                .getReduced(newInsets);

              const svgPath = currentStyle.renderer
                .getShapeGeometry(dummyNode, currentStyle)
                .getOutline()
                .createSvgPathData();

              currentNode.layout = new LayoutDto(
                dummyNode.layout.x,
                dummyNode.layout.y,
                dummyNode.layout.width,
                dummyNode.layout.height
              );
              (currentNode as PowerPointDiagramNodeDto).svgPath = svgPath;
              currentNode.id = null;
              currentNode.uuid = null;
              currentNode.groupUuid = null;
              currentNode.label = '';
              children.push(currentNode);
            });
            (diagramNodeDto as PowerPointDiagramNodeDto).children = children;

            break;
          }
          case NodeVisualType.Image:
            diagram.nodes[elementIndex] = await this.setupImageNode(
              graphNode,
              diagramNodeDto
            );

            break;
        }

        if (!diagramNodeDto.data.labelData) {
          diagramNodeDto.data.labelData = {
            textFit:
              graphNode.tag.labelData?.textFit ??
              DiagramUtils.getNodeDefaultTextFit(graphNode.tag.annotationType),
          };
        }
        const nodeLabelData: INodeLabelData = diagramNodeDto.data.labelData;

        if (nodeLabelData && graphNode.labels.size == 1) {
          const label = graphNode.labels.first();
          const geometry = label.layoutParameter.model.getGeometry(
            label,
            label.layoutParameter
          );

          diagramNodeDto.data.labelLayout = {
            anchorX: geometry.anchorX,
            anchorY: geometry.anchorY,
            upX: geometry.upX,
            upY: geometry.upY,
            width: geometry.width,
            height: geometry.height,
          };
        }
      }

      for (const diagramEdgeDto of diagram.edges) {
        diagramEdgeDto.label = await this.getLabel(diagramEdgeDto);
        const graphEdge = this.getEdge(graphComponent, diagramEdgeDto);
        const path = graphEdge.style.renderer
          .getPathGeometry(graphEdge, graphEdge.style)
          .getPath()
          .createSvgPathData();
        const pptEdge = diagramEdgeDto as PowerPointDiagramEdgeDto;

        if (!diagramEdgeDto.data.labelData) {
          diagramEdgeDto.data.labelData = {
            textFit:
              graphEdge.tag.labelData?.textFit ??
              DiagramUtils.getEdgeDefaultTextFit(),
          };
        }
        pptEdge.svgPath = path;
        pptEdge.layout = graphEdge.tag.layout;
        const label = graphEdge.labels.firstOrDefault();
        if (label) {
          pptEdge.labelLayout = new LayoutDto(
            label.layout.anchorX,
            label.layout.anchorY,
            label.layout.width,
            label.layout.height
          );
        }
      }

      await this.processNodes(diagram.nodes, graphComponent);
      await this.processLabels(diagram.nodes, graphComponent);

      const layout = ZoomService.fitDiagram(graphComponent, {
        document: options.document,
        page: options.metadata.currentPage.page,
      });
      const diagramInsets = JInsets.fromDto(layout.insets).getEnlarged(
        ExportConfig.innerDiagramMargins
      );
      exportSlide.diagramInsets = new InsetsDto(
        Math.round(diagramInsets.top / ExportConfig.pointToPixelFactor),
        Math.round(diagramInsets.left / ExportConfig.pointToPixelFactor),
        Math.round(diagramInsets.bottom / ExportConfig.pointToPixelFactor),
        Math.round(diagramInsets.right / ExportConfig.pointToPixelFactor)
      );

      const diagramPadding = ExportUtils.calculatePadding(
        document,
        page,
        'diagram'
      );
      exportSlide.diagramPadding = new InsetsDto(
        Math.round(diagramPadding.top),
        Math.round(diagramPadding.left),
        Math.round(diagramPadding.bottom),
        Math.round(diagramPadding.right)
      );
      exportSlide.nodes = diagram.nodes.filter((x) => !x.isGroupNode);
      exportSlide.edges = diagram.edges.filter(
        (x: DiagramEdgeDto & { layout: Rect }) =>
          !x.layout || x.layout.height > 0 && x.layout.width > 0
      );

      graphComponent.cleanUp();
    }

    return exportSlide;
  }

  private addGroupings(
    exportSlide: PowerPointExportSlideDto,
    context: IRenderContext,
    diagram: DiagramDto
  ): void {
    this.groupingVisual = new GroupingVisual({
      valueProvider: (): GroupOptions => diagram.groupSettings,
    });
    const groupsSvgElement = (
      this.groupingVisual.createVisual(context) as SvgVisualGroup
    ).svgElement;
    const groups = [];

    for (let i = 0; i < groupsSvgElement.children.length; i++) {
      const groupDto = new NodeGroupDto(0);
      const childElement = groupsSvgElement.children[i];
      const groupUuid = childElement.getAttribute('group-uuid');
      const groupNode = diagram.nodes.filter(
        (node) => node.isGroupNode && node.groupUuid == groupUuid
      )[0];

      groupDto.fillColor = groupNode.data.grouping.fillColor;
      groupDto.strokeColor =
        groupNode.data.grouping.strokeColor ??
        diagramConfig.groupingDefaults.strokeColor;

      groupDto.dashType =
        groupNode.data.grouping?.strokeDash ??
        diagramConfig.groupingDefaults.strokeDash;

      groupDto.strokeWidth =
        groupNode.data.grouping?.strokeWidth ??
        diagramConfig.groupingDefaults.strokeWidth;

      groupDto.svgPaths = childElement
        .getAttribute('d')
        .split('Z')
        .filter((d) => d)
        .map((path) => `${path} Z`);
      groups.push(groupDto);
    }
    exportSlide.groups = groups;
  }

  private scaleSize(from: Size, maxWidth?: number, maxHeight?: number): Size {
    if (!maxWidth && !maxHeight)
      throw 'At least one scale factor (toWidth or toHeight) must not be null.';
    if (from.height == 0 || from.width == 0)
      throw 'Cannot scale size from zero.';

    let widthScale: number = null;
    let heightScale: number = null;

    if (maxWidth) {
      widthScale = maxWidth / from.width;
    }
    if (maxHeight) {
      heightScale = maxHeight / from.height;
    }

    const scale = Math.min(
      widthScale ?? heightScale,
      heightScale ?? widthScale
    );

    return new Size(
      Math.floor(from.width * scale),
      Math.ceil(from.height * scale)
    );
  }

  private async setupImageNode(
    graphNode: INode,
    diagramNodeDto: DiagramNodeDto
  ): Promise<PowerPointDiagramNodeDto> {
    const imageNode = { ...diagramNodeDto } as DiagramNodeDto;
    const imageNodeStyleDto = diagramNodeDto.style as ImageNodeStyleDto;
    diagramNodeDto.style = new ShapeNodeStyleDto(
      new FillDto(null),
      new StrokeDto(0, new FillDto(null), null),
      NodeShape.Rectangle,
      NodeSize.Medium,
      NodeVisualType.Shape
    );
    const imageSize = await getImageSize(imageNodeStyleDto.imageUrl);
    imageNode.layout = { ...diagramNodeDto.layout };

    // scale the image to fit
    const newSize = this.scaleSize(
      new Size(imageSize.width, imageSize.height),
      diagramNodeDto.layout.width,
      diagramNodeDto.layout.height
    );

    // apply new dimensions
    imageNode.layout.width = newSize.width;
    imageNode.layout.height = newSize.height;

    // horizontally and vertically align
    const xDiff = diagramNodeDto.layout.width - imageNode.layout.width;
    const yDiff = diagramNodeDto.layout.height - imageNode.layout.height;
    if (xDiff > 0) {
      imageNode.layout.x += xDiff / 2;
    }
    if (yDiff > 0) {
      imageNode.layout.y += yDiff / 2;
    }

    const { src } = await convertImageSrcToPng(
      imageNodeStyleDto.imageUrl,
      false
    );
    imageNodeStyleDto.imageUrl = src;

    return imageNode;
  }

  private async getExport(request: PowerPointExportDto): Promise<string> {
    let exportString: string = null;
    this._fileExtension = 'pptx';

    try {
      const response = await DiagramExportApiService.postPowerPointExport(
        request
      );
      if (response.data.result.base64String.length > 0) {
        exportString = response.data.result.base64String;
      }
    } catch (e) {
      console.error(
        e,
        'Error calling DiagramExportApiService.postPowerPointExport'
      );
    }
    return exportString;
  }

  private async processNodes(
    diagramNodeDtos: DiagramNodeDto[],
    graphComponent: GraphComponent
  ): Promise<void> {
    const groupColorLookup = this.getGroupColorLookup(diagramNodeDtos);

    for (const diagramElementNode of diagramNodeDtos) {
      if (!diagramElementNode.uuid) continue;

      const node = this.getNode(graphComponent, diagramElementNode);

      if (!node || node.tag.isGroupNode) continue;

      if (node.tag.groupUuid && node.tag.groupUuid.length > 0) {
        diagramElementNode.data.groupPadding =
          diagramConfig.groupNodePaddingWidth;
        diagramElementNode.data.groupColor =
          groupColorLookup[node.tag.groupUuid];
      }

      await this.configureIndicators(node, diagramElementNode);
      await this.configureJurisdictionDecorator(node, diagramElementNode);
      await this.configureDataPropertiesDecorator(node, diagramElementNode);
    }
  }

  private getNode(
    graphComponent: GraphComponent,
    element: DiagramNodeDto
  ): INode {
    return graphComponent.graph.nodes.first((x) => x.tag.uuid === element.uuid);
  }

  private getEdge(
    graphComponent: GraphComponent,
    element: DiagramEdgeDto
  ): IEdge {
    return graphComponent.graph.edges.first((x) => x.tag.uuid === element.uuid);
  }

  /**
   Use path geometry to ascertain the direction that the line is coming from.
   Powerpoint needs this when constructing connectors
   */
  private assignPortDirections(graph: IGraph): void {
    const graphEdges = graph.edges;
    graphEdges.forEach((edge) => {
      const sourcePort = edge.sourcePort;
      const targetPort = edge.targetPort;

      const sourcePortSide =
        edge.bends.size === 0
          ? DiagramUtils.getSide(
              JPoint.fromYFiles(targetPort.location),
              JPoint.fromYFiles(sourcePort.location)
            )
          : DiagramUtils.getSide(
              JPoint.fromYFiles(sourcePort.location),
              JPoint.fromYFiles(edge.bends.first().location.toPoint())
            );

      const targetPortSide =
        edge.bends.size === 0
          ? DiagramUtils.getSide(
              JPoint.fromYFiles(sourcePort.location),
              JPoint.fromYFiles(targetPort.location)
            )
          : DiagramUtils.getSide(
              JPoint.fromYFiles(targetPort.location),
              JPoint.fromYFiles(edge.bends.last().location.toPoint())
            );

      edge.tag.sourcePortDirection =
        EdgePortGenerator.convertSideToDirection(sourcePortSide);
      edge.tag.targetPortDirection =
        EdgePortGenerator.convertSideToDirection(targetPortSide);
    });
  }

  private getGroupColorLookup(nodes: DiagramNodeDto[]): Record<string, string> {
    const groupColorLookup = {};
    nodes
      .filter((x) => x.isGroupNode)
      .forEach((x) => {
        let groupColor = x.data.grouping.fillColor
          .replace('rgb(', '')
          .replace(')', '')
          .split(',');

        groupColorLookup[x.groupUuid] = RgbaToHex(
          groupColor[0],
          groupColor[1],
          groupColor[2],
          255
        );
      });
    return groupColorLookup;
  }

  /**
   Composite shapes are created in powerpoint by stacking shapes and grouping.
   Keep the label layout by creating a transparent node on top and assigning the label to that one.
   */
  private async processLabels(
    nodes: DiagramNodeDto[],
    graphComponent: GraphComponent
  ): Promise<void> {
    for (const node of nodes) {
      node.label = await this.getLabel(node);
    }

    nodes
      .filter(
        (x) =>
          (x as PowerPointDiagramNodeDto).children &&
          (x as PowerPointDiagramNodeDto).children.length > 0
      )
      .forEach((node) => {
        const children = (node as PowerPointDiagramNodeDto).children;
        const nodeStyle = node.style as ShapeNodeStyleDto;
        const label = node.label;
        const diagramNode = this.getNode(graphComponent, node);
        const diagramLabel = diagramNode.labels.firstOrDefault();

        if (node.style.visualType == NodeVisualType.Composite) {
          children.push({
            style: new ShapeNodeStyleDto(
              { color: '#00000000' },
              { ...nodeStyle.stroke, fill: { color: '#00000000' } },
              nodeStyle.shape,
              nodeStyle.size,
              nodeStyle.visualType
            ),
            isGroupNode: false,
            label: label,
            layout: {
              x: node.layout.x,
              y: node.layout.y,
              width: node.layout.width,
              height: node.layout.height,
            },
            data: {
              isAnnotation: diagramNode.tag.isAnnotation,
              annotationType: diagramNode.tag.annotationType,
              labelLayout: label
                ? {
                    anchorX: diagramLabel.layout.anchorX,
                    anchorY: diagramLabel.layout.anchorY,
                    width: diagramLabel.layout.width,
                    height: diagramLabel.layout.height,
                  }
                : null,
            },
            uuid: null,
          });
          node.label = '';
        }
      });
  }

  /**
   * Appends a child element to the @param diagramElementNode children if the node has a flag attached
   * @param node the node to query for a flag
   * @param diagramElementNode
   * @returns  promise
   */
  private async configureJurisdictionDecorator(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ): Promise<void> {
    //check if flag toggled as visible
    const isJurisdictionFlagVisible =
      JurisdictionDecorator.INSTANCE.isJurisdictionDecoratorVisible(node);

    //check if state initials toggled as visible
    const isStateInitialsVisible =
      JurisdictionDecorator.INSTANCE.isStateDecoratorVisible(node);

    // no jurisdiction flag or state initials, return out
    if (!isJurisdictionFlagVisible && !isStateInitialsVisible) {
      return;
    }

    // ask the decorator for the correct position
    const layout = JurisdictionDecorator.INSTANCE.getLayout(node);

    const visualWidth =
      isJurisdictionFlagVisible && isStateInitialsVisible
        ? layout.width / 2
        : layout.width;
    // get current state for the flag decorator on the given node
    const state = DecorationStateManager.getState(
      JurisdictionDecorator.INSTANCE,
      node
    ) as JurisdictionDecorationState;

    /*JURISDICTION FLAG*/

    if (isJurisdictionFlagVisible) {
      //create image for jurisdiction
      const imageJurisdiction = await convertSvgToImage(
        state.jurisdictionFlagImage,
        'png'
      );

      //set layout of jurisdictionFlag
      let layoutJurisdiction = {
        x: layout.x,
        y: layout.y,
        width: layout.width,
        height: layout.height,
      };

      // adjust the jurisdiction flag location to accomdate for the state visual if it is toggled on
      if (JurisdictionDecorator.INSTANCE.isStateDecoratorVisible(node)) {
        layoutJurisdiction = {
          x: layout.x,
          y: layout.y,
          width: visualWidth,
          height: layout.height,
        };
      }

      //create DiagramNodeDto for pushing to the PowerpointDiagramNodeDto
      const decoratorNodeJurisdiction: DiagramNodeDto = {
        style: new ImageNodeStyleDto(
          NodeVisualType.Image,
          imageJurisdiction.src
        ),
        isGroupNode: false,
        label: '',
        layout: layoutJurisdiction,
        data: { isAnnotation: true, annotationType: 0 },
      };

      //push node to the PowerPointDiagramNodeDto as child
      (diagramElementNode as PowerPointDiagramNodeDto).children.push(
        decoratorNodeJurisdiction
      );
    }

    /*STATE INITIALS*/
    if (isStateInitialsVisible) {
      //create image
      let circleSize = 72;
      let fontSize = state.stateInitials.length === 3 ? 55 : 80;
      let circleX = 75;
      let circleY = 75;
      let strokeWidth = 6;
      let textOffsetX = 2.1;
      let textOffsetY = 4.25;
      let padding = 2;

      const imageStateSVG =
        DataPropertyUtils.createStateInitialsCircleSvgVisual(
          state.stateInitials,
          circleSize,
          fontSize,
          circleX,
          circleY,
          textOffsetX,
          textOffsetY,
          strokeWidth
        );

      const encodedSvg = encodeURIComponent(
        `<svg xmlns="http://www.w3.org/2000/svg" height="150" width="150">${imageStateSVG.svgElement.outerHTML}</svg>`
      );
      const imageState = await convertSvgToImage(
        `data:image/svg+xml;utf8,${encodedSvg}`,
        'png'
      );

      //create DiagramNodeDto for pushing to the PowerpointDiagramNodeDto as children
      const decoratorNodeState: DiagramNodeDto = {
        style: new ImageNodeStyleDto(
          NodeVisualType.Image,
          imageState.src,
          null,
          150,
          150,
          null
        ),
        isGroupNode: false,
        label: '',
        layout: {
          x: layout.x + padding + (isJurisdictionFlagVisible ? visualWidth : 0),
          y: layout.y,
          width: JurisdictionDecorator.INSTANCE.stateVisualSize * 2,
          height: JurisdictionDecorator.INSTANCE.stateVisualSize * 2,
        },
        data: { isAnnotation: true, annotationType: 0 },
      };

      //push node to the PowerPointDiagramNodeDto as child
      (diagramElementNode as PowerPointDiagramNodeDto).children.push(
        decoratorNodeState
      );
    }
  }

  /**
   * Appends a child element to the @param diagramElementNode children if the node has data properties
   * @param node the node to query for a data properties
   * @param diagramElementNode
   * @returns  promise
   */
  private async configureDataPropertiesDecorator(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ): Promise<void> {
    const isVisible = DataPropertiesDecorator.INSTANCE.shouldBeVisible(node);
    if (!isVisible) {
      return;
    }
    const layout = DataPropertiesDecorator.INSTANCE.getLayout(node);
    const image = await convertSvgToImage(
      DataPropertiesDecorator.INSTANCE.imageStyle.image,
      'png'
    );

    const decoratorNode: DiagramNodeDto = {
      style: new ImageNodeStyleDto(NodeVisualType.Image, image.src),
      isGroupNode: false,
      label: '',
      layout: {
        x: layout.x,
        y: layout.y,
        width: layout.width,
        height: layout.height,
      },
      data: { isAnnotation: true, annotationType: 0 },
    };

    (diagramElementNode as PowerPointDiagramNodeDto).children.push(
      decoratorNode
    );
  }

  private async configureIndicators(
    node: INode,
    diagramElementNode: DiagramNodeDto
  ): Promise<void> {
    const state = DecorationStateManager.getState(
      IndicatorDecorators.INSTANCE,
      node
    ) as IndicatorState;

    if (state.indicators && state.indicators.length > 0) {
      let offset = 0;
      for (let i = 0; i < state.indicators.length; i++) {
        const indicator = state.indicators[i];
        let layout = IndicatorDecorators.INSTANCE.getLayout(
          node.layout,
          i,
          state.indicators.length,
          DiagramUtils.getNodeShape(node) as ShapeNodeShape,
          node.tag.annotationType,
          node.tag.indicatorsPosition
        );

        const image = await convertSvgToImage(indicator, 'png');

        const decoratorNode: DiagramNodeDto = {
          style: new ImageNodeStyleDto(NodeVisualType.Image, image.src),
          isGroupNode: false,
          label: '',
          // cannot clone object;
          layout: {
            x: layout.x,
            y: layout.y,
            width: layout.width,
            height: layout.height,
          },
          data: { isAnnotation: true, annotationType: 0 },
        };

        (diagramElementNode as PowerPointDiagramNodeDto).children.push(
          decoratorNode
        );

        offset = offset + 20; // TODO: find a way to remove this if statement
      }
    }
  }

  private async getHtmlPageContent(
    document: DocumentDto,
    exportPage: DocumentPageDto,
    subPageIndex: number
  ): Promise<string[]> {
    if (!exportPage.content) return [];

    const contentSize = ExportUtils.calculateBodyPartSize(
      document,
      exportPage,
      'content',
      false,
      subPageIndex
    );

    const inlineOptions = new HtmlStylesToInlineOptions();
    inlineOptions.containerClassList = [ExportConfig.pageContentClass];
    inlineOptions.containerSize = contentSize;
    if (exportPage.contentColumns > 0) {
      inlineOptions.containerColumns = exportPage.contentColumns;
      inlineOptions.containerColumnGap =
        ExportUtils.calculateHtmlContentGap(document);
    }
    inlineOptions.setDimensionData = true;
    inlineOptions.dimensionDataDepthLimit = 1;
    inlineOptions.updateLineHeights = 'compute';

    const contentList: string[] = ContentPagination.splitPagedContentIntoPages(
      exportPage.content
    );
    for (let i = 0; i < contentList.length; i++) {
      const content = await ExportUtils.htmlStylesToInline(
        contentList[i],
        inlineOptions
      );
      contentList[i] = content;
    }
    return contentList;
  }

  private calculateAvailablePageSize(
    options: ExportOptions,
    pageMargins: InsetsDto
  ): JSize {
    const page = options.metadata.currentPage.page;
    const document = options.document;
    const isHalfScreen =
      page.pageType == DocumentPageType.Split &&
      page.contentType != DocumentPageContentType.MasterLegend;

    const padding = ExportUtils.calculatePadding(
      document,
      page,
      'diagram'
    ).multiply(ExportConfig.pointToPixelFactor);

    let availableWidth =
      document.pageStyle.width -
      pageMargins.left -
      pageMargins.right -
      padding.left -
      padding.right;

    if (isHalfScreen) {
      availableWidth = availableWidth * document.pageStyle.splitRatio;
    }

    const titleHeight = this.getPageTitleHeight(
      options.document,
      options.metadata.currentPage,
      options.metadata.currentPage.subPageIndex
    );

    const availableHeight =
      document.pageStyle.height -
      pageMargins.bottom -
      pageMargins.top -
      padding.top -
      padding.bottom -
      titleHeight;

    return new JSize(availableWidth, availableHeight).multiply(
      ExportConfig.pointToPixelFactor
    );
  }

  private calculateLegendTableScaleMultiplier(
    availablePageSize: JSize,
    width: number,
    height: number
  ): number {
    let scaleMultiplier = 1;

    if (availablePageSize.width < width) {
      scaleMultiplier = availablePageSize.width / width;
    } else if (availablePageSize.height < height) {
      scaleMultiplier = availablePageSize.height / height;
    }

    return scaleMultiplier;
  }

  private async getLabel(
    item: DiagramNodeDto | DiagramEdgeDto
  ): Promise<string | null> {
    const inlineOptions = new HtmlStylesToInlineOptions();
    inlineOptions.containerClassList = [
      ExportConfig.pageContentClass,
      ExportConfig.diagramContentClass,
    ];
    const label = await ExportUtils.htmlStylesToInline(
      item.label,
      inlineOptions
    );
    if (!label) {
      return null;
    }
    // Remove filler blocks (https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_filler.html)
    return label?.replaceAll(
      /<br[^>]+data-cke-filler[^>]+>/g,
      ZERO_WIDTH_SPACE
    );
  }
}
