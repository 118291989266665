﻿import {
  ImageNodeStyleRenderer,
  IRenderContext,
  SvgVisual,
  Visual,
} from 'yfiles';
import DiagramUtils from '@/core/utils/DiagramUtils';
import SvgDefs from '@/core/services/graph/SvgDefs';

export class JigsawImageNodeStyleRenderer extends ImageNodeStyleRenderer {
  createVisual(context: IRenderContext): SvgVisual {
    let visual = super.createVisual(context) as SvgVisual;
    this.removeAttribute(visual.svgElement as SVGImageElement);
    return visual;
  }

  updateVisual(context: IRenderContext, oldVisual: Visual): SvgVisual {
    let visual = super.updateVisual(context, oldVisual) as SvgVisual;
    DiagramUtils.updateSvgFilteredStyles(
      this.node,
      visual.svgElement,
      SvgDefs.definitions.SEARCH_BLUR
    );
    this.removeAttribute(visual.svgElement as SVGImageElement);
    return visual;
  }

  private removeAttribute(image: SVGImageElement): void {
    image.removeAttribute('preserveAspectRatio');
  }
}
