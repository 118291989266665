import JSize from '@/core/common/JSize';

export class HtmlStylesToInlineOptions {
  recursive = true;
  convertImageUrls = true;
  properties = [
    'font-size',
    'font-family',
    'font-style',
    'font-weight',
    'text-decoration-line',
    'margin-bottom',
  ];
  containerClassList: string[] = [];
  containerSize: JSize = JSize.EMPTY;
  containerColumns = 0;
  containerColumnGap = 0;
  // If set to true, elements that have a font-size style in pixels won't be convert to points
  maintainPixelValues = false;
  // compute is used to compute actual line-height values based on the element content (spans)
  // convert is only used by LegacyPdfExportProvider (https://github.com/bpampuch/pdfmake/issues/845)
  updateLineHeights: 'none' | 'compute' | 'convert' = 'none';
  // Set data-width/height/left/top for html elements (needed for PPT export)
  setDimensionData = false;
  // Recursion depth limit for setting dimension data on elements
  dimensionDataDepthLimit = 0;
}
