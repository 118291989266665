import {
  IInputModeContext,
  Point,
  Visual,
  IRenderContext,
  SvgVisual,
  ArcEdgeStyleRenderer,
  ICanvasContext,
  Rect,
} from 'yfiles';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { isViewPortChanging } from '@/core/services/graph/input-modes/JigsawMoveViewportInputMode';
import { isEdgeVisibleAtZoom } from './IsEdgeVisibleAtZoom';
import { EdgeHitTestHelper } from './EdgeHitTestHelper';
import AutomationUtils, {
  SvgElementTypes,
} from '../../analytics/AutomationUtils';

export class JigsawArcEdgeStyleRenderer extends ArcEdgeStyleRenderer {
  createVisual(context: IRenderContext): Visual | null {
    const visual = super.createVisual(context) as SvgVisual;
    if (visual?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, visual.svgElement);

      AutomationUtils.attachAutomationIdToSvg(
        visual.svgElement,
        SvgElementTypes.Edge
      );
    }
    return visual;
  }

  updateVisual(
    context: IRenderContext,
    oldVisual: Visual | null
  ): Visual | null {
    if (isViewPortChanging(context)) {
      return oldVisual;
    }
    let visual = oldVisual as SvgVisual;
    if (visual?.svgElement) {
      DiagramUtils.updateSvgFilteredStyles(this.edge, visual.svgElement);

      AutomationUtils.attachAutomationIdToSvg(
        visual.svgElement,
        SvgElementTypes.Edge
      );
    }

    return super.updateVisual(context, visual) as SvgVisual;
  }

  private createSegmentedDebugVisual() {
    const pathGroup = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    );

    const path = this.getPath();
    const cursor = path.createCursor();
    cursor.moveNext();
    for (let index = 0; index < path.getSegmentCount(); index++) {
      const color = index % 2 == 0 ? 'red' : 'blue';
      const pathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      pathElement.setAttribute('fill', 'none');
      pathElement.setAttribute('stroke', color);
      pathElement.setAttribute('stroke-width', '2px');
      pathGroup.appendChild(pathElement);
      let data = `M ${cursor.currentEndPointX}, ${cursor.currentEndPointY}`;
      cursor.moveNext();
      data += `L ${cursor.currentEndPointX}, ${cursor.currentEndPointY} Z`;

      pathElement.setAttribute('d', data);
    }
    return new SvgVisual(pathGroup);
  }

  isHit(context: IInputModeContext, location: Point): boolean {
    if (isViewPortChanging(context) || !isEdgeVisibleAtZoom(context)) {
      return false;
    }
    this.configure();
    return EdgeHitTestHelper.isHit(context, location, this);
  }
  isInBox(context: IInputModeContext, rectangle: Rect): boolean {
    if (!isEdgeVisibleAtZoom(context)) {
      return false;
    }
    return super.isInBox(context, rectangle);
  }

  isVisible(context: ICanvasContext, rectangle: Rect): boolean {
    if (isViewPortChanging(context) || !isEdgeVisibleAtZoom(context)) {
      return false;
    }
    return super.isVisible(context, rectangle);
  }
}
