<template>
  <AppModal
    :value="showModal"
    :title="$t('IMPORT')"
    :loading="loading"
    @closed="$emit('closed')"
    min-width="590"
    :content-vertical-scroll="false"
  >
    <div class="w-full">
      <AppSelect
        v-if="importType === ImportType.Excel"
        :value="selectedImportDefinition"
        :options="importDefinitions"
        fullWidth
        label-key="name"
        value-key="id"
        @input="$emit('select:import-definition', $event)"
      />
      <div class="w-full pt-24 pb-12 relative" v-if="showModal">
        <ImportDropzone
          :dropzone-options="dropzoneOptions"
          @error="$emit('error')"
          @success="$emit('success', $event)"
        />
      </div>
    </div>
  </AppModal>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ImportDefinitionDto, ImportType } from '@/api/models';
import ImportDropzone from '@/view/pages/hub/components/imports/ImportDropzone.vue';
import { DropzoneOptions } from 'dropzone';
@Component({
  name: 'ImportFileModal',
  components: { ImportDropzone },
})
export default class ImportFileModal extends Vue {
  @Prop({ type: Boolean })
  showModal: boolean;
  @Prop({ type: Object, required: false })
  dropzoneOptions: DropzoneOptions;
  @Prop({ type: Array, default: () => [] })
  importDefinitions: ImportDefinitionDto[];
  @Prop({ type: Object, default: '' })
  selectedImportDefinition: ImportDefinitionDto;
  @Prop({ type: Boolean, default: false })
  loading: boolean;
  @Prop({ type: Number, required: true })
  importType: ImportType;
  get title(): string {
    return this.$t('IMPORT');
  }
  public ImportType = ImportType;
}
</script>
