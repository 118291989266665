export enum AnalyticsEvent {
  DocumentCreated = 'document-created',
  DocumentCreatedSteps = 'document-created-steps',
  DocumentSaved = 'document-saved',
  DocumentAutoSaved = 'document-auto-saved',
  DocumentExported = 'document-exported',
  DocumentPrinted = 'document-printed',
  DocumentCloned = 'document-cloned',
  DiagramExported = 'diagram-exported',
  DocumentImported = 'document-imported',
  EntityTypeCreated = 'entity-type-created',
}
