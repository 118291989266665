export enum RouterParams {
  documentId = 'documentId',
  themeId = 'themeId',
  stepsDesignSetUuid = 'stepsDesignSetUuid',
  layoutId = 'layoutId',
  folderId = 'folderId',
  importDefinitionId = 'importDefinitionId',
  resetCode = 'resetCode',
  userId = 'userId',
  pageNumber = 'pageNumber',
}
